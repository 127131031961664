import React, { useEffect, useRef } from 'react';
import { GraphServedCustomers } from './';
import { AjaxStateFragment } from './';
import { useTranslation } from 'react-i18next';

function MetricDisplayServedCustomers(props) {
    var displayData = props.displayData;
    const { t } = useTranslation();
    const graph1 = useRef(null);

    const jsonDisplayData = JSON.stringify(displayData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            let canvas1 = graph1.current.querySelector('canvas');
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: 'localizeDashboardCustomerCountTitle',
                    displayData: displayData,
                    render: ( <MetricDisplayServedCustomers displayData={ displayData }/> ),
                    pdfData: {
                        requestTime: displayData._requestTime,
                        type: 'canvas1',
                        title: t('localizeDashboardCustomerCountTitle'),
                        items: [
                            {
                            },
                        ],
                    },
                };
                Object.defineProperty(prevState.items[props.position].pdfData.items[0], 'imageData', {
                    get: function () { return canvas1.toDataURL('image/png'); },
                    enumerable: true,
                } );
                return prevState;
            } );
        }
    }, [ jsonDisplayData, displayData, props, t ] );

    return (
        <div className="metric">
            <div className="row text-center my-1 pt-5 title">
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t('localizeDashboardCustomerCountTitle') }</h4>
                </div>
            </div>
            <div className="row metric-content text-center my-1">
                <AjaxStateFragment _state={ displayData._state } _failed={ displayData._failed } />
                <div className="col-sm-4"></div>
                <div className="col-sm-4">
                    <div className="metric-item-single" ref={ graph1 } >
                        <GraphServedCustomers
                            value1={ displayData.graph1Value } title1={ displayData.graph1Title }
                            value2={ displayData.graph2Value } title2={ displayData.graph2Title }
                            value3={ displayData.graph3Value } title3={ displayData.graph3Title }
                        />
                    </div>
                </div>
                <div className="col-sm-4"></div>
            </div>
        </div>
    );
}

export default MetricDisplayServedCustomers;
