import React from 'react';
import { QbTools, Config } from '../modules';
import { Link } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { BsStar } from 'react-icons/bs';

function BreakdownDisplayServiceLevel(props) {
    var displayData = props.displayData;
    const match = props.match;

    const progressbarWidth = Math.min(displayData.value * 100, 100);
    const progressbarStyle = {
        backgroundColor: Config.graphColors.ServiceLevel[QbTools.analyticsGraphLimits(progressbarWidth, Config.graphDefaults.ServiceLevel)],
        width: progressbarWidth + "%",
    };

    const BreakdownListItemStyle = {
        minHeight: Config.appearance.breakdownListItemHeight,
    };

    return (
        <div className="metric staff-member-container">
            <div className="progressbar" style={ progressbarStyle }></div>
            <Link style={BreakdownListItemStyle} className="text-decoration-none" to={ '/dashboard/breakdown/' + match.params.id + '/' + match.params.pagetype + '/' + encodeURIComponent(displayData.itemId) }>
                <div className="row staff-member align-items-center mx-1">
                    <div className="col-6 d-flex staff-member-name align-items-center">
                        <div className="breakdown-subitem-icon">
                            <BsStar className="icon-with-bg-information" />
                        </div>
                        <div>
                            <div className="pl-2">{ displayData.name }</div>
                            <div className="pl-2 worst-detail">{ displayData.worst.name }</div>
                        </div>
                    </div>
                    <div className="col-6 text-right d-flex justify-content-end align-items-center">
                        <div className="">
                            <div className="breakdown-value pr-1">{ QbTools.formatServiceLevel(displayData.value) }</div>
                            <div className="breakdown-value worst-detail pr-1">{ QbTools.formatServiceLevel(displayData.worst.value) }</div>
                        </div>
                        <div className="breakdown-subitem-link">
                            <BsChevronRight className="icon-with-bg" />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default BreakdownDisplayServiceLevel;
