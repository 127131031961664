import React from 'react';
import { CustomGoogleMap  } from './';
import { AjaxStateFragment } from './';
import { useTranslation } from 'react-i18next';

function MetricProxyServedCustomersAndAverageWaitingTime(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();

    return (
        <div id="google-map" className="metric map-container">
            <div className="row text-center my-1 pt-5 title">
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t('localizeDashboardWaitingTimeAndCustomerCountTitle') }</h4>
                </div>
            </div>
            <div className="row metric-content text-center my-1">
                <div className="col-12">
                    <AjaxStateFragment _state={ metricData._state } _failed={ metricData._failed } />
                    <CustomGoogleMap dataMap={ metricData.leaf?.data } match={ props.match } />
                </div>
            </div>
        </div>
    );
}

export default MetricProxyServedCustomersAndAverageWaitingTime;
