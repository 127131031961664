import React from 'react';

function Footer() {
    return (
        <div className="footer">
        </div>
    );
}

export default Footer;
