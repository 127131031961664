//
//  QBDashboardFigureServiceCompare.js
//  qboard
//
//  Created by Zoltan Meszaros on 2020. 05. 25..
//  Copyright © 2020 Xperion. All rights reserved.
//
//  Generated by PaintCode
//  http://www.paintcodeapp.com
//



//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    QBDashboardFigureServiceCompare.drawService(canvas, compareUnit, referenceValue, compareValue1, compareValue2, compareValue3, overallValue, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//
// Available Utilities:
//    QBDashboardFigureServiceCompare.clearCanvas(canvas)
//    QBDashboardFigureServiceCompare.makeRect(x, y, width, height)


//// Create StyleKit Object
var QBDashboardFigureServiceCompare = {};
(function() {

    //// Drawing Methods

    function drawService(canvas, compareUnit, referenceValue, compareValue1, compareValue2, compareValue3, overallValue, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        //var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 210, 70), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 210, resizedFrame.h / 70);


        //// Color Declarations
        var background = 'rgba(82, 189, 204, 0.19)';
        var text = 'rgba(173, 163, 163, 1)';
        var dial = 'rgba(0, 191, 255, 1)';
        var green = 'rgba(22, 162, 14, 1)';
        var red = 'rgba(254, 0, 0, 1)';

        //// Variable Declarations
        var compareOffset1 = '-1 ' + compareUnit + ':';
        var compareOffset2 = '-2 ' + compareUnit + ':';
        var compareOffset3 = '-3 ' + compareUnit + ':';
        var compareDifference1 = compareValue1 - referenceValue;
        var comparePercent1 = 100 * (referenceValue > 0 ? compareDifference1 / referenceValue : 0);
        var compareColor1 = compareDifference1 > 0 ? green : red;
        var compareAlpha1 = compareDifference1 === 0 ? 0 : Math.min(1, 0.2 + Math.abs(comparePercent1 / 100));
        var compareRotation1 = compareDifference1 > 0 ? 0 : 180;
        var compareText1 = (compareDifference1 >= 0 ? '+' : '') + Math.round(compareDifference1) + ' (' + (compareDifference1 >= 0 ? '+' : '') + Math.round(comparePercent1) + '%)';
        var compareDifference2 = compareValue2 - referenceValue;
        var comparePercent2 = 100 * (referenceValue > 0 ? compareDifference2 / referenceValue : 0);
        var compareColor2 = compareDifference2 > 0 ? green : red;
        var compareAlpha2 = compareDifference2 === 0 ? 0 : Math.min(1, 0.2 + Math.abs(comparePercent2 / 100));
        var compareRotation2 = compareDifference2 > 0 ? 0 : 180;
        var compareText2 = (compareDifference2 >= 0 ? '+' : '') + Math.round(compareDifference2) + ' (' + (compareDifference2 >= 0 ? '+' : '') + Math.round(comparePercent2) + '%)';
        var compareDifference3 = compareValue3 - referenceValue;
        var comparePercent3 = 100 * (referenceValue > 0 ? compareDifference3 / referenceValue : 0);
        var compareColor3 = compareDifference3 > 0 ? green : red;
        var compareAlpha3 = compareDifference3 === 0 ? 0 : Math.min(1, 0.2 + Math.abs(comparePercent3 / 100));
        var compareRotation3 = compareDifference3 > 0 ? 0 : 180;
        var compareText3 = (compareDifference3 >= 0 ? '+' : '') + Math.round(compareDifference3) + ' (' + (compareDifference3 >= 0 ? '+' : '') + Math.round(comparePercent3) + '%)';
        var text_result = ('' + Math.round(referenceValue < 0 ? 0 : (referenceValue > overallValue ? overallValue : referenceValue)));
        var referencePercent = 100 * (overallValue > 0 ? referenceValue / overallValue : 0);
        var text_percent = ('' + Math.round(referencePercent)) + '%';
        var alpha = referencePercent >= 0 ? 1 : 0.215;
        var progress = referencePercent < 0 ? 0 : (referencePercent > 100 ? 100 : referencePercent);
        var angle_result = -1 * progress / 100 * 360 + 90;

        //// Group
        //// data_background Drawing
        context.save();
        context.globalAlpha = alpha;
        oval(context, 141, 2, 66, 66);
        context.strokeStyle = background;
        context.lineWidth = 3.5;
        context.stroke();
        context.restore();


        //// data_oval Drawing
        context.save();
        context.globalAlpha = alpha;
        arc(context, 141, 2, 66, 66, -90, -angle_result, false);
        context.strokeStyle = dial;
        context.lineWidth = 3.5;
        context.stroke();
        context.restore();


        //// data_text Drawing
        context.save();
        context.globalAlpha = alpha;
        var data_textRect = makeRect(141, 17, 66, 23);
        context.fillStyle = text;
        context.font = '15px HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        var data_textTotalHeight = 15 * 1.3;
        context.fillText(text_result, data_textRect.x + data_textRect.w/2, data_textRect.y + 15 + data_textRect.h / 2 - data_textTotalHeight / 2);
        context.restore();


        //// data_text 2 Drawing
        context.save();
        context.globalAlpha = alpha;
        var data_text2Rect = makeRect(147, 37, 60, 18);
        context.fillStyle = text;
        context.font = '10px HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        var data_text2TotalHeight = 10 * 1.3;
        context.fillText(text_percent, data_text2Rect.x + data_text2Rect.w/2, data_text2Rect.y + 10 + data_text2Rect.h / 2 - data_text2TotalHeight / 2);
        context.restore();




        //// Bezier Drawing
        context.beginPath();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Group 2
        //// Bezier 4 Drawing
        context.save();
        context.translate(51, 10.5);
        context.rotate(-compareRotation1 * Math.PI / 180);

        context.save();
        context.globalAlpha = compareAlpha1;
        context.beginPath();
        context.moveTo(-0, 8.5);
        context.bezierCurveTo(-0, -8.5, -0, -8.5, -0, -8.5);
        context.moveTo(-4, -2.83);
        context.lineTo(-0, -8.5);
        context.lineTo(4, -2.83);
        context.strokeStyle = compareColor1;
        context.lineWidth = 2.5;
        context.lineCap = 'round';
        context.stroke();
        context.restore();

        context.restore();


        //// Text 2 Drawing
        var text2Rect = makeRect(57, 2, 97, 17);
        context.fillStyle = text;
        context.font = '10px -apple-system, "Helvetica Neue", Helvetica, sans-serif';
        context.textAlign = 'left';
        var text2TotalHeight = 10 * 1.3;
        context.fillText(compareText1, text2Rect.x, text2Rect.y + 10 + text2Rect.h / 2 - text2TotalHeight / 2);


        //// Text 5 Drawing
        var text5Rect = makeRect(3, 2, 39, 17);
        context.fillStyle = 'rgb(85, 85, 85)';
        context.font = '8px Courier, monospace';
        context.textAlign = 'right';
        var text5TotalHeight = 8 * 1.3;
        context.fillText(compareOffset1, text5Rect.x + text5Rect.w, text5Rect.y + 8 + text5Rect.h / 2 - text5TotalHeight / 2);




        //// Group 3
        //// Bezier 2 Drawing
        context.save();
        context.translate(50.98, 34);
        context.rotate(-compareRotation2 * Math.PI / 180);

        context.save();
        context.globalAlpha = compareAlpha2;
        context.beginPath();
        context.moveTo(0, 8.5);
        context.bezierCurveTo(0, -8.5, 0, -8.5, 0, -8.5);
        context.moveTo(-3.98, -2.83);
        context.lineTo(0, -8.5);
        context.lineTo(3.98, -2.83);
        context.strokeStyle = compareColor2;
        context.lineWidth = 3;
        context.lineCap = 'round';
        context.stroke();
        context.restore();

        context.restore();


        //// Text 3 Drawing
        var text3Rect = makeRect(57.5, 26, 96, 17);
        context.fillStyle = text;
        context.font = '10px -apple-system, "Helvetica Neue", Helvetica, sans-serif';
        context.textAlign = 'left';
        var text3TotalHeight = 10 * 1.3;
        context.fillText(compareText2, text3Rect.x, text3Rect.y + 10 + text3Rect.h / 2 - text3TotalHeight / 2);


        //// Text 6 Drawing
        var text6Rect = makeRect(3, 26, 39, 17);
        context.fillStyle = 'rgb(85, 85, 85)';
        context.font = '8px Courier, monospace';
        context.textAlign = 'right';
        var text6TotalHeight = 8 * 1.3;
        context.fillText(compareOffset2, text6Rect.x + text6Rect.w, text6Rect.y + 8 + text6Rect.h / 2 - text6TotalHeight / 2);




        //// Group 4
        //// Bezier 3 Drawing
        context.save();
        context.translate(50.96, 58.5);
        context.rotate(-compareRotation3 * Math.PI / 180);

        context.save();
        context.globalAlpha = compareAlpha3;
        context.beginPath();
        context.moveTo(0, 8.5);
        context.bezierCurveTo(0, -8.5, 0, -8.5, 0, -8.5);
        context.moveTo(-3.96, -2.83);
        context.lineTo(0, -8.5);
        context.lineTo(3.96, -2.83);
        context.strokeStyle = compareColor3;
        context.lineWidth = 2.5;
        context.lineCap = 'round';
        context.stroke();
        context.restore();

        context.restore();


        //// Text 4 Drawing
        var text4Rect = makeRect(58, 50, 96, 17);
        context.fillStyle = text;
        context.font = '10px -apple-system, "Helvetica Neue", Helvetica, sans-serif';
        context.textAlign = 'left';
        var text4TotalHeight = 10 * 1.3;
        context.fillText(compareText3, text4Rect.x, text4Rect.y + 10 + text4Rect.h / 2 - text4TotalHeight / 2);


        //// Text 7 Drawing
        var text7Rect = makeRect(3, 50, 39, 17);
        context.fillStyle = 'rgb(85, 85, 85)';
        context.font = '8px Courier, monospace';
        context.textAlign = 'right';
        var text7TotalHeight = 8 * 1.3;
        context.fillText(compareOffset3, text7Rect.x + text7Rect.w, text7Rect.y + 8 + text7Rect.h / 2 - text7TotalHeight / 2);
        
        context.restore();

    }

    //// Infrastructure

    function clearCanvas(canvas) {
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    // Possible arguments for 'resizing' parameter are:
    //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
    //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
    //   'stretch': The content is stretched to match the entire target rectangle.
    //   'center': The content is centered in the target rectangle, but it is NOT resized.
    function applyResizingBehavior(resizing, rect, targetRect) {
        if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
            return rect;
        }

        var scales = makeSize(0, 0);
        scales.w = Math.abs(targetRect.w / rect.w);
        scales.h = Math.abs(targetRect.h / rect.h);

        switch (resizing) {
            case 'aspectfit': {
                scales.w = Math.min(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'aspectfill': {
                scales.w = Math.max(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'stretch':
            case undefined:
                break;
            case 'center': {
                scales.w = 1;
                scales.h = 1;
                break;
            }
            default:
                throw new Error('Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.');
        }

        var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h));
        result.w *= scales.w;
        result.h *= scales.h;
        result.x = targetRect.x + (targetRect.w - result.w) / 2;
        result.y = targetRect.y + (targetRect.h - result.h) / 2;
        return result;
    }

    function oval(context, x, y, w, h) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, 0, 2*Math.PI, false);
        context.closePath();
        context.restore();
    }

    function arc(context, x, y, w, h, startAngle, endAngle, isClosed) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, Math.PI/180*startAngle, Math.PI/180*endAngle, false);
        if (isClosed)
        {
            context.lineTo(1, 1);
            context.closePath();
        }
        context.restore();
    }

    function makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    function equalRects(r1, r2) {
        return r1.x === r2.x && r1.y === r2.y && r1.w === r2.w && r1.h === r2.h;
    }

    function makeSize(w, h) {
        return { w: w, h: h };
    }

    function initializeCanvas(canvas) {
        if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        var context = canvas.getContext('2d');

        var devicePixelRatio = window.devicePixelRatio || 1;
        var backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        var pixelRatio = devicePixelRatio / backingStorePixelRatio;

        //canvas.style.width = canvas.width + 'px';
        //canvas.style.height = canvas.height + 'px';
        canvas.width *= pixelRatio;
        canvas.height *= pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return canvas;
    }

    //// Public Interface

    // Drawing Methods
    QBDashboardFigureServiceCompare.drawService = drawService;

    // Utilities
    QBDashboardFigureServiceCompare.clearCanvas = clearCanvas;
    QBDashboardFigureServiceCompare.makeRect = makeRect;

})();

export default QBDashboardFigureServiceCompare;
