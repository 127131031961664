import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';
import { AiOutlineReload } from 'react-icons/ai';
import { UpperNavigation } from './';
import { Config } from '../modules';


function FeedbackImageUpload() {
    let history = useHistory();

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link pl-2 text-center image-icons" onClick={ () => history.goBack() }>
                            X
                        </div>
                    }
                    middle={
                        <AiOutlineReload className="image-icons" />
                    }
                    rightSide={
                        <FiCheck className="image-icons" />
                    }
                />
            </div>

            <div className="container">
                <div className="row justify-content-center p-2">
                    <img alt="placeholder" src="https://via.placeholder.com/400x600" />
                </div>
                <div className="row adjustment-conatiner">
                    
                </div>
            </div>
        </React.Fragment>
    );
}

export default FeedbackImageUpload;
