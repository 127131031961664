import React from 'react';

function HqIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" stroke={ props.strokeColor } fill="none" {...props}>
      <path
        d="M12.833 8.167l8.076 1.604C21.56 9.91 22 10.46 22 11.11v9.35c0 .761-.614 1.375-1.375 1.375h-8.25a.454.454 0 00.458-.458v-.458h7.792a.46.46 0 00.458-.459v-9.35a.46.46 0 00-.357-.449l-7.893-1.558v-.935z"
        fill="#3F3356"
      />
      <path
        d="M17.875 12.668c.257 0 .459.202.459.458a.454.454 0 01-.459.459h-1.833a.454.454 0 01-.459-.459c0-.256.202-.458.459-.458h1.833zM17.875 15.418c.257 0 .459.202.459.458a.454.454 0 01-.459.459h-1.833a.454.454 0 01-.459-.459c0-.256.202-.458.459-.458h1.833zM17.875 18.168c.257 0 .459.202.459.458a.454.454 0 01-.459.459h-1.833a.454.454 0 01-.459-.459c0-.256.202-.458.459-.458h1.833zM12.833 21.376a.454.454 0 01-.458.458.454.454 0 01-.459-.458V8.543c0-.138.065-.266.165-.358a.482.482 0 01.386-.091l.366.073V21.376z"
        fill="#3F3356"
      />
      <path
        d="M11.917 20.918v.458c0 .257.201.459.458.459H8.71a.454.454 0 00.458-.459v-.458h2.75zM9.625 4.418c.257 0 .459.202.459.458a.454.454 0 01-.459.459H7.792a.454.454 0 01-.458-.459c0-.256.201-.458.458-.458h1.833zM10.084 7.626a.454.454 0 01-.459.459H7.792a.454.454 0 01-.458-.459c0-.256.201-.458.458-.458h1.833c.257 0 .459.202.459.458zM9.625 9.918c.257 0 .459.202.459.458a.454.454 0 01-.459.459H7.792a.454.454 0 01-.458-.459c0-.256.201-.458.458-.458h1.833zM9.625 12.668c.257 0 .459.202.459.458a.454.454 0 01-.459.459H7.792a.454.454 0 01-.458-.459c0-.256.201-.458.458-.458h1.833zM5.5 13.126a.454.454 0 01-.458.459H3.208a.454.454 0 01-.458-.459c0-.256.202-.458.458-.458h1.834c.256 0 .458.202.458.458zM5.042 4.418c.256 0 .458.202.458.458a.454.454 0 01-.458.459H3.208a.454.454 0 01-.458-.459c0-.256.202-.458.458-.458h1.834zM5.042 7.168c.256 0 .458.202.458.458a.454.454 0 01-.458.459H3.208a.454.454 0 01-.458-.459c0-.256.202-.458.458-.458h1.834zM5.042 9.918c.256 0 .458.202.458.458a.454.454 0 01-.458.459H3.208a.454.454 0 01-.458-.459c0-.256.202-.458.458-.458h1.834zM8.25 16.793a.46.46 0 00-.458-.458h-2.75a.454.454 0 00-.459.458v4.125h-.917v-4.125c0-.76.615-1.375 1.376-1.375h2.75c.76 0 1.374.614 1.374 1.375v4.125H8.25v-4.125z"
        fill="#3F3356"
      />
      <path
        d="M4.583 20.918h4.583v.458a.454.454 0 01-.458.459H4.125a.454.454 0 01-.458-.459v-.458h.916z"
        fill="#3F3356"
      />
      <path
        d="M1.604.018l10.074 1.53a1.37 1.37 0 011.155 1.357v5.262l-.366-.073a.482.482 0 00-.385.091.487.487 0 00-.165.358V2.905a.463.463 0 00-.385-.458L1.457.925C1.43.916 1.403.916 1.375.916c-.11 0-.21.037-.293.11a.438.438 0 00-.165.349v19.084c0 .257.21.459.458.459h2.292v.458c0 .257.201.458.458.458h-2.75C.615 21.834 0 21.22 0 20.46V1.375C0 .97.174.586.486.33A1.376 1.376 0 011.604.018z"
        fill="#3F3356"
      />
    </svg>
  );
}

export default HqIcon;
