import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { AppStateContext, Config } from '../modules';
import { UpperNavigation } from './';

function NoRoutePage() {
    let history = useHistory();
    const appState = useContext(AppStateContext);
    useEffect( () => {
        appState.setActivePage('404');
    } );

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        404
                    }
                />
            </div>

            <div className="contact d-flex align-items-center h-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="font-weight-light">404</h1>
                            <p>
                                404 page not found
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NoRoutePage;
