import React from 'react'
import {Modal, Button} from "react-bootstrap";
import {Config} from "../modules";
import CloseIcon from '../img/closeIcon.png'
import {useTranslation} from "react-i18next";

const ConfirmationModal = (props) => {
	const {title, message, noTitle} = props || {}
    const {t: webappT} = useTranslation('webapp');
    const { t } = useTranslation();
	return (
		<Modal
			show={ props.show }
			onHide={ props.onHide }
			size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<div className='confirmation-modal-close-icon'>
				<img src={ CloseIcon } className='confirmation-modal-close-icon' alt="" onClick={ props.onHide }/>
			</div>
			{ noTitle !== true ?
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						{ title || webappT('unsavedChanges') }
					</Modal.Title>
				</Modal.Header>
				: '' }
			<Modal.Body>
				<p>{ message }</p>
			</Modal.Body>
			<Modal.Footer>
				<div className='d-flex justify-content-between w-100 overflow-hidden'>
					<Button size={ 'lg' } style={ {width: '48%', backgroundColor: Config.appearance.mainColor,} } onClick={ props.onDone }>{ t('localizeYes') }</Button>
					<Button size={ 'lg' } style={ {width: '48%', borderColor: Config.appearance.mainColor, color: Config.appearance.mainColor, backgroundColor: 'transparent'} } variant="outline-primary"
					        onClick={ props.onHide }>{ t('localizeNo') }</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default ConfirmationModal
