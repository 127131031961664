import React, { useEffect, useRef } from 'react';
import { QBDashboardFigureCustomerCount } from '../graph/';
import { Config } from '../modules';

function GraphServedCustomers(props) {
    const canvasGraph = useRef(null);

    useEffect( () => {
        function getParentWidth() {
            return canvasGraph.current.parentElement.clientWidth;
        }

        function _resizeHandler() {
            canvasGraph.current.width = getParentWidth() * 2;
            canvasGraph.current.height = getParentWidth() * 2;

            clearAndDraw();
        }

        function clearAndDraw() {
            const ctx = canvasGraph.current.getContext('2d');
            if (ctx) {
                var rect = QBDashboardFigureCustomerCount.makeRect(0, 0, getParentWidth() * 2, getParentWidth() * 2);
                QBDashboardFigureCustomerCount.clearCanvas(canvasGraph.current);
                QBDashboardFigureCustomerCount.drawCustomer_count(
                    canvasGraph.current,
                    0, // const_inactive_alpha
                    props.value1, // value_1
                    props.value2, // value_2
                    props.value3, // value_3
                    props.title1, // title_1
                    props.title2, // title_2
                    props.title3, // title_3
                    rect,
                    'aspectfit',
                    Config.appearance.darkGreen,
                    Config.appearance.darkYellow,
                    Config.appearance.darkRed,
                );
            }
        }

        window.addEventListener('resize', _resizeHandler);

        canvasGraph.current.width = getParentWidth() * 2;
        canvasGraph.current.height = getParentWidth() * 2;

        clearAndDraw();

        return function cleanup() {
            window.removeEventListener('resize', _resizeHandler);
        }
    }, [
        props.value1, props.title1,
        props.value2, props.title2,
        props.value3, props.title3,
    ] );

    return (
        <div>
            <canvas ref={canvasGraph} style={ { width: '100%' } } />
        </div>
    );
}

export default GraphServedCustomers;
