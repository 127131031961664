import React from 'react';
import { Config } from '../modules';

function AnalyticsDisplayUnusedServices(props) {
    var displayData = props.displayData;

    const unusedServicesStyle = {
        height: Config.appearance.analyticsMaxLineHeightUnusedServices,
    }

    return (
        <div className="metric unused-service px-2">
            <div style={ unusedServicesStyle } className="row unused-services-list-item align-items-center mx-0">
                <div className="col-12">
                    { displayData.name }
                </div>
            </div>
        </div>
    );
}

export default AnalyticsDisplayUnusedServices;
