import React from 'react';

function AnalyticsIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 14" fill="none" {...props}>
      <path
        d="M23 1l-9.5 9.5-5-5L1 13"
        stroke={ props.strokeColor }
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 1h6v6"
        stroke={ props.strokeColor }
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AnalyticsIcon;
