import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth, Constants, Config } from '../modules';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import XperionLogo from '../img/xperion-logo.png';
import { UpperNavigation } from './';

const frameworksAndLibraries = [
    { name: '@material-ui/core',                version: '4.11.4'   },
    { name: '@svgr/cli',                        version: '5.4.0'    },
    { name: '@svgr/webpack',                    version: '5.4.0'    },
    { name: '@testing-library/jest-dom',        version: '4.2.4'    },
    { name: '@testing-library/react',           version: '9.5.0'    },
    { name: '@testing-library/user-event',      version: '7.2.1'    },
    { name: 'bootstrap',                        version: '4.5.0'    },
    { name: 'd3-scale',                         version: '3.2.2'    },
    { name: 'file-saver',                       version: '2.0.2'    },
    { name: 'formik',                           version: '2.1.5'    },
    { name: 'i18next',                          version: '19.5.4'   },
    { name: 'i18next-browser-languagedetector', version: '5.0.0'    },
    { name: 'jsonwebtoken',                     version: '8.5.1'    },
    { name: 'lodash',                           version: '4.17.21'  },
    { name: 'memoize-one',                      version: '5.1.1'    },
    { name: 'moment',                           version: '2.25.3'   },
    { name: 'moment-timezone',                  version: '0.5.31'   },
    { name: 'react',                            version: '16.13.1'  },
    { name: 'react-beautiful-dnd',              version: '13.0.0'   },
    { name: 'react-bootstrap',                  version: '1.0.1'    },
    { name: 'react-bottom-drawer',              version: '0.1.1'    },
    { name: 'react-dom',                        version: '16.13.1'  },
    { name: 'react-dropzone',                   version: '11.2.0'   },
    { name: 'react-dropzone-uploader',          version: '2.11.0'   },
    { name: 'react-geocode',                    version: '0.2.1'    },
    { name: 'react-google-maps',                version: '9.4.5'    },
    { name: 'react-i18next',                    version: '11.7.0'   },
    { name: 'react-icons',                      version: '3.11.0'   },
    { name: 'react-image-lightbox',             version: '5.1.1'    },
    { name: 'react-infinite-scroll-component',  version: '5.1.0'    },
    { name: 'react-moment',                     version: '0.9.7'    },
    { name: 'react-notifications',              version: '1.7.2'    },
    { name: 'react-router-dom',                 version: '5.1.2'    },
    { name: 'react-router-hash-link',           version: '2.2.2'    },
    { name: 'react-scripts',                    version: '4.0.3'    },
    { name: 'react-swipeable',                  version: '5.5.1'    },
    { name: 'reactjs-popup',                    version: '1.5.0'    },
    { name: 'recharts',                         version: '1.8.5'    },
    { name: 'recompose',                        version: '0.30.0'   },
    { name: 'semver',                           version: '7.3.2'    },
    { name: 'sprintf-js',                       version: '1.1.2'    },
    { name: 'styled-components',                version: '5.2.1'    },
    { name: 'yup',                              version: '0.29.3'   },

    // custom modules, not in node dependency graph
    { name: 'RNCryptor-js',                     version: '6ba58d6' },
    { name: 'sjcl',                             version: '1.0.0' },
];

function About() {
    let history = useHistory();
    const { t } = useTranslation();
    const {t: mzlT} = useTranslation('mzlegal');

    var frameworksAndLibrariesFragment = [ ];
    for (let index = 0, len = frameworksAndLibraries.length; index < len; ++index)  {
        const library = frameworksAndLibraries[index];
        frameworksAndLibrariesFragment.push(
            <div key={ index } className="row about-us-element d-flex align-items-center">
                <div className="col-10">{ library.name }</div>
                <div className="col-2 text-right">{ library.version }</div>
            </div>
        );
    }

    var otherInformationFragment = [ ];
    if (Constants.debug.loginPage) {
        otherInformationFragment.push(
            <div className="row about-us-element d-flex align-items-center">
                <div className="col-10">{ t('webapp:loginPageDebugMode') }</div>
                <div className="col-2 text-right">{ t('localizeYes') }</div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizeAbout')
                    }
                />
            </div>

            <div className="container about">
                <div className="row help-page-title py-1">
                    <div className="col-12">{ t('localizeAboutCompanyTitle') }</div>
                </div>
                <a className="qboard-link" rel="noopener noreferrer" href="https://qboardapp.com/" target="_blank">
                    <div className="row align-items-center py-1 mb-2 setting-item">
                        <div className="col-12 px-3">
                            <div className="row settings-branch-item">
                                <div className="col-10 col-sm-11 text-center">
                                    <div className="row d-block text-center py-1">
                                        <img alt="Xperion logo" className="img-fluid" width="400px" src={ XperionLogo } />
                                        <div>H-1087 Budapest, Könyves Kálmán Krt. 76.</div>
                                    </div>
                                </div>
                                <div className="col-2 col-sm-1 text-right d-flex align-items-center justify-content-end">
                                    <BsChevronRight className='icon-without-bg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>

                <div className="row help-page-title pt-1 mb-2">
                    <div className="col-12">{ mzlT('localizeLegalTitle') }</div>
                </div>
                <Link className="qboard-link" to="/legal/current">
                    <div className="row align-items-center py-1 mb-2 setting-item">
                        <div className="col-12 px-3">
                            <div className="row settings-branch-item">
                                <div className="col-10 d-flex align-items-center">
                                    { t('mzlegal:localizeLegalTermsAndPoliciesTitle') }
                                </div>
                                <div className="col-2 text-right">
                                    <BsChevronRight className='icon-without-bg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                { Auth.safeGetItem('authPhase') === 'auth' ? (
                    <Link className="qboard-link" to="/legal/history">
                        <div className="row align-items-center py-1 mb-2 setting-item">
                            <div className="col-12 px-3">
                                <div className="row settings-branch-item">
                                    <div className="col-10 d-flex align-items-center">
                                        { t('localizeLegalHistory') }
                                    </div>
                                    <div className="col-2 text-right">
                                        <BsChevronRight className='icon-without-bg'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                ) : '' }


                <div className="row help-page-title py-1">
                    <div className="col-12">{ t('localizeAboutAppTitle') }</div>
                </div>
                <div className="row about-us-element d-flex align-items-center">
                    <div className="col-8">QBoard-react</div>
                    <div className="col-4 text-right">{ Constants.appInfo.version }</div>
                </div>

                <div className="row help-page-title py-1">
                    <div className="col-12">{ t('localizeAboutFrameworksTitle') }</div>
                </div>
                { frameworksAndLibrariesFragment }

                <div className="row help-page-title py-1">
                    <div className="col-12">{ t('localizeAboutFontsTitle') }</div>
                </div>
                <div className="row py-1">
                    <div className="col-12">OpenSans</div>
                </div>

                { otherInformationFragment.length > 0 ? (
                    <div className="row help-page-title py-1">
                        <div className="col-12">{ t('webapp:otherInformation') }</div>
                    </div>
                ) : '' }
                { otherInformationFragment }

            </div>
        </React.Fragment>
    );
}

export default About;
