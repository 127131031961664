import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { AppStateContext } from '../modules';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { QbApi, Auth, Config } from '../modules';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { UpperNavigation } from './';

function LegalDocumentsHistoryList() {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const appState = useContext(AppStateContext);
    const [ legalDocuments, setLegalDocuments ] = useState( [ ] );
    const lng = i18n.language;

    useEffect( () => {
        async function renderOutput() {
            let legalHistory = Auth.getItem('legalHistory');
            if ((typeof legalHistory === 'undefined') || (legalHistory === null)) {
                legalHistory = await QbApi.instance.getAsync(appState, 'legal/history');
                Auth.setItem('legalHistory', legalHistory);
            }

            let legalDocumentsFragment = [ ];
            let lastDate = null;
            for (const [, legalPerDate] of Object.entries(legalHistory.data)) {
                for (let index = 0, len = legalPerDate.length; index < len; ++index) {
                    const legal = legalPerDate[index];
                    let showDate = false;
                    if (lastDate !== legal.date) {
                        lastDate = legal.date;
                        showDate = true;
                    }
                    legalDocumentsFragment.push(
                        <div key={ legal.id }>
                            { showDate ? (
                                <div className="row legal-history-date title mb-3" >
                                    <div className="col-12">
                                        { moment(legal.date).format(Config.legalDateDisplayFormat) }
                                    </div>
                                </div>
                            ) : '' }
                            <Link className="qboard-link" to={ '/legal/history/' + legal.id }>
                                <div className="row align-items-center py-1 mb-2 setting-item">
                                    <div className="col-12 px-3">
                                        <div className="row settings-branch-item">
                                            <div className="col-10 d-flex align-items-center">
                                                { legal.name }<br />
                                            </div>
                                            <div className="col-2 d-flex justify-content-end align-items-center">
                                                <div className="breakdown-value pr-1">{ legal.version }</div>
                                                <BsChevronRight className='icon-without-bg'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                }
            }
            setLegalDocuments(legalDocumentsFragment);
        }
        renderOutput();
    }, [ lng, t, appState ] );

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizeLegalHistory')
                    }
                />
            </div>

            <div className="container">
                { legalDocuments }
            </div>
        </React.Fragment>
    );
}

export default LegalDocumentsHistoryList;
