import React from 'react';

function ProblemReportIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M15.683 1h2.67a2.31 2.31 0 012.33 2v7a2.31 2.31 0 01-2.33 2h-2.67m-7 2v4a3 3 0 003 3l4-9V1H4.403a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3h5.66z"
        stroke={props.strokeColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProblemReportIcon;
