import React from 'react';
import {Config, QbTools} from '../modules';
import {BsPerson, BsClock, BsDisplayFill} from 'react-icons/bs';
import {ServicePointIcon, TicketIcon} from '../icons';
import {FiWatch} from 'react-icons/fi';
import {useTranslation} from 'react-i18next';
import {MessagingModal} from './index';

function BreakdownDisplayBmSubItem(props) {
	var displayData = props.displayData;
	const {t} = useTranslation();
	
	const percentbarWidth = {
		ServingTime: Math.min((displayData.KI / 1800) * 100, 100),
        LoggedInCounterTime: Math.min((QbTools.rstToElapsed(displayData.RST) / 1800) * 100, 100),
	};
	const percentbarStyle = {
		ServingTime: {
			backgroundColor: Config.graphColors.ServingTime[QbTools.analyticsGraphLimits(displayData.KI / 60, Config.graphDefaults.ServingTime)],
			width: percentbarWidth.ServingTime + "%",
		},
		LoggedInCounterTime: {
			backgroundColor: Config.graphColors.LoggedInCounterTime[QbTools.analyticsGraphLimits(QbTools.rstToElapsed(displayData.RST) / 60, Config.graphDefaults.LoggedInCounterTime)],
			width: percentbarWidth.LoggedInCounterTime + "%",
        },
    };

    const informationRowStyle = {
        minHeight: Config.appearance.overviewInformationRowHeight,
    }
	return (
		<div className="metric">
            <div className="row text-center my-1 pt-3 title">
				<div className="col-12">
					<h4 className="font-weight-light">{ t('localizeAlertContactMessage') }</h4>
				</div>
			</div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-1" />
                    <div className="col-10 col-lg-6 py-2 px-0" >
                        <div className="overview-information-box">
                            <div className="conatact-item-overview d-flex">
                                <MessagingModal
                                    chanonicalName={ displayData.name }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-1" />
                </div>
            </div>

            <div className="row text-center my-1 pt-3 title">
				<div className="col-12">
					<h4 className="font-weight-light">{ t('localizeInformation') }</h4>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-1"></div>
					<div className="col-10 col-lg-6 overview-information-box">
						<div style={ informationRowStyle } className="row information-row d-flex justify-content-center align-content-center">
							<div className="col-8 information-data-name d-flex align-items-center">
								<BsPerson
									className="icon-with-bg-information mr-1"/> { t('localizeDashboardCustomerCountTitle') }
							</div>
							<div
								className="col-4 text-right information-data d-flex align-items-center justify-content-end">
								{ QbTools.formatCustomerCount(displayData.USZ) }
							</div>
						</div>
						<div style={ informationRowStyle } className="row information-row d-flex justify-content-center align-content-center">
							<div className="progressbar" style={ percentbarStyle.ServingTime }></div>
							<div className="col-8 information-data-name d-flex align-items-center">
								<FiWatch
									className="icon-with-bg-information mr-1"/> { t('localizeDashboardServeTimeTitle') }
							</div>
							<div
								className="col-4 text-right information-data d-flex align-items-center justify-content-end">
								{ QbTools.formatWaitingTimeLong(displayData.KI) }
							</div>
						</div>
                        <div style={ informationRowStyle } className="row information-row d-flex justify-content-center align-content-center">
							<div className="progressbar" style={ percentbarStyle.LoggedInCounterTime }></div>
							<div className="col-6 information-data-name d-flex align-items-center">
								<ServicePointIcon className="icon-with-bg-information mr-1"/> { t('localizeMetricLoggedInCounter') }
							</div>
							<div className="col-6 text-right information-data d-flex align-items-center justify-content-end">
								<div className="text-center">
									<div className="staff-detail text-right">
										{ displayData.RLC ? (<span className="rlc">{<BsDisplayFill
											className="icon-with-bg-information secondary"/>} { displayData.RLC }</span>) : '' }
									</div>
									<div className="staff-detail secondary text-right">
										{ displayData.RTS ? (<span className="rts d-block d-sm-inline">{<TicketIcon stroke="#C2C2FF"
											className="icon-with-bg-information secondary"/>} { displayData.RTS }</span>) : '' }
										{ displayData.RST ? (<span className="rst d-block d-sm-inline">{<BsClock
											className="icon-with-bg-information secondary"/>} { QbTools.formatElapsedTime(QbTools.rstToElapsed(displayData.RST), t) }</span>) : '' }
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-1"></div>
				</div>
			</div>
		</div>
	);
}

export default BreakdownDisplayBmSubItem;
