import {UpperNavigation} from "./index";
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import React, {useState} from 'react'
import { Modal } from 'react-bootstrap';
import FiltersDraggableList from "./DraggableList";
import {BsChevronLeft} from 'react-icons/bs';
import {Auth, QbApi, Config, Constants} from "../modules";
import { DownloadIcon, GeneratePdfIcon } from '../icons';
import moment from 'moment-timezone';
import ImageGridList from '../utilities/ImageGridList.jsx';

const DashboardReport = () => {
    const location = useLocation();
    const history = useHistory()
	const {t} = useTranslation();

	const goBackPressHandler = () => {
		return history.goBack()
	}
    
    let componentList;
    try {
        const { componentData } = location.state;
        componentList = JSON.parse(componentData);
    }
    catch (e) {
    }

    let finalFiltersList = componentList && Object.values(componentList.items)
            .filter(item => item.hasOwnProperty('render'))
            .map(item => {
                return {
                    active: true,
                    id: item.id,
                    position: item.position,
                    name: item.filterName || item.id,
                };
            } );

    const [ errorShown, setErrorShown ] = useState(false);
	const [ filtersList, setFiltersList ] = useState(finalFiltersList)
    const [ pdfFilename, setPdfFilename ] = useState(null);
    const [ pdfResultShown, setPdfResultShown ] = useState(false);
    const [ pdfGenerating, setPdfGenerating ] = useState(false);
    const [ pdfDocument, setPdfDocument ] = useState(null);
    const [ pdfPages, setPdfPages ] = useState( [ ] );

    function openError() {
        setPdfGenerating(false);
        setErrorShown(true);
    }

    function closeError() {
        setPdfGenerating(false);
        setErrorShown(false);
    }

    const openPdfResult = () => {
        setPdfGenerating(false);
        setPdfResultShown(true);
    }

    const closePdfResult = () => {
        setPdfGenerating(false);
        setPdfFilename(null);
        setPdfResultShown(false);
        setPdfDocument(null);
        setPdfPages( [ ] );
    }

    async function apiGetDocument(_componentList) {
        const apiMethod = 'POST';
        const apiPath = Constants.pdfApi.pdfApiEndpoint;
        const apiHeaders = {
            'Content-Type': 'application/json',
            'X-Authorization': 'Bearer ' + Auth.getToken(),
        };

        let pdfHeader = componentList.header;
        const now = moment();
        setPdfFilename('QBoard-Report-' + now.format('YYYYMMDD_HHmmss') + '.pdf');
        pdfHeader.generateTime = now.format(Config.dateTimeDisplayFormat);

        const apiBody = {
            componentList: _componentList,
            header: pdfHeader,
        };
        const apiJsonBody = JSON.stringify(apiBody);

        let response = await QbApi.instance.fetch(null, apiPath, {
            method: apiMethod,
            headers: apiHeaders,
            body: apiJsonBody,
        } );
        
        const info = await response.json();
        return info['id'] ? 
            apiPath + '/document/' + info['id'] : 
            null;
    }

    async function apiGetPages(apiPath) {
        let out = null;

        if (apiPath !== null) {
            out = [];

            const apiMethod = 'GET';
            apiPath += '/pages';

            let response = await fetch(apiPath, {
                method: apiMethod,
            } );

            const count = await response.text();
            for (let i = 0; i < parseInt(count); ++i) {
                out.push( apiPath + '/' + i);
            }
        }

        return out;
    }

    async function generatePdf() {
        setPdfGenerating(true);

        try {
            let _componentList = { }; 
            for (let idx = 0; idx < filtersList.length; idx++) {
                const filter = filtersList[idx];
                if (filter.active) {
                    const filterItem = Object.values(componentList.items).find(item => item.id === filter.id);
                    const filterItem2 = {
                        pdfData: filterItem.pdfData,
                    }
                    _componentList[idx] = filterItem2;
                }
            }

            const documentUrl   = await apiGetDocument(_componentList);
            const documentPages = await apiGetPages(documentUrl);

            if (documentPages !== null) {
                setPdfPages(documentPages); 
                setPdfDocument(documentUrl);
                openPdfResult();
            }
            else {
                openError();
            }
        }
        catch (e) {
            openError();
        }
    }

	if (!componentList) {
        return <Redirect to={ '/dashboard' } />;
    }

	return <React.Fragment>
        <div>
            <div className={ 'form-spinner position-fixed justify-content-center align-items-center ' + ( pdfGenerating ? 'd-flex' : '' ) }>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Working...</span>
                </div>
            </div>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className='text-white qboard-link' onClick={ goBackPressHandler }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                        </div>
                    }
                    middle={ t('localizeReportTitle') }
                    rightSide={ 
                        <button type="button" className="btn btn-light" data-dismiss="modal" onClick={ generatePdf } >
                            <GeneratePdfIcon stroke="#000000" className="icon-with-bg-information" /> 
                        </button> 
                    }
                />
            </div>
		    <div>
                <FiltersDraggableList
                    dataList={ filtersList }
                    setData={ (updatedFiltersList) => setFiltersList(updatedFiltersList) }/>
		    </div>
	    </div>
        <Modal show={ errorShown } onHide={ closeError } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="text-center">
                { t('localizeBackendErrorReportError') }
            </Modal.Body>
        </Modal>
        <Modal show={ pdfResultShown } onHide={ closePdfResult } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <div className="row w-100">
                    <div className="col-4 text-nowrap">
                        <a target="_blank" rel="noopener noreferrer" href={ pdfDocument } download={ pdfFilename }>
                            <DownloadIcon stroke="#000000" className="icon-with-bg-information" /> 
                        </a>
                    </div>
                    <div className="col-4 text-center">
                        <Modal.Title>
                            { t('localizeReportTitle') }
                        </Modal.Title>
                    </div>
                    <div className="col-4 text-right">
                        <button type="button" className="btn btn-close" data-dismiss="modal" aria-label="Close" onClick={ closePdfResult } >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="text-center container pdf-modal">
                <ImageGridList className="text-center container pdf-modal body" images={pdfPages}/>
            </Modal.Body>
        </Modal>
    </React.Fragment>;
}

export default DashboardReport
