//
//  QBDashboardFigureCounter.js
//  qboard
//
//  Created by Zoltan Meszaros on 2020. 05. 25..
//  Copyright © 2020 Xperion. All rights reserved.
//
//  Generated by PaintCode
//  http://www.paintcodeapp.com
//



//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    QBDashboardFigureCounter.drawCounter(canvas, const_inactive_alpha, text, active, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//
// Available Utilities:
//    QBDashboardFigureCounter.clearCanvas(canvas)
//    QBDashboardFigureCounter.makeRect(x, y, width, height)
import { Config } from '../modules';


//// Create StyleKit Object
var QBDashboardFigureCounter = {};
(function() {

    //// Drawing Methods

    function drawCounter(canvas, const_inactive_alpha, text, active, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        //var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 70), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 70, resizedFrame.h / 70);


        //// Color Declarations
        //var color = 'rgba(0, 102, 255, 1)';
        var color = Config.appearance.mainColor;

        //// Variable Declarations
        var alpha = active ? 1 : const_inactive_alpha;

        //// Screen Drawing
        context.save();
        context.globalAlpha = alpha;
        context.beginPath();
        context.moveTo(15, 55);
        context.arcTo(5, 55, 5, 45, 10);
        context.lineTo(5, 20);
        context.arcTo(5, 10, 15, 10, 10);
        context.lineTo(55, 10);
        context.arcTo(65, 10, 65, 20, 10);
        context.lineTo(65, 45);
        context.arcTo(65, 55, 45, 55, 10);
        context.strokeStyle = color;
        context.lineWidth = 3;
        context.lineJoin = 'round';
        context.stroke();
        context.restore();

        ////Rectangle drawing
        context.save();
        context.globalAlpha = alpha;
        context.beginPath();
        context.moveTo(35, 50);
        context.lineTo(24, 65);
        context.lineTo(46, 65);
        context.lineTo(35, 50);
        context.strokeStyle = color;
        context.lineWidth = 3;
        context.lineJoin = 'round';
        context.stroke();
        context.restore();


        //// data_text Drawing
        var data_textRect = makeRect(5, 10, 60, 46);
        context.fillStyle = color;
        context.font = '14px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        var data_textTotalHeight = 14 * 1.3;
        context.fillText(text, data_textRect.x + data_textRect.w/2, data_textRect.y + 13 + data_textRect.h / 2 - data_textTotalHeight / 2);

        //// Bezier Drawing
        /*context.save();
        context.globalAlpha = alpha;
        context.beginPath();
        context.moveTo(25.52, 63.33);
        context.bezierCurveTo(25.52, 63.33, 27.92, 62.72, 29.22, 60.89);
        context.bezierCurveTo(30.51, 59.06, 30.7, 56, 30.7, 56);
        context.lineTo(34.88, 56);
        context.lineTo(39.57, 56);
        context.bezierCurveTo(39.57, 56, 39.75, 59.06, 41.04, 60.89);
        context.bezierCurveTo(42.34, 62.72, 44.74, 63.33, 44.74, 63.33);
        context.bezierCurveTo(44.74, 63.33, 55.83, 63.33, 58.04, 63.33);
        context.bezierCurveTo(60.26, 63.33, 61, 67, 61, 67);
        context.lineTo(10, 67);
        context.bezierCurveTo(10, 67, 10.74, 63.33, 12.96, 63.33);
        context.bezierCurveTo(15.17, 63.33, 25.52, 63.33, 25.52, 63.33);
        context.closePath();
        context.fillStyle = color;
        context.fill();
        context.restore();
        
        context.restore();*/

    }

    //// Infrastructure

    function clearCanvas(canvas) {
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    // Possible arguments for 'resizing' parameter are:
    //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
    //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
    //   'stretch': The content is stretched to match the entire target rectangle.
    //   'center': The content is centered in the target rectangle, but it is NOT resized.
    function applyResizingBehavior(resizing, rect, targetRect) {
        if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
            return rect;
        }

        var scales = makeSize(0, 0);
        scales.w = Math.abs(targetRect.w / rect.w);
        scales.h = Math.abs(targetRect.h / rect.h);

        switch (resizing) {
            case 'aspectfit': {
                scales.w = Math.min(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'aspectfill': {
                scales.w = Math.max(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'stretch':
            case undefined:
                break;
            case 'center': {
                scales.w = 1;
                scales.h = 1;
                break;
            }
            default:
                throw new Error('Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.');
        }

        var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h));
        result.w *= scales.w;
        result.h *= scales.h;
        result.x = targetRect.x + (targetRect.w - result.w) / 2;
        result.y = targetRect.y + (targetRect.h - result.h) / 2;
        return result;
    }

    function makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    function equalRects(r1, r2) {
        return r1.x === r2.x && r1.y === r2.y && r1.w === r2.w && r1.h === r2.h;
    }

    function makeSize(w, h) {
        return { w: w, h: h };
    }

    function initializeCanvas(canvas) {
        if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        var context = canvas.getContext('2d');

        var devicePixelRatio = window.devicePixelRatio || 1;
        var backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        var pixelRatio = devicePixelRatio / backingStorePixelRatio;

        //canvas.style.width = canvas.width + 'px';
        //canvas.style.height = canvas.height + 'px';
        canvas.width *= pixelRatio;
        canvas.height *= pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return canvas;
    }

    //// Public Interface

    // Drawing Methods
    QBDashboardFigureCounter.drawCounter = drawCounter;

    // Utilities
    QBDashboardFigureCounter.clearCanvas = clearCanvas;
    QBDashboardFigureCounter.makeRect = makeRect;

})();

export default QBDashboardFigureCounter;
