import React from 'react';

function SalesTeamIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 20" fill="none" {...props}>
      <path
        d="M17 19v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M9 9a4 4 0 100-8 4 4 0 000 8zM23 19v-2a4 4 0 00-3-3.87M16 1.13a4 4 0 010 7.75"
        stroke={ props.strokeColor ? props.strokeColor :  "#3F3356"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SalesTeamIcon;
