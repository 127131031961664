import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { Config, AppStateContext, QbTools } from '../modules';
import { GrCircleInformation } from 'react-icons/gr';
import { CardsIcon } from '../icons';
import { UpperNavigation } from './';

function HelpIndex(props) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const appState = useContext(AppStateContext);
    const lng = i18n.language;
    const [ searchString, setSearchString ] = useState('');

    let pageList = [ ];
    let titleFragment = null;
    const toSearch = searchString.trim();
    const toSearchArr = toSearch.toLowerCase().split(' ');

    for (let index = 0, len = Config.help.pages.length; index < len; ++index) {
        const helpPage = Config.help.pages[index];
        let page = null;
        if (helpPage.hasOwnProperty('head')) {
            titleFragment = (
                <div key={ 'head-' + index } className="row help-page-title">
                    <div className="col-12">
                        { QbTools.getByLocale(helpPage.head, lng) }
                    </div>
                </div>
            );
        }
        const pageContent = QbTools.helptextToTxt(QbTools.getByLocale(helpPage.content, lng).toLowerCase());
        let contains = false;
        if (toSearch !== '') {
            contains = true;
            for (const word of toSearchArr) {
                if (! pageContent.includes(word)) {
                    contains = false;
                }
            }
        }

        const helpRowStyle = {
            height: Config.appearance.helpRowHeight,
        }

        if ((toSearch === '') || contains) {
            page = (
                <Link key={ index } replace={ true } className="qboard-link" to={ '/help/' + (index + 1) }>
                    <div className="row help-page-item align-items-center py-3">
                        <div style={ helpRowStyle } className="col-2 d-flex align-items-center help-index-image-list">
                            <img width="100%" alt={ QbTools.getByLocale(helpPage.title, lng) } src={ helpPage.thumbnail } />
                        </div>
                        <div style={ helpRowStyle } className="col-10 d-flex align-items-center">
                            <div>{ QbTools.helptextToJsx(QbTools.getByLocale(helpPage.content, lng)) }</div>
                        </div>
                    </div>
                </Link>
            );
        }
        if ((page !== null) && (titleFragment !== null)) {
            pageList.push(titleFragment);
            titleFragment = null;
        }
        if (page !== null) {
            pageList.push(page);
        }
    }

    let switchToPage = appState.helpPage;
    const helpPage = Config.help.pages[switchToPage - 1];
    if (helpPage === undefined) {
        switchToPage = 1;
    }

    function handleSearchChange(event) {
        setSearchString(event.target.value);
    }

    const layoutStyle = {
        backgroundColor: Config.appearance.globalBackgroundColor,
    }

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizeHelpTitle')
                    }
                    rightSide={
                        <div className="d-flex">
                            <Link replace={ true } className="qboard-link px-1" to={ '/help/' + switchToPage }>
                                <CardsIcon strokeColor={ Config.appearance.mainColor } className="icon-with-bg float-none ml-1" />
                            </Link>
                            <Link className="qboard-link px-1 info-icon" to="/about">
                                <GrCircleInformation className="help-information-icon pl-1" />
                            </Link>
                        </div>
                    }
                    bottom={
                        <input className="form-control help-search-field" type="text" name="search" onChange={ handleSearchChange } />
                    }
                />
            </div>

            <div style={ layoutStyle } className="layout vh-100">
                <div className="container">
                    { pageList }
                </div>
            </div>
        </React.Fragment>
    );
}

export default HelpIndex;
