import React from 'react';
import { DashboardHqHeadquarter, DashboardHqRegions, DashboardRmRegion, DashboardRmSubregions, DashboardSrmSubregion, DashboardSrmBranches, DashboardBmBranch, DashboardBmStaff, DashboardBmCamera } from './';
import { BreakdownProxyServedCustomers, BreakdownProxyServiceLevel, BreakdownProxyAverageWaitingTime, BreakdownProxyAverageServingTime, BreakdownProxyLoggedIntoServicePoint } from './';
import { DashboardBmCameraImage } from './';
import { HqIcon, LocationIcon, SalesTeamIcon } from '../icons';
import { FiMap, FiShoppingBag } from 'react-icons/fi';
import { BsCameraVideo } from 'react-icons/bs';

const DashboardHierarchy = {
    'hq': {
        metric:     { boardtype: 'metric',      name: 'Headquarter',   translation: 'localizeDashboardMenuCentralTitle',    component: DashboardHqHeadquarter,  icon: ( <HqIcon strokeColor="#ffffff" /> ),
            pageIcon: ( <HqIcon className="page-main-icon" /> ) },
        breakdown:  { boardtype: 'breakdown',   name: 'Regions',       translation: 'localizeDashboardMenuRegionsTitle',    component: DashboardHqRegions,      icon: ( <LocationIcon stroke="#ffffff" /> ),
            pageIcon: ( <LocationIcon stroke="#3f3356" className="page-main-icon" /> ),
            pagetypes: {
                scc: { id: 'scc',    name: 'Served Customer Count', translation: 'localizeMetricCustomerCount',         component: BreakdownProxyServedCustomers    },
                sl:  { id: 'sl',     name: 'Service Level',         translation: 'localizeMetricServeLevel',            component: BreakdownProxyServiceLevel       },
                awt: { id: 'awt',    name: 'Average Waiting Time',  translation: 'localizeMetricAverageWaitingTime',    component: BreakdownProxyAverageWaitingTime },
                ast: { id: 'ast',    name: 'Average Serving Time',  translation: 'localizeMetricAverageServeTime',      component: BreakdownProxyAverageServingTime },
            }
        },
    },
    'rv': {
        metric:     { boardtype: 'metric',      name: 'Region',       translation: 'localizeDashboardMenuRegionTitle',      component: DashboardRmRegion,       icon: ( <LocationIcon stroke="#ffffff" /> ),
            pageIcon: ( <FiShoppingBag className="page-main-icon" /> ) },
        breakdown:  { boardtype: 'breakdown',   name: 'Subregions',   translation: 'localizeDashboardMenuSubregionsTitle',  component: DashboardRmSubregions,   icon: ( <FiMap /> ),
            pageIcon: ( <SalesTeamIcon className="page-main-icon" /> ),
            pagetypes: {
                scc: { id: 'scc',    name: 'Served Customer Count', translation: 'localizeMetricCustomerCount',         component: BreakdownProxyServedCustomers    },
                sl:  { id: 'sl',     name: 'Service Level',         translation: 'localizeMetricServeLevel',            component: BreakdownProxyServiceLevel       },
                awt: { id: 'awt',    name: 'Average Waiting Time',  translation: 'localizeMetricAverageWaitingTime',    component: BreakdownProxyAverageWaitingTime },
                ast: { id: 'ast',    name: 'Average Serving Time',  translation: 'localizeMetricAverageServeTime',      component: BreakdownProxyAverageServingTime },
            }
        },
    },
    'tv': {
        metric:     { boardtype: 'metric',      name: 'Subregion',   translation: 'localizeDashboardMenuSubregionTitle',    component: DashboardSrmSubregion,   icon: ( <FiMap /> ),
            pageIcon: ( <FiShoppingBag className="page-main-icon" /> ) },
        breakdown:  { boardtype: 'breakdown',   name: 'Branches',    translation: 'localizeDashboardMenuBranchesTitle',     component: DashboardSrmBranches,    icon: ( <FiShoppingBag /> ),
            pageIcon: ( <SalesTeamIcon className="page-main-icon" /> ),
            pagetypes: {
                scc: { id: 'scc',    name: 'Served Customer Count', translation: 'localizeMetricCustomerCount',         component: BreakdownProxyServedCustomers    },
                sl:  { id: 'sl',     name: 'Service Level',         translation: 'localizeMetricServeLevel',            component: BreakdownProxyServiceLevel       },
                awt: { id: 'awt',    name: 'Average Waiting Time',  translation: 'localizeMetricAverageWaitingTime',    component: BreakdownProxyAverageWaitingTime },
                ast: { id: 'ast',    name: 'Average Serving Time',  translation: 'localizeMetricAverageServeTime',      component: BreakdownProxyAverageServingTime },
            }
        },
    },
    'uv': {
        metric:     { boardtype: 'metric',      name: 'Branch',   translation: 'localizeDashboardMenuBranchTitle',          component: DashboardBmBranch,       icon: ( <FiShoppingBag /> ),
            pageIcon: ( <FiShoppingBag className="page-main-icon" /> ) },
        breakdown:  { boardtype: 'breakdown',   name: 'Staff',    translation: 'localizeDashboardMenuEmployeesTitle',       component: DashboardBmStaff,        icon: ( <SalesTeamIcon strokeColor="#ffffff" /> ),
            pageIcon: ( <SalesTeamIcon className="page-main-icon" /> ),
            pagetypes: {
                scc: { id: 'scc',    name: 'Served Customer Count',     translation: 'localizeMetricCustomerCount',     component: BreakdownProxyServedCustomers        },
                ast: { id: 'ast',    name: 'Average Serving Time',      translation: 'localizeMetricAverageServeTime',  component: BreakdownProxyAverageServingTime     },
                lsp: { id: 'lsp',    name: 'Logged Into Service Point', translation: 'localizeMetricLoggedInCounter',   component: BreakdownProxyLoggedIntoServicePoint },
            }
        },
        camera:     { boardtype: 'camera',      name: 'Camera',   translation: 'localizeDashboardMenuCamerasTitle',         component: DashboardBmCamera,       icon: ( <BsCameraVideo /> ),
            pageIcon: ( <BsCameraVideo className="page-main-icon" /> ),
            subComponent: DashboardBmCameraImage,
        },
    },
};

export default DashboardHierarchy;
