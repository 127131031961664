import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from 'react-icons/bs';
import { Field, Form, Formik} from 'formik';
import Popup from 'reactjs-popup';
import { UpperNavigation } from './';
import { Config } from '../modules';

function FeedbackNew() {
    let history = useHistory();
    const { t } = useTranslation();
    const { t:webappT } = useTranslation('webapp');

    let formValues = {
        message: '',
    };


    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        <Popup trigger={<span className="c-pointer"> { webappT('localizeFeedbackCategoryLike') }</span> } modal>
                            <div className="row">
                                <div className="col-12 py-2 text-center feedback-category-item">
                                    { webappT('localizeFeedbackCategoryLike') }
                                </div>
                                <div className="col-12 py-2 text-center feedback-category-item">
                                    { webappT('localizeFeedbackCategoryDislike') }
                                </div>
                                <div className="col-12 py-2 text-center feedback-category-item">
                                    { webappT('localizeFeedbackCategoryOther') }
                                </div>
                            </div>
                        </Popup>
                    }
                    rightSide={
                        <Link className="send-button" to="#">
                            { t('localizeTopDeskButtonSendTitle') }
                        </Link>
                    }
                />
            </div>

            <div className="container">
                <div className="row justify-content-center p-2">
                    <img alt="placeholder" src="https://via.placeholder.com/400x600" />
                </div>
                <div className="row position-fixed fixed-bottom bottom-form-container">
                    <div className="col-12 py-2 px-4 d-flex align-items-center">
                        <Formik
                            initialValues={ formValues }
                            /*onSubmit={ formSubmit }*/>
                            { ({dirty}) => {
                                return <Form className="w-100">
                                    <div className="form-container-with-button">
                                        <Field className="form-control feedback-content" name="feedback-content" placeholder={ t('localizeFeedbackPlaceholder') } type="text" />
                                        
                                    </div>
                                </Form>
                            } }
                        </Formik>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FeedbackNew;
