import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphServiceLevel } from './';
import { AjaxStateFragment } from './';
import { Config, QbTools } from '../modules';

function AnalyticsDisplayServiceLevels(props) {
    const { t } = useTranslation();
    var displayData = props.displayData;
    const graph1 = useRef(null);
    const graph1Title = useRef(null);

    const jsonDisplayData = JSON.stringify(displayData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: displayData.title,
                    displayData: displayData,
                    render: ( <AnalyticsDisplayServiceLevels { ...props } /> ),
                    pdfData: {
                        requestTime: displayData._requestTime,
                        type: 'htmlsvg',
                        titleHtml: graph1Title.current.outerHTML,
                        html: QbTools.copyDom(graph1.current).outerHTML,
                    },
                };
                return prevState;
            } );
        }
    }, [ jsonDisplayData, displayData, props ] );

    const serviceLevelStyle = {
        height: Config.appearance.analyticsMaxLineHeightServiceLevel,
    }

    var KSZ = [ ];
    displayData.KSZ.forEach(function (item, index) {
        KSZ.push(
            <div style={ serviceLevelStyle } key={ index } className="row align-items-center analytics-service-level mx-0">
                <div className="col-9 col-md-10 col-lg-10 col-xl-10 align-self-center">
                    { item.title }
                </div>
                <div className="col-3 col-md-2 col-lg-2 px-lg-4 px-xl-5 col-xl-2">
                    <GraphServiceLevel value={ item.value * 100 } />
                </div>
            </div>
        );
    } );

    return (
        <div className="metric">
            <div className="row text-center my-1 pt-3 title" ref={ graph1Title } >
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title pb-2">{ t(displayData.title) }</h4>
                </div>
            </div>
            <div className="service-levels-container position-relative" ref={ graph1 } >
                <AjaxStateFragment _state={ displayData._state } _failed={ displayData._failed } />
                { KSZ }
            </div>
        </div>
    );
}

export default AnalyticsDisplayServiceLevels;
