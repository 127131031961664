import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Config } from '../modules';
    

const GlobalStyle = createGlobalStyle`
    .subregion-name-upper-navigation, .overview-region-name, .srm-subregion-name{
        color: ${Config.appearance.upperNavigationtextColor};
        margin-bottom: 0px;
    }
    .analytics-graph-full-title {
        color: ${Config.appearance.metricTitleColor};
    }
    .overview-information-box > div, .information-row {
        min-height: ${Config.appearance.breakdownOverviewRowHeight} !important;
    }
    .overview-information-box{
        background-color: ${Config.appearance.overviewBoxBackgroundColor}
    }
    .metric .row.metric-content, .branch-row-icon, .icon-holder{
        background-color: ${Config.appearance.metricContentBackgroundColor};
    }
    .analytics-composed-chart, .analytics-pie-chart, .analytics-popular-service-chart, .unused-services-container, .service-levels-container{
        background-color: ${Config.appearance.analyticsContentBackgroundColor};
    }
    .breakdown-list-container{
        background-color: ${Config.appearance.breakdownListBackgroundColor};
    }
    svg.icon-with-bg{
        background-color: ${Config.appearance.iconWithBgBackgroundColor};
        color: ${Config.appearance.iconWithBgIconColor};
    }
    /* START Checkboxokhoz tartozó css forrás: https://codepen.io/aaroniker/pen/ZEYoxEY */
    @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        input[type='checkbox'],
        input[type='radio'] {
            --active: ${Config.appearance.mainColor};
            --active-inner: #fff;
            --focus: 2px rgba(39, 94, 254, .3);
            --border: ${Config.appearance.mainColor};
            --border-hover: ${Config.appearance.mainColor};
            --background: #fff;
            --disabled: #F6F8FF;
            --disabled-inner: #E1E6F9;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 21px;
            outline: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin: 0;
            cursor: pointer;
            border: 1px solid var(--bc, var(--border));
            background: var(--b, var(--background));
            -webkit-transition: background .3s, border-color .3s, box-shadow .2s;
            transition: background .3s, border-color .3s, box-shadow .2s;
        }
    
        input[type='checkbox']:after,
        input[type='radio']:after {
            content: '';
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            -webkit-transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
            transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
        }
    
        input[type='checkbox']:checked,
        input[type='radio']:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: .3s;
            --d-t: .6s;
            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
        }
    
        input[type='checkbox']:disabled,
        input[type='radio']:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: .9;
        }
    
        input[type='checkbox']:disabled:checked,
        input[type='radio']:disabled:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
        }
    
        input[type='checkbox']:disabled + label,
        input[type='radio']:disabled + label {
            cursor: not-allowed;
        }
    
        input[type='checkbox']:hover:not(:checked):not(:disabled),
        input[type='radio']:hover:not(:checked):not(:disabled) {
            --bc: var(--border-hover);
        }
    
        input[type='checkbox']:focus,
        input[type='radio']:focus {
            box-shadow: 0 0 0 var(--focus);
        }
    
        input[type='checkbox']:not(.switch),
        input[type='radio']:not(.switch) {
            width: 21px;
        }
    
        input[type='checkbox']:not(.switch):after,
        input[type='radio']:not(.switch):after {
            opacity: var(--o, 0);
        }
    
        input[type='checkbox']:not(.switch):checked,
        input[type='radio']:not(.switch):checked {
            --o: 1;
        }
    
        input[type='checkbox'] + label,
        input[type='radio'] + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
    
        input[type='checkbox']:not(.switch) {
            border-radius: 7px;
        }
    
        input[type='checkbox']:not(.switch):after {
            width: 5px;
            height: 9px;
            border: 2px solid var(--active-inner);
            border-top: 0;
            border-left: 0;
            left: 7px;
            top: 4px;
            -webkit-transform: rotate(var(--r, 20deg));
            transform: rotate(var(--r, 20deg));
        }
    
        input[type='checkbox']:not(.switch):checked {
            --r: 43deg;
        }
    
        input[type='checkbox'].switch {
            width: 38px;
            border-radius: 11px;
        }
    
        input[type='checkbox'].switch:after {
            left: 2px;
            top: 2px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background: var(--ab, var(--border));
            -webkit-transform: translateX(var(--x, 0));
            transform: translateX(var(--x, 0));
        }
    
        input[type='checkbox'].switch:checked {
            --ab: var(--active-inner);
            --x: 17px;
        }
    
        input[type='checkbox'].switch:disabled:not(:checked):after {
            opacity: .6;
        }
    
        input[type='radio'] {
            border-radius: 50%;
        }
    
        input[type='radio']:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            -webkit-transform: scale(var(--s, 0.7));
            transform: scale(var(--s, 0.7));
        }
    
        input[type='radio']:checked {
            --s: .5;
        }
    }
    /* END Checkboxokhoz tartozó css */
    .ticket-status-progress {
        background-color: ${Config.appearance.mainColor};
    }
    .help-information-icon * {
        stroke: ${Config.appearance.iconColorWithoutBg};
    }
    .help-page-current-name{
        color: ${Config.appearance.upperNavigationtextColor}
    }
    .help-page-navigation.navigation-next {
        color: #fff;
        background: ${Config.appearance.mainColor};
    }
    .information-data {
        color: ${Config.appearance.mainColor};
    }
    .breakdown-value {
        color: ${Config.appearance.mainColor};
    }
    .upper-icons-text > .upper-icons-value {
        color: ${Config.appearance.mainColor};
    }
    .dropdown-item.active {
        color: ${Config.appearance.mainColor};
    }
    .analytics-graph-subtitle {
        color: ${Config.appearance.mainColor};
    }
    .sidebar .main-menu-item {
        color: ${Config.appearance.mainColor};
    }
    .round-menu-item > a {
        background-color: ${Config.appearance.mainColor};
    }
    .profile-image-edit {
        background: ${Config.appearance.mainColor};
    }
    .help-page-navigation.navigation-prev {
        color: ${Config.appearance.mainColor};
    }
    .qboard-link.legal-documents.accept {
        background: ${Config.appearance.mainColor};
    }
    .qboard-link.legal-documents.show {
        border: 2px solid ${Config.appearance.mainColor};
        color: ${Config.appearance.mainColor};
    }
    .regio-selector .left-selector.active, .regio-selector .right-selector.active {
        background-color: ${Config.appearance.mainColor};
    }
    .qboard-link.legal-document-open-btn {
        color: ${Config.appearance.mainColor};
    }
    .btn.form-submit {
        background-color: ${Config.appearance.mainColor};
    }
    .popup-link.email .popup-icon-container, .popup-link.pdf .popup-icon-container {
        color: ${Config.appearance.mainColor};
    }
    .send-button, .save-button {
        background: ${Config.appearance.mainColor};
    }
    .ticket-status {
        color: ${Config.appearance.mainColor};
    }
    .ticket-status-completed {
        background-color: ${Config.appearance.mainColor};
    }
    .graph-active-status .dot-left {
        background-color: ${Config.appearance.mainColor};
    }
    .graph-active-status .dot-right {
        background-color: ${Config.appearance.mainColor};
    }
    .progress-bar{
        background-color: ${Config.appearance.mainColor};
    }
    .spinner-border.text-primary{
        color: ${Config.appearance.mainColor} !important
    }
    `

    function GlobalStyles() {
        return <GlobalStyle />;
    }

export default GlobalStyles;
