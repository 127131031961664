import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth, AppStateContext, QbApi, QbApiTimeoutError, Config, QbTools } from '../modules';
import { BsCheck, BsChevronLeft } from 'react-icons/bs';
import { UpperNavigation } from './';
import { showMessage } from '../utilities/UiUtils';

function SettingsStaff() {
    const { t } = useTranslation();
    let history = useHistory();
    const match = useRouteMatch('/settings/branch/:id/staff');
    const appState = useContext(AppStateContext);
    useEffect( () => {
        appState.setActivePage('settings.staff');
    } );
    const [ ,setUpdate ] = useState(0);
    const [ searchString, setSearchString ] = useState('');

    const toSearch = searchString.trim();
    const toSearchArr = toSearch.toLowerCase().split(' ');



    let authData = Auth.getData();
    let branch = {
        userProfile: authData.userProfile.branches?.find(element => String(element.id) === match.params.id),
        user: authData.user.branches?.find(element => String(element.id) === match.params.id),
    };
    const branchIndex = authData.userProfile.branches?.findIndex(element => String(element.id) === match.params.id);
	if ((branchIndex === -1) || (typeof branchIndex === 'undefined')) {
        return <Redirect to={ '/settings' }/>
    }
    branch.user.staff = branch.user.staff.sort( (a, b) => QbTools.formatName(a.chanonicalName.fullName).localeCompare(QbTools.formatName(b.chanonicalName.fullName), 'hu', { ignorePunctuation: true } ) );
    branch.userProfile.staff = branch.userProfile.staff.sort( (a, b) => QbTools.formatName(a.chanonicalName.fullName).localeCompare(QbTools.formatName(b.chanonicalName.fullName), 'hu', { ignorePunctuation: true } ) );

    var activeStaffFragment = [ ];
    var allStaffFragment = [ ];
    for (const staff of branch.user.staff) {
        const contains = (branch.userProfile.staff.find(element => element.chanonicalName.userName === staff.chanonicalName.userName));
        let contains2 = false;

        if (toSearch !== '') {
            contains2 = true;
            for (const word of toSearchArr) {
                if (! staff.chanonicalName.fullName.toLowerCase().includes(word)) {
                    contains2 = false;
                }
            }
        }
        if ((toSearch === '') || contains2) {
            allStaffFragment.push(
                <div key={ staff.chanonicalName.userName } className="col-12 c-pointer" onClick={ () => changeStaff(staff) }>
                    <div className="row py-2 d-flex align-items-center settings-staff-item">
                        <div className="col-8">
                            <div>{ QbTools.formatName(staff.chanonicalName.fullName) }</div>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center settings-secondary-text">
                            { contains ?
                                <BsCheck className='icon-with-bg' />
                                :
                                <div className="col-1"></div>
                            }
                        </div>
                    </div>
                </div>
            );
        }

        
    }
    for (const staff of branch.userProfile.staff) {
        activeStaffFragment.push(
            <div key={ staff.chanonicalName.userName } className="col-12 c-pointer" onClick={ () => changeStaff(staff) }>
                <div className="row py-2 d-flex align-items-center settings-staff-item">
                    <div className="col-8">
                        <div>{ QbTools.formatName(staff.chanonicalName.fullName) }</div>
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center settings-secondary-text">
                        <BsCheck className='icon-with-bg' />
                    </div>
                </div>
            </div>
        );
    }

    async function changeStaff(staff) {
        let staffList = authData.userProfile.branches[branchIndex].staff;

        if (staffList.find(element => element.chanonicalName.userName === staff.chanonicalName.userName)) {
            staffList.splice(staffList.findIndex(element => element.chanonicalName.userName === staff.chanonicalName.userName), 1);
        } else {
            staffList.push(staff);
        }
        staffList.sort( (a, b) => QbTools.formatName(a.chanonicalName.fullName).localeCompare(QbTools.formatName(b.chanonicalName.fullName), 'hu', { ignorePunctuation: true } ) );
        authData.userProfile.branches[branchIndex].staff = staffList;
        const userProfile = {
            branches: authData.userProfile.branches
        };
        const updatedUserProfile = Auth.updateUserProfile(userProfile);
        setUpdate(prevState => prevState + 1);
        try {
            await QbApi.instance.updateUserProfile(appState, { userProfile: updatedUserProfile } );
        } catch (error) {
            if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
                showMessage( { title: t('webapp:toastTitleFailed'), message: t('webapp:toastDescriptionFailedUpdate'), type: 'error' } )
            } else {
                throw error;
            }
        }
    }
    function handleSearchChange(event) {
        setSearchString(event.target.value);
    }

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizeStoreStaff')
                    }
                    bottom={
                        <input className="form-control help-search-field" type="text" name="search" onChange={ handleSearchChange } />
                    }
                />
            </div>

            <div className="settings">
                <div className="container">
                    <div className="row align-items-center py-1 mb-2 setting-item mx-4">
                        <div className="col-12">
                            <div className="row font-weight-bold">
                                <div className="mb-2">{ t('localizeActive') }</div>
                            </div>
                            <div className="row settings-branch-item py-0">
                                { activeStaffFragment }
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center py-1 mb-2 setting-item mx-4">
                        <div className="col-12">
                            <div className="row font-weight-bold">
                                <div className="mb-2">{ t('localizeAll') }</div>
                            </div>
                            <div className="row settings-branch-item py-0">
                                { allStaffFragment }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SettingsStaff;
