import React, { useEffect, useRef } from 'react';
import { QBDashboardFigureCounter } from '../graph/';

function GraphServicePoint(props) {
    const canvasGraph = useRef(null);

    useEffect( () => {
        function getParentWidth() {
            return canvasGraph.current.parentElement.clientWidth;
        }

        function _resizeHandler() {
            canvasGraph.current.width = getParentWidth() * 2;
            canvasGraph.current.height = getParentWidth() * 2;

            clearAndDraw();
        }

        function clearAndDraw() {
            const ctx = canvasGraph.current.getContext('2d');
            if (ctx) {
                var rect = QBDashboardFigureCounter.makeRect(0, 0, getParentWidth() * 2, getParentWidth() * 2);
                QBDashboardFigureCounter.clearCanvas(canvasGraph.current);
                QBDashboardFigureCounter.drawCounter(
                    canvasGraph.current,
                    0.3, // const_inactive_alpha
                    props.text, // text
                    props.active, //  active
                    rect,
                    'aspectfit'
                );
            }
        }

        window.addEventListener('resize', _resizeHandler);

        canvasGraph.current.width = getParentWidth() * 2;
        canvasGraph.current.height = getParentWidth() * 2;

        clearAndDraw();

        return function cleanup() {
            window.removeEventListener('resize', _resizeHandler);
        }
    }, [ props.text, props.active ] );

    return (
        <div>
            <canvas ref={canvasGraph} />
        </div>
    );
}

export default GraphServicePoint;
