import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HelpIndex, HelpPage } from './';
import { NoRoutePage } from './';

function Help() {
    return (
        <Switch>
            <Route exact path="/help"><HelpIndex /></Route>
            <Route exact path="/help/:page"><HelpPage /></Route>

            <Route component={() => <NoRoutePage />} />
        </Switch>
    );
}

export default Help;
