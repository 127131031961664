import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphLineBarComposed, GraphPie } from './';
import { Config } from '../modules';
import { AjaxStateFragment } from './';

function AnalyticsDisplayLineBarComposedWithPie(props) {
    const { t } = useTranslation();
    var displayData = props.displayData;
    var pieData = props.pieData;
    const [ chartShown, setChartShown ] = useState('composed');
    const [ chartChanged, setChartChanged ] = useState(0);
    const graph1 = useRef(null);
    const graph1Title = useRef(null);
    var pieChart = false;
    if (props.hasOwnProperty('pieData')) {
        pieChart = true;
    }

    const jsonDisplayData = JSON.stringify(displayData);
    const jsonPieData = JSON.stringify(pieData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: displayData.subTitle || displayData.mainTitle,
                    displayData: displayData,
                    render: ( <AnalyticsDisplayLineBarComposedWithPie { ...props } /> ),
                    pdfData: {
                        requestTime: displayData._requestTime,
                        type: 'htmlsvg',
                        titleHtml: graph1Title.current.outerHTML,
                        html: graph1.current.innerHTML,
                    },
                };
                return prevState;
            } );
        }
    }, [ jsonDisplayData, displayData, jsonPieData, pieData, props, chartChanged ] );

    function handleChartSwitch(event) {
        if (! pieChart) {
            return;
        }
        setChartShown(prevState => {
            if (prevState === 'composed') {
                return 'pie';
            } else {
                return 'composed';
            }
        } );
        // a props.setComponentList()-et késleltetve hívjuk, így a recharts újra tudja rajzolni a grafikont, mielőtt lekérjük a tartalmát
        setTimeout( () => {
            setChartChanged(prevState => ( prevState + 1 ));
        }, 0);
    }

    const analyticsGraphSubtitleStyle = {
        color: Config.appearance.mainColor,
    }
    const roundDotStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    return (
        <div className="metric">
            <div className="row text-center my-1 pt-3 title" ref={ graph1Title } >
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t(displayData.mainTitle) } <span style={ analyticsGraphSubtitleStyle } className="analytics-graph-subtitle">–{ t(displayData.subTitle) }</span></h4>
                </div>
            </div>
            <div className="row my-1 position-relative chart-container" onClick={ handleChartSwitch }>
                <AjaxStateFragment _state={ displayData._state } _failed={ displayData._failed } />
                <div className="col-12 px-0">
                    { chartShown === 'composed' ?
                        <div className="analytics-composed-chart">
                            <GraphLineBarComposed displayData={ displayData } />
                        </div>
                    :
                        <div className="analytics-pie-chart">
                            <GraphPie pieData={ pieData } hasGraphLineBar={true}/>
                        </div>
                    }
                    { (pieChart) &&
                        <div className="graph-active-dots d-flex justify-content-end">
                            <div style={ roundDotStyle } className={ 'round-dot ' + (chartShown === 'composed' ? 'active' : '') } />
                            <div style={ roundDotStyle } className={ 'round-dot ' + (chartShown === 'pie'      ? 'active' : '') } />
                        </div>
                    }
                </div>
                <div className="chart-print-container">
                    { chartShown === 'composed' ?
                        <div className="analytics-composed-chart" ref={ graph1 } >
                            <GraphLineBarComposed displayData={ displayData } />
                        </div>
                    :
                        <div className="analytics-pie-chart" ref={ graph1 } >
                            <GraphPie pieData={ pieData } hasGraphLineBar={true}/>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

export default AnalyticsDisplayLineBarComposedWithPie;
