class Constants {
    _appInfo = {
        platform: 'web',
        version: '2.5.0',
    };

    _i18nDayOfWeek = [
        'localizeDayOfWeekMonday',
        'localizeDayOfWeekTuesday',
        'localizeDayOfWeekWednesday',
        'localizeDayOfWeekThursday',
        'localizeDayOfWeekFriday',
        'localizeDayOfWeekSaturday',
        'localizeDayOfWeekSunday',
    ];

    _i18nDayOfWeekMoment = [
        'localizeDayOfWeekSunday',
        'localizeDayOfWeekMonday',
        'localizeDayOfWeekTuesday',
        'localizeDayOfWeekWednesday',
        'localizeDayOfWeekThursday',
        'localizeDayOfWeekFriday',
        'localizeDayOfWeekSaturday',
    ];

    _ajaxState = {
        init: 0,
        done: 0,
        loading: 1,
        failed: 2,
    }


    static get appInfo() {
        return new Constants()._appInfo;
    }

    static get security() {
        return  {
            loginForm: process.env.REACT_APP_SECURITY_LOGINFORM,
        };
    }

    static get debug() {
        return  {
            loginPage: (Number(process.env.REACT_APP_DEBUG_LOGINPAGE) === 1),
            errorReportEnable: (Number(process.env.REACT_APP_ERRORREPORT_ENABLE) === 1),
            errorReportEndpoint: (process.env.REACT_APP_ERRORREPORT_API_ENDPOINT === '' ? process.env.REACT_APP_API_URL + 'log/webapp' : process.env.REACT_APP_ERRORREPORT_API_ENDPOINT),
            errorReportAnonymousAccessToken: String(process.env.REACT_APP_ERRORREPORT_ANONYMOUS_ACCESSTOKEN),
        }
    }

    static get pdfApi() {
        return  {
            enable: (Number(process.env.REACT_APP_PDF_ENABLE) === 1),
            pdfApiEndpoint: (process.env.REACT_APP_PDF_API_ENDPOINT === '' ? '/api/pdf' : process.env.REACT_APP_PDF_API_ENDPOINT),
        }
    }

    static get feedbackStatus() {
        return  {
            status: (Number(process.env.REACT_APP_ENABLE_FEEDBACK) === 1),
        }
    }

    static get supportStatus() {
        return  {
            status: (Number(process.env.REACT_APP_ENABLE_SUPPORT) === 1),
        }
    }

    static get authStorage() {
        return  process.env.REACT_APP_AUTHSTORAGE;
    }

    static get i18nDayOfWeek() {
        return new Constants()._i18nDayOfWeek;
    }

    static get i18nDayOfWeekMoment() {
        return new Constants()._i18nDayOfWeekMoment;
    }

    static get ajaxState() {
        return new Constants()._ajaxState;
    }
}

export default Constants;
