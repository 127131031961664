import React from 'react'
import {FaCheck} from 'react-icons/fa'
import {TICKET_STATUS_KEYS} from "../utilities/Constant";

const TicketStatusBar = (props) => {
	const {onClick}=props||{}
	const cursorStyle=typeof onClick==='function'?{cursor:'pointer'}:{cursor:'auto'}
	let {state,progress} = props
	if (state === TICKET_STATUS_KEYS.IDLE) {
		// return <button onClick={onClick}>testButton</button>
		return null
	}
	if (state === TICKET_STATUS_KEYS.COMPLETED) {
		return <div className="ticket-status-completed" onClick={onClick} style={cursorStyle}>
			<FaCheck/>
		</div>
	}
	return <div className="ticket-status p-0" onClick={onClick} style={cursorStyle}>
		<div className='ticket-status-progress' style={{width:`${progress}%`}}/>
	</div>
}

export default TicketStatusBar

TicketStatusBar.propTypes = {}

TicketStatusBar.defaultProps = {}
