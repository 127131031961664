import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Auth } from './modules';

// TODO: a language listát i18n.js-ből és SettingsLanguage.js-ből át kell tenni valami külsö(bb) config file-ba
const resources = {
    hu: { translations: require('./locales/hu/translation.json'), mzlegal: require('./locales/hu/mzlegal.json'), webapp: require('./locales/hu/webapp.json'), },
    en: { translations: require('./locales/en/translation.json'), mzlegal: require('./locales/en/mzlegal.json'), webapp: require('./locales/en/webapp.json'), },
    de: { translations: require('./locales/de/translation.json'), mzlegal: require('./locales/de/mzlegal.json'), webapp: require('./locales/de/webapp.json'), },
    nl: { translations: require('./locales/nl/translation.json'), mzlegal: require('./locales/nl/mzlegal.json'), webapp: require('./locales/nl/webapp.json'), },
    sv: { translations: require('./locales/sv/translation.json'), mzlegal: require('./locales/sv/mzlegal.json'), webapp: require('./locales/sv/webapp.json'), },
    nb: { translations: require('./locales/nb/translation.json'), mzlegal: require('./locales/nb/mzlegal.json'), webapp: require('./locales/nb/webapp.json'), },
    fi: { translations: require('./locales/fi/translation.json'), mzlegal: require('./locales/fi/mzlegal.json'), webapp: require('./locales/fi/webapp.json'), },
    ro: { translations: require('./locales/ro/translation.json'), mzlegal: require('./locales/ro/mzlegal.json'), webapp: require('./locales/ro/webapp.json'), },
    pl: { translations: require('./locales/pl/translation.json'), mzlegal: require('./locales/pl/mzlegal.json'), webapp: require('./locales/pl/webapp.json'), },
    sk: { translations: require('./locales/sk/translation.json'), mzlegal: require('./locales/sk/mzlegal.json'), webapp: require('./locales/sk/webapp.json'), },
    cs: { translations: require('./locales/cs/translation.json'), mzlegal: require('./locales/cs/mzlegal.json'), webapp: require('./locales/cs/webapp.json'), },
    it: { translations: require('./locales/it/translation.json'), mzlegal: require('./locales/it/mzlegal.json'), webapp: require('./locales/it/webapp.json'), },
    es: { translations: require('./locales/es/translation.json'), mzlegal: require('./locales/es/mzlegal.json'), webapp: require('./locales/es/webapp.json'), },
    fr: { translations: require('./locales/fr/translation.json'), mzlegal: require('./locales/fr/mzlegal.json'), webapp: require('./locales/fr/webapp.json'), },
};

var QbLanguageDetector = {
    type: 'languageDetector',
    async: false, // If this is set to true, your detect function receives a callback function that you should call with your language, useful to retrieve your language stored in AsyncStorage for example
    init: function(services, detectorOptions, i18nextOptions) {
        this.services = services;
        this.detectorOptions = detectorOptions;
        this.i18nextOptions = i18nextOptions;

        const i18nextLanguageDetector = new LanguageDetector(this.services, this.detectorOptions, this.i18nextOptions);
        this.detectedLanguage = i18nextLanguageDetector.detect();
    },
    detect: function(callback) { // You'll receive a callback if you passed async true
        var languageCode = '';
        var detectedLanguage = null;
        if (Auth.isUserAuthenticated()) {
            try {
                languageCode = Auth.getData().userProfile.settings.language.code;
            } catch (e) {
            }
        } else {
            try {
                languageCode = localStorage.getItem('qbLanguage');
            } catch (e) {
            }
        }
        if (! Object.keys(resources).includes(languageCode)) {
            languageCode = '';
        }

        if (languageCode === '') {
            for (const detectedLanguageCode of this.detectedLanguage) {
                if (Object.keys(resources).includes(detectedLanguageCode)) {
                    detectedLanguage = detectedLanguageCode;
                    break;
                }
            }
            if (detectedLanguage === null) {
                detectedLanguage = i18n.options.fallbackLng[0];
            }
        if (! Object.keys(resources).includes(languageCode)) {
            languageCode = '';
        }

        } else {
            detectedLanguage = languageCode;
        }
        return detectedLanguage;

        // callback('de'); if you used the async flag
        //return 'en';
    },
    cacheUserLanguage: function(lng) {
        /* cache language */
    }
}

i18n
    .use(QbLanguageDetector)
    .use(initReactI18next)
    .init( {
        resources: resources,

        //lng: 'en',
        fallbackLng: 'en',
        debug: true,

        // have a common namespace used around the full app
        ns: [ 'translations', 'mzlegal', 'webapp' ],
        defaultNS: 'translations',

        // keySeparator: false, // we use content as keys // XXX: használjuk-e vagy sem?

        interpolation: {
            escapeValue: false, // not needed for react
            formatSeparator: ',',
        },

        react: {
            wait: true,
        },
    } );

export default i18n;
