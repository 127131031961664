import React from 'react';
import { OptionsModal } from './';
import { Config } from '../modules';

function LowerNavigation(props) {

    const navigationLowerStyle = {
        backgroundColor: Config.appearance.lowerNavigationBackgroundColor,
    }

    if (props.hasOwnProperty('middle') && (typeof props.middle !== 'undefined')) {
        return (
            <div style={navigationLowerStyle} className="navigation navigation-lower settings-row py-2 sticky-top">
                <div className="container">
                    <div className="row">
                        <div className="col-5 col-lg-5">
                            { props.content }
                        </div>
                        <div className="col-5 col-lg-6">
                            { props.middle }
                        </div>
                        <div className="col-2 col-lg-1 d-flex justify-content-end">
                            <OptionsModal componentList={ props.componentList }/>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={navigationLowerStyle} className="navigation navigation-lower settings-row py-2 sticky-top">
                <div className="container">
                    <div className="row">
                        <div className="col-10 col-lg-11">
                            { props.content }
                        </div>
                        <div className="col-2 col-lg-1 d-flex justify-content-end">
                            <OptionsModal componentList={ props.componentList }/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LowerNavigation;
