import React, { useEffect, useRef } from 'react';
import { GraphServicePoint } from './';
import { AjaxStateFragment } from './';
import { useTranslation } from 'react-i18next';

function MetricDisplayBmActiveServicePoints(props) {
    var displayData = props.displayData;
    const { t } = useTranslation();
    const graph1 = useRef(null);

    const jsonDisplayData = JSON.stringify(displayData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            let canvasList = graph1.current.querySelectorAll('canvas');
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: 'localizeMetricCounters',
                    displayData: displayData,
                    render: ( <MetricDisplayBmActiveServicePoints displayData={ displayData }/> ),
                    pdfData: {
                        requestTime: displayData._requestTime,
                        type: 'canvaslist',
                        title: t('localizeMetricCounters'),
                        infoText: isNaN(displayData.open) ? '' : displayData.open + ' / ' + displayData.sum,
                        items: [
                        ],
                    },
                };
                for (const canvas of Object.values(canvasList)) {
                    let item = {
                    }
                    Object.defineProperty(item, 'imageData', {
                        get: function () { return canvas.toDataURL('image/png'); },
                        enumerable: true,
                    } );
                    prevState.items[props.position].pdfData.items.push(item);
                }
                return prevState;
            } );
        }
    }, [ jsonDisplayData, displayData, props, t ] );

    var servicePoints = [ ];
    for (const servicePoint of displayData.servicePoints) {
        servicePoints.push(
            <div key={ servicePoint.name } className={"service-point-icon " + (servicePoint.active ? 'active' : 'inactive')}>
                <GraphServicePoint text={ servicePoint.name } active={ servicePoint.active } />
            </div>
        );
    }

    return (
        <div className="metric service-points">
            <div className="row text-center my-1 pt-3 title">
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t('localizeMetricCounters') }</h4>
                </div>
            </div>
            <div className="row branch-row-icon">
                <AjaxStateFragment _state={ displayData._state } _failed={ displayData._failed } />
                <div className="col-2 col-md-1 text-center active-graph-counter">
                    { isNaN(displayData.open) ? '' : ( <React.Fragment> { displayData.open } / { displayData.sum } </React.Fragment> ) }
                </div>
                <div className="col-10 col-md-11 service-point-icon-holder icon-holder" ref={ graph1 } >
                    { servicePoints }
                </div>
            </div>
        </div>
    );
}

export default MetricDisplayBmActiveServicePoints;
