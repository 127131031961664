import React  from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Config, Constants, QbApi } from '../modules';
import { BsChevronLeft } from 'react-icons/bs';
import { UpperNavigation } from '../components/';

class QbErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState( {
            error: error,
            errorInfo: errorInfo,
        } );
        // Log the error to an error reporting service
        QbApi.instance.sendErrorReport( {
            error: error && error.toString(),
            errorInfo: errorInfo,
        } );
    }

    goBackUpdate() {
        this.props.history.goBack();
        // töröljük az error state-et; de késleltetve, hogy ne okozzunk azonnali re-rendert, és azzal exception rethrow-t
        setTimeout( () => {
            this.setState( {
                hasError: false,
            } );
        }, 250);
        return true;
    }

    render() {
        if (this.state.hasError) {
            // error fallback UI
            return (
                <React.Fragment>
                    { this.props.scope === 'layout' ? (
                        <div className={( Config.appearance.enableStickyTop) ? "sticky-top" : "" }>
                            <UpperNavigation
                                leftSide={
                                    <div className="qboard-link" onClick={ () => this.goBackUpdate() }>
                                        <BsChevronLeft className="icon-with-bg float-none ml-1" />
                                    </div>
                                }
                                middle={
                                    this.props.t('localizeDefaultErrorTitle')
                                }
                            />
                        </div>
                    ) : '' }
                    <div className="container">
                        <h1>{ this.props.t('localizeDefaultErrorTitle') }</h1>
                        { Constants.debug.loginPage ? (
                            <details style={{ whiteSpace: 'pre-wrap' }}>
                                { this.state.error && this.state.error.toString() }<br/>
                                { this.state.errorInfo?.componentStack && this.state.errorInfo.componentStack }
                            </details>
                        ) : '' }
                    </div>
                </React.Fragment>
            );
        }
        return this.props.children;
    }
}

export default withRouter(withTranslation()(QbErrorBoundary));
