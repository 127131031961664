import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LegalDocumentsCurrentList, LegalDocumentsCurrentShowPdf, LegalDocumentsHistoryList, LegalDocumentsHistoryShowPdf } from './';
import { NoRoutePage } from './';

function LegalDocuments(props) {
    return (
        <Switch>
            <Route exact path="/legal/current"><LegalDocumentsCurrentList /></Route>
            <Route exact path="/legal/current/:id"><LegalDocumentsCurrentShowPdf /></Route>

            { (props.authPhase === 'auth') ? (
                <React.Fragment>
                    <Route exact path="/legal/history"><LegalDocumentsHistoryList /></Route>
                    <Route exact path="/legal/history/:id"><LegalDocumentsHistoryShowPdf /></Route>
                </React.Fragment>
            ) : '' }

            <Route component={() => <NoRoutePage />} />
        </Switch>
    );
}

export default LegalDocuments;
