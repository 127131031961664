function QbApiTimeoutError(errorString, ...args) {
    const instance = Reflect.construct(Error, args);
    Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
    instance.errorString = errorString;
    instance.name = 'QbApiTimeoutError';
    return instance;
}

QbApiTimeoutError.prototype = Object.create(Error.prototype, {
    constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true
    }
} );

Reflect.setPrototypeOf(QbApiTimeoutError, Error);

export default QbApiTimeoutError;
