//
//  QBDashboardFigureServeLevel.js
//  qboard
//
//  Created by Zoltan Meszaros on 2019. 12. 16..
//  Copyright ÂŠ 2019 Xperion. All rights reserved.
//
//  Generated by PaintCode
//  http://www.paintcodeapp.com
//



//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    QBDashboardFigureServeLevel.drawLevel(canvas, const_inactive_alpha, const_percent_max, const_percent_limit_green, const_percent_limit_yellow, value, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//
// Available Utilities:
//    QBDashboardFigureServeLevel.clearCanvas(canvas)
//    QBDashboardFigureServeLevel.makeRect(x, y, width, height)


//// Create StyleKit Object
var QBDashboardFigureServeLevel = {};
(function() {

    //// Drawing Methods

    function drawLevel(canvas, const_inactive_alpha, const_percent_max, const_percent_limit_green, const_percent_limit_yellow, value, targetFrame, resizing, color1, color2, color3) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        //var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 70), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 75, resizedFrame.h / 75);


        //// Color Declarations
        var green = color1;
        var yellow = color2;
        var red = color3;
        var color6 = 'rgba(205,210,253, 1)';

        /*var green = 'rgba(85, 226, 87, 1)';
        var yellow = 'rgba(0, 102, 255, 1)';
        var red = 'rgba(254,76,76, 1)';
        var color6 = 'rgba(205,210,253, 1)';*/

        //// Variable Declarations
        /*var const_percent_limit_green_angle = -1 * (const_percent_max / const_percent_max) * 360 + 90;
        var const_percent_limit_yellow_angle = -1 * (const_percent_limit_green / const_percent_max) * 360 + 90;
        var const_percent_limit_red_angle = -1 * (const_percent_limit_yellow / const_percent_max) * 360 + 90;*/
        var alpha = value >= 0 ? 1 : const_inactive_alpha;
        var progress = value < 0 ? 0 : (value > 100 ? 100 : value);
        var angle_result = -1 * progress / 100 * 360 + 90;
        var percent_float = progress;
        var percent_int = Math.floor(percent_float);
        var percent_fraction_int = 10 * (percent_float - percent_int);
        var text_result = ('' + Math.round(percent_fraction_int > 9 ? percent_int + 1 : percent_int)) + '.' + Math.round(percent_fraction_int > 9 ? 0 : percent_fraction_int) + '%';
        var colorOfDial = percent_float > const_percent_limit_green ? green : (percent_float > const_percent_limit_yellow ? yellow : red);
        var colorOfText = 'rgba(0,0,0,1)';

        //// data_background Drawing
        context.save();
        context.globalAlpha = alpha;
        oval(context, 3, 3, 69, 69);
        context.strokeStyle = color6;
        context.lineWidth = 6;
        context.stroke();
        context.restore();


        //// data_oval Drawing
        context.save();
        context.globalAlpha = alpha;
        arc(context, 3, 3, 69, 69, -90, -angle_result, false);
        context.strokeStyle = colorOfDial;
        context.lineWidth = 6;
        context.stroke();
        context.restore();


        //// data_text Drawing
        context.save();
        context.globalAlpha = alpha;
        var data_textRect = makeRect(14, 27, 51, 23);
        context.fillStyle = colorOfText;
        context.font = '600 13px HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        var data_textTotalHeight = 13 * 1.3;
        context.fillText(text_result, data_textRect.x + data_textRect.w/2, data_textRect.y + 13 + data_textRect.h / 2 - data_textTotalHeight / 2);
        context.restore();


        //// green_oval Drawing
        /*context.save();
        context.globalAlpha = alpha;
        arc(context, 6, 6, 58, 58, -const_percent_limit_yellow_angle, -const_percent_limit_green_angle, false);
        context.strokeStyle = green;
        context.lineWidth = 0.5;
        context.stroke();
        context.restore();*/


        //// yellow_oval Drawing
        /*context.save();
        context.globalAlpha = alpha;
        arc(context, 6, 6, 58, 58, -const_percent_limit_red_angle, -const_percent_limit_yellow_angle, false);
        context.strokeStyle = yellow;
        context.lineWidth = 0.5;
        context.stroke();
        context.restore();*/


        //// red_oval Drawing
        /*context.save();
        context.globalAlpha = alpha;
        arc(context, 6, 6, 58, 58, -90, -const_percent_limit_red_angle, false);
        context.strokeStyle = red;
        context.lineWidth = 0.5;
        context.stroke();
        context.restore();*/
        
        context.restore();

    }

    //// Infrastructure

    function clearCanvas(canvas) {
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    // Possible arguments for 'resizing' parameter are:
    //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
    //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
    //   'stretch': The content is stretched to match the entire target rectangle.
    //   'center': The content is centered in the target rectangle, but it is NOT resized.
    function applyResizingBehavior(resizing, rect, targetRect) {
        if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
            return rect;
        }

        var scales = makeSize(0, 0);
        scales.w = Math.abs(targetRect.w / rect.w);
        scales.h = Math.abs(targetRect.h / rect.h);

        switch (resizing) {
            case 'aspectfit': {
                scales.w = Math.min(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'aspectfill': {
                scales.w = Math.max(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'stretch':
            case undefined:
                break;
            case 'center': {
                scales.w = 1;
                scales.h = 1;
                break;
            }
            default:
                throw new Error('Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.');
        }

        var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h));
        result.w *= scales.w;
        result.h *= scales.h;
        result.x = targetRect.x + (targetRect.w - result.w) / 2;
        result.y = targetRect.y + (targetRect.h - result.h) / 2;
        return result;
    }

    function oval(context, x, y, w, h) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, 0, 2*Math.PI, false);
        context.closePath();
        context.restore();
    }

    function arc(context, x, y, w, h, startAngle, endAngle, isClosed) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, Math.PI/180*startAngle, Math.PI/180*endAngle, false);
        if (isClosed)
        {
            context.lineTo(1, 1);
            context.closePath();
        }
        context.restore();
    }

    function makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    function equalRects(r1, r2) {
        return r1.x === r2.x && r1.y === r2.y && r1.w === r2.w && r1.h === r2.h;
    }

    function makeSize(w, h) {
        return { w: w, h: h };
    }

    function initializeCanvas(canvas) {
        if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        var context = canvas.getContext('2d');

        var devicePixelRatio = window.devicePixelRatio || 1;
        var backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        var pixelRatio = devicePixelRatio / backingStorePixelRatio;

        //canvas.style.width = canvas.width + 'px';
        //canvas.style.height = canvas.height + 'px';
        canvas.width *= pixelRatio;
        canvas.height *= pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return canvas;
    }

    //// Public Interface

    // Drawing Methods
    QBDashboardFigureServeLevel.drawLevel = drawLevel;

    // Utilities
    QBDashboardFigureServeLevel.clearCanvas = clearCanvas;
    QBDashboardFigureServeLevel.makeRect = makeRect;

})();

export default QBDashboardFigureServeLevel;
