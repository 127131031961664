import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth, AppStateContext, Config } from '../modules';
import { useTranslation } from 'react-i18next';

function AuthLegalForm(props) {
    const { t, i18n } = useTranslation();
    const appState = useContext(AppStateContext);
    const lng = i18n.language;
    const legalMissingNum = Auth.getItem('legalMissing').data.length

    useEffect( () => {
        if (legalMissingNum === 0) {
            appState.setAuthPhase('tour');
            Auth.setAuthPhase('tour');
        }
    }, [ legalMissingNum, appState ] );

    if (legalMissingNum === 0) {
        return '';
    }

    return (
        <React.Fragment>
            <div className="auth-legal-background"></div>
            <div className="container h-100 d-flex justify-content-center">
                <div className="row justify-content-center align-items-center text-center">
                    <div className="col-md-7  auth-legal-form">
                        <div className="row">
                            <div className="col-12">
                                <h4>{ t('mzlegal:localizeLegalUpdateTitle') }</h4>
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-12">
                                { t('mzlegal:localizeLegalUpdateMessage') }
                            </div>
                        </div>
                        <div className="row d-flex align-items-center mt-3">
                            <div className="col-6">
                                <Link style={{color: Config.appearance.mainColor, borderColor: Config.appearance.mainColor,}} className="qboard-link legal-documents show" to={ '/auth/legal/current' }>
                                    <div className="row my-2">
                                        <div className="col-12">{ t('mzlegal:localizeLegalViewPolicies') }</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-6">
                                <div style={{backgroundColor: Config.appearance.mainColor,}} className="qboard-link legal-documents accept" onClick={ (e) => props.acceptLegalDocuments(e, appState, lng) }>
                                    <div className="row my-2">
                                        <div className="col-12">
                                            { t('mzlegal:localizeLegalAcceptAndContinue') }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AuthLegalForm;
