import React from 'react';
import { MetricDisplayBmActiveServicePoints } from './';
import { useTranslation } from 'react-i18next';
import { QbTools } from '../modules';

function MetricProxyBmActiveServicePoints(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();

    var data = {
        ROP: { },
        RCP: { },
        sum: NaN,
        open: NaN,
        closed: NaN,
        servicePoints: [ ],
    };
    if (QbTools.metricDataReady(metricData, [ 'current' ] )) {
        const branchName = Object.keys(metricData.current.data)[0];
        data.ROP = metricData.current.data[branchName]['ROP'] || [ ];
        data.RCP = metricData.current.data[branchName]['RCP'] || [ ];
        data.open = data.ROP.length;
        data.closed = data.RCP.length;
        data.sum = data.open + data.closed;
        for (const [, value] of Object.entries(data.ROP)) {
            data.servicePoints.push( { name: value, active: true } );
        }
        for (const [, value] of Object.entries(data.RCP)) {
            data.servicePoints.push( { name: value, active: false } );
        }
        data.servicePoints.sort(function (a, b) {
            return (a.name < b.name ? -1 : ( a.name > b.name ? 1 : 0 ));
        } );
    }

    var displayData = {
        ROP: data.ROP,
        RCP: data.RCP,
        sum: data.sum,
        open: data.open,
        closed: data.closed,
        servicePoints: data.servicePoints,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'current' ], t),
    };

    return (
        <MetricDisplayBmActiveServicePoints displayData={ displayData } { ...props } />
    );
}

export default MetricProxyBmActiveServicePoints;
