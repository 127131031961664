import React from 'react';
import { QbTools } from '../modules/';
import { Config } from '../modules/';
import { AnalyticsDisplayLineBarComposedWithPie } from './';
import { useTranslation } from 'react-i18next';

function AnalyticsProxyCustomerCountServiceLevel(props) {
    const { t } = useTranslation();
    var sprintf = require('sprintf-js').sprintf;
    var metricData = props.metricData;
    var sortedData = Object.entries(metricData.timebased?.data || { } );
    sortedData = QbTools.analyticsFilterDailyChart(sortedData, props.durationInfo.key);
    sortedData.sort(function (a, b) {
        return (a[0] < b[0] ? -1 : ( a[0] > b[0] ? 1 : 0 ));
    } );
    const graphDefaults = Config.graphDefaults;

    var data = {
        values: [ ],
    };

    var pieData = {
        values: [
            { value: 0, color: Config.appearance.pieColorsWithBars[0] },
            { value: 0, color: Config.appearance.pieColorsWithBars[1] },
            { value: 0, color: Config.appearance.pieColorsWithBars[3] },
            { value: 0, color: Config.appearance.pieColorsWithBars[2] },
        ],
    };

    for (const [key, child] of sortedData) {
        data.values.push( {
            datetime: QbTools.analyticsFormatDatetime(key, props.durationInfo.key, t),
            graph1: child.USZ,
            graph2: child.KSZ * 100,
        } );
        pieData.values[QbTools.analyticsGraphLimits(child.KSZ * 100, graphDefaults.ServiceLevel)].value++;
    }

    var displayData = {
        mainTitle: 'localizeAnalyticsCustomerCountTitle',
        subTitle: 'localizeAnalyticsServeLevelTitle',

        graph1Title: 'localizeAnalyticsCustomerCountTitle',

        graph2Title: 'localizeAnalyticsServeLevelTitle',
        graph2Min: 0,
        graph2Max: 100,

        graph2Formatter: (val) => sprintf('%.0f%%', val),

        values: data.values,
        colors: [
            { color: Config.appearance.darkRed, max: graphDefaults.ServiceLevel[0] },
            { color: Config.appearance.darkGreen, max: graphDefaults.ServiceLevel[1] },
            { color: Config.appearance.darkGreen },
        ],

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'timebased' ], t),
    };

    return (
        <AnalyticsDisplayLineBarComposedWithPie displayData={ displayData } pieData={ pieData } { ...props } />
    );
}

export default AnalyticsProxyCustomerCountServiceLevel;
