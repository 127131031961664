import React from 'react'
import {useTranslation} from "react-i18next";
import {Modal, Spinner} from "react-bootstrap";
import {Config} from "../modules";

const CustomSpinner = () =>
	<Spinner
		animation="border"
		size="sm"
		className='d-inline-block ml-2'
		style={ {marginBottom: 2} }/>

const ReviewReportModal = (props) => {
	const {userName, isBranchesAllowed, branchTitleKey, groupItem = {}} = props || {}
	console.log({groupItem})
	const {
		problemHeading,
		failureDescription,
		affectedSystems,
		affectedUsers,
		urgency,
	} = props?.reportData || {}
	const {isLoading} = props || {}
	const {t} = useTranslation()
    const {t: webappT} = useTranslation('webapp');
    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
	return <Modal
		show={ props.show }
		onHide={ props.onHide }
		scrollable
		size="lg" aria-labelledby="contained-modal-title-vcenter"
		centered
	>
		<div>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{ webappT('reviewReport') }
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={ {maxHeight: 400} }>
				<div className="container px-0">
					<div className="ticket-item col-12">
						<span className="title">Host: </span>
						<span className='break-word'> { window.location.hostname }</span>
					</div>
					<div className="ticket-item col-12">
						<span className="title">Username: </span>
						<span className='break-word'> { userName }</span>
					</div>
					<div className="ticket-item col-12">
						<span className="title">Short Description: </span>
						<span className='break-word'>{ problemHeading }</span>
					</div>
					<div className="ticket-item col-12">
						<span className="title">Failure Description: </span>
						<span className='break-word'>{ failureDescription }</span>
					</div>
					<div className="ticket-item col-12">
						<span className="title">Affected Systems: </span>
						<span className='break-word'>{ affectedSystems }</span>
					</div>
					<div className="ticket-item col-12">
						<span className="title">Effect: </span>
						<span className='break-word'>{ affectedUsers }</span>
					</div>
					<div className="ticket-item col-12">
						<span className="title">Urgency: </span>
						<span className='break-word'>{ urgency }</span>
					</div>
					{ isBranchesAllowed && groupItem?.id !== '0' &&
					<div className="ticket-item col-12">
						<span className="title">{ webappT(branchTitleKey) }: </span>
						<span className='break-word'>{ `${ groupItem?.name } (${ groupItem?.id })` }</span>
					</div>
					}
					
					<div className="row pt-2">
						<div className="col-6">
							<button
                                disabled={ isLoading }
                                style={ submitButtonStyle }
								className="btn btn-primary btn-block"
								onClick={ props.onHide }>{ t('localizeCancel') }</button>
						</div>
						<div className="col-6">
							<button
                                disabled={ isLoading }
                                style={ submitButtonStyle }
								className="btn btn-primary btn-block"
								onClick={ props.onDone }>{ t('localizeSend') }
								{ isLoading && <CustomSpinner/> }
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</div>
	</Modal>
}


export default ReviewReportModal
