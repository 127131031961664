import {QbApi} from "../modules";

export const convertImageToBase64 = (file) => new Promise(function (resolve, reject) {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result)
	reader.onerror = (error) => reject('Error: ', error);
})

export const getBase64 = (file) => new Promise(function (resolve, reject) {
	let reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result)
	reader.onerror = (error) => reject('');
})

export const fetchImage = async ({id}) => {
	const rawResponse = await QbApi.instance.fetchReportImage({}, {id});
	const image = await rawResponse.blob()
	return await getBase64(image)
}
