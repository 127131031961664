import React, {useContext, useEffect, useState} from 'react';
import {Link, Redirect, useRouteMatch} from 'react-router-dom';
import { AppStateContext, Auth, Config, QbApiCache, QbTools } from '../modules';
import {DashboardHierarchy, DashboardInvalid, NoRoutePage} from './';
import Dropdown from 'react-bootstrap/Dropdown';
import {FaCaretDown} from 'react-icons/fa';
import {FaChevronDown} from 'react-icons/fa';
import {FiRefreshCcw} from 'react-icons/fi';
import {GiHamburgerMenu} from 'react-icons/gi';
import {useTranslation} from 'react-i18next';
import { UpperNavigation, LowerNavigation } from './';
import { IconContext } from "react-icons";
import moment from 'moment-timezone';

function DashboardBreakdown(props) {
	const boardtype = 'breakdown';
	const appState = useContext(AppStateContext);
	const [ refreshCounter, setRefreshCounter ] = useState(0);
	const [ lowerNavigationMiddle, setLowerNavigationMiddle ] = useState();
	var match = {};
	const match1 = useRouteMatch('/dashboard/' + boardtype + '/:id/:pagetype');
	const match2 = useRouteMatch('/dashboard/' + boardtype + '/:id/:pagetype/:subid');
	const match3 = useRouteMatch('/dashboard/' + boardtype + '/:id/:pagetype/:subid/:worst');
	const matchShort = useRouteMatch('/dashboard/' + boardtype + '/:id');
    const { t, i18n } = useTranslation();
    const lng = i18n.language;
	useEffect(() => {
		appState.setActivePage('breakdown');
	});
	useEffect(() => {
		const timer = setInterval(() => {
			incRefreshCounter();
		}, Config.dataRefreshInterval * 1000);
		
		return () => clearInterval(timer);
	}, []);
	
	function incRefreshCounter() {
		setRefreshCounter(refreshCounter => refreshCounter + 1);
	}
	
    //const to = QbTools.nowApiTimeOffset().set('second', 0);
    const to = moment().set('second', 0);
	
	const userMerged = Auth.getData().userMerged;
	const groups = userMerged.groups;
	const topHierarchyLevel = userMerged.topHierarchyLevel.toLowerCase();
    const [ componentList, setComponentList ] = useState( {
        items: { },
        header: {
            title: Object.values(Auth.getData().userMerged.groups).find(group => group.id === matchShort?.params?.id)?.name,
            subTitle: t(DashboardHierarchy[topHierarchyLevel].breakdown.translation),
        },
    } );
	if (props.type === 'main') {
		match = match1;
	} else if (props.type === 'subitem') {
		match = match2;
	} else if (props.type === 'worstitem') {
		match = match3;
	} else {
		return (<NoRoutePage/>);
	}
	if (! match) {
		return ( <Redirect to={ '/dashboard/' + boardtype + '/' + Object.keys(groups)[0] + '/scc' }/> );
	}
	if (! groups.hasOwnProperty(match.params.id)) {
		return ( <Redirect to={ '/dashboard/' + boardtype + '/' + Object.keys(groups)[0] + '/scc' }/> );
	}
	
	var hierarchySelector = [];
	let currentItem = {
		name: null,
		component: DashboardInvalid,
    }
    const activeDropdownItem = {
        color: Config.appearance.mainColor,
    }
	for (const [ , item ] of Object.entries(DashboardHierarchy[topHierarchyLevel])) {
		let itemActive = false;
		if ((item.boardtype === boardtype) && item.component) {
			currentItem = item;
			itemActive = true;
		}
		hierarchySelector.push(
			<Dropdown.Item style={(itemActive) ? activeDropdownItem : null } key={ item.boardtype } as={ Link } to={ '/dashboard/' + item.boardtype } active={ itemActive }>{ t(item.translation) }</Dropdown.Item>
		);
	}
	let DisplayComponent = currentItem.component;
	
    var itemSelector = [ ];
	const currentBranch = groups[match.params.id];
	for (const group of Object.values(groups)) {
		itemSelector.push(
			<Dropdown.Item style={ (match.params.id === group.id)? activeDropdownItem : null } key={ group.id } as={ Link } to={ '/dashboard/' + boardtype + '/' + group.id + '/' + match.params.pagetype } active={ match.params.id === group.id }>{ group.name }</Dropdown.Item>
		);
	}
	
	function refreshData() {
		QbApiCache.instance.clear();
		incRefreshCounter();
	}
    
    const dataRefreshIconStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    const upperNavigationTextColor = {
        color: Config.appearance.upperNavigationtextColor,
    }
	
    return (
        <React.Fragment>
            { (props.type === 'main') ? (
                <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                    <UpperNavigation
                        leftSide={
                            <span className="toggle-menu" onClick={ appState.toggleMenuVisible }>
                                <GiHamburgerMenu/>
                            </span>
                        }
                        middle={
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="sub-page-selector" style={ upperNavigationTextColor }>
                                    { currentItem.pageIcon } { t(currentItem.translation) } 
                                    <IconContext.Provider value={{ color: Config.appearance.upperNavigationtextColor, className: "down-icon" }}>
                                        <FaChevronDown />
                                    </IconContext.Provider>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    { hierarchySelector }
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        rightSide={
                            <div className="d-flex">
                                <div className="refresh-time pr-1">
                                    { to.format(Config.onlyTimeDisplayFormat) }
                                    <br/>
                                    { new Date(to.format(Config.onlyDateDisplayFormat)).toLocaleDateString(lng, {year: 'numeric', month: 'short', day: 'numeric'}) }
                                </div>
                                <div className="refresh-container d-flex align-items-center">
                                    <FiRefreshCcw style={ dataRefreshIconStyle } className="data-refresh-icon" onClick={ () => refreshData() }/>
                                </div>
                            </div>
                        }
                    />
                    <LowerNavigation
                        content={
                            <Dropdown className="branch-selector-container">
                                <Dropdown.Toggle variant="primary" id="branch-selector">
                                    <span className="single-line-page-name">{ currentBranch.name }</span> { (topHierarchyLevel !== 'hq') ? (<FaCaretDown/>) : '' }
                                </Dropdown.Toggle>
                                { (topHierarchyLevel !== 'hq') ? (
                                    <Dropdown.Menu>
                                        { itemSelector }
                                    </Dropdown.Menu>
                                ) : '' }
                            </Dropdown>
                        }
                        middle={ lowerNavigationMiddle }
                        componentList={ componentList }
                    />

                </div>
            ) : '' }

            <DisplayComponent match={ match } type={ props.type } refreshCounter={ refreshCounter } setLowerNavigationMiddle={ setLowerNavigationMiddle } componentList={ componentList } setComponentList={ setComponentList } />
        </React.Fragment>
    );
}

export default DashboardBreakdown;
