import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { AppStateContext, Config, Auth, QbErrorBoundary } from '../modules';
import { NoRoutePage } from './';
import { DashboardHierarchy } from './';
import { DashboardInvalid } from './';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaCaretDown } from 'react-icons/fa';
import { FaChevronDown } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsChevronLeft } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { UpperNavigation, LowerNavigation } from './';
import { IconContext } from "react-icons";

function DashboardCamera(props) {
    const boardtype = 'camera';
    const appState = useContext(AppStateContext);
    var match = false;
    const match1 = useRouteMatch('/dashboard/' + boardtype + '/:id');
    const match2 = useRouteMatch('/dashboard/' + boardtype + '/:id/:cameraId');
    const { t } = useTranslation();
    useEffect( () => {
        appState.setActivePage('camera');
    } );

    if (props.type === 'redirect') {
    } else if (props.type === 'main') {
        match = match1;
    } else if (props.type === 'subitem') {
        match = match2;
    } else {
        return ( <NoRoutePage /> );
    }
    const userData = Auth.getData().user;
    const userMerged = Auth.getData().userMerged;
    const groups = userMerged.groups;
    const branches = userMerged.branches;
    const topHierarchyLevel = userData.topHierarchyLevel.toLowerCase();
    if (! branches) {
        return ( <Redirect to={ '/dashboard/' } /> );
    }
    if (! match) {
        return ( <Redirect to={ '/dashboard/' + boardtype + '/' + Object.keys(groups)[0] } /> );
    }
    if (! groups.hasOwnProperty(match.params.id)) {
        return ( <Redirect to={ '/dashboard/' + boardtype + '/' + Object.keys(groups)[0] } /> );
    }
    if (! DashboardHierarchy[topHierarchyLevel].hasOwnProperty(boardtype)) {
        return ( <Redirect to={ '/dashboard/' } /> );
    }

    const branch = branches[match.params.id];

    var hierarchySelector = [ ];
    let currentItem = {
        name: null,
        component: DashboardInvalid,
        subComponent: DashboardInvalid,
    }
    const activeDropdownItem = {
        color: Config.appearance.mainColor,
    }
    for (const [, item] of Object.entries(DashboardHierarchy[topHierarchyLevel])) {
        let itemActive = false;
        if ((item.boardtype === boardtype) && item.component) {
            currentItem = item;
            itemActive = true;
        }
        hierarchySelector.push(
            <Dropdown.Item style={(itemActive) ? activeDropdownItem : null } key={ item.boardtype } as={ Link } to={ '/dashboard/' + item.boardtype } active={ itemActive }>{ t(item.translation) }</Dropdown.Item>
        );
    }
    let DisplayComponent = currentItem.component;
    if (props.type === 'subitem') {
        DisplayComponent = currentItem.subComponent;
    }

    var itemSelector = [ ];
    const currentBranch = groups[match.params.id];
    for (const group of Object.values(groups)) {
        itemSelector.push(
            <Dropdown.Item style={ (match.params.id === group.id)? activeDropdownItem : null } key={ group.id } as={ Link } to={ '/dashboard/' + boardtype + '/' + group.id } active={ match.params.id === group.id }>{ group.name }</Dropdown.Item>
        );
    }

    const upperNavigationTextColor = {
        color: Config.appearance.upperNavigationtextColor,
    }

    return (
        <React.Fragment>
            { (props.type === 'main') ? (
                <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                    <UpperNavigation 
                        leftSide={
                            <span className="toggle-menu" onClick={ appState.toggleMenuVisible }>
                                <GiHamburgerMenu />
                            </span>
                        }
                        middle={
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="sub-page-selector" style={ upperNavigationTextColor }>
                                    { currentItem.pageIcon } { t(currentItem.translation) } 
                                    <IconContext.Provider value={{ color: Config.appearance.upperNavigationtextColor, className: "down-icon" }}>
                                        <FaChevronDown />
                                    </IconContext.Provider>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    { hierarchySelector }
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    />
                    <LowerNavigation 
                        content={
                            <Dropdown className="branch-selector-container">
                                <Dropdown.Toggle variant="primary" id="branch-selector">
                                    { currentBranch.name } <FaCaretDown />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    { itemSelector }
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    />
                </div>
            ) : (
                <UpperNavigation 
                        leftSide={
                            <Link to={ '/dashboard/' + boardtype + '/' + match.params.id }>
                                <BsChevronLeft className="icon-with-bg float-none ml-1" />
                            </Link>
                        }
                        middle={
                             branch.name + ' – ' + t('localizeDashboardMenuCamerasTitle')
                        }
                    />
            ) }

            <QbErrorBoundary>
                <DisplayComponent match={ match } type={ props.type }/>
            </QbErrorBoundary>

        </React.Fragment>
    );
}

export default DashboardCamera;
