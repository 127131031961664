import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QbApi, Auth, AppStateContext, Config } from '../modules';
import { BsChevronLeft } from 'react-icons/bs';
import { UpperNavigation, LegalDocumentsFragment } from './';

// almost identical with SettingsPrivacyPolicy
function LegalDocumentsCurrentList(props) {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const appState = useContext(AppStateContext);
    const [ legalDocuments, setLegalDocuments ] = useState( [ ] );
    const lng = i18n.language;
    let pathPrefix = '';
    if (props.hasOwnProperty('pathPrefix')) {
        pathPrefix = props.pathPrefix;
    }

    useEffect( () => {
        async function renderOutput() {
            let legalData = Auth.safeGetItem('legalDocuments');
            let missing = Auth.safeGetItem('legalMissing');
            if ((typeof legalData === 'undefined') || (legalData === null)) {
                legalData = await QbApi.instance.getAsync(appState, 'legal/documents');
                Auth.setItem('legalDocuments', legalData);
            }

            let legalDocumentsFragment = [ ];
            for (let index = 0, len = legalData.data.length; index < len; ++index) {
                const legal = legalData.data[index];
                if ((props.missing === 1) && (! missing.data.includes(legal.id))) {
                    continue;
                }
                legalDocumentsFragment.push(
                    <LegalDocumentsFragment legalData={ legalData } index={ index } pathPrefix={ pathPrefix } />
                );
            }
            setLegalDocuments(legalDocumentsFragment);
        }
        renderOutput();
    }, [ lng, t, appState, pathPrefix, props.missing ] );

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('mzlegal:localizeLegalTermsAndPoliciesTitle')
                    }
                    rightSide={ props.missing === 1 ? (
                        <div className="row d-flex align-items-center">
                            <div className="col-12">
                                <div className="qboard-link legal-documents list-page accept p-sm-0 px-lg-2 text-center" onClick={ (e) => props.acceptLegalDocuments(e, appState, lng) }>
                                    <div className="row my-md-2">
                                        <div className="col-12 my-sm-0">
                                            { t('mzlegal:localizeLegalAcceptAndContinue') }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : '' }
                />
            </div>

            <div className="container h-100">
                { legalDocuments }
            </div>

        </React.Fragment>
    );
}

export default LegalDocumentsCurrentList;
