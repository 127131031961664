import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { TourIndex, TourPage } from './';
import { NoRoutePage } from './';

function Tour(props) {
    let location = useLocation();

    let redirectTo = '/dashboard';
    try {
        redirectTo = location.state.from.pathname;
    } catch (e) {
    }

    if (props.authPhase !== 'tour') {
        return <Redirect to={ redirectTo } />;
    }

    return (
        <Switch>
            <Route exact path="/auth/tour"><TourIndex /></Route>
            <Route exact path="/auth/tour/:page"><TourPage /></Route>

            <Route component={() => <NoRoutePage />} />
        </Switch>
    );
}

export default Tour;
