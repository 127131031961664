import React from 'react';
import { Auth } from '../modules';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function DashboardBmCamera(props) {
    const boardtype = 'camera';
    const userMerged = Auth.getData().userMerged;
    const branches = userMerged.branches;
    const cameras = branches[props.match.params.id]?.cameras;
    const {t} = useTranslation()
    

    var camerasFragment = [ ];
    for (let index = 0, len = cameras.length; index < len; ++index)  {
        const camera = cameras[index];
        camerasFragment.push(
            <div key={ index } className="camera-item text-center my-1 pt-3 title">
                <div className="row">
                    <h4 className="col-12 text-center font-weight-bold analytics-graph-full-title">{ camera.name }</h4>
                </div>
                <Link to={ '/dashboard/' + boardtype + '/' + props.match.params.id + '/' + index }>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <img alt={ camera.name } width="100%" src={ camera.url } />
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </Link>
            </div>
        );
    }
    
     if ((cameras.length === 0)) {
        camerasFragment.push(
            <div key={ 0 } className="camera-item">
                <div className="row">
                    <div className="col-12 text-center pt-3">
                        {t('localizeDashboardNoDataWarning')}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="container">
                { camerasFragment }
            </div>
        </React.Fragment>
    )
}

export default DashboardBmCamera;
