import {map} from 'lodash'
import { Config, QbTools } from '../modules';
import {Link} from 'react-router-dom';
import React, {useState} from "react";
import {compose, withProps} from "recompose";
import {normalizeData} from "../utilities/Transform";
import { API_KEY } from "../utilities/Constant";
import InfoIcon from '../img/infoIcon.png';
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from "react-google-maps";

const getColorByVlValue = (klValue) => {
    return Config.graphColors.mapWaitingTime[QbTools.analyticsGraphLimits(klValue / 60, Config.graphDefaults.mapWaitingTime)];
}

const ClickableMarker = (props) => {
	const {item, groupId, isTooltip, scale} = props || {}
	const {dataKey, VI, USZ} = item || {}
	const {longitude: lng, latitude: lat, address} = item?.branchDetails?.address || {}
	if ((lat === 0 || lat === 0.0) && (lng === 0 || lng === 0.0)) {
		return null
	}
	const color = getColorByVlValue(VI)
	return <Marker
		key={ dataKey + address }
		icon={ {
			path: window.google.maps.SymbolPath.CIRCLE,
			strokeWeight: 1,
			fillColor: color,
			scale: scale(USZ || 0),
			fillOpacity: Config.graphDefaults.mapMarkerOpacity,
		} }
		position={ {lat, lng} }
		onClick={ props.toggleTooltipHandler }>
		{ isTooltip && <InfoWindow onCloseClick={ props.toggleTooltipHandler }>
			<div className="info-window-container">
				<div>
					<p className="title">{ QbTools.apiNameToFriendlyName(dataKey) }</p>
					<span className="subtitle">{ address }</span>
				</div>
				<Link to={ {pathname: `/dashboard/metric/${ groupId }/map/${ encodeURIComponent(QbTools.apiNameToItemId(dataKey)) }`} }>
					<img src={ InfoIcon } className="icon" alt=""/>
				</Link>
			</div>
		</InfoWindow>
		}
	</Marker>
}

const MarkersList = (props) => {
	const {dataMap = {}, groupId, scale} = props || {}
	const [ selectedPlaceDataKey, setSelectedPlaceDataKey ] = useState('')

	const toggleTooltipHandler = (updatedDataKey) => {
		if (selectedPlaceDataKey === updatedDataKey) {
			setSelectedPlaceDataKey('')
		} else {
			setSelectedPlaceDataKey(updatedDataKey)
		}
	}

	return map(dataMap, (item, dataKey) => {
		return <ClickableMarker
			key={ dataKey }
			item={ {...item, dataKey} }
			groupId={ groupId }
			scale={ scale }
			isTooltip={ dataKey === selectedPlaceDataKey }
			toggleTooltipHandler={ () => toggleTooltipHandler(dataKey) }
		/>
	})
}

const CustomGoogleMap = compose(
	withProps({
		googleMapURL:
			`https://maps.googleapis.com/maps/api/js?key=${ API_KEY }&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={ {height: `100%`} }/>,
		containerElement: <div style={ {height: `500px`, width: '100%'} }/>,
		mapElement: <div style={ {height: `500px`} }/>
	}),
	withScriptjs,
	withGoogleMap
)(props => {
		const groupId = props.match.params.id;
		const dataMap = props?.dataMap
		const {scale} = normalizeData(dataMap, Config.graphDefaults.mapMarkerSize.min, Config.graphDefaults.mapMarkerSize.max)

		return <div>
			<GoogleMap
				options={{streetViewControl: false, gestureHandling: "cooperative", scrollwheel: false}}
				defaultZoom={ Config.graphDefaults.mapDefaultZoom } defaultCenter={ Config.graphDefaults.mapDefaultCenter } gestureHandling="cooperative">
				<MarkersList dataMap={ dataMap } groupId={ groupId } scale={ scale }/>
			</GoogleMap>
		</div>
	}
);

export default CustomGoogleMap
