import moment from 'moment';
import {AppStateContext, Auth, Config, QbApi, Constants, QbErrorBoundary} from './modules';
import React, {useEffect, useState} from 'react';
import {
    About,
    Analytics,
    AnalyticsRedirect,
    AuthLegalDocuments,
    Dashboard,
    DashboardBreakdown,
    DashboardCamera,
    DashboardMapSubItem,
    Feedback,
    FeedbackImageOne,
    FeedbackImageTwo,
    FeedbackImageUpload,
    FeedbackNew,
    Help,
    Home,
    Layout,
    LegalDocuments,
    LoginPage,
    NoRoutePage,
    Report,
    ReportItem,
    ReportItemHistory,
    ReportList,
    ReportReview,
    Settings,
    Tour,
    ScrollToTop
} from './components';
import {BrowserRouter, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import DashboardFilters from "./components/DashboardFilters";
import DashboardReport from "./components/DashboardReport";
import AnalyticsFilters from "./components/AnalyticsFilters";

const AuthRoute = ({ component: Component, authPhase, ...rest }) => {
    const location = useLocation();

    return (
        <Route
            {...rest}
            render={ (props) => {
                let component = ( <div>AuthRoute failure</div> );
                if (authPhase === 'auth') {
                    component = ( <Component {...props} /> );
                } else if (authPhase === 'legal') {
                    component = (
                        <Redirect
                            to={{
                                pathname: '/auth/legal',
                                state: { from: location },
                            }}
                        />
                    );
                } else if (authPhase === 'tour') {
                    component = (
                        <Redirect
                            to={{
                                pathname: '/auth/tour',
                                state: { from: location },
                            }}
                        />
                    );
                } else {
                    component = (
                        <Redirect
                            to={{
                                pathname: '/auth/login',
                                state: { from: location },
                            }}
                        />
                    );
                }
                return component;
            } }
        />
    );
};

function AuthRoutes() {
    const feedbackStatus = Constants.feedbackStatus.status;
    const supportStatus = Constants.supportStatus.status;
    return (
        <Layout>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={() => <Home />} />

                <Route exact path="/dashboard" component={() => <Dashboard />} />
                <Route exact path="/dashboard/metric" component={() => <Dashboard />} />
                <Route exact path="/dashboard/metric/:id" component={() => <Dashboard />} />
                <Route exact path="/dashboard/filters" component={() => <DashboardFilters />} />
                <Route exact path="/dashboard/report" component={() => <DashboardReport />} />
                <Route exact path="/dashboard/metric/:id/map/:subid" component={() => <DashboardMapSubItem />} />

                <Route exact path="/dashboard/breakdown/" component={() => <DashboardBreakdown type="main" />} />
                <Route exact path="/dashboard/breakdown/:id" component={() => <DashboardBreakdown type="main" />} />
                <Route exact path="/dashboard/breakdown/:id/:pagetype" component={() => <DashboardBreakdown type="main" />} />
                <Route exact path="/dashboard/breakdown/:id/:pagetype/:subid" component={() => <DashboardBreakdown type="subitem" />} />
                <Route exact path="/dashboard/breakdown/:id/:pagetype/:subid/:worst" component={() => <DashboardBreakdown type="worstitem" />} />

                <Route exact path="/dashboard/camera" component={() => <DashboardCamera type="redirect" />} />
                <Route exact path="/dashboard/camera/:id" component={() => <DashboardCamera type="main" />} />
                <Route exact path="/dashboard/camera/:id/:cameraId/" component={() => <DashboardCamera type="subitem" />} />

                <Route exact path="/analytics/filters/day" component={() => <AnalyticsFilters />} />
                <Route exact path="/analytics/filters/week" component={() => <AnalyticsFilters />} />
                <Route exact path="/analytics/filters/month" component={() => <AnalyticsFilters />} />
                <Route exact path="/analytics" component={() => <AnalyticsRedirect />} />
                <Route exact path="/analytics/:duration" component={() => <AnalyticsRedirect />} />
                <Route exact path="/analytics/:duration/:id" component={() => <Analytics />} />

                <Route path="/settings" component={() => <Settings />} />

                { supportStatus && <Route exact path="/report/create" component={() => <Report />} />}
                { supportStatus && <Route exact path="/report" component={() => <ReportList />} />}
                { supportStatus && <Route exact path="/report/item" component={() => <ReportItem />} />}
                { supportStatus && <Route exact path="/report/item/history" component={() => <ReportItemHistory />} />}
                { supportStatus && <Route exact path="/report/review" component={() => <ReportReview />} />}
                
                { feedbackStatus && <Route exact path="/feedback" component={() => <Feedback />} />}
                { feedbackStatus && <Route exact path="/feedback/upload" component={() => <FeedbackImageUpload />} />}
                { feedbackStatus && <Route exact path="/feedback/image/1" component={() => <FeedbackImageOne />} />}
                { feedbackStatus && <Route exact path="/feedback/image/2" component={() => <FeedbackImageTwo />} />}
                { feedbackStatus && <Route exact path="/feedback/new" component={() => <FeedbackNew />} />}

                <Route component={() => <NoRoutePage />} />
            </Switch>
        </Layout>
    );
}

function App() {
    //const [ config, setConfig ] = useState( { } );
    const [ menuVisible, setMenuVisible ] = useState(false);
    const [ activePage, setActivePage ] = useState('home');
    const [ authPhase, setAuthPhase ] = useState(Auth.getAuthPhase());
    const [ hierarcyChanged, setHierarcyChanged ] = useState(false); // /userprofile/check API hívás 409-cel tért vissza; beállítása App.userprofileCheck() -ben, kezelése és törlése AuthLegalDocuments-ben történik
    const [ helpPage, setHelpPage ] = useState(null);
    const [ ajaxInProgress, setAjaxInProgress ] = useState(false);
    const [ reportDetailItem, setReportDetailItem ] = useState(false);
    const [ appInited, setAppInited ] = useState(false);
    const appStateDefault = {
        menuVisible, toggleMenuVisible,
        activePage, setActivePage,
        authPhase, setAuthPhase,
        helpPage, setHelpPage,
        ajaxInProgress, setAjaxInProgress,
        reportDetailItem, setReportDetailItem
    };

    function toggleMenuVisible(visible) {
        if (typeof visible === 'boolean') {
            setMenuVisible(menuVisible => visible);
        } else {
            setMenuVisible(menuVisible => ! menuVisible);
        }
    }

    /*
     * authToken frissítése Config.tokenRefreshInterval időközönként
     * Auth.authTokenAt - authToken ekkor kapta meg legújabb értékét
     * Auth.authTokenRefreshAt - authToken legutolsó frissítése ekkor kezdődött meg
     * csak akkor frissítünk. ha mindkettő Config.tokenRefreshInterval-nál régebbi
     * így elkerülhető, hogy több refresh kérést indítsunk lassú szerverválasz esetén
     */
    async function refreshAuthToken() {
        if (Config.tokenRefreshInterval < 5) {
            return;
        }
        const now = moment().unix();
        if ( ! [ 'auth', 'legal', 'tour' ].includes(Auth.safeGetItem('authPhase'))) {
            return;
        }
        if ((Auth.getItem('authTokenAt') > now - Config.tokenRefreshInterval) ||
            (Auth.getItem('authTokenRefreshAt') > now - Config.tokenRefreshInterval)) {
            return;
        }
        await QbApi.instance.refreshAuthToken(appStateDefault, { periodic: true } );
    }

    async function userprofileCheck() {
        if (Config.userprofileCheckInterval < 5) {
            return;
        }
        const now = moment().unix();
        if ( ! [ 'auth', 'legal', 'tour' ].includes(Auth.safeGetItem('authPhase'))) {
            return;
        }
        if ((Auth.getItem('userprofileCheckAt') > now - Config.userprofileCheckInterval) ||
            (Auth.getItem('userprofileCheckRefreshAt') > now - Config.userprofileCheckInterval)) {
            return;
        }
        try {
            Auth.setItem('userprofileCheckRefreshAt', now);

            const apiPath = Config.apiUrl + 'userprofile/check';
            let init = {
                method: 'HEAD',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + Auth.getToken(),
                },
            }

            // nem QbApi-n keresztül, mert a response itt máshogy működik, mint az összes többi hívás esetén
            let response = await fetch(apiPath, init);
            if (response.status === 401) {
                await QbApi.instance.refreshAuthToken(appStateDefault);
                init.headers['X-Authorization'] = 'Bearer ' + Auth.getToken();
                response = await fetch(apiPath, init);
            }
            Auth.setItem('userprofileCheckAt', now);
            if ((response.status === 409) || (response.status === 500)) {
                setHierarcyChanged(true);
                Auth.deauthenticateUser();
                setAuthPhase('unauth');
                return;
            }
        } catch (error) {
            console.error('Error:', error);
            Auth.deauthenticateUser();
            setAuthPhase('unauth');
        }
    }

    useEffect( () => {
        const timer = setInterval(() => {
            refreshAuthToken();
            userprofileCheck();
        }, 1000);
        return () => clearInterval(timer);
    });

    useEffect( () => {
        if (! appInited) {
            setAppInited(true);
            if ( ! [ 'unauth', 'auth', 'legal', 'tour' ].includes(Auth.safeGetItem('authPhase'))) {
                Auth.deauthenticateUser();
                setAuthPhase('unauth');
            }
        }
    }, [ appInited, setAppInited ] );

    return (
        <AppStateContext.Provider value={ appStateDefault }>
            <BrowserRouter>
                <QbErrorBoundary scope="layout">
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/auth/login" component={() => <LoginPage />} />
                        <Route path="/auth/legal" component={() => <AuthLegalDocuments authPhase={ authPhase } hierarcyChanged={ hierarcyChanged } setHierarcyChanged={ setHierarcyChanged } />} />
                        <Route path="/auth/tour" component={() => <Tour authPhase={ authPhase } />} />
                        <Route path="/help" component={() => <Help />} />
                        <Route exact path="/about" component={() => <About />} />
                        <Route path="/legal" component={() => <LegalDocuments authPhase={ authPhase } />} />

                        <AuthRoute path="/" authPhase={ authPhase } component={ AuthRoutes } />
                    </Switch>
                </QbErrorBoundary>
            </BrowserRouter>
        </AppStateContext.Provider>
    );
}

export default App;
