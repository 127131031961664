import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Config, Constants, Auth, QbTools } from '../modules';
import { DashboardHierarchy } from './';
import { BreakdownProxySubItem } from './';
import { AjaxStateFragment } from './';
import { AppStateContext, QbApi, QbErrorBoundary } from '../modules';
import { BsChevronLeft } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaCaretDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { UpperNavigation } from './';

function DashboardSrmBranches(props) {
    const boardtype = 'breakdown';
    let history = useHistory();
    const { t } = useTranslation();
    const appState = useContext(AppStateContext);
    const [ areaName, setAreaName ] = useState('');

    const from = QbTools.nowApiTimeOffset().set('hour', 0).set('minute', 0).set('second', 0);
    const to = QbTools.nowApiTimeOffset().set('second', 0);
    const apiFrom = from.format();
    const apiTo = to.format();
    const apiTargetPath = props.match.params.id;
    const apiTargetPath2 = props.match.params.subid || null;

    const [ metricData, setMetricData ] = useState( { _state: Constants.ajaxState.init, _failed: false, current: { }, children: { }, leaf: { }, parent: { }, top: { } } );
    useEffect( () => {
        setMetricData(prevState => ( { ...prevState, _state: Constants.ajaxState.loading, _failed: false } ));
        var indices = [ ];

        indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'children', moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI'             ], (data) => QbApi.updateMetricData(setMetricData, 'children', data)));
        if (apiTargetPath2 !== null) {
            indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath2, 'current', moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'managers' ], (data) => QbApi.updateMetricData(setMetricData, 'children', data)));
        }

        return function cleanup() {
            QbApi.instance.cancelQueue(indices);
        };
    }, [ apiTargetPath, apiTargetPath2, apiFrom, apiTo, appState, props.refreshCounter ] );

    const userData = Auth.getData().user;
    const topHierarchyLevel = userData.topHierarchyLevel.toLowerCase();
    const pagetypes = DashboardHierarchy[topHierarchyLevel].breakdown.pagetypes

    const matchPagetype = props.match.params.pagetype;
    const matchId = props.match.params.id;
    const setLowerNavigationMiddle = props.setLowerNavigationMiddle;

    useEffect( () => {
        var pagetypeSelector = [ ];
        let currentPageTypeNameTranslation;
        const activeDropdownItem = {
            color: Config.appearance.mainColor,
        }
        for (const [, pagetype] of Object.entries(pagetypes)) {
            if (matchPagetype === pagetype.id) {
                currentPageTypeNameTranslation = pagetype.translation;
            }
            pagetypeSelector.push(
                <Dropdown.Item style={(matchPagetype === pagetype.id) ? activeDropdownItem : null } key={ pagetype.id } as={ Link } to={ '/dashboard/' + boardtype + '/' + matchId + '/' + pagetype.id } active={ matchPagetype === pagetype.id }>{ t(pagetype.translation) }</Dropdown.Item>
            );
        }

        const pagetypeSelectorFragment = (
            <Dropdown className="branch-selector-container">
                <Dropdown.Toggle variant="primary" id="branch-selector">
                    <span className="single-line-page-name">{ t(currentPageTypeNameTranslation) }</span> <FaCaretDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    { pagetypeSelector }
                </Dropdown.Menu>
            </Dropdown>
        );

        setLowerNavigationMiddle(pagetypeSelectorFragment);
    }, [ pagetypes, t, matchPagetype, matchId, setLowerNavigationMiddle ] );

    const pagetypeMatch = DashboardHierarchy[topHierarchyLevel].breakdown.pagetypes[props.match.params.pagetype];
    var DisplayComponent = pagetypeMatch?.component;
    if (typeof DisplayComponent === 'undefined') {
        return (<Redirect to={ '/dashboard/' + boardtype + '/' + matchId }/>);
    }
    if (props.type === 'main') {
    } else if (props.type === 'subitem') {
        DisplayComponent = BreakdownProxySubItem;
    }

    return (
        <React.Fragment>
            { (props.type !== 'main') ? (
                <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                    <UpperNavigation
                        leftSide={
                            <div className="qboard-link" onClick={ () => history.goBack() }>
                                <BsChevronLeft className="icon-with-bg float-none ml-1" />
                            </div>
                        }
                        middle={
                            <div className="text-center">
                                { t('localizeDashboardTypeSummary') }
                                <p className="srm-subregion-name font-weight-light">
                                    { areaName }
                                </p>
                            </div>
                        }
                    />
                </div>
            ) : '' }

            <div className="dashboard">
                <div className="container px-0 position-relative">
                    <AjaxStateFragment _state={ metricData._state } _failed={ metricData._failed } />
                    <div className="row align-items-center my-3">
                        <div className="col-lg-12">
                            <div className={(props.type === 'main')? "breakdown-list-container" : ''}>
                                <QbErrorBoundary>
                                    <DisplayComponent boardtype={ boardtype } metricData={ metricData } match={ props.match } setAreaName={ setAreaName } componentList={ props.componentList } setComponentList={ props.setComponentList } />
                                </QbErrorBoundary>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DashboardSrmBranches;
