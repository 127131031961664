import React from 'react';

function LocationIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 24" fill="none" {...props}>
      <path
        d="M19 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1118 0z"
        stroke={props.stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13a3 3 0 100-6 3 3 0 000 6z"
        stroke={props.stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LocationIcon;
