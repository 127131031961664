import * as React from "react";

function SvgUploadFile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 70 70" fill="none" {...props}>
      <path
        d="M35 68c18.225 0 33-14.775 33-33S53.225 2 35 2 2 16.775 2 35s14.775 33 33 33z"
        fill="#F3F3FC"
      />
      <path
        d="M27.293 67.095c1.66.397 3.369.669 5.115.805l-.077.997a34.428 34.428 0 005.338 0l-.077-.997a32.942 32.942 0 005.115-.805l.233.973a33.748 33.748 0 005.075-1.648l-.383-.924a32.888 32.888 0 004.613-2.355l.523.852a34.11 34.11 0 004.314-3.139l-.65-.76a33.2 33.2 0 003.662-3.662l.76.65a34.11 34.11 0 003.14-4.314l-.853-.523a32.888 32.888 0 002.355-4.613l.924.383a33.753 33.753 0 001.648-5.075l-.973-.233c.397-1.66.669-3.368.805-5.115l.997.077a34.428 34.428 0 000-5.338l-.997.077a32.942 32.942 0 00-.805-5.115l.973-.233a33.753 33.753 0 00-1.648-5.075l-.924.383a32.888 32.888 0 00-2.355-4.612l.852-.524a34.11 34.11 0 00-3.139-4.314l-.76.65a33.201 33.201 0 00-3.662-3.662l.65-.76a34.11 34.11 0 00-4.314-3.14l-.523.853a32.891 32.891 0 00-4.613-2.355l.383-.924a33.75 33.75 0 00-5.075-1.648l-.233.973a32.939 32.939 0 00-5.115-.805l.077-.997a34.457 34.457 0 00-5.338 0l.077.997a32.939 32.939 0 00-5.115.805l-.233-.973a33.748 33.748 0 00-5.075 1.648l.383.924a32.891 32.891 0 00-4.612 2.355l-.524-.853a34.11 34.11 0 00-4.314 3.14l.65.76a33.202 33.202 0 00-3.662 3.662l-.76-.65a34.11 34.11 0 00-3.14 4.314l.853.524a32.892 32.892 0 00-2.355 4.612l-.924-.383a33.75 33.75 0 00-1.648 5.075l.973.233a32.939 32.939 0 00-.805 5.115l-.997-.077a34.457 34.457 0 000 5.338l.997-.077c.136 1.747.408 3.455.805 5.115l-.973.233a33.744 33.744 0 001.648 5.075l.924-.383a32.891 32.891 0 002.355 4.613l-.853.523a34.11 34.11 0 003.14 4.314l.76-.65a33.201 33.201 0 003.662 3.662l-.65.76a34.11 34.11 0 004.314 3.14l.524-.853c1.465.9 3.007 1.69 4.612 2.355l-.383.924a33.753 33.753 0 005.075 1.648l.233-.973z"
        stroke="#06F"
        strokeOpacity={0.65}
        strokeWidth={2}
        strokeDasharray="5 5"
      />
      <path
        d="M39.72 39.711l-4.715-4.714-4.714 4.714M35.006 34.997v10.607"
        stroke="#06F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.895 42.528a5.892 5.892 0 00-2.817-11.067h-1.485a9.427 9.427 0 00-16.742-3.201 9.427 9.427 0 00.549 11.805"
        stroke="#06F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.72 39.711l-4.715-4.714-4.714 4.714"
        stroke="#06F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgUploadFile;

