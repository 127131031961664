//
//  QBDashboardFigureTime.js
//  qboard
//
//  Created by Zoltan Meszaros on 2020. 05. 25..
//  Copyright © 2020 Xperion. All rights reserved.
//
//  Generated by PaintCode
//  http://www.paintcodeapp.com
//



//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    QBDashboardFigureTime.drawTime(canvas, const_inactive_alpha, const_minutes_max, const_minutes_limit_green, const_minutes_limit_yellow, minutes, subtitle, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//
// Available Utilities:
//    QBDashboardFigureTime.clearCanvas(canvas)
//    QBDashboardFigureTime.makeRect(x, y, width, height)


//// Create StyleKit Object
var QBDashboardFigureTime = {};
(function() {

    //// Drawing Methods

    function drawTime(canvas, const_inactive_alpha, const_minutes_max, const_minutes_limit_green, const_minutes_limit_yellow, minutes, subtitle, targetFrame, resizing, color1, color2, color3) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        //var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 70), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 70, resizedFrame.h / 70);


        //// Color Declarations
        var color4 = 'rgba(240, 233, 233, 1)';
        var green = color1;
        var yellow = color2;
        var red = color3;

        /*var color4 = 'rgba(240, 233, 233, 1)';
        var green = 'rgba(85, 226, 99, 1)';
        var yellow = 'rgba(0, 102, 255, 1)';
        var red = 'rgba(254, 76, 76, 1)';*/

        //// Variable Declarations
        var progress = (minutes > const_minutes_max ? const_minutes_max : (minutes < 0 ? 0 : minutes)) / const_minutes_max;
        var minutes_float = progress * const_minutes_max;
        var colorOfDial = minutes_float < const_minutes_limit_green ? green : (minutes_float > const_minutes_limit_yellow ? red : yellow);
        var minutes_int = Math.floor(minutes_float);
        var seconds_float = 60 * (minutes_float - minutes_int);
        var seconds_int = Math.floor(seconds_float);
        var text_result = (minutes_int < 10 ? '0' : '') + Math.round(minutes_int) + ':' + ((seconds_int < 10 ? '0' : '') + Math.round(seconds_int));
        var angle_result = -1 * progress * 360 + 90;
        var alpha = minutes >= 0 ? 1 : const_inactive_alpha;

        //// data_background Drawing
        context.save();
        context.globalAlpha = alpha;
        oval(context, 2, 2, 66, 66);
        context.strokeStyle = color4;
        context.lineWidth = 4.5;
        context.stroke();
        context.restore();


        //// oval Drawing
        context.save();
        context.globalAlpha = alpha;
        arc(context, 2, 2, 66, 66, -90, -angle_result, false);
        context.strokeStyle = colorOfDial;
        context.lineWidth = 4.5;
        context.stroke();
        context.restore();


        //// data_foreground Drawing
        context.beginPath();
        context.moveTo(33, 0.26);
        context.bezierCurveTo(32.28, 0.3, 31.68, 0.8, 31.5, 1.46);
        context.bezierCurveTo(31.46, 1.62, 31.44, 1.79, 31.44, 1.96);
        context.bezierCurveTo(31.48, 2.86, 32.24, 3.56, 33.14, 3.52);
        context.bezierCurveTo(34.04, 3.48, 34.73, 2.72, 34.7, 1.82);
        context.bezierCurveTo(34.66, 0.93, 33.9, 0.23, 33, 0.26);
        context.closePath();
        context.moveTo(35.1, 1.63);
        context.bezierCurveTo(35.08, 1.71, 35.07, 1.79, 35.07, 1.87);
        context.bezierCurveTo(35.04, 2.77, 35.74, 3.52, 36.64, 3.55);
        context.bezierCurveTo(37.54, 3.58, 38.29, 2.88, 38.33, 1.98);
        context.bezierCurveTo(38.36, 1.08, 37.65, 0.33, 36.76, 0.3);
        context.bezierCurveTo(35.94, 0.27, 35.24, 0.85, 35.1, 1.63);
        context.closePath();
        context.moveTo(29.03, 0.75);
        context.bezierCurveTo(28.45, 0.84, 27.99, 1.22, 27.78, 1.73);
        context.bezierCurveTo(27.67, 2, 27.63, 2.3, 27.67, 2.61);
        context.bezierCurveTo(27.82, 3.5, 28.65, 4.11, 29.54, 3.97);
        context.bezierCurveTo(30.43, 3.82, 31.03, 2.99, 30.89, 2.1);
        context.bezierCurveTo(30.75, 1.21, 29.92, 0.61, 29.03, 0.75);
        context.closePath();
        context.moveTo(39.04, 2.11);
        context.bezierCurveTo(39.03, 2.17, 39.02, 2.22, 39.02, 2.26);
        context.bezierCurveTo(38.97, 3.1, 39.56, 3.84, 40.4, 3.98);
        context.bezierCurveTo(41.28, 4.11, 42.12, 3.51, 42.26, 2.62);
        context.bezierCurveTo(42.39, 1.73, 41.79, 0.9, 40.9, 0.76);
        context.bezierCurveTo(40.01, 0.62, 39.18, 1.23, 39.04, 2.11);
        context.closePath();
        context.moveTo(43.03, 2.86);
        context.bezierCurveTo(42.98, 3.03, 42.96, 3.21, 42.97, 3.39);
        context.bezierCurveTo(43, 4.06, 43.46, 4.67, 44.15, 4.87);
        context.bezierCurveTo(45.01, 5.11, 45.91, 4.61, 46.16, 3.75);
        context.bezierCurveTo(46.41, 2.88, 45.91, 1.98, 45.04, 1.74);
        context.bezierCurveTo(44.18, 1.49, 43.27, 1.99, 43.03, 2.86);
        context.closePath();
        context.moveTo(24.96, 1.74);
        context.bezierCurveTo(24.55, 1.85, 24.22, 2.12, 24.01, 2.46);
        context.bezierCurveTo(23.79, 2.83, 23.71, 3.3, 23.84, 3.75);
        context.bezierCurveTo(24.09, 4.61, 24.99, 5.11, 25.85, 4.87);
        context.bezierCurveTo(26.72, 4.62, 27.22, 3.72, 26.97, 2.86);
        context.bezierCurveTo(26.73, 1.99, 25.82, 1.49, 24.96, 1.74);
        context.closePath();
        context.moveTo(20.93, 3.23);
        context.bezierCurveTo(20.68, 3.33, 20.48, 3.49, 20.32, 3.68);
        context.bezierCurveTo(19.93, 4.13, 19.81, 4.78, 20.06, 5.37);
        context.bezierCurveTo(20.41, 6.19, 21.37, 6.58, 22.2, 6.23);
        context.bezierCurveTo(23.03, 5.88, 23.41, 4.92, 23.06, 4.09);
        context.bezierCurveTo(22.71, 3.27, 21.75, 2.88, 20.93, 3.23);
        context.closePath();
        context.moveTo(46.94, 4.09);
        context.bezierCurveTo(46.81, 4.39, 46.78, 4.7, 46.83, 4.99);
        context.bezierCurveTo(46.92, 5.53, 47.27, 6, 47.8, 6.23);
        context.bezierCurveTo(48.63, 6.58, 49.59, 6.19, 49.94, 5.37);
        context.bezierCurveTo(50.29, 4.54, 49.9, 3.58, 49.07, 3.23);
        context.bezierCurveTo(48.25, 2.88, 47.29, 3.27, 46.94, 4.09);
        context.closePath();
        context.moveTo(50.62, 5.78);
        context.bezierCurveTo(50.39, 6.17, 50.35, 6.61, 50.45, 7.02);
        context.bezierCurveTo(50.56, 7.42, 50.82, 7.78, 51.21, 8);
        context.bezierCurveTo(51.99, 8.45, 52.99, 8.19, 53.44, 7.41);
        context.bezierCurveTo(53.89, 6.63, 53.62, 5.63, 52.84, 5.18);
        context.bezierCurveTo(52.06, 4.73, 51.07, 5, 50.62, 5.78);
        context.closePath();
        context.moveTo(17.16, 5.18);
        context.bezierCurveTo(17.07, 5.23, 17, 5.29, 16.92, 5.35);
        context.bezierCurveTo(16.33, 5.85, 16.16, 6.71, 16.56, 7.41);
        context.bezierCurveTo(17.01, 8.19, 18.01, 8.45, 18.79, 8);
        context.bezierCurveTo(19.57, 7.55, 19.83, 6.56, 19.38, 5.78);
        context.bezierCurveTo(18.93, 5, 17.94, 4.73, 17.16, 5.18);
        context.closePath();
        context.moveTo(13.83, 7.47);
        context.bezierCurveTo(13.77, 7.5, 13.71, 7.54, 13.66, 7.58);
        context.bezierCurveTo(12.94, 8.12, 12.8, 9.14, 13.34, 9.86);
        context.bezierCurveTo(13.88, 10.58, 14.9, 10.72, 15.62, 10.18);
        context.bezierCurveTo(16.34, 9.64, 16.48, 8.62, 15.94, 7.9);
        context.bezierCurveTo(15.44, 7.24, 14.53, 7.06, 13.83, 7.47);
        context.closePath();
        context.moveTo(54.06, 7.9);
        context.bezierCurveTo(53.71, 8.36, 53.65, 8.95, 53.84, 9.45);
        context.bezierCurveTo(53.94, 9.73, 54.13, 9.99, 54.38, 10.18);
        context.bezierCurveTo(55.1, 10.72, 56.12, 10.58, 56.66, 9.86);
        context.bezierCurveTo(57.2, 9.14, 57.06, 8.12, 56.34, 7.58);
        context.bezierCurveTo(55.62, 7.04, 54.6, 7.18, 54.06, 7.9);
        context.closePath();
        context.moveTo(57.22, 10.43);
        context.bezierCurveTo(56.73, 10.93, 56.64, 11.67, 56.93, 12.27);
        context.bezierCurveTo(57.01, 12.43, 57.12, 12.59, 57.26, 12.73);
        context.bezierCurveTo(57.91, 13.35, 58.94, 13.33, 59.57, 12.69);
        context.bezierCurveTo(60.19, 12.04, 60.17, 11.01, 59.52, 10.39);
        context.bezierCurveTo(58.88, 9.76, 57.85, 9.78, 57.22, 10.43);
        context.closePath();
        context.moveTo(11.07, 10.02);
        context.bezierCurveTo(10.86, 10.09, 10.65, 10.21, 10.48, 10.39);
        context.bezierCurveTo(9.83, 11.01, 9.81, 12.04, 10.43, 12.69);
        context.bezierCurveTo(11.06, 13.33, 12.09, 13.35, 12.74, 12.73);
        context.bezierCurveTo(13.38, 12.1, 13.4, 11.07, 12.78, 10.43);
        context.bezierCurveTo(12.32, 9.95, 11.65, 9.82, 11.07, 10.02);
        context.closePath();
        context.moveTo(60.05, 13.32);
        context.bezierCurveTo(59.41, 13.83, 59.27, 14.73, 59.68, 15.42);
        context.bezierCurveTo(59.72, 15.48, 59.76, 15.55, 59.81, 15.61);
        context.bezierCurveTo(60.38, 16.31, 61.4, 16.41, 62.1, 15.85);
        context.bezierCurveTo(62.8, 15.28, 62.91, 14.26, 62.34, 13.56);
        context.bezierCurveTo(61.78, 12.86, 60.75, 12.75, 60.05, 13.32);
        context.closePath();
        context.moveTo(8.7, 12.97);
        context.bezierCurveTo(8.31, 13.02, 7.93, 13.22, 7.66, 13.56);
        context.bezierCurveTo(7.09, 14.26, 7.2, 15.28, 7.9, 15.85);
        context.bezierCurveTo(8.6, 16.41, 9.62, 16.31, 10.19, 15.61);
        context.bezierCurveTo(10.76, 14.91, 10.65, 13.88, 9.95, 13.32);
        context.bezierCurveTo(9.58, 13.02, 9.13, 12.91, 8.7, 12.97);
        context.closePath();
        context.moveTo(62.51, 16.53);
        context.bezierCurveTo(61.74, 17.01, 61.51, 18.01, 61.99, 18.78);
        context.bezierCurveTo(62.54, 19.56, 63.5, 19.75, 64.23, 19.29);
        context.bezierCurveTo(65, 18.82, 65.23, 17.81, 64.75, 17.05);
        context.bezierCurveTo(64.27, 16.29, 63.27, 16.05, 62.51, 16.53);
        context.closePath();
        context.moveTo(5.25, 17.05);
        context.bezierCurveTo(4.77, 17.81, 5, 18.82, 5.77, 19.29);
        context.bezierCurveTo(6.53, 19.77, 7.53, 19.54, 8.01, 18.78);
        context.bezierCurveTo(8.49, 18.01, 8.26, 17.01, 7.49, 16.53);
        context.bezierCurveTo(7.26, 16.38, 7, 16.3, 6.74, 16.29);
        context.bezierCurveTo(6.16, 16.25, 5.58, 16.52, 5.25, 17.05);
        context.closePath();
        context.moveTo(64.55, 20.02);
        context.bezierCurveTo(63.74, 20.4, 63.39, 21.37, 63.77, 22.19);
        context.bezierCurveTo(63.83, 22.32, 63.91, 22.44, 64, 22.55);
        context.bezierCurveTo(64.46, 23.1, 65.25, 23.29, 65.93, 22.97);
        context.bezierCurveTo(66.74, 22.59, 67.1, 21.62, 66.72, 20.81);
        context.bezierCurveTo(66.34, 19.99, 65.37, 19.64, 64.55, 20.02);
        context.closePath();
        context.moveTo(3.28, 20.81);
        context.bezierCurveTo(2.9, 21.62, 3.26, 22.59, 4.07, 22.97);
        context.bezierCurveTo(4.89, 23.35, 5.85, 23, 6.23, 22.19);
        context.bezierCurveTo(6.61, 21.37, 6.26, 20.4, 5.45, 20.02);
        context.bezierCurveTo(5.37, 19.98, 5.28, 19.95, 5.2, 19.93);
        context.bezierCurveTo(4.44, 19.72, 3.62, 20.08, 3.28, 20.81);
        context.closePath();
        context.moveTo(1.79, 24.78);
        context.bezierCurveTo(1.51, 25.64, 1.98, 26.55, 2.84, 26.83);
        context.bezierCurveTo(3.69, 27.11, 4.61, 26.64, 4.89, 25.79);
        context.bezierCurveTo(5.17, 24.93, 4.7, 24.01, 3.84, 23.73);
        context.bezierCurveTo(2.99, 23.46, 2.07, 23.92, 1.79, 24.78);
        context.closePath();
        context.moveTo(66.16, 23.73);
        context.bezierCurveTo(65.3, 24.01, 64.83, 24.93, 65.11, 25.79);
        context.bezierCurveTo(65.19, 26.04, 65.33, 26.26, 65.51, 26.43);
        context.bezierCurveTo(65.93, 26.85, 66.56, 27.03, 67.16, 26.83);
        context.bezierCurveTo(68.02, 26.55, 68.49, 25.64, 68.21, 24.78);
        context.bezierCurveTo(67.93, 23.92, 67.01, 23.46, 66.16, 23.73);
        context.closePath();
        context.moveTo(67.3, 27.62);
        context.bezierCurveTo(66.42, 27.79, 65.84, 28.64, 66.01, 29.53);
        context.bezierCurveTo(66.08, 29.9, 66.28, 30.22, 66.55, 30.45);
        context.bezierCurveTo(66.91, 30.76, 67.41, 30.91, 67.92, 30.81);
        context.bezierCurveTo(68.8, 30.64, 69.38, 29.79, 69.21, 28.9);
        context.bezierCurveTo(69.04, 28.02, 68.18, 27.44, 67.3, 27.62);
        context.closePath();
        context.moveTo(0.79, 28.9);
        context.bezierCurveTo(0.62, 29.79, 1.2, 30.64, 2.08, 30.81);
        context.bezierCurveTo(2.96, 30.98, 3.82, 30.41, 3.99, 29.53);
        context.bezierCurveTo(4.16, 28.64, 3.58, 27.79, 2.7, 27.62);
        context.bezierCurveTo(1.82, 27.44, 0.96, 28.02, 0.79, 28.9);
        context.closePath();
        context.moveTo(67.93, 31.38);
        context.bezierCurveTo(67.04, 31.45, 66.37, 32.23, 66.43, 33.13);
        context.bezierCurveTo(66.47, 33.61, 66.72, 34.03, 67.08, 34.31);
        context.bezierCurveTo(67.39, 34.53, 67.77, 34.66, 68.18, 34.62);
        context.bezierCurveTo(69.08, 34.56, 69.75, 33.77, 69.68, 32.88);
        context.bezierCurveTo(69.61, 31.98, 68.83, 31.31, 67.93, 31.38);
        context.closePath();
        context.moveTo(0.39, 33.01);
        context.bezierCurveTo(0.32, 33.91, 0.99, 34.69, 1.88, 34.76);
        context.bezierCurveTo(2.78, 34.84, 3.56, 34.17, 3.64, 33.27);
        context.bezierCurveTo(3.71, 32.37, 3.04, 31.59, 2.14, 31.52);
        context.bezierCurveTo(1.25, 31.44, 0.46, 32.11, 0.39, 33.01);
        context.closePath();
        context.moveTo(1.9, 35.16);
        context.bezierCurveTo(1, 35.19, 0.3, 35.95, 0.33, 36.85);
        context.bezierCurveTo(0.37, 37.74, 1.12, 38.45, 2.02, 38.41);
        context.bezierCurveTo(2.92, 38.38, 3.62, 37.62, 3.59, 36.72);
        context.bezierCurveTo(3.55, 35.82, 2.8, 35.12, 1.9, 35.16);
        context.closePath();
        context.moveTo(66.41, 36.72);
        context.bezierCurveTo(66.39, 37.32, 66.69, 37.85, 67.16, 38.15);
        context.bezierCurveTo(67.39, 38.31, 67.67, 38.4, 67.98, 38.41);
        context.bezierCurveTo(68.88, 38.45, 69.63, 37.74, 69.67, 36.85);
        context.bezierCurveTo(69.7, 35.95, 69, 35.19, 68.1, 35.16);
        context.bezierCurveTo(67.2, 35.12, 66.45, 35.82, 66.41, 36.72);
        context.closePath();
        context.moveTo(2.15, 39.02);
        context.bezierCurveTo(1.26, 39.16, 0.65, 39.99, 0.79, 40.88);
        context.bezierCurveTo(0.93, 41.77, 1.76, 42.38, 2.65, 42.24);
        context.bezierCurveTo(3.54, 42.1, 4.15, 41.27, 4.01, 40.38);
        context.bezierCurveTo(3.87, 39.49, 3.04, 38.88, 2.15, 39.02);
        context.closePath();
        context.moveTo(65.99, 40.38);
        context.bezierCurveTo(65.88, 41.07, 66.23, 41.73, 66.81, 42.06);
        context.bezierCurveTo(66.98, 42.14, 67.16, 42.21, 67.35, 42.24);
        context.bezierCurveTo(68.24, 42.38, 69.07, 41.77, 69.21, 40.88);
        context.bezierCurveTo(69.35, 39.99, 68.74, 39.16, 67.85, 39.02);
        context.bezierCurveTo(66.96, 38.88, 66.13, 39.49, 65.99, 40.38);
        context.closePath();
        context.moveTo(2.89, 43);
        context.bezierCurveTo(2.02, 43.24, 1.52, 44.14, 1.77, 45.01);
        context.bezierCurveTo(2.01, 45.88, 2.91, 46.38, 3.78, 46.13);
        context.bezierCurveTo(4.64, 45.88, 5.14, 44.98, 4.9, 44.12);
        context.bezierCurveTo(4.65, 43.25, 3.75, 42.75, 2.89, 43);
        context.closePath();
        context.moveTo(65.1, 44.12);
        context.bezierCurveTo(64.88, 44.9, 65.27, 45.71, 65.99, 46.04);
        context.bezierCurveTo(66.06, 46.08, 66.14, 46.11, 66.22, 46.13);
        context.bezierCurveTo(67.09, 46.38, 67.99, 45.88, 68.23, 45.01);
        context.bezierCurveTo(68.48, 44.14, 67.98, 43.24, 67.11, 43);
        context.bezierCurveTo(66.25, 42.75, 65.35, 43.25, 65.1, 44.12);
        context.closePath();
        context.moveTo(63.74, 47.77);
        context.bezierCurveTo(63.39, 48.6, 63.78, 49.56, 64.6, 49.91);
        context.bezierCurveTo(65.43, 50.26, 66.39, 49.87, 66.74, 49.04);
        context.bezierCurveTo(67.09, 48.22, 66.7, 47.26, 65.88, 46.91);
        context.bezierCurveTo(65.05, 46.56, 64.09, 46.94, 63.74, 47.77);
        context.closePath();
        context.moveTo(4.09, 46.98);
        context.bezierCurveTo(3.27, 47.35, 2.9, 48.31, 3.26, 49.13);
        context.bezierCurveTo(3.62, 49.95, 4.58, 50.33, 5.41, 49.96);
        context.bezierCurveTo(6.23, 49.6, 6.6, 48.64, 6.24, 47.82);
        context.bezierCurveTo(5.88, 46.99, 4.92, 46.62, 4.09, 46.98);
        context.closePath();
        context.moveTo(5.84, 50.55);
        context.bezierCurveTo(5.05, 50.99, 4.78, 51.98, 5.22, 52.76);
        context.bezierCurveTo(5.66, 53.55, 6.65, 53.83, 7.44, 53.38);
        context.bezierCurveTo(8.22, 52.94, 8.5, 51.95, 8.06, 51.17);
        context.bezierCurveTo(7.61, 50.38, 6.62, 50.1, 5.84, 50.55);
        context.closePath();
        context.moveTo(61.97, 51.18);
        context.bezierCurveTo(61.52, 51.96, 61.78, 52.96, 62.56, 53.41);
        context.bezierCurveTo(63.34, 53.86, 64.34, 53.59, 64.79, 52.81);
        context.bezierCurveTo(65.24, 52.03, 64.97, 51.04, 64.19, 50.59);
        context.bezierCurveTo(63.41, 50.14, 62.42, 50.4, 61.97, 51.18);
        context.closePath();
        context.moveTo(59.79, 54.35);
        context.bezierCurveTo(59.25, 55.07, 59.39, 56.09, 60.11, 56.63);
        context.bezierCurveTo(60.83, 57.17, 61.85, 57.03, 62.39, 56.31);
        context.bezierCurveTo(62.93, 55.59, 62.79, 54.57, 62.07, 54.03);
        context.bezierCurveTo(61.35, 53.49, 60.33, 53.63, 59.79, 54.35);
        context.closePath();
        context.moveTo(7.93, 54.03);
        context.bezierCurveTo(7.21, 54.57, 7.07, 55.59, 7.61, 56.31);
        context.bezierCurveTo(8.15, 57.03, 9.17, 57.17, 9.89, 56.63);
        context.bezierCurveTo(10.61, 56.09, 10.75, 55.07, 10.21, 54.35);
        context.bezierCurveTo(9.67, 53.63, 8.65, 53.49, 7.93, 54.03);
        context.closePath();
        context.moveTo(10.46, 57.19);
        context.bezierCurveTo(9.81, 57.82, 9.79, 58.85, 10.42, 59.49);
        context.bezierCurveTo(11.04, 60.14, 12.07, 60.16, 12.72, 59.53);
        context.bezierCurveTo(13.37, 58.91, 13.38, 57.88, 12.76, 57.23);
        context.bezierCurveTo(12.13, 56.59, 11.1, 56.57, 10.46, 57.19);
        context.closePath();
        context.moveTo(57.24, 57.23);
        context.bezierCurveTo(56.62, 57.88, 56.63, 58.91, 57.28, 59.53);
        context.bezierCurveTo(57.93, 60.16, 58.96, 60.14, 59.58, 59.49);
        context.bezierCurveTo(60.21, 58.85, 60.19, 57.82, 59.54, 57.19);
        context.bezierCurveTo(58.9, 56.57, 57.87, 56.59, 57.24, 57.23);
        context.closePath();
        context.moveTo(13.22, 59.91);
        context.bezierCurveTo(12.66, 60.61, 12.77, 61.64, 13.47, 62.2);
        context.bezierCurveTo(14.16, 62.77, 15.19, 62.66, 15.76, 61.96);
        context.bezierCurveTo(16.32, 61.26, 16.21, 60.24, 15.52, 59.67);
        context.bezierCurveTo(14.82, 59.11, 13.79, 59.21, 13.22, 59.91);
        context.closePath();
        context.moveTo(54.36, 59.78);
        context.bezierCurveTo(53.66, 60.35, 53.56, 61.37, 54.12, 62.07);
        context.bezierCurveTo(54.69, 62.77, 55.71, 62.88, 56.41, 62.31);
        context.bezierCurveTo(57.11, 61.75, 57.22, 60.72, 56.65, 60.02);
        context.bezierCurveTo(56.09, 59.32, 55.06, 59.21, 54.36, 59.78);
        context.closePath();
        context.moveTo(16.32, 62.29);
        context.bezierCurveTo(15.84, 63.06, 16.08, 64.06, 16.84, 64.54);
        context.bezierCurveTo(17.6, 65.01, 18.61, 64.78, 19.08, 64.02);
        context.bezierCurveTo(19.56, 63.26, 19.33, 62.25, 18.56, 61.77);
        context.bezierCurveTo(17.8, 61.3, 16.8, 61.53, 16.32, 62.29);
        context.closePath();
        context.moveTo(51.19, 61.96);
        context.bezierCurveTo(50.43, 62.44, 50.2, 63.44, 50.68, 64.2);
        context.bezierCurveTo(51.15, 64.97, 52.16, 65.2, 52.92, 64.72);
        context.bezierCurveTo(53.68, 64.24, 53.92, 63.24, 53.44, 62.48);
        context.bezierCurveTo(52.96, 61.71, 51.96, 61.48, 51.19, 61.96);
        context.closePath();
        context.moveTo(47.78, 63.73);
        context.bezierCurveTo(46.97, 64.12, 46.62, 65.08, 47, 65.9);
        context.bezierCurveTo(47.38, 66.71, 48.35, 67.07, 49.16, 66.69);
        context.bezierCurveTo(49.98, 66.31, 50.33, 65.34, 49.95, 64.52);
        context.bezierCurveTo(49.57, 63.71, 48.6, 63.35, 47.78, 63.73);
        context.closePath();
        context.moveTo(20.05, 64.52);
        context.bezierCurveTo(19.67, 65.34, 20.02, 66.31, 20.84, 66.69);
        context.bezierCurveTo(21.65, 67.07, 22.62, 66.71, 23, 65.9);
        context.bezierCurveTo(23.38, 65.08, 23.03, 64.12, 22.22, 63.73);
        context.bezierCurveTo(21.4, 63.35, 20.43, 63.71, 20.05, 64.52);
        context.closePath();
        context.moveTo(44.18, 65.08);
        context.bezierCurveTo(43.33, 65.36, 42.86, 66.28, 43.14, 67.13);
        context.bezierCurveTo(43.42, 67.99, 44.33, 68.46, 45.19, 68.18);
        context.bezierCurveTo(46.05, 67.9, 46.51, 66.98, 46.24, 66.13);
        context.bezierCurveTo(45.96, 65.27, 45.04, 64.8, 44.18, 65.08);
        context.closePath();
        context.moveTo(23.76, 66.13);
        context.bezierCurveTo(23.49, 66.98, 23.95, 67.9, 24.81, 68.18);
        context.bezierCurveTo(25.67, 68.46, 26.58, 67.99, 26.86, 67.13);
        context.bezierCurveTo(27.14, 66.28, 26.67, 65.36, 25.82, 65.08);
        context.bezierCurveTo(24.96, 64.8, 24.04, 65.27, 23.76, 66.13);
        context.closePath();
        context.moveTo(27.65, 67.27);
        context.bezierCurveTo(27.47, 68.15, 28.05, 69.01, 28.93, 69.18);
        context.bezierCurveTo(29.82, 69.35, 30.67, 68.77, 30.84, 67.89);
        context.bezierCurveTo(31.01, 67.01, 30.44, 66.15, 29.56, 65.98);
        context.bezierCurveTo(28.67, 65.81, 27.82, 66.39, 27.65, 67.27);
        context.closePath();
        context.moveTo(40.44, 65.98);
        context.bezierCurveTo(39.56, 66.15, 38.99, 67.01, 39.16, 67.89);
        context.bezierCurveTo(39.33, 68.77, 40.18, 69.35, 41.07, 69.18);
        context.bezierCurveTo(41.95, 69.01, 42.53, 68.15, 42.35, 67.27);
        context.bezierCurveTo(42.18, 66.39, 41.33, 65.81, 40.44, 65.98);
        context.closePath();
        context.moveTo(36.95, 66.4);
        context.bezierCurveTo(36.06, 66.47, 35.39, 67.26, 35.46, 68.15);
        context.bezierCurveTo(35.53, 69.05, 36.32, 69.72, 37.22, 69.64);
        context.bezierCurveTo(38.11, 69.57, 38.78, 68.79, 38.71, 67.89);
        context.bezierCurveTo(38.64, 66.99, 37.85, 66.33, 36.95, 66.4);
        context.closePath();
        context.moveTo(31.64, 67.93);
        context.bezierCurveTo(31.57, 68.82, 32.25, 69.6, 33.15, 69.67);
        context.bezierCurveTo(34.05, 69.73, 34.82, 69.05, 34.89, 68.15);
        context.bezierCurveTo(34.95, 67.26, 34.27, 66.48, 33.38, 66.42);
        context.bezierCurveTo(32.48, 66.35, 31.7, 67.03, 31.64, 67.93);
        context.closePath();
        context.moveTo(73, -3);
        context.lineTo(73, 73);
        context.lineTo(-3, 73);
        context.lineTo(-3, -3);
        context.lineTo(73, -3);
        context.closePath();
        context.fillStyle = 'rgb(255, 255, 255)';
        context.fill();


        //// data_text_value Drawing
        context.save();
        context.globalAlpha = alpha;
        var data_text_valueRect = makeRect(9, 25, 53, 13);
        context.fillStyle = 'rgba(0,0,0,1)';
        context.font = '600 13px HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        var data_text_valueTotalHeight = 13 * 1.3;
        context.fillText(text_result, data_text_valueRect.x + data_text_valueRect.w/2, data_text_valueRect.y + 13 + data_text_valueRect.h / 2 - data_text_valueTotalHeight / 2);
        context.restore();


        //// data_text_title Drawing
        context.save();
        context.globalAlpha = alpha;
        var data_text_titleRect = makeRect(9, 38, 53, 10);
        context.fillStyle = 'rgba(0,0,0,1)';
        context.font = '7px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        var data_text_titleTotalHeight = 7 * 1.3;
        context.fillText(subtitle, data_text_titleRect.x + data_text_titleRect.w/2, data_text_titleRect.y + 7 + data_text_titleRect.h / 2 - data_text_titleTotalHeight / 2);
        context.restore();
        
        context.restore();

    }

    //// Infrastructure

    function clearCanvas(canvas) {
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    // Possible arguments for 'resizing' parameter are:
    //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
    //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
    //   'stretch': The content is stretched to match the entire target rectangle.
    //   'center': The content is centered in the target rectangle, but it is NOT resized.
    function applyResizingBehavior(resizing, rect, targetRect) {
        if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
            return rect;
        }

        var scales = makeSize(0, 0);
        scales.w = Math.abs(targetRect.w / rect.w);
        scales.h = Math.abs(targetRect.h / rect.h);

        switch (resizing) {
            case 'aspectfit': {
                scales.w = Math.min(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'aspectfill': {
                scales.w = Math.max(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'stretch':
            case undefined:
                break;
            case 'center': {
                scales.w = 1;
                scales.h = 1;
                break;
            }
            default:
                throw new Error('Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.');
        }

        var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h));
        result.w *= scales.w;
        result.h *= scales.h;
        result.x = targetRect.x + (targetRect.w - result.w) / 2;
        result.y = targetRect.y + (targetRect.h - result.h) / 2;
        return result;
    }

    function oval(context, x, y, w, h) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, 0, 2*Math.PI, false);
        context.closePath();
        context.restore();
    }

    function arc(context, x, y, w, h, startAngle, endAngle, isClosed) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, Math.PI/180*startAngle, Math.PI/180*endAngle, false);
        if (isClosed)
        {
            context.lineTo(1, 1);
            context.closePath();
        }
        context.restore();
    }

    function makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    function equalRects(r1, r2) {
        return r1.x === r2.x && r1.y === r2.y && r1.w === r2.w && r1.h === r2.h;
    }

    function makeSize(w, h) {
        return { w: w, h: h };
    }

    function initializeCanvas(canvas) {
        if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        var context = canvas.getContext('2d');

        var devicePixelRatio = window.devicePixelRatio || 1;
        var backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        var pixelRatio = devicePixelRatio / backingStorePixelRatio;

        //canvas.style.width = canvas.width + 'px';
        //canvas.style.height = canvas.height + 'px';
        canvas.width *= pixelRatio;
        canvas.height *= pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return canvas;
    }

    //// Public Interface

    // Drawing Methods
    QBDashboardFigureTime.drawTime = drawTime;

    // Utilities
    QBDashboardFigureTime.clearCanvas = clearCanvas;
    QBDashboardFigureTime.makeRect = makeRect;

})();

export default QBDashboardFigureTime;
