import Geocode from "react-geocode";
import {API_KEY} from "./Constant";
import {isEmpty} from "lodash";
import {isValidLatLng} from "./ValidationUtils";

Geocode.setApiKey(API_KEY);
Geocode.setLanguage('hu');

export const addressToLatLngHandler = async (address) => {
	if (isEmpty(address)) {
		return {lat: 0, lng: 0}
	}
	try {
		const response = await Geocode.fromAddress(address) || {}
		const {lat, lng} = response?.results?.[0]?.geometry?.location
		const formatted_address = response?.results?.[0]?.formatted_address ?? ''
		return isValidLatLng({lat, lng}) ? {lat, lng, address:formatted_address} : {lat: 0, lng: 0}
	} catch (e) {
		return {lat: 0, lng: 0}
	}
}

export const navigateMap = (mapRef, placeItem) => {
	if (!mapRef || isEmpty(placeItem)) {
		return
	}
	const {lat, lng} = placeItem || {}
	mapRef.panTo(new window.google.maps.LatLng(lat, lng))
}

export const geocodeLatLngToAddress = async (lat, lng) => {
    function getAddressComponent(response, _options) {
        const options = { type: null, fromAll: false, field: 'long_name', ..._options };
        let rmax = Math.min(response.results.length, 2); // ha options.fromAll === false, akkor az első két találatból dolgozunk
        if (options.fromAll === true) {
            rmax = response.results.length;
        }
        for (let r = 0; r < rmax; r++) {
            for (let i = 0; i < response.results[r].address_components.length; i++) {
                for (let j = 0; j < response.results[r].address_components[i].types.length; j++) {
                    if (response.results[r].address_components[i].types[j] === options.type) {
                        return response.results[r].address_components[i][options.field];
                    }
                }
            }
        }
    }

    const response = await Geocode.fromLatLng(lat, lng);
    let address = null;
    try {
        address = {
            address: getAddressComponent(response, { type: 'route', fromAll: false } ) + ' ' + (getAddressComponent(response, { type: 'street_number', fromAll: false } ) || ''),
            city: getAddressComponent(response, { type: 'locality', fromAll: false } ),
            state: getAddressComponent(response, { type: 'administrative_area_level_1', fromAll: true } ),
            country: getAddressComponent(response, { type: 'country', fromAll: false } ),
            postalCode: getAddressComponent(response, { type: 'postal_code', fromAll: false } ),
            ISOCountryCode: getAddressComponent(response, { type: 'country', fromAll: false, field: 'short_name' } ),
        };
    } catch (e) {
        console.error(e);
    }
    return address;
}
