import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from 'react-icons/bs';
import { Formik, Form } from 'formik';
import { SubmitButton } from './form';
import { Auth, Config } from '../modules';
import { UpperNavigation } from './';

function ReportReview() {
    let history = useHistory();
    const { t } = useTranslation();

    /*let formValues = {
        problemHeading:     '',
        failureDescription: '',
        affectedSystems:    '',
        affectedUsers:      '',
        urgency:            '',
        branch:             '',
    };*/
    
    const userData = Auth.getData().user;
    const groups = userData.groups;
    var itemSelector = [ ];
    for (const group of groups) {
        itemSelector.push(
            <option value={ group.id }>{ group.name }</option>
        );
    }
    

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizeTopDeskNewTitle')
                    }
                />
            </div>

            <div className="container px-0">
                <div className="col-12 text-center py-2">
                    { t('localizeTopDeskEmailAccountMessage') }
                </div>
                <div className="ticket-subject col-12">
                    <span className="title">Subject:</span>
                    <span> --Paper--</span>
                </div>
                <div className="ticket-item col-12">
                    <span className="title">Private_ID:</span>
                    <span> #########################</span>
                </div>
                <div className="ticket-item col-12">
                    <span className="title">Username:</span>
                    <span> #########################</span>
                </div>
                <div className="ticket-item col-12">
                    <span className="title">Subject:</span>
                    <span> #########################</span>
                </div>
                <div className="ticket-item col-12">
                    <span className="title">Failure_description:</span>
                    <span> #########################</span>
                </div>
                <div className="ticket-item col-12">
                    <span className="title">Effected_systems:</span>
                    <span> #########################</span>
                </div>
                <div className="ticket-item col-12">
                    <span className="title">Effect 1:</span>
                    <span> #########################</span>
                </div>
                <div className="ticket-item col-12">
                    <span className="title">Urgency 1:</span>
                    <span> #########################</span>
                </div>
                <Formik>
                    <Form>
                        <div className="row pt-2">
                            <div className="col-6">
                                <SubmitButton text={ t('localizeCancel') } />
                            </div>
                            <div className="col-6">
                                <SubmitButton text={ t('localizeSend') } />
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </React.Fragment>
    );
}

export default ReportReview;
