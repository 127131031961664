import React from 'react';
import {Config, QbTools} from '../modules';
import {BsClock, BsPerson, BsStar} from 'react-icons/bs';
import {FiWatch} from 'react-icons/fi';
import {useTranslation} from 'react-i18next';
import {MessagingModal} from "./index";
import {BreakdownDisplayItemSelector} from "./";

function BreakdownDisplaySubItem(props) {
	var displayData = props.displayData;
	const {t} = useTranslation();
	const match = props.match;
	
	const percentbarWidth = {
		ServiceLevel: Math.min(displayData.KSZ * 100, 100),
		ServingTime: Math.min((displayData.KI / 1800) * 100, 100),
		WaitingTime: Math.min((displayData.VI / 1800) * 100, 100),
	};
	const percentbarStyle = {
		ServiceLevel: {
			backgroundColor: Config.graphColors.ServiceLevel[QbTools.analyticsGraphLimits(percentbarWidth.ServiceLevel, Config.graphDefaults.ServiceLevel)],
			width: percentbarWidth.ServiceLevel + "%",
		},
		ServingTime: {
			backgroundColor: Config.graphColors.ServingTime[QbTools.analyticsGraphLimits(displayData.KI / 60, Config.graphDefaults.ServingTime)],
			width: percentbarWidth.ServingTime + "%",
		},
		WaitingTime: {
			backgroundColor: Config.graphColors.WaitingTime[QbTools.analyticsGraphLimits(displayData.VI / 60, Config.graphDefaults.WaitingTime)],
			width: percentbarWidth.WaitingTime + "%",
		},
	};
	
	const managers = displayData.managers || [ ];
	let contactsFragment = [];
	for (let index = 0, len = managers.length; index < len; ++index) {
		const legal = managers[index];
		const {contactInfo, chanonicalName} = legal || {}
		contactsFragment.push(
            <div className="conatact-item-overview d-flex" key={ legal.chanonicalName.userName }>
                    <MessagingModal
                        chanonicalName={ chanonicalName }
                        contactInfo={ contactInfo }
                    />
            </div>
		);
    }
    
    const informationRowStyle = {
        minHeight: Config.appearance.overviewInformationRowHeight,
    }
	
	return (
		<div className="metric">
			{ props.displayWorst ?
           <BreakdownDisplayItemSelector
                bestLink={ '/dashboard/breakdown/' + match.params.id + '/' + match.params.pagetype + '/' + encodeURIComponent(props.displayData.key) }
                bestName={ props.displayData.name }
                worstLink={ '/dashboard/breakdown/' + match.params.id + '/' + match.params.pagetype + '/' + encodeURIComponent(props.displayData.key) + '/worst' }
                worstName={ props.displayData.worst.name }
                active="best"
            />
            
				:
				''
			}
			<div className="row text-center my-1 pt-3 title">
				<div className="col-12">
					<h4 className="font-weight-light">{ t('localizeAlertContactMessage') }</h4>
				</div>
			</div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-1" />
                    <div className="col-10 col-lg-6 py-2 px-0" >
                        <div className="overview-information-box">
                            { contactsFragment }
                        </div>
                    </div>
                    <div className="col-lg-3 col-1" />
                </div>
            </div>
			
			<div className="row text-center my-1 pt-3 title">
				<div className="col-12">
					<h4 className="font-weight-light">{ t('localizeInformation') }</h4>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-1"></div>
					<div className="col-10 col-lg-6 overview-information-box">
						<div style={ informationRowStyle } className="row information-row d-flex justify-content-center align-content-center">
							<div className="col-8 information-data-name d-flex align-items-center">
								<BsPerson
									className="icon-with-bg-information mr-1"/> { t('localizeDashboardCustomerCountTitle') }
							</div>
							<div
								className="col-4 text-right information-data d-flex align-items-center justify-content-end">
								{ QbTools.formatCustomerCount(displayData.USZ) }
							</div>
						</div>
						<div style={ informationRowStyle } className="row information-row d-flex justify-content-center align-content-center">
							<div className="progressbar" style={ percentbarStyle.ServiceLevel }></div>
							<div className="col-8 information-data-name d-flex align-items-center">
								<BsStar
									className="icon-with-bg-information mr-1"/> { t('localizeDashboardServeLevelTitle') }
							</div>
							<div
								className="col-4 text-right information-data d-flex align-items-center justify-content-end">
								{ QbTools.formatServiceLevel(displayData.KSZ) }
							</div>
						</div>
						<div style={ informationRowStyle } className="row information-row d-flex justify-content-center align-content-center">
							<div className="progressbar" style={ percentbarStyle.WaitingTime }></div>
							<div className="col-8 information-data-name d-flex align-items-center">
								<BsClock
									className="icon-with-bg-information mr-1"/> { t('localizeDashboardWaitingTimeTitle') }
							</div>
							<div
								className="col-4 text-right information-data d-flex align-items-center justify-content-end">
								{ QbTools.formatWaitingTimeLong(displayData.VI) }
							</div>
						</div>
						<div style={ informationRowStyle } className="row information-row d-flex justify-content-center align-content-center">
							<div className="progressbar" style={ percentbarStyle.ServingTime }></div>
							<div className="col-8 information-data-name d-flex align-items-center">
								<FiWatch
									className="icon-with-bg-information mr-1"/> { t('localizeDashboardServeTimeTitle') }
							</div>
							<div
								className="col-4 text-right information-data d-flex align-items-center justify-content-end">
								{ QbTools.formatWaitingTimeLong(displayData.KI) }
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-1"></div>
				</div>
			</div>
		</div>
	);
}

export default BreakdownDisplaySubItem;
