export const API_KEY = 'AIzaSyB87W7hJjIlYqc_IBGpGd51qdt_kxMi1ic'
export const TESTING_PLACE_LAT_LNG_1 = {lat: 0, lng: 0}

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL
export const SUPPORT_PHONE_NUMBER = process.env.REACT_APP_SUPPORT_PHONE_NUMBER
export const HOST_URL = process.env.REACT_APP_SUPPORT_HOST_URL
export const SUPPORT_USER_NAME=process.env.REACT_APP_SUPPORT_USERNAME

export const FEEDBACK_CATEGORIES = {
	LIKE: 'like',
	DISLIKE: 'dislike',
	WOULD_LIKE: 'request',
	OTHER: 'other',
}

export const FEEDBACK_CATEGORIES_COLORS = {
	[FEEDBACK_CATEGORIES.LIKE]: {light: '#e3ffd6', dark: '#55e263'},
	[FEEDBACK_CATEGORIES.DISLIKE]: {light: '#fac8c8', dark: '#f76666'},
	[FEEDBACK_CATEGORIES.WOULD_LIKE]: {light: '#FFFDD9', dark: '#F4D841'},
	[FEEDBACK_CATEGORIES.OTHER]: {light: '#DEF5FF', dark: '#0066FF'},
}


export const FEEDBACK_CATEGORIES_TO_LOCALIZE_MAP = {
	[FEEDBACK_CATEGORIES.LIKE]: 'localizeFeedbackCategoryLike',
	[FEEDBACK_CATEGORIES.DISLIKE]: 'localizeFeedbackCategoryDislike',
	[FEEDBACK_CATEGORIES.WOULD_LIKE]: 'localizeFeedbackCategoryIWouldLike',
	[FEEDBACK_CATEGORIES.OTHER]: 'localizeFeedbackCategoryOther',
}

export const FEEDBACK_CATEGORIES_LIST = [
	{type: FEEDBACK_CATEGORIES.LIKE, localizeKey: 'localizeFeedbackCategoryLike'},
	{type: FEEDBACK_CATEGORIES.DISLIKE, localizeKey: 'localizeFeedbackCategoryDislike'},
	{type: FEEDBACK_CATEGORIES.WOULD_LIKE, localizeKey: 'localizeFeedbackCategoryIWouldLike'},
	{type: FEEDBACK_CATEGORIES.OTHER, localizeKey: 'localizeFeedbackCategoryOther'},
]

export const REPORT_STATUS_COLORS = {
	critical: '#f1717a',
	urgent: '#f1ee15',
	normal: '#f3f3fc',
}

export const TICKET_STATUS_KEYS = {
	IDLE: 'idle',
	IN_PROGRESS: 'in progress',
	WAITING: 'waiting',
	COMPLETED: 'completed',
}

export const USERS_CONFIG = {
	BRANCH_MANAGER: 'UV',
	SUB_REGION_MANAGER: 'TV',
	REGION_MANAGER: 'RV',
	HEAD_QUARTER: 'HQ',
}

export const USERS_CONFIG_TO_BRANCH_MAPPING = {
	[USERS_CONFIG.BRANCH_MANAGER]: 'branch',
	[USERS_CONFIG.SUB_REGION_MANAGER]: 'subRegion',
	[USERS_CONFIG.REGION_MANAGER]: 'region',
}

export const FETCH_FEED_PAGE_SIZE = 10
export const TEST_IMAGES = [
	{id: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'}
]

export const FILTERS_LIST_HQ = [
	{id: 'MetricProxyHqServiceLevel', name: 'localizeDashboardServeLevelTitle', active: true, position: 1},
	{id: 'MetricProxyHqAverageWaitingTime', name: 'localizeDashboardWaitingTimeTitle', active: true, position: 2},
	{id: 'MetricProxyHqAverageServingTime', name: 'localizeDashboardServeTimeTitle', active: true, position: 3},
	{id: 'MetricProxyHqNoShow', name: 'localizeDashboardNoShowCountTitle', active: true, position: 4},
	{id: 'MetricProxyHqServedCustomers', name: 'localizeDashboardCustomerCountTitle', active: true, position: 5},
	{id: 'MetricProxyServedCustomersAndAverageWaitingTime', name: 'localizeDashboardWaitingTimeAndCustomerCountTitle', active: true, position: 6},
]

export const FILTERS_LIST_ANALYTICS_HQ_DAY = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsDay', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_ANALYTICS_HQ_WEEK = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsWeek', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_ANALYTICS_HQ_MONTH = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsMonth', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_RM = [
	{id: 'MetricProxyRmServiceLevel', name: 'localizeDashboardServeLevelTitle', active: true, position: 1},
	{id: 'MetricProxyRmAverageWaitingTime', name: 'localizeDashboardWaitingTimeTitle', active: true, position: 2},
	{id: 'MetricProxyRmAverageServingTime', name: 'localizeDashboardServeTimeTitle', active: true, position: 3},
	{id: 'MetricProxyRmNoShow', name: 'localizeDashboardNoShowCountTitle', active: true, position: 4},
	{id: 'MetricProxyRmServedCustomers', name: 'localizeDashboardCustomerCountTitle', active: true, position: 5},
	{id: 'MetricProxyServedCustomersAndAverageWaitingTime', name: 'localizeDashboardWaitingTimeAndCustomerCountTitle', active: true, position: 6},
]

export const FILTERS_LIST_ANALYTICS_RM_DAY = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsDay', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_ANALYTICS_RM_WEEK = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsWeek', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_ANALYTICS_RM_MONTH = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsMonth', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_BM = [
	{id: 'MetricProxyBmActiveServicePoints', secondaryName: '', name: 'localizeMetricCounters', active: true, position: 1},
	{id: 'MetricProxyBmTicketsServingWaiting', secondaryName: '', name: 'localizeMetricTicketWaiting', active: true, position: 2},
	{id: 'MetricProxySrmServiceLevel', secondaryName: '', name: 'localizeDashboardServeLevelTitle', active: true, position: 3},
	{id: 'MetricProxySrmAverageWaitingTime', secondaryName: '', name: 'localizeDashboardWaitingTimeTitle', active: true, position: 4},
	{id: 'MetricProxySrmAverageServingTime', secondaryName: '', name: 'localizeDashboardServeTimeTitle', active: true, position: 5},
	{id: 'MetricProxySrmNoShow', secondaryName: '', name: 'localizeDashboardNoShowCountTitle', active: true, position: 6},
	{id: 'MetricProxySrmServedCustomers', secondaryName: '', name: 'localizeDashboardCustomerCountTitle', active: true, position: 7},
]

export const FILTERS_LIST_ANALYTICS_BM_DAY = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
]

export const FILTERS_LIST_ANALYTICS_BM_WEEK = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
]

export const FILTERS_LIST_ANALYTICS_BM_MONTH = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
]

export const FILTERS_LIST_SRM = [
	{id: 'MetricProxySrmServiceLevel', name: 'localizeDashboardServeLevelTitle', active: true, position: 1},
	{id: 'MetricProxySrmAverageWaitingTime', name: 'localizeDashboardWaitingTimeTitle', active: true, position: 2},
	{id: 'MetricProxySrmAverageServingTime', name: 'localizeDashboardServeTimeTitle', active: true, position: 3},
	{id: 'MetricProxySrmNoShow', name: 'localizeDashboardNoShowCountTitle', active: true, position: 4},
	{id: 'MetricProxySrmServedCustomers', name: 'localizeDashboardCustomerCountTitle', active: true, position: 5},
]

export const FILTERS_LIST_ANALYTICS_SRM_DAY = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountDay', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesDay', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsDay', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_ANALYTICS_SRM_WEEK = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountWeek', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesWeek', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsWeek', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]

export const FILTERS_LIST_ANALYTICS_SRM_MONTH = [
	{id: 'AnalyticsProxyCustomerCountServiceLevelMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeLevelTitle', active: true, position: 1},
	{id: 'AnalyticsProxyCustomerCountWaitingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsWaitingTimeTitle', active: true, position: 2},
	{id: 'AnalyticsProxyCustomerCountServingTimeMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsServeTimeTitle', active: true, position: 3},
	{id: 'AnalyticsProxyCustomerCountNoShowCustomerCountMonth', secondaryName: 'localizeAnalyticsCustomerCountTitle', name: 'localizeAnalyticsNoShowCountTitle', active: true, position: 4},
	{id: 'AnalyticsProxyMostPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesTopTitle', active: true, position: 5},
	{id: 'AnalyticsProxyLeastPopularServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesBottomTitle', active: true, position: 6},
	{id: 'AnalyticsProxyUnusedServicesMonth', secondaryName: '', name: 'localizeAnalyticsServicesZeroTitle', active: true, position: 7},
	{id: 'AnalyticsProxyServiceLevelsMonth', secondaryName: '', name: 'localizeAnalyticsRegionsServeLevelTitle', active: true, position: 8},
]
