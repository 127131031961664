class AuthStorageLocalStorage {
    getItem(keyName, toThrow = false) {
        let value = null;
        try {
            value = JSON.parse(localStorage.getItem(keyName));
        } catch (error) {
            if (toThrow) {
                throw error;
            }
        }
        return value;
    }

    setItem(keyName, keyValue) {
        return localStorage.setItem(keyName, JSON.stringify(keyValue));
    }

    removeItem(keyName) {
        return localStorage.removeItem(keyName);
    }
}

export default AuthStorageLocalStorage;
