//
//  QBDashboardFigureTicket.js
//  qboard
//
//  Created by Zoltan Meszaros on 2020. 05. 25..
//  Copyright © 2020 Xperion. All rights reserved.
//
//  Generated by PaintCode
//  http://www.paintcodeapp.com
//



//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    QBDashboardFigureTicket.drawTicket(canvas, const_inactive_alpha, text, active, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//
// Available Utilities:
//    QBDashboardFigureTicket.clearCanvas(canvas)
//    QBDashboardFigureTicket.makeRect(x, y, width, height)
import { Config } from '../modules';


//// Create StyleKit Object
var QBDashboardFigureTicket = {};
(function() {

    //// Drawing Methods

    function drawTicket(canvas, const_inactive_alpha, text, active, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        //var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 70), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 70, resizedFrame.h / 70);


        //// Color Declarations
        //var color = 'rgba(0, 102, 255, 1)';
        var color = Config.appearance.mainColor;

        //// Variable Declarations
        var alpha = active ? 1 : const_inactive_alpha;

        //// Rectangle 3 Drawing
        //// New tikcet style
        context.save();
        context.globalAlpha = alpha;
        context.beginPath();
        context.moveTo(5, 20);
        context.lineTo(65, 20);
        context.lineTo(65, 25);
        context.bezierCurveTo(65, 25, 45, 35, 65, 45);
        context.lineTo(65, 50);
        context.lineTo(5, 50);
        context.lineTo(5, 45);
        context.bezierCurveTo(5, 45, 25, 35, 5, 25);
        context.lineTo(5, 20);
        context.strokeStyle = color;
        context.lineWidth = 3;
        context.lineJoin = 'round';
        context.stroke();
        context.restore();

        //Orignal ticket style
        /*context.save();
        context.globalAlpha = alpha;
        context.beginPath();
        context.moveTo(44, 22);
        context.lineTo(44, 22);
        context.moveTo(36, 5);
        context.lineTo(44, 13);
        context.lineTo(50, 4);
        context.lineTo(57, 10);
        context.lineTo(63, 4);
        context.lineTo(66, 4);
        context.lineTo(66, 56);
        context.lineTo(56, 66);
        context.lineTo(56, 66);
        context.lineTo(14, 66);
        context.lineTo(14, 66);
        context.lineTo(5, 56);
        context.lineTo(5, 26);
        context.lineTo(5, 4);
        context.lineTo(8, 4);
        context.lineTo(16, 13);
        context.lineTo(23, 4);
        context.lineTo(30, 11);
        context.lineTo(36.5, 4);
        context.strokeStyle = color;
        context.lineWidth = 2;
        context.lineJoin = 'round';
        context.stroke();
        context.restore();*/


        //// data_text Drawing
        var data_textRect = makeRect(6, 20, 59, 30);
        context.fillStyle = color;
        context.font = '17px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
        context.textAlign = 'center';
        var data_textTotalHeight = 17 * 1.3;
        context.fillText(text, data_textRect.x + data_textRect.w/2, data_textRect.y + 16 + data_textRect.h / 2 - data_textTotalHeight / 2);
        
        context.restore();

    }

    //// Infrastructure

    function clearCanvas(canvas) {
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    // Possible arguments for 'resizing' parameter are:
    //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
    //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
    //   'stretch': The content is stretched to match the entire target rectangle.
    //   'center': The content is centered in the target rectangle, but it is NOT resized.
    function applyResizingBehavior(resizing, rect, targetRect) {
        if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
            return rect;
        }

        var scales = makeSize(0, 0);
        scales.w = Math.abs(targetRect.w / rect.w);
        scales.h = Math.abs(targetRect.h / rect.h);

        switch (resizing) {
            case 'aspectfit': {
                scales.w = Math.min(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'aspectfill': {
                scales.w = Math.max(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'stretch':
            case undefined:
                break;
            case 'center': {
                scales.w = 1;
                scales.h = 1;
                break;
            }
            default:
                throw new Error('Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.');
        }

        var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h));
        result.w *= scales.w;
        result.h *= scales.h;
        result.x = targetRect.x + (targetRect.w - result.w) / 2;
        result.y = targetRect.y + (targetRect.h - result.h) / 2;
        return result;
    }

    function makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    function equalRects(r1, r2) {
        return r1.x === r2.x && r1.y === r2.y && r1.w === r2.w && r1.h === r2.h;
    }

    function makeSize(w, h) {
        return { w: w, h: h };
    }

    function initializeCanvas(canvas) {
        if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        var context = canvas.getContext('2d');

        var devicePixelRatio = window.devicePixelRatio || 1;
        var backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        var pixelRatio = devicePixelRatio / backingStorePixelRatio;

        //canvas.style.width = canvas.width + 'px';
        //canvas.style.height = canvas.height + 'px';
        canvas.width *= pixelRatio;
        canvas.height *= pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return canvas;
    }

    //// Public Interface

    // Drawing Methods
    QBDashboardFigureTicket.drawTicket = drawTicket;

    // Utilities
    QBDashboardFigureTicket.clearCanvas = clearCanvas;
    QBDashboardFigureTicket.makeRect = makeRect;

})();

export default QBDashboardFigureTicket;
