import React, {useContext, useEffect, useState} from 'react';
import {Redirect, useRouteMatch, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Auth, QbApi, QbApiTimeoutError, AppStateContext} from '../modules';
import {GoogleMapDrawerFullPage} from "./index";
import {set} from "lodash";
import { ConfirmationModal } from './';
import { showMessage } from '../utilities/UiUtils';
import { geocodeLatLngToAddress } from '../utilities/PlaceUtils';

function SettingsAddressMap() {
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
	let history = useHistory();
	const match = useRouteMatch('/settings/branch/:id/address');
	const appState = useContext(AppStateContext);

    const [ isUnsavedProfileModal, setUnsavedProfileModal ] = useState(false);
    const [ isFormDirty, setFormDirty ] = useState(false);
    const showUnsavedProfileModal = () => setUnsavedProfileModal(true);
    const hideUnsavedProfileModal = () => setUnsavedProfileModal(false);


	let authData = Auth.getData();
	let branch = {
		userProfile: authData.userProfile.branches?.find(element => String(element.id) === match.params.id),
		user: authData.user.branches?.find(element => String(element.id) === match.params.id),
	};
	const branchIndex = authData.userProfile.branches?.findIndex(element => String(element.id) === match.params.id);
	const [ location, updateLocation ] = useState({
		lat: branch?.userProfile?.address?.latitude ?? 0,
		lng: branch?.userProfile?.address?.longitude ?? 0,
		address: (branch?.userProfile?.address?.postalCode ?? '') + ' ' + (branch?.userProfile?.address?.address ?? '') + ', ' + (branch?.userProfile?.address?.city ?? '') + ', ' + (branch?.userProfile?.address?.country ?? ''),
	});

	useEffect(() => {
		appState.setActivePage('settings.address');
	});
	if ((branchIndex === -1) || (typeof branchIndex === 'undefined')) {
        return <Redirect to={ '/settings' }/>
    }

	const updateLocationHandler = async (updatedLocation) => {
		set(authData, `userProfile.branches[${branchIndex}].address.latitude`, updatedLocation?.lat);
		set(authData, `userProfile.branches[${branchIndex}].address.longitude`, updatedLocation?.lng);
        let revgeoAddress = await geocodeLatLngToAddress(updatedLocation.lat, updatedLocation.lng);
        if (revgeoAddress !== null) {
            set(authData, `userProfile.branches[${branchIndex}].address.address`, revgeoAddress.address);
            set(authData, `userProfile.branches[${branchIndex}].address.city`, revgeoAddress.city);
            set(authData, `userProfile.branches[${branchIndex}].address.state`, revgeoAddress.state);
            set(authData, `userProfile.branches[${branchIndex}].address.country`, revgeoAddress.country);
            set(authData, `userProfile.branches[${branchIndex}].address.postalCode`, revgeoAddress.postalCode);
            set(authData, `userProfile.branches[${branchIndex}].address.ISOCountryCode`, revgeoAddress.ISOCountryCode);
        }
		const userProfile = {branches: authData.userProfile.branches};
		const updatedUserProfile = Auth.updateUserProfile(userProfile);
		try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage( { title: t('webapp:toastTitleFailed'), message: t('webapp:toastDescriptionFailedUpdate'), type: 'error' } )
			} else {
				throw error;
			}
		}
		updateLocation(updatedLocation)
		history.goBack()
	}

    const updateMarkerLocation = async(location) => {
        setFormDirty(true);
    }

	const goBackHandler = () => {
		if (! isFormDirty) {
			return history.goBack()
		}
		return showUnsavedProfileModal()
	}

	return (
		<React.Fragment>
			<GoogleMapDrawerFullPage
				isGoogleMapModal={ true }
				closeGoogleMapModal={ goBackHandler }
				location={ location }
				branchName={ webappT('updateAddress') }
				onUpdateLocation={ updateLocationHandler }
				onUpdateMarkerLocation= { updateMarkerLocation }
			/>
            <ConfirmationModal
                message={ webappT('youHaveUnsavedChangesGoBack') }
                show={ isUnsavedProfileModal }
                onHide={ hideUnsavedProfileModal }
                onDone={ () => history.goBack() }/>
		</React.Fragment>
	);
}

export default SettingsAddressMap;
