import React, {useRef, useEffect, useState} from "react";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

function calcDimensions(width) {
  const aspectRatio = 297 / 210;
  const contentFactor = 0.95;

  return { 
    height: width * aspectRatio * contentFactor,
    width: width * contentFactor
  }
}

export default function ImageGridList(props) {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState(calcDimensions(300))

  useEffect(() => {
    const handleResize = function handleResize() {
      if (ref.current) {
        setDimensions(calcDimensions(ref.current?.offsetWidth))
      }
    }

    const debouncedHandleResize = debounce(handleResize, 100) 

    window.addEventListener('resize', debouncedHandleResize)

    handleResize()

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize) 
    }
  }, [])

  return (
    <div ref={ref} className={props.className} style={{backgroundColor:'lightGray', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', overflow: 'hidden'}}>
      <GridList cellHeight={dimensions.height} spacing={5} style={{height: '100%', paddingTop: '5px'}} cols={1}>
        {props.images.map((i) => (
          <GridListTile key={i} cols={1}>
            <img src={i} width={dimensions.width} height={dimensions.height} load="lazy" alt="" />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
