import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {SubmitButton, TextField} from './form';
import React, {useContext, useEffect, useState, useRef} from 'react';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import {AppStateContext, Auth, QbApi, QbApiTimeoutError, QbTools} from '../modules';
import {Link, Redirect, useHistory, useRouteMatch} from 'react-router-dom';
import { UpperNavigation, ConfirmationModal } from './';
import { Config } from '../modules';
import * as yup from 'yup';
import {showMessage} from "../utilities/UiUtils";


function SettingsBranch() {
	const match = useRouteMatch('/settings/branch/:id');
	const appState = useContext(AppStateContext);
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
    let history = useHistory();
    const formikInnerRef = useRef();

    const [ isUnsavedProfileModal, setUnsavedProfileModal ] = useState(false);
    const showUnsavedProfileModal = () => setUnsavedProfileModal(true)
    const hideUnsavedProfileModal = () => setUnsavedProfileModal(false)
	
	let authData = Auth.getData();
	let branch = {
		userProfile: authData.userProfile.branches?.find(element => String(element.id) === match.params.id),
		user: authData.user.branches?.find(element => String(element.id) === match.params.id),
	};
	
	useEffect(() => {
		appState.setActivePage('settings.branch');
	});
	
	const branchIndex = authData.userProfile.branches?.findIndex(element => String(element.id) === match.params.id);
	if ((branchIndex === -1) || (typeof branchIndex === 'undefined')) {
		return <Redirect to={ '/settings' }/>
	}
	
	let formValues = {
		name: branch.userProfile.name,
		counters: branch.userProfile.counters,
	};
	
	let openingHoursList = [];
	for (let index = 0; index < 7; ++index) {
		openingHoursList.push(
            <div className="col-12" key={ index }>
                <Link className="qboard-link" to={ '/settings/branch/' + match.params.id + '/openinghours/' + index }>
                    <div className="row py-2 d-flex align-items-center settings-opening-hours-item">
                        <div className="col-4">
                            <div key={ index }>{ QbTools.formatStoreOpeningHoursDay(branch.userProfile?.open, index, true, t) }</div>
                        </div>
                        <div className="col-8 d-flex justify-content-end align-items-center settings-secondary-text">
                            <div key={ index }>{ QbTools.formatStoreOpeningHoursTime(branch.userProfile?.open, index, true, t) }</div>
                            <BsChevronRight className='icon-without-bg'/>
                        </div>
                    </div>
                </Link>
            </div>
		);
	}
	
	async function formSubmit(formValues, {resetForm,setSubmitting}) {
        setSubmitting(true);
		authData.userProfile.branches[branchIndex].name = formValues.name;
		authData.userProfile.branches[branchIndex].counters = formValues.counters;
		const userProfile = {branches: authData.userProfile.branches,};
		const updatedUserProfile = Auth.calcUserProfile(userProfile);
        try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
            Auth.updateUserProfile(userProfile);
			resetForm({values: formValues})
			showMessage({title:webappT("toastTitleSuccess"),message:webappT("toastDescriptionSuccessUpdate")})
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedUpdate"), type: 'error' })
			} else {
				throw error;
			}
		} finally {
			setSubmitting(false)
		}
    }
    
    const goBackHandler = () => {
		if (!formikInnerRef.current.dirty) {
			return history.goBack()
		}
		return showUnsavedProfileModal()
    }
    
    const validation = {
        number: /(^[0-9]*$)/,
    }
    
    let enableSubmit = false;
	
	// TODO: opneing hours szerkesztése
	return (
		<React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ goBackHandler }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                        </div>
                    }
                    middle={
                        t('localizeSettingsSectionStores')
                    }
                />
            </div>
			<div className="settings body_container">
				<div className="container">
					<Formik
                        innerRef={ formikInnerRef }
						initialValues={ formValues }
                        onSubmit={ formSubmit }
                        validationSchema={ yup.object().shape({
                            counters: yup.string()
                                .matches(
                                    validation.number,
                                    webappT('invalidNumber')
                                ),
                        }) }>
                        { ({dirty, isValid, isSubmitting, setFieldValue}) => {
                        return <Form className="mb-3">
							<TextField name="name" label={ t('localizeStoreName') } isClearable setFieldValue={setFieldValue}/>
							<TextField name="counters" label={ t('localizeStoreWorkstationCount') } pattern="[0-9]+" isClearable setFieldValue={setFieldValue}/>

                            <Link className="qboard-link" to={ '/settings/branch/' + match.params.id + '/staff' }>
                                <div className="row align-items-center py-1 mb-2 setting-item mx-4">
                                    <div className="col-12">
                                        <div className="row font-weight-bold">
                                            <div className="mb-2">{ t('localizeStoreStaff') }</div>
                                        </div>
                                        <div className="row settings-branch-item">
                                            <div className="col-4 d-flex align-items-center">
                                                { t('localizeActive') }
                                            </div>
                                            <div className="col-8 d-flex align-items-center justify-content-end text-right settings-secondary-text">
                                                    { branch.userProfile.staff.length } / { branch.user.staff.length }
                                                <BsChevronRight className='icon-without-bg'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link className="qboard-link" to={ '/settings/branch/' + match.params.id + '/address' }>
                                <div className="row align-items-center py-1 mb-2 setting-item mx-4">
                                    <div className="col-12">
                                        <div className="row font-weight-bold">
                                            <div className="mb-2">{ t('localizeStoreAddress') }</div>
                                        </div>
                                        <div className="row settings-branch-item">
                                            <div className="col-4 d-flex align-items-center">
                                                { t('localizePostalAddress') }
                                            </div>
                                            <div className="col-8 d-flex align-items-center justify-content-end settings-secondary-text text-right">
                                                { branch.userProfile.address.address }<br/>
                                                { branch.userProfile.address.city } { branch.userProfile.address.state } { branch.userProfile.address.postalCode }<br/>
                                                { branch.userProfile.address.country }<br/>
                                                <BsChevronRight className='icon-without-bg'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="row align-items-center py-1 mb-2 setting-item mx-4">
                                <div className="col-12">
                                    <div className="row font-weight-bold">
                                        <div className="mb-2">{ t('localizeStoreWeek') }</div>
                                    </div>
                                    <div className="row settings-branch-item py-0">
                                        { openingHoursList }
                                    </div>
                                </div>
                            </div>
                            {
                                (isValid) ?
                                    (dirty && !isSubmitting) ?
                                        enableSubmit = true
                                        :
                                        enableSubmit = false
                                    :
                                    enableSubmit = false
								}
							<SubmitButton disabled={ !enableSubmit }/>
						</Form>
                    } }
					</Formik>
				</div>
			</div>
            <ConfirmationModal
                message={ webappT('youHaveUnsavedChangesGoBack') }
                show={ isUnsavedProfileModal }
                onHide={ hideUnsavedProfileModal }
                onDone={ () => history.goBack() }/>
		</React.Fragment>
	);
}

export default SettingsBranch;
