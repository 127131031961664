import React, { useEffect, useState, useContext } from 'react';
import { AppStateContext } from '../modules';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { QbTools, QbApi, Auth } from '../modules';
import LegalDocumentsShowPdf from '../components/LegalDocumentsShowPdf';

function LegalDocumentsCurrentShowPdf(props) {
    const { t, i18n } = useTranslation();
    const appState = useContext(AppStateContext);
    const [ legal, setLegal ] = useState( null );
    const lng = i18n.language;
    let pathPrefix = '';
    if (props.hasOwnProperty('pathPrefix')) {
        pathPrefix = props.pathPrefix;
    }
    const match = useRouteMatch('/' + pathPrefix + 'legal/current/:id');
    const legalId = match.params.id;

    // XXX: cache-elni a lekért pdf-et(?)
    useEffect( () => {
        async function renderOutput() {
            let legalData = Auth.safeGetItem('legalDocuments');
            if ((typeof legalData === 'undefined') || (legalData === null)) {
                legalData = await QbApi.instance.getAsync(appState, 'legal/documents');
                Auth.setItem('legalDocuments', legalData);
            }

            for (let index = 0, len = legalData.data.length; index < len; ++index) {
                const legal = legalData.data[index];
                if (String(legal.id) === legalId) {
                    setLegal({ 
                        name : QbTools.getByLocale(legalData.localization[legal.name], lng), 
                        path : QbTools.getByLocale(legalData.localization[legal.path], lng) 
                    });
                }
            }
        }
        renderOutput();
    }, [ legalId, lng, t, appState ] );

    return (<LegalDocumentsShowPdf legal = {legal}/>);
}

export default LegalDocumentsCurrentShowPdf;
