import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { AnalyticsProxyCustomerCountServiceLevel, AnalyticsProxyCustomerCountWaitingTime, AnalyticsProxyCustomerCountServingTime, AnalyticsProxyCustomerCountNoShowCustomerCount } from './';
import { AnalyticsProxyMostPopularServices, AnalyticsProxyLeastPopularServices, AnalyticsProxyUnusedServices } from './';
import { AppStateContext, Constants, QbTools, QbApi, Auth } from '../modules';
import {isEmpty} from "lodash";
import { FILTERS_LIST_ANALYTICS_BM_DAY, FILTERS_LIST_ANALYTICS_BM_WEEK, FILTERS_LIST_ANALYTICS_BM_MONTH } from '../utilities/Constant';

function AnalyticsBm(props) {
    const appState = useContext(AppStateContext);
    const isDay = useRouteMatch('*day*');
    const isWeek = useRouteMatch('*week*');
    const isMonth = useRouteMatch('*month*');

    const userProfile = Auth.getData().userProfile || {}
	const filterSort = userProfile?.config?.filterSort || []
    let initialFiltersList;
    let defaultFiltersList;
    let finalFiltersList = [];
    if(isDay){
        initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_BM_DAY : filterSort
        defaultFiltersList = FILTERS_LIST_ANALYTICS_BM_DAY;
    } else if(isWeek){
        initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_BM_WEEK : filterSort
        defaultFiltersList = FILTERS_LIST_ANALYTICS_BM_WEEK;
    } else if(isMonth){
        initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_BM_MONTH : filterSort
        defaultFiltersList = FILTERS_LIST_ANALYTICS_BM_MONTH;
    }
    let empty = true;

    for (var s = 0; s < initialFiltersList.length; s++) {
        for (var i = 0; i < defaultFiltersList.length; i++) {
            if(defaultFiltersList[i].id === initialFiltersList[s].id){
                empty = false
                finalFiltersList.push(initialFiltersList[s])
            }
        }
    }
    if(empty){ finalFiltersList=defaultFiltersList; }

    const now = moment();
    const apiTimeResolution = props.durationInfo.timeResolution;
    const apiTargetPath = props.match.params.id;
    const fromTo = {
        fromTo3: QbTools.analyticsDuration(now, props.durationInfo, 3),
        fromTo2: QbTools.analyticsDuration(now, props.durationInfo, 2),
        fromTo1: QbTools.analyticsDuration(now, props.durationInfo, 1),
        fromTo0: QbTools.analyticsDuration(now, props.durationInfo, 0),
        fromTo:  QbTools.analyticsDuration(now, props.durationInfo, 0),
    };
    const apiFromToJson = JSON.stringify(fromTo);

    const ComponentsMap = {
        'AnalyticsProxyCustomerCountServiceLevelDay': (metricData, match, position, id) => <AnalyticsProxyCustomerCountServiceLevel metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountWaitingTimeDay': (metricData, match, position, id) => <AnalyticsProxyCustomerCountWaitingTime metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountServingTimeDay': (metricData, match, position, id) => <AnalyticsProxyCustomerCountServingTime metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountNoShowCustomerCountDay': (metricData, match, position, id) => <AnalyticsProxyCustomerCountNoShowCustomerCount metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyMostPopularServicesDay': (metricData, match, position, id) => <AnalyticsProxyMostPopularServices metricData={ metricData } durationInfo={ props.durationInfo } graphTitle={ 'localizeAnalyticsServicesTopTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyLeastPopularServicesDay': (metricData, match, position, id) => <AnalyticsProxyLeastPopularServices metricData={ metricData } durationInfo={ props.durationInfo } graphTitle={ 'localizeAnalyticsServicesBottomTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyUnusedServicesDay': (metricData, match, position, id) => <AnalyticsProxyUnusedServices metricData={ metricData } graphTitle={ 'localizeAnalyticsServicesZeroTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,


        'AnalyticsProxyCustomerCountServiceLevelWeek': (metricData, match, position, id) => <AnalyticsProxyCustomerCountServiceLevel metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountWaitingTimeWeek': (metricData, match, position, id) => <AnalyticsProxyCustomerCountWaitingTime metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountServingTimeWeek': (metricData, match, position, id) => <AnalyticsProxyCustomerCountServingTime metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountNoShowCustomerCountWeek': (metricData, match, position, id) => <AnalyticsProxyCustomerCountNoShowCustomerCount metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyMostPopularServicesWeek': (metricData, match, position, id) => <AnalyticsProxyMostPopularServices metricData={ metricData } durationInfo={ props.durationInfo } graphTitle={ 'localizeAnalyticsServicesTopTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyLeastPopularServicesWeek': (metricData, match, position, id) => <AnalyticsProxyLeastPopularServices metricData={ metricData } durationInfo={ props.durationInfo } graphTitle={ 'localizeAnalyticsServicesBottomTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyUnusedServicesWeek': (metricData, match, position, id) => <AnalyticsProxyUnusedServices metricData={ metricData } graphTitle={ 'localizeAnalyticsServicesZeroTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,


        'AnalyticsProxyCustomerCountServiceLevelMonth': (metricData, match, position, id) => <AnalyticsProxyCustomerCountServiceLevel metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountWaitingTimeMonth': (metricData, match, position, id) => <AnalyticsProxyCustomerCountWaitingTime metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountServingTimeMonth': (metricData, match, position, id) => <AnalyticsProxyCustomerCountServingTime metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyCustomerCountNoShowCustomerCountMonth': (metricData, match, position, id) => <AnalyticsProxyCustomerCountNoShowCustomerCount metricData={ metricData } durationInfo={ props.durationInfo } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyMostPopularServicesMonth': (metricData, match, position, id) => <AnalyticsProxyMostPopularServices metricData={ metricData } durationInfo={ props.durationInfo } graphTitle={ 'localizeAnalyticsServicesTopTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyLeastPopularServicesMonth': (metricData, match, position, id) => <AnalyticsProxyLeastPopularServices metricData={ metricData } durationInfo={ props.durationInfo } graphTitle={ 'localizeAnalyticsServicesBottomTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,
        'AnalyticsProxyUnusedServicesMonth': (metricData, match, position, id) => <AnalyticsProxyUnusedServices metricData={ metricData } graphTitle={ 'localizeAnalyticsServicesZeroTitle' } setComponentList={ props.setComponentList } position={ position } id={ id } />,

    }

    const [ metricData, setMetricData ] = useState( { _state: Constants.ajaxState.init, _failed: false, timebased: { }, groupbased: { }, tk1: { }, tk2: { }, tk3: { }, tk4: { } } );
    useEffect( () => {
        setMetricData(prevState => ( { ...prevState, _state: Constants.ajaxState.loading, _failed: false } ));
        var indices = [ ];
        const apiFromTo = JSON.parse(apiFromToJson);

        indices.push(QbApi.instance.getMetricTimebased(appState, apiTargetPath, apiTimeResolution, moment(apiFromTo.fromTo.from), moment(apiFromTo.fromTo.to), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS' ], (data) => QbApi.updateMetricData(setMetricData, 'timebased',  data)));

        indices.push(QbApi.instance.getMetricCustom(appState, apiTargetPath, moment(apiFromTo.fromTo0.from), moment(apiFromTo.fromTo0.to), [ 'TK' ], (data) => QbApi.updateMetricData(setMetricData, 'tk1',  data)));
        indices.push(QbApi.instance.getMetricCustom(appState, apiTargetPath, moment(apiFromTo.fromTo1.from), moment(apiFromTo.fromTo1.to), [ 'TK' ], (data) => QbApi.updateMetricData(setMetricData, 'tk2',  data)));
        indices.push(QbApi.instance.getMetricCustom(appState, apiTargetPath, moment(apiFromTo.fromTo2.from), moment(apiFromTo.fromTo2.to), [ 'TK' ], (data) => QbApi.updateMetricData(setMetricData, 'tk3',  data)));
        indices.push(QbApi.instance.getMetricCustom(appState, apiTargetPath, moment(apiFromTo.fromTo3.from), moment(apiFromTo.fromTo3.to), [ 'TK' ], (data) => QbApi.updateMetricData(setMetricData, 'tk4',  data)));

        return function cleanup() {
            QbApi.instance.cancelQueue(indices);
        };
    }, [ apiTargetPath, apiTimeResolution, apiFromToJson, appState, props.refreshCounter ] );

    return (
        <div>
			{ finalFiltersList.map(item => {
				const { id, active, position } = item || {}
				const renderItem = ComponentsMap[id]
				return active && renderItem ? renderItem(metricData, props.match, position, id) : null
			}) }
		</div>
    );
}

export default AnalyticsBm;
