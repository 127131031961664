import {Config, Constants, Auth, QbApiCache, QbApiTimeoutError} from '../modules';
import moment from 'moment';
import {RNCryptor} from './rncryptor';

class QbApi {
	static instance = QbApi.instance || new QbApi();

	queue = [ ];
	queueId = 0;
	queueActive = false;
	queueActiveId = null;
	queueActiveCancelled = false;
	queueTimeout = null;

    metricMap = {
        plaintext: {
            timeBased:  [ 'VI', 'KI', 'KSZ', 'USZ', 'NS',                                                                                            ],
            groupBased: [ 'VI', 'KI', 'KSZ', 'USZ', 'NS',      'ROP', 'RCP', 'RTW', 'RTS',                              'branchDetails',             ],
            staffBased: [ 'VI', 'KI', 'KSZ', 'USZ', 'NS',                           'RTS', 'RST', 'RLC',                                             ],
            custom:     [                                'TK',                                                                                       ],
        },
        encrypted: {
            timeBased:  [                                                                                                                            ],
            groupBased: [                                                                                                                'managers', ],
            staffBased: [                                                                                'userDetails',                              ],
            custom:     [                                                                                                                            ],
        },
        all: {
            timeBased:  [ 'VI', 'KI', 'KSZ', 'USZ', 'NS',                                                                                            ],
            groupBased: [ 'VI', 'KI', 'KSZ', 'USZ', 'NS',      'ROP', 'RCP', 'RTW', 'RTS',                              'branchDetails', 'managers', ],
            staffBased: [ 'VI', 'KI', 'KSZ', 'USZ', 'NS',                           'RTS', 'RST', 'RLC', 'userDetails',                              ],
            custom:     [                                'TK',                                                                                       ],
        },
    };

    // helper function a metricData proper frissítésére
    static updateMetricData(setter, field, data) {
        let updater = { };
        if (data.hasOwnProperty('_state')) {
            updater._state = data._state;
        }
        if (data.hasOwnProperty('_failed')) {
            updater._failed = data._failed;
        }
        if (data.hasOwnProperty('data')) {
            updater[field] = data;
        }
        setter((prevState) => ( { ...prevState, ...updater } ) );
    }

	generateCacheKey(metricApi) {
        let tmpBody = JSON.parse(JSON.stringify(metricApi.apiBody));
        tmpBody.metric = metricApi.metricMap.plaintext;
		// XXX: ha az api* property-kben '|' jel szerepel, akkor potenciális cache poisoning
		return metricApi.apiMethod + '|' + metricApi.apiPath + '|' + JSON.stringify(tmpBody);
	}

	queuePush(queueItem) {
		QbApi.instance.queueId++;
		queueItem.id = QbApi.instance.queueId;
		queueItem.cacheKey = QbApi.instance.generateCacheKey(queueItem);
		queueItem.timing = {
			enterQueue: moment().unix(),
			request: null,
			response: null,
		};
		QbApi.instance.queue.push(queueItem);

		if (!QbApi.instance.queueActive) {
			QbApi.instance.queueActive = true;
			QbApi.instance.queueActiveCancelled = false;
			QbApi.instance.queueTimeout = setTimeout(QbApi.instance.consumeQueue, 100);
		}
		return QbApi.instance.queueId;
	}

	deactivateEmptyQueue() {
		if (QbApi.instance.queue.length === 0) {
			QbApi.instance.queueActive = false;
			clearTimeout(QbApi.instance.queueTimeout);
			QbApi.instance.queueTimeout = null;
			return true;
		}
		return false;
	}

	cancelQueue(ids) {
		for (const id of ids) {
			const index = QbApi.instance.queue.findIndex((element) => element.id = id);
			if (index > -1) {
				QbApi.instance.queue.splice(index, 1);
			}
			if (QbApi.instance.queueActiveId === id) {
				QbApi.instance.queueActiveCancelled = true;
			}
		}
	}

	async consumeQueue() {
		if (QbApi.instance.deactivateEmptyQueue()) {
			return;
		}

		const metricApi = QbApi.instance.queue.shift();
		QbApi.instance.queueActiveId = metricApi.id;
		QbApi.instance.queueActiveCancelled = false;
		if (QbApiCache.instance.has(metricApi.cacheKey)) {
            let data = QbApiCache.instance.get(metricApi.cacheKey);
			if (!QbApi.instance.queueActiveCancelled) {
                if ((QbApi.instance.queue.length === 0) && (! metricApi.hasEncryptedMetric)) {
                    data._state = Constants.ajaxState.done;
                }
				metricApi.setData(data);
			}
            if (! metricApi.hasEncryptedMetric) {
                QbApi.instance.queueTimeout = setTimeout(QbApi.instance.consumeQueue, 0);
                return;
            }
		}

        metricApi.timing.request = moment().unix();
		try {
            const authData = Auth.getData();
            const cryptoSecret = authData.token.substring(0, 7) + Config.transportSecret + authData.token.substring(authData.token.length - 3, authData.token.length);
			let response = await QbApi.instance.fetchTimeout(metricApi.appState, metricApi.apiPath, {
				method: metricApi.apiMethod,
				headers: {
					'Content-Type': 'application/json',
					'X-Authorization': 'Bearer ' + Auth.getToken()
				},
				body: JSON.stringify(metricApi.apiBody),
			});
            metricApi.timing.response = moment().unix();
            if ( [ 401, 403, 500 ].includes(response.status)) {
                Auth.deauthenticateUser();
                if ((typeof metricApi.appState === 'object') && (metricApi.appState !== null)) {
                    metricApi.appState.setAuthPhase('unauth');
                }
                return;
            }
			let data = await response.json();
			if ((typeof data === 'object') && ('errorCode' in data)) {
				throw new Error('consumeQueue error: ' + data.message + ' (errorCode: ' + data.errorCode + ')');
			}
			if (typeof data === 'object') {
                data.timing = metricApi.timing;
                data._failed = false;
            }
			let cacheData = JSON.parse(JSON.stringify(data));

			let encryptedMetrics = metricApi.apiMetric.filter(value => QbApi.instance.metricMap.encrypted[metricApi.metricType].includes(value));
			if (encryptedMetrics.length > 0) {
				for (const metric of encryptedMetrics) {
					for (const [ key, item ] of Object.entries(data.data)) {
						if (item.hasOwnProperty(metric)) {
							const _original = data.data[key][metric];
							Object.defineProperty(data.data[key], metric, {
								get: function () {
									console.log('getter called');
                                    let value = null;
                                    try {
                                        value = JSON.parse(RNCryptor.decrypt(_original, cryptoSecret))
                                    } catch (error) {
                                        QbApi.instance.sendErrorReport( {
                                            error: error && error.toString(),
                                            stack: error && error.stack,
                                            data: {
                                                key: key,
                                                metric: metric,
                                            },
                                        } );
                                        console.error(error);
                                    }
                                    return value;
								}
							});
							delete cacheData.data[key][metric];
						}
					}
				}
			}
			//console.log(data, cacheData);
			// TODO: cacheData-t cache-elni a következő sorban
			// elővételnél ellenőrizni, hogy a lekért metrikák tartalmaznak-e encrypted-et, és ha igen, akkor újra lekérni az adatokat
			// vagy esetleg a getterben lekérni az adatokat netről
			// mert ha nem, akkor nem kérhetjük le a breakdown nézetekben, mert csak a megfelelő contact nézetben van szükség erre az adatra

			QbApiCache.instance.put(metricApi.cacheKey, cacheData);
			if (!QbApi.instance.queueActiveCancelled) {
				metricApi.setData(data);
			}

		} catch (error) {
			console.error('Error:', error);
                metricApi.setData( {
                    _failed: true,
                    timing: metricApi.timing,
                    data: null,
                } );
		} finally {
			QbApi.instance.queueActiveId = null;
            if ((QbApi.instance.queue.length === 0) && (!QbApi.instance.queueActiveCancelled)) {
                metricApi.setData( { _state: Constants.ajaxState.done } );
            }
			QbApi.instance.queueTimeout = setTimeout(QbApi.instance.consumeQueue, 0);
		}
	}

    getMetricMap(mode) {
        return {
            plaintext: QbApi.instance.metricMap.plaintext[mode],
            encrypted: QbApi.instance.metricMap.encrypted[mode],
            all: QbApi.instance.metricMap.all[mode],
        }
    }

	getMetricGroupbased(appState, apiTargetPath, subGroup, apiFrom, apiTo, apiMetricRequest, setData) {
        const metricType = 'groupBased';
        let hasEncryptedMetric = apiMetricRequest.filter(value => QbApi.instance.metricMap.encrypted.groupBased.includes(value)).length > 0;
        let apiMetric = QbApi.instance.metricMap.plaintext.groupBased;
        if (hasEncryptedMetric) {
            apiMetric = QbApi.instance.metricMap.all.groupBased;
        }
		const apiBody = {
			'metric': apiMetric,
			'from': apiFrom.format(Config.apiTimeFormat),
			'to': apiTo.format(Config.apiTimeFormat),
		};
		const queueItem = {
			apiMethod: 'POST',
			apiPath: Config.apiUrl + 'metric/groupbased/' + subGroup + '?path=' + apiTargetPath + '&withDetails=false',
            apiBody: apiBody,
			apiJsonBody: JSON.stringify(apiBody),
			setData: setData,
			appState: appState,
			metricType: metricType,
			apiMetric: apiMetric,
            metricMap: QbApi.instance.getMetricMap(metricType),
            hasEncryptedMetric: hasEncryptedMetric,
		};
		return QbApi.instance.queuePush(queueItem);
	}

	getMetricStaffbased(appState, apiTargetPath, apiFrom, apiTo, apiMetricRequest, setData) {
        const metricType = 'staffBased';
        let hasEncryptedMetric = apiMetricRequest.filter(value => QbApi.instance.metricMap.encrypted.staffBased.includes(value)).length > 0;
        let apiMetric = QbApi.instance.metricMap.plaintext.staffBased;
        if (hasEncryptedMetric) {
            apiMetric = QbApi.instance.metricMap.all.staffBased;
        }
		const apiBody = {
			'metric': apiMetric,
			'from': apiFrom.format(Config.apiTimeFormat),
			'to': apiTo.format(Config.apiTimeFormat),
		};
		const queueItem = {
			apiMethod: 'POST',
			apiPath: Config.apiUrl + 'metric/staffbased?path=' + apiTargetPath + '&withDetails=false',
            apiBody: apiBody,
			apiJsonBody: JSON.stringify(apiBody),
			setData: setData,
			appState: appState,
			metricType: metricType,
			apiMetric: apiMetric,
            metricMap: QbApi.instance.getMetricMap(metricType),
            hasEncryptedMetric: hasEncryptedMetric,
		};
		return QbApi.instance.queuePush(queueItem);
	}

	getMetricTimebased(appState, apiTargetPath, apiTimeResolution, apiFrom, apiTo, apiMetric, setData) {
        const metricType = 'timeBased';
		const apiBody = {
			'timeResolution': apiTimeResolution,
			'metric': QbApi.instance.metricMap.plaintext.timeBased,
			'from': apiFrom.format(Config.apiTimeFormat),
			'to': apiTo.format(Config.apiTimeFormat),
		};
		const queueItem = {
			apiMethod: 'POST',
			apiPath: Config.apiUrl + 'metric/timebased?path=' + apiTargetPath + '&withDetails=false',
            apiBody: apiBody,
			apiJsonBody: JSON.stringify(apiBody),
			setData: setData,
			appState: appState,
			metricType: metricType,
			apiMetric: apiMetric,
            metricMap: QbApi.instance.getMetricMap(metricType),
            hasEncryptedMetric: false,
		};
		return QbApi.instance.queuePush(queueItem);
	}

	getMetricCustom(appState, apiTargetPath, apiFrom, apiTo, apiMetric, setData) {
        const metricType = 'custom';
		const apiBody = {
			'metric': QbApi.instance.metricMap.plaintext.custom,
			'from': apiFrom.format(Config.apiTimeFormat),
			'to': apiTo.format(Config.apiTimeFormat),
		};
		const queueItem = {
			apiMethod: 'POST',
			apiPath: Config.apiUrl + 'metric/custom?path=' + apiTargetPath + '&withDetails=false',
            apiBody: apiBody,
			apiJsonBody: JSON.stringify(apiBody),
			setData: setData,
			appState: appState,
			metricType: metricType,
			apiMetric: apiMetric,
            metricMap: QbApi.instance.getMetricMap(metricType),
            hasEncryptedMetric: false,
		};
		return QbApi.instance.queuePush(queueItem);
	}

    // no appState, no token refresh
    sendErrorReport(apiBody) {
        if (Constants.debug.errorReportEnable !== true) {
            return;
        }
        try {
            const apiMethod = 'POST';
            const apiPath = Constants.debug.errorReportEndpoint;
            const apiHeaders = {
                'Content-Type': 'application/json',
                'X-Authorization': 'Bearer ' + (Auth.getToken() || Constants.debug.errorReportAnonymousAccessToken),
            };
            apiBody = {
                ...apiBody,
                timestamp: moment().unix(),
            }
            const apiJsonBody = JSON.stringify(apiBody);
            return QbApi.instance.fetch(null, apiPath, {
                method: apiMethod,
                headers: apiHeaders,
                body: apiJsonBody,
            } );
        } catch (e) {
        }
    }

	updateUserProfile(appState, apiBody) {
		const endPoint = 'userprofile';
		const apiMethod = 'PUT';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		const apiJsonBody = JSON.stringify(apiBody);
		return QbApi.instance.fetchTimeout(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
			body: apiJsonBody,
		});
	}

	updateUserProfileImage(appState, imageData) {
		const endPoint = 'userprofile/image';
		const apiMethod = 'PUT';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		const apiBody = {
			get data() {
				return Auth.encrypt(imageData)
			},
		};
		return QbApi.instance.fetch2(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
			body: apiBody,
		});
	}

	sendMessage(appState, apiBody) {
		const endPoint = 'message/type/message?wait';
		const apiMethod = 'POST';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		const apiJsonBody = JSON.stringify(apiBody);
		return QbApi.instance.fetch(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
			body: apiJsonBody,
		});
	}

	sendReport(appState, apiBody) {
		const endPoint = 'message/type/support?wait';
		const apiMethod = 'POST';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		const apiJsonBody = JSON.stringify(apiBody);
		return QbApi.instance.fetch(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
			body: apiJsonBody,
		});
	}

	fetchReportImage(appState, apiBody) {
		const {id} = apiBody || {}
		const endPoint = `message/attachment/${ id }`;
		const apiMethod = 'GET';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		return QbApi.instance.fetch(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
		});
	}

	async fetchReports(appState, params) {
		const endPoint = 'message/type/support?' + params;
		const apiMethod = 'GET';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		const response = await QbApi.instance.fetch(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
		});
		if (response.ok) {
			try {
				return await response.json()
			} catch (e) {
				return {}
			}
		}
		return {}
	}

	sendFeedback(appState, apiBody) {
		const endPoint = 'message/type/feedback?';
		const apiMethod = 'POST';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		const apiJsonBody = JSON.stringify(apiBody);
		return QbApi.instance.fetch(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
			body: apiJsonBody,
		});
	}

	deleteFeedback(appState, apiBody) {
		const {id}=apiBody||{}
		const endPoint = `message/type/feedback/${id}`;
		const apiMethod = 'DELETE';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		return QbApi.instance.fetch(appState, apiPath, {method: apiMethod, headers: apiHeaders,});
	}

	async fetchFeedback(appState, params) {
		const endPoint = 'message/type/feedback?' + params;
		const apiMethod = 'GET';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		const response = await QbApi.instance.fetch(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
		});
		if (response.ok) {
			try {
				return await response.json()
			} catch (e) {
				return {}
			}
		}
		return {}
	}

	async deleteMessages(appState, messageType) {
		const endPoint = 'message/type/' + messageType + '?wait';
		const apiMethod = 'DELETE';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + Auth.getToken(),
		};
		return QbApi.instance.fetch(appState, apiPath, {
			method: apiMethod,
			headers: apiHeaders,
		} );
	}

	async executeAsync(appState, endPoint, options = {}) {
		const apiMethod = 'GET';
		const apiPath = Config.apiUrl + endPoint;
		const apiHeaders = {
			'Content-Type': 'application/json',
			'X-Authorization': 'Bearer ' + (options.refreshToken === true ? Auth.getRefreshToken() : Auth.getToken()),
		};

		try {
			let response = await QbApi.instance.fetch(appState, apiPath, {
				method: apiMethod,
				headers: apiHeaders,
			});
			let data;
			if (options.raw === true) {
				const contentType = response.headers.get("Content-Type");
				data = ( contentType.indexOf("application/pdf") >= 0) ?
					await response.blob() :
					await response.text();

				try {
					const parsedData = JSON.parse(data);
					data = parsedData;
				} catch (e) {
				}
			} else {
				data = await response.json();
			}
			if ((typeof data === 'object') && ('errorCode' in data)) {
				throw new Error('executeAsync error: ' + data.message + ' (errorCode: ' + data.errorCode + ')');
			}
			return data;
		} catch (error) {
			console.error('Error:', error);
			if (options.reThrow === true) {
				throw error;
			}
		}
		return undefined;
	}

	async getAsync(appState, endPoint, options = {}) {
		return QbApi.instance.executeAsync(appState, endPoint, options);
	}

	async getRawAsync(appState, endPoint, options = {}) {
		options = {
			raw: true,
			...options
		}
		return QbApi.instance.executeAsync(appState, endPoint, options);
	}

	async refreshAuthToken(appState, options) {
        options = {
            periodic: false,
            ...options
        };
        // periodikus tokenfrissítés esetén ha metrika lekérés van folyamatban, akkor nem tesszük meg
        if ((options.periodic === true) && (QbApi.instance.queueActive)) {
            return false;
        }
		const now = moment().unix();
		try {
			Auth.setItem('authTokenRefreshAt', now);

			let response = await fetch(Config.apiUrl + 'auth/token', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Authorization': 'Bearer ' + Auth.getRefreshToken(),
				},
			});
			const data = await response.json();
			if ('errorCode' in data) {
				throw new Error('refreshAuthToken error: ' + data.message + ' (errorCode: ' + data.errorCode + ')');
			}
			Auth.setToken(data.token);
			Auth.setItem('authTokenAt', now);
		} catch (error) {
			console.error('Error:', error);
			Auth.deauthenticateUser();
			if ((typeof appState === 'object') && (appState !== null)) {
				appState.setAuthPhase('unauth');
			}
		}
	}

    async fetchTimeout(appState, resource, init) {
        function timeoutPromise(ms, promise) {
            return new Promise((resolve, reject) => {
                const timeoutId = setTimeout(() => {
                    reject(new QbApiTimeoutError('promise timeout'))
                }, ms);
                promise.then(
                    (res) => {
                        clearTimeout(timeoutId);
                        resolve(res);
                    },
                    (err) => {
                        clearTimeout(timeoutId);
                        reject(err);
                    }
                );
            } );
        }

        return timeoutPromise(Config.apiTimeout * 1000, QbApi.instance.fetch(appState, resource, init));
    }

	async fetch(appState, resource, init) {
		let response = await fetch(resource, init);
		if ((response.status === 401) || (response.status === 500)) {
            const data = await response.text();
            let json = { };
            let toDo = 'unknown';
            try {
                json = JSON.parse(data);
                if ( [ 10, 12, 13 ].includes(json.errorCode)) {
                    toDo = 'deauth';
                } else if (json.errorCode === 11) {
                    toDo = 'refresh';
                }
            } catch (error) {
                toDo = 'deauth';
            }
            if (toDo === 'deauth') {
                Auth.deauthenticateUser();
                if ((typeof appState === 'object') && (appState !== null)) {
                    appState.setAuthPhase('unauth');
                } else {
                }
                response.json = function() { return json; };
                response.text = function() { return data; };
                response.blob = function() { return data; };
                return response;
            } else if (toDo === 'unknown') {
                var sprintf = require('sprintf-js').sprintf;
                QbApi.instance.sendErrorReport( {
                    error: 'Unknown response status + errorCode combination in QbApi.instance.fetch()',
                    errorInfo: sprintf('status: %s, data: %s', response.status, data),
                } );
            }
			await QbApi.instance.refreshAuthToken(appState);
			if (init.hasOwnProperty('headers') && init.headers.hasOwnProperty('X-Authorization')) {
				init.headers['X-Authorization'] = 'Bearer ' + Auth.getToken();
			}
			response = await fetch(resource, init);
		}
		return response;
	}

	// fetch, de az init.body mindig "kiértékelésre" kerül
	// updateUserProfileImage hasznlja, mert a body.data encrypted value kell legyen
	// és ha egy token-update közbejön, akkor újra kell számolni az értékét
	// getter funkció segítségével lehet kihasznélni a funkcionalitást
	async fetch2(appState, resource, _init) {
		let init = JSON.parse(JSON.stringify(_init));
		if (typeof init.body === 'object') {
			init.body = JSON.stringify(_init.body);
		}
		let response = await fetch(resource, init);
		if (response.status === 401) {
			await QbApi.instance.refreshAuthToken(appState);
			init = JSON.parse(JSON.stringify(_init));
			if (typeof init.body === 'object') {
				init.body = JSON.stringify(_init.body);
			}
			if (init.hasOwnProperty('headers') && init.headers.hasOwnProperty('X-Authorization')) {
				init.headers['X-Authorization'] = 'Bearer ' + Auth.getToken();
			}
			response = await fetch(resource, init);
		}

		return response;
	}
}

export default QbApi;
