import {isEmpty, map} from 'lodash'
import {UpperNavigation} from "./index";
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import React, {useContext, useState} from 'react'
import FiltersDraggableList from "./DraggableList";
import {BsChevronLeft} from 'react-icons/bs';
import {FILTERS_LIST_HQ} from "../utilities/Constant";
import {FILTERS_LIST_RM} from "../utilities/Constant";
import {FILTERS_LIST_BM} from "../utilities/Constant";
import {FILTERS_LIST_SRM} from "../utilities/Constant";
import {AppStateContext, Auth, QbApi, QbApiTimeoutError, QbTools, Config} from "../modules";
import { showMessage } from '../utilities/UiUtils';

const DashboardFilters = (props) => {
    const appState = useContext(AppStateContext);
    const userMerged = Auth.getData().userMerged;
    //const groups = userMerged.groups;
    const topHierarchyLevel = userMerged.topHierarchyLevel.toLowerCase();
	const userProfile = Auth.getData().userProfile || {}
    const filterSort = userProfile?.config?.filterSort || []
    let initialFiltersList;
    let defaultFiltersList;
    let finalFiltersList = [];
    let unusedFiltersList = [];
    
    if (topHierarchyLevel === 'hq'){
        initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_HQ : filterSort
        defaultFiltersList = FILTERS_LIST_HQ
    } else if (topHierarchyLevel === 'rv'){
        initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_RM : filterSort
        defaultFiltersList = FILTERS_LIST_RM
    } else if (topHierarchyLevel === 'uv'){
        initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_BM : filterSort
        defaultFiltersList = FILTERS_LIST_BM
    } else if (topHierarchyLevel === 'tv'){
        initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_SRM : filterSort
        defaultFiltersList = FILTERS_LIST_SRM
    }
    var s = 0;
    var i = 0;

    for (s = 0; s < initialFiltersList.length; s++) {
        let exists = false;
        for (i = 0; i < defaultFiltersList.length; i++) {
            if(defaultFiltersList[i].id === initialFiltersList[s].id){
                exists = true
            }
        }
        (!exists)&& unusedFiltersList.push(initialFiltersList[s])
    }

    for (i = 0; i < defaultFiltersList.length; i++) {
        let exists = false;
        for (s = 0; s < initialFiltersList.length; s++) {
            if(defaultFiltersList[i].id === initialFiltersList[s].id){
                exists = true
                finalFiltersList.push(initialFiltersList[s])
            }
        }
        (!exists)&& finalFiltersList.push(defaultFiltersList[i])
    }
    finalFiltersList.sort(function(a, b){return a.position - b.position});
    finalFiltersList = QbTools.filterDisabledComponentsFilter(finalFiltersList);

	const [ filtersList, setFiltersList ] = useState(finalFiltersList)
	const history = useHistory()
	const {t: webappT} = useTranslation('webapp')
	const {t} = useTranslation();
	const goBackPressHandler = () => {
		return history.goBack()
	}
    
	const onDonePressHandler = async () => {
		const reformattedList = map(filtersList, (item, index) => {
			return {...item, position: index + 1}
		})
		const updatedUserProfile = Auth.updateUserProfile({config: {filterSort: reformattedList.concat(unusedFiltersList)}});
		try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage( { title: t('webapp:toastTitleFailed'), message: t('webapp:toastDescriptionFailedUpdate'), type: 'error' } )
			} else {
				throw error;
			}
		}
		goBackPressHandler()
    }

	/*const onResetPressHandler = async () => {
		const updatedUserProfile = Auth.updateUserProfile({config: {filterSort: []}});
		try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage( { title: t('webapp:toastTitleFailed'), message: t('webapp:toastDescriptionFailedUpdate'), type: 'error' } )
			} else {
				throw error;
			}
		}
		history.go(0)
    }*/

    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
	
	return <div>
        <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
            <UpperNavigation
                leftSide={
                    <div className='text-white qboard-link' onClick={ goBackPressHandler }>
                        <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                    </div>
                }
                middle={ webappT('configure') }
                rightSide={
                    <button style={ submitButtonStyle } className="btn btn-primary bm-sm" onClick={ onDonePressHandler }>
                        { webappT('done') }
                    </button>
                }
            />
        </div>
		<div>
			<FiltersDraggableList
				dataList={ filtersList }
				setData={ (updatedFiltersList) => setFiltersList(updatedFiltersList) }/>
		</div>
	</div>
}

export default DashboardFilters
