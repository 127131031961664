import React, { useEffect, useRef } from 'react';
import { QBDashboardFigureTime } from '../graph/';
import { Config } from '../modules/';

function GraphAverageWaitingTime(props) {
    const canvasGraph = useRef(null);

    useEffect( () => {
        function getParentWidth() {
            return canvasGraph.current.parentElement.clientWidth;
        }

        function _resizeHandler() {
            canvasGraph.current.width = getParentWidth() * 2;
            canvasGraph.current.height = getParentWidth() * 2;

            clearAndDraw();
        }

        function clearAndDraw() {
            const ctx = canvasGraph.current.getContext('2d');
            if (ctx) {
                const graphDefaults = Config.graphDefaults;
                var rect = QBDashboardFigureTime.makeRect(0, 0, getParentWidth() * 2, getParentWidth() * 2);
                QBDashboardFigureTime.clearCanvas(canvasGraph.current);
                QBDashboardFigureTime.drawTime(
                    canvasGraph.current,
                    0, // const_inactive_alpha
                    graphDefaults.WaitingTime[2], // const_minutes_max
                    graphDefaults.WaitingTime[0], // percent_limit_green,
                    graphDefaults.WaitingTime[1], // percent_limit_yellow
                    props.value, // minutes
                    'minute', // subtitle
                    rect,
                    'aspectfit',
                    Config.appearance.darkGreen,
                    Config.appearance.darkYellow,
                    Config.appearance.darkRed,
                );
            }
        }

        window.addEventListener('resize', _resizeHandler);

        canvasGraph.current.width = getParentWidth() * 2;
        canvasGraph.current.height = getParentWidth() * 2;

        clearAndDraw();

        return function cleanup() {
            window.removeEventListener('resize', _resizeHandler);
        }
    }, [ props.value, props.max ] );

    return (
        <div>
            <canvas ref={canvasGraph} />
        </div>
    );
}

export default GraphAverageWaitingTime;
