import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Legend, LabelList } from 'recharts';
import { useRouteMatch } from 'react-router-dom';
import { Config, QbTools } from '../modules';

function GraphLineBarComposed(props) {
    const { t } = useTranslation();
    const match = useRouteMatch('/analytics/:duration/:id');
    const duration = match.params.duration;
    var hideLabels = false;
    
    function customizedDot(props) {
        let {
            key, cx, cy, value,
        } = props;
        var circleColor = '#000000';
        var circleR = 7;
        if (displayData.hasOwnProperty('colors')) {
            if (displayData.hasOwnProperty('colorFunction')) {
                value = displayData.colorFunction(props.payload);
            }
            if (value === 0) {
                circleR = 0;
            } else if (value <= displayData.colors[0].max) {
                circleColor = displayData.colors[0].color;
            } else if (value <= displayData.colors[1].max) {
                circleColor = displayData.colors[1].color;
            } else {
                circleColor = displayData.colors[2].color;
            }
        }
        if (displayData.hasOwnProperty('color')) {
            circleColor = displayData.color;
        }
        return (
            <circle key={ key } r={ circleR } stroke="#fff" strokeWidth="3" fill={ circleColor } width="30" height="30" className="recharts-dot recharts-line-dot" cx={ cx } cy={ cy }></circle>
        );
    };

    // Source: https://usehooks.com/useWindowSize/
    const size = useWindowSize();
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });
        useEffect(() => {
          function handleResize() {
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
          }
          window.addEventListener("resize", handleResize);
          handleResize();
          return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
      }

    var displayData = props.displayData;
    var hasGraph2Axis = false
    if (displayData.hasOwnProperty('graph2Min') && displayData.hasOwnProperty('graph2Max') && displayData.values.length > 1) {
        displayData.values[0].Graph2Axis = displayData.graph2Min;
        displayData.values[1].Graph2Axis = displayData.graph2Max;
        hasGraph2Axis = true;
    }
    if (! displayData.hasOwnProperty('graph2Formatter')) {
        displayData.graph2Formatter = (val) => val;
    }

    const CustomizeLabelBar = (props) => {
        const { x, y, value } = props;
        return (
          <g>
           <text style={{ fontWeight: 'bold', fontSize: '10px' }} x={x} y={y-5} fill={Config.appearance.darkYellow} rotate="0">{value}</text>
          </g>
        )
      }

    const CustomizeLabelLine = (props) => {
        const { x, y, value } = props;
        return (
          <g>
           <text style={{ fontWeight: 'bold', fontSize: '10px' }} x={x-10} y={y-10} fill="#5467D0" rotate="0">{(displayData.contentType === 'time')? (value.toFixed(1) > displayData.graph2Max)? QbTools.formatWaitingTimeLong(displayData.graph2Max) + '<': QbTools.formatWaitingTimeLong(value)  :value.toFixed(1)}</text>
          </g>
        )
      }

    const tickStyleYLeft = {
        fill: Config.appearance.darkYellow,
        strokeWidth: 0,
        fontSize: '10px',
        fontWeight: 600,
    };

    const tickStyleYRight = {
        fill: '#5467D0',
        strokeWidth: 0,
        fontSize: '10px',
        fontWeight: 600,
    };

    const tickStyleX = {
        fill: '#999999',
        strokeWidth: 0,
        fontSize: '12px',
        fontWeight: 300,
    };

    if (size.width < 540 && (duration === 'month')){
        hideLabels = true;
    }

    return (
        <ResponsiveContainer width="100%" height={ 300 }>
            <ComposedChart height={ 300 } data={ displayData.values } margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis dataKey="datetime" tick={ tickStyleX }/>
                <YAxis tickSize={ 1 }  width={ 40 } tick={ tickStyleYLeft }/>
                { hasGraph2Axis ?
                    <YAxis yAxisId="1" orientation="right" dataKey="Graph2Axis" tickFormatter={ displayData.graph2Formatter } tick={tickStyleYRight} width={ 40 } allowDecimals={ false } />
                :
                    <YAxis yAxisId="1" orientation="right" tickFormatter={ displayData.graph2Formatter } tick={ tickStyleYRight } />
                }
                <Legend iconType="circle" verticalAlign="top" height={ 60 } />
                <Bar barSize={15} name={ t(displayData.graph1Title) } dataKey="graph1" fill={Config.appearance.darkYellow} isAnimationActive={false}>
                    {(hideLabels)?'':<LabelList dataKey="graph1" position="top" color="red" content={ <CustomizeLabelBar />} />}
                </Bar>
                <Line name={ t(displayData.graph2Title) } dataKey="graph2" stroke={ Config.appearance.darkBlue } strokeWidth={ 5 } yAxisId="1" dot={ customizedDot } isAnimationActive={false} >
                    { (hideLabels)? '': <LabelList dataKey="graph2" position="top"  formatter={ displayData.graph2Formatter } className="graph1-text" content={ <CustomizeLabelLine />} />}
                </Line>
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default GraphLineBarComposed;
