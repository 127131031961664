import React from 'react';
import { MetricDisplayBmTicketsServingWaiting } from './';
import { useTranslation } from 'react-i18next';
import { QbTools } from '../modules';

function MetricProxyBmTicketsServingWaiting(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();

    var data = {
        RTS: { },
        RTW: { },
        sum: NaN,
        serving: NaN,
        waiting: NaN,
        tickets: [ ],
    };
    if (QbTools.metricDataReady(metricData, [ 'current' ] )) {
        const branchName = Object.keys(metricData.current.data)[0];
        data.RTS = metricData.current.data[branchName]['RTS'] || [ ];
        data.RTW = metricData.current.data[branchName]['RTW'] || [ ];
        data.serving = data.RTS.length;
        data.waiting = data.RTW.length;
        data.sum = data.serving + data.waiting;
        for (const [, value] of Object.entries(data.RTS)) {
            data.tickets.push( { name: value, active: true } );
        }
        for (const [, value] of Object.entries(data.RTW)) {
            data.tickets.push( { name: value, active: false } );
        }
        data.tickets.sort(function (a, b) {
            return (a.name < b.name ? -1 : ( a.name > b.name ? 1 : 0 ));
        } );
    }

    var displayData = {
        RTS: data.RTS,
        RTW: data.RTW,
        sum: data.sum,
        serving: data.serving,
        waiting: data.waiting,
        tickets: data.tickets,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'current' ], t),
    };

    return (
        <MetricDisplayBmTicketsServingWaiting displayData={ displayData } { ...props } />
    );
}

export default MetricProxyBmTicketsServingWaiting;
