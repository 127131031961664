import React from 'react';
import { QbTools, Config } from '../modules';
import { Link } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { FiWatch } from 'react-icons/fi';

function BreakdownDisplayAverageWaitingTime(props) {
    var displayData = props.displayData;
    const match = props.match;
    const waitingTime = (displayData.value / 60).toFixed(2);
    const progressbarWidth = Math.min((displayData.value / 1800) * 100, 100);
    const progressbarStyle = {
        backgroundColor: Config.graphColors.WaitingTime[QbTools.analyticsGraphLimits(waitingTime, Config.graphDefaults.WaitingTime)],
        width: progressbarWidth + "%",
    };

    const BreakdownListItemStyle = {
        minHeight: Config.appearance.breakdownListItemHeight,
    };

    return (
        <div className="metric staff-member-container">
            <div className="progressbar" style={ progressbarStyle }></div>
            <Link style={BreakdownListItemStyle} className="text-decoration-none" to={ '/dashboard/breakdown/' + match.params.id + '/' + match.params.pagetype + '/' + encodeURIComponent(displayData.itemId) }>
                <div className="row staff-member align-items-center mx-1">
                    <div className="col-6 staff-member-name d-flex align-items-center">
                        <div className="breakdown-subitem-icon">
                            <FiWatch className="icon-with-bg-information" />
                        </div>
                        <div>
                            <div className="pl-2">{ displayData.name }</div>
                            <div className="pl-2 worst-detail">{ displayData.worst.name }</div>
                        </div>
                    </div>
                    <div className="col-6 text-right d-flex justify-content-end align-items-center">
                        <div>
                            <div className="breakdown-value pr-1">{ QbTools.formatWaitingTimeLong(displayData.value) }</div>
                            <div className="breakdown-value worst-detail pr-1">{ QbTools.formatWaitingTimeLong(displayData.worst.value) }</div>
                        </div>
                        <div className="breakdown-subitem-link">
                            <BsChevronRight className="icon-with-bg" />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default BreakdownDisplayAverageWaitingTime;
