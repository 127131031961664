import React, { useContext } from 'react';
import { AppStateContext, Constants } from '../modules';
import { useTranslation } from 'react-i18next';
import { Config, Auth, QbApiCache, QbTools } from '../modules';
import ProfilePlaceholder from '../img/profile-placeholder.png';
import { BsGear } from 'react-icons/bs';
import { Link, useRouteMatch } from 'react-router-dom';
import { FiLogOut, } from 'react-icons/fi';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { RiTwitchLine } from 'react-icons/ri';
import { DashboardHierarchy } from './';
import { AnalyticsIcon, DashboardIcon, ProblemReportIcon } from '../icons';

async function logout(appState) {
    try {
        appState.setAjaxInProgress(true);

        // nem QbApi-n keresztül, mert logout a refreshToken-t használja, nem az authToken-t
        let response = await fetch(Config.apiUrl + 'auth/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Authorization': 'Bearer ' + Auth.getRefreshToken()
            },
        } );
        let text = await response.text();
        let data;
        try {
            data = JSON.parse(text);
        } catch (e) {
            data = {
                'type': 'Success'
            };
        }
        if ('errorCode' in data) {
            throw new Error('something bad happened: ' + data.message + ' (errorCode: ' + data.errorCode + ')');
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
        QbApiCache.instance.clear();
        Auth.deauthenticateUser();
        appState.setAuthPhase('unauth');
        appState.toggleMenuVisible(false);
        appState.setAjaxInProgress(false);
    }
}

function Sidebar() {
    const appState = useContext(AppStateContext);
    const { t } = useTranslation();
    const isDashboard = useRouteMatch('/dashboard');
    const isAnalytics = useRouteMatch('/analytics');
    const isMetric    = useRouteMatch('/dashboard/metric');
    const isBreakdown = useRouteMatch('/dashboard/breakdown');
    const isCamera    = useRouteMatch('/dashboard/camera');

    const userMerged = Auth.getData().userMerged;
    const topHierarchyLevel = userMerged.topHierarchyLevel.toLowerCase();
    const userHierarchyCode = topHierarchyLevel.toUpperCase();
    const feedbackStatus = Constants.feedbackStatus.status;
    const supportStatus = Constants.supportStatus.status;

    const subSidebarIconStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    var hierarchySelector = [ ];
    for (const [, item] of Object.entries(DashboardHierarchy[topHierarchyLevel])) {
        hierarchySelector.push(
            <div className="submenu-item" key={ item.boardtype }>
                <Link className={`side-sub-item ml-3 mt-2 w-100 d-flex align-items-center ${ isMetric && item.boardtype === 'metric' ? 'active-sub-item' : '' } ${ isBreakdown && item.boardtype === 'breakdown' ? 'active-sub-item' : '' } ${ isCamera && item.boardtype === 'camera' ? 'active-sub-item' : '' }`} to={ '/dashboard/' + item.boardtype } onClick={ () => appState.toggleMenuVisible(false) } >
                    <div style={ subSidebarIconStyle } className="sidebar-sub-icon">{ item.icon }</div><span className="side-sub-item-name pl-2">{ t(item.translation) }</span>
                </Link>
            </div>
        );
    }

    let userprofileImage = null;
    const authUserprofileImage = Auth.getItem('userprofileImage');
    if ((typeof authUserprofileImage === 'object') && (authUserprofileImage !== null) && authUserprofileImage.hasOwnProperty('data') && (authUserprofileImage.data !== '')) {
        userprofileImage = 'data:image/jpeg;base64,' + authUserprofileImage.data;
    }

    const activeStyle = {
        background: Config.appearance.mainColor,
    }

    const settingsStyle = {
        color: Config.appearance.mainColor,
    }

    return (
        <div className={ 'sidebar ' + ( appState.menuVisible ? 'menu-visible' : 'menu-invisible' ) } >
            <div className="main-menu-hider" onClick={ appState.toggleMenuVisible }></div>
            <div className="main-menu pt-2">
                <div className="container h-100">
                    <div className="row d-flex alig-items-center mb-4">
                        <div className="col-5 text-right">
                            <div className="sidebar-image-container">
                                { userprofileImage !== null ?
                                    <img alt="User face" src={ userprofileImage } className="sidebar-profile-image" height="100%"/>
                                :
                                    <img alt="User face" src={ ProfilePlaceholder } className="sidebar-profile-image" height="100%"/>
                                }
                            </div>
                            <Link to="/settings" onClick={ () => appState.toggleMenuVisible(false) } >
                                <BsGear style={ settingsStyle } className="sidebar-settings" />
                            </Link>
                        </div>
                        <div className="col-7 d-flex justify-content-center sidebar-user-info">
                            <p className="user-fullname mb-0">{ QbTools.formatName(userMerged.chanonicalName.fullName) }</p>
                            <p className="user-hierarchy mb-0">{ t( 'localizeUserType' + userHierarchyCode) }</p>
                        </div>
                    </div>
                    <Link to="/dashboard" onClick={ () => appState.toggleMenuVisible(false) } >
                        <div style={isDashboard && activeStyle }  className={`main-menu-item d-flex align-items-center ${isDashboard ? "active" : "" }`}>
                            <div  className="sidebar-main-icon">
                                <DashboardIcon strokeColor={` ${isDashboard ? "#fff" : Config.appearance.mainColor }`} />
                            </div>
                            <span className="main-menu-text pl-2">
                                { t('localizeDashboardTypeDashboard') }
                            </span>
                        </div>
                    </Link>
                    { hierarchySelector }
                    <Link to="/analytics" onClick={ () => appState.toggleMenuVisible(false) } >
                        <div style={isAnalytics && activeStyle } className={`main-menu-item d-flex align-items-center mt-2 ${isAnalytics ? "active" : "" }`}>
                            <div  className="sidebar-main-icon">
                                <AnalyticsIcon strokeColor={` ${isAnalytics ? "#fff" : Config.appearance.mainColor }`} />
                            </div>
                            <span className="main-menu-text pl-2">
                                { t('localizeAnalyticsTitle') }
                            </span>
                        </div>
                    </Link>
                    <div className="sidebar-bottom-menu mt-3 pt-3">
                       {(supportStatus) && 
                            <div className="menu-item mt-1">
                                <Link className="w-100" to="/report" onClick={ () => appState.toggleMenuVisible(false) } >
                                    <div className="round-menu-icon">
                                        <ProblemReportIcon strokeColor="#000" />
                                    </div>
                                    { t('localizeTopDeskTitle') }
                                </Link>
                            </div>
                        }
                        <div className="menu-item mt-1">
                            <Link className="w-100" to="/help" onClick={ () => appState.toggleMenuVisible(false) } >
                                <div className="round-menu-icon">
                                    <AiOutlineInfoCircle strokeColor="#000" />
                                </div>
                                { t('localizeHelpTitle') }
                            </Link>
                        </div>
                        {feedbackStatus &&
                            <div className="menu-item mt-1">
                            <Link className="w-100" to="/feedback" onClick={ () => appState.toggleMenuVisible(false) } >
                                <div className="round-menu-icon">
                                    <RiTwitchLine strokeColor="#000" />
                                </div>
                                { t('localizeFeedbackTitle') }
                            </Link>
                        </div>}
                        <div style={ subSidebarIconStyle } className="main-menu-item logout mt-3 logout-container" onClick={ () => logout(appState) } >
                            <div className="logout-items d-inline-flex align-items-center">
                                <div className="sidebar-main-icon">
                                    <FiLogOut />
                                </div>
                                <span className="main-menu-text pl-2">{ t('localizeLogoutButtonTitle') }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
