import React from 'react';
import { AnalyticsDisplayServiceLevels } from './';
import { QbTools } from '../modules';
import { useTranslation } from 'react-i18next';

function AnalyticsProxyServiceLevels(props) {
    const { t } = useTranslation();
    var metricData = props.metricData;
    var sortedData = Object.entries(metricData.groupbased?.data || { } );
    sortedData.sort(function (a, b) {
        return (a[1].KSZ < b[1].KSZ ? -1 : ( a[1].KSZ > b[1].KSZ ? 1 : 0 ));
    } );

    var data = {
        KSZ: [ ],
    };

    for (const [key, child] of sortedData) {
        data.KSZ.push( {
            title: QbTools.apiNameToFriendlyName(key),
            value: child.KSZ,
        } );
    }

    var displayData = {
        title: props.graphTitle,
        KSZ: data.KSZ,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'groupbased' ], t),
    };

    return (
        <AnalyticsDisplayServiceLevels displayData={ displayData } { ...props } />
    );
}

export default AnalyticsProxyServiceLevels;
