import React from 'react';
import { Auth, Config, Constants } from '../modules';
import moment from 'moment-timezone';

class QbTools {
    static formatCustomerCount(USZ) {
        if (USZ === null) {
            return '';
        }
        return USZ;
    }

    static formatServiceLevel(KSZ) {
        if (KSZ === null) {
            return '';
        }
        var sprintf = require('sprintf-js').sprintf;
        return sprintf('%.1f %%', KSZ * 100);
    }

    static formatWaitingTime(sec) {
        var sprintf = require('sprintf-js').sprintf;
        sec = Math.round(sec);
        if (sec < 60) {
            return [ sec, ((sec > 1) ? 'seconds' : 'second') ];
        } else {
            var m = Math.floor(sec / 60);
            var s = sec % 60;
            return [ sprintf('%s:%02s', m, s), ((m > 1) ? 'minutes' : 'minute') ];
        }
    }

    static formatWaitingTimeLong(sec) {
        if (sec === null) {
            return '';
        }
        var sprintf = require('sprintf-js').sprintf;
        sec = Math.round(sec);
        var m = Math.floor(sec / 60);
        var s = sec % 60;
        return sprintf('%s:%02s', m, s);
    }

    static analyticsDuration(ts, durationInfo, offset) {
        let from = QbTools.addApiTimeOffset(ts).set('minute', 0).set('second', 0);
        if (durationInfo.key !== 'day') {
            from.set('hour', 0);
        }
        from.add(durationInfo.startOffset * (offset + 1), durationInfo.offsetUnit);
        let to = QbTools.addApiTimeOffset(ts).set('second', 0).add(durationInfo.startOffset * offset, durationInfo.offsetUnit);

        const durations = {
            from: from.format(),
            to: to.format(),
        }
        return durations;
    }

    static addApiTimeOffset(ts) {
        return ts.clone().add(Config.apiTimeOffset, 'seconds');
    }

    static nowApiTimeOffset() {
        const now = QbTools.addApiTimeOffset(moment());
        return now;
    }

    static analyticsFormatDatetime(datetime, type, t) {
        const dt = moment(datetime);
        if (type === 'day') {
            return dt.format('HH [h]');
        } else if (type === 'week') {
            return t(Constants.i18nDayOfWeekMoment[dt.day()]).substring(0, 2);
        } else if (type === 'month') {
            return dt.format('D');
        } else {
            return '?';
        }
    }

    static analyticsFilterDailyChart(metricData, type) {
        if (type !== 'day') {
            return metricData;
        }
        const skipHoursOnDaily = Config.graphDefaults.skipHoursOnDaily
        const filteredData = metricData.filter(function(item) {
            return ! skipHoursOnDaily.includes(moment(item[0]).hour());
        } );
        //const dt = moment(datetime);
        return filteredData;
    }

    // limits három elemű array
    // value értéke alapján visszaad egy 0-3-ig terjedő számot, melyik régióba tartozik
    // limits első két eleme számít csak, harmadik inf-nek tekintendő
    // value 0 értéke jelenti a 0-t
    static analyticsGraphLimits(value, limits) {
        if (value === 0) {
            return 0;
        } else if (value <= limits[0]) {
            return 1;
        } else if (value <= limits[1]) {
            return 2;
        } else {
            return 3;
        }

    }

    // array szöveges rendezése
    // arr - input array
    // sortField - mező, ami alapján rendezönk
    // options - object of:
    //     reverse - fordított sorren - default false
    //     nullsAtEnd - null value-k a rendezett array végére (true) vagy elejére (false) kerüljenek - default true
    // returns: rendezett array
    // note: null érték esetén key alapján rendez, _mindig_ növekvő sorrendben
    static sortBy(arr, sortField, _options) {
        function keyCompare(a, b, _options) {
            const options = { localeCompare: false, ..._options };
            if (options.localeCompare) {
                return a[0].localeCompare(b[0], 'hu', { ignorePunctuation: true } )
            } else {
                return (a[0] < b[0] ? -1 : ( a[0] > b[0] ? 1 : 0 ));
            }
        }

        const options = { reverse: false, nullsAtEnd: true, ..._options };
        arr.sort(function (a, b) {
            if (options.reverse === true) {
                if ((a[1][sortField] === null) && (b[1][sortField] === null)) {
                    return keyCompare(a, b, options);
                }
                if (b[1][sortField] === null) {
                    return (options.nullsAtEnd ? -1 : 1);
                }
                if (a[1][sortField] === null) {
                    return (options.nullsAtEnd ? 1 : -1);
                }
                return (b[1][sortField] < a[1][sortField] ? -1 : ( b[1][sortField] > a[1][sortField] ? 1 : 0 ));
            } else {
                if ((a[1][sortField] === null) && (b[1][sortField] === null)) {
                    return keyCompare(a, b, options);
                }
                if (a[1][sortField] === null) {
                    return (options.nullsAtEnd ? 1 : -1);
                }
                if (b[1][sortField] === null) {
                    return (options.nullsAtEnd ? -1 : 1);
                }
                return (a[1][sortField] < b[1][sortField] ? -1 : ( a[1][sortField] > b[1][sortField] ? 1 : 0 ));
            }
        } );
        return arr;
    }

    static filterStaffData(sortedData, branchId) {
        let authData = Auth.getData();
        const topHierarchyLevel = authData.user.topHierarchyLevel.toLowerCase();
        if (topHierarchyLevel === 'uv') {
            const branchIndex = authData.userProfile.branches.findIndex(element => String(element.id) === branchId);
            if (branchIndex >= 0) {
                let staffList = authData.userProfile.branches[branchIndex].staff;
                sortedData = sortedData.filter(item => {
                    if (staffList.length === 0) {
                        return true;
                    }
                    let found = false;
                    staffList.forEach(function (staff) {
                        if (item[0].trim().endsWith('( ' + staff.chanonicalName.userName + ' )')) {
                            found = true;
                        }
                    } );
                    return found;
                } );
            }
        }
        return sortedData;
    }

    // input: Kennedy, John   - lastName, firstName
    static fullNameSplit(fullName) {
        return (fullName ?? ', ').split(', ');
    }

    // firstName: John
    // lastName: Kennedy
    static fullNameJoin(lastName, firstName) {
        return (lastName ?? '').trim() + ', ' + (firstName ?? '').trim();
    }

    static formatStoreOpeningHours(openingHours, idx, withDow, t) {
        var sprintf = require('sprintf-js').sprintf;
        const open = openingHours[idx];
        let fromTo = '';
        if (open?.closed !== false) {
            fromTo = t('localizeStoreClosed');
        } else {
            fromTo = sprintf('%s - %s', moment(open?.from).utc().format('HH:mm'), moment(open?.to).utc().format('HH:mm'));
        }
        if (withDow) {
            return sprintf('%s: %s', t(Constants.i18nDayOfWeek[idx]).substring(0, 2), fromTo);
        }
        return fromTo;
    }
    static formatStoreOpeningHoursTime(openingHours, idx, withDow, t) {
        var sprintf = require('sprintf-js').sprintf;
        const open = openingHours[idx];
        let fromTo = '';
        if (open?.closed !== false) {
            fromTo = t('localizeStoreClosed');
        } else {
            fromTo = sprintf('%s - %s', moment(open?.from).utc().format('HH:mm'), moment(open?.to).utc().format('HH:mm'));
        }
        return fromTo;
    }
    static formatStoreOpeningHoursDay(openingHours, idx, withDow, t) {
        var sprintf = require('sprintf-js').sprintf;
        let fromTo = '';
        if (withDow) {
            return sprintf('%s', t(Constants.i18nDayOfWeek[idx]), fromTo);
        }
        return fromTo;
    }

    static isoTimeToHm(isoTime) {
        return moment(isoTime)
            .utc()
            .format('HH:mm');
    }

    static HmToisoTime(hmTime) {
        let hm = hmTime.split(':')
        return moment.utc('1970-01-01T00:00:00+00:00')
            .set('hour', hm[0])
            .set('minute', hm[1])
            .utcOffset(moment().utcOffset())
            .format('YYYY-MM-DDTHH:mm:ssZ');
    }

    static getByLocale(obj, lng) {
        if (obj.hasOwnProperty(lng)) {
            return obj[lng];
        } else if (obj.hasOwnProperty('en')) {
            return obj['en'];
        } else {
            return Object.entries(obj)[0];
        }
    }

    static pathToId(path) {
        if (typeof path === 'string') {
            return path.substring(0, path.indexOf('/'));
        } else {
            return null;
        }
    }

    static apiNameToFriendlyName(name) {
        if (typeof name === 'string') {
            return name.replace(/ *\([^)]*\) */g, '');
        } else {
            return null;
        }
    }

    static apiNameToItemId(name) {
        if (typeof name === 'string') {
            return name.replace(/^.*\( ([^ ]+) \)$/, '$1');
        } else {
            return null;
        }
    }

    // [ ] => bold
    // { } => italic
    // \n => new paragraph
    static helptextToJsx(text) {
        function expandTags(text) {
            let idx = 0;
            let textJsx = [ ];
            let bStart = -1;
            let bEnd = -1;
            let lastEnd = -1;
            let Tag;
            const len = text.length;
            do {
                if (text[idx] === '[') {
                    bStart = idx;
                    bEnd = text.indexOf(']', bStart);
                    Tag = 'b';
                } else if (text[idx] === '{') {
                    bStart = idx;
                    bEnd = text.indexOf('}', bStart);
                    Tag = 'i';
                } else {
                    idx++;
                    continue;
                }
                if (bEnd > -1) {
                    textJsx.push(text.substring(lastEnd, bStart));
                    textJsx.push(<Tag key={ idx }>{ expandTags(text.substring(bStart + 1, bEnd)) }</Tag>);
                    lastEnd = bEnd + 1;
                    idx = bEnd;
                } else {
                    idx++;
                    continue;
                }
                idx++;
                bStart = -1;
                bEnd = -1;
            } while (idx < len);
            if (lastEnd >= 0) {
                textJsx.push(text.substring(lastEnd));
            } else {
                textJsx.push(text);
            }
            return textJsx;
        }

        let textFragment = [ ];
        let idx = 0;
        for (const textPart of text.split('\n')) {
            const paragraph = textPart.trim();
            if (paragraph !== '') {
                textFragment.push(
                    <p key={ idx }>{ expandTags(paragraph) }</p>
                );
                idx++;
            }
        }

        return textFragment;
    }

    static helptextToTxt(text) {
        return text.replace(/[\][\n]/g, ' ').replace(/  +/g, ' ').trim();
    }

    // RST (Kiszolgálás kezdete ISO timestamp (valósidejű)) átalakítása eltelt másodpercre
    // RST értéke:
    // - ha több mint 24 óra (86400), akkor unix timestamp
    // - ha kevesebb, akkor Europe/Budapest (huzalozva) időzónában nap kezdete óta eltelt másodpercek száma
    static rstToElapsed(RST) {
        if ((RST === null) || (RST === 0)) {
            return null;
        }
        if (RST > 86400) {
            return moment().format('X') - RST;
        }

        let newRST = Number(moment().tz('Europe/Budapest').set( { 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 } ).format('X')) + RST;
        return moment().format('X') - newRST;
    }

    static formatElapsedTime(sec, t, _options = { }) {
        let sprintf = require('sprintf-js').sprintf;
        let options = {
            nullValue: '',
            ..._options
        };
        if ((sec === null) || (sec === 0)) {
            return options.nullValue;
        }
        return sprintf('%d %s', sec / 60 , t('localizeTimeMinute'));
    }

    // API sends: a) Doe, John      b) Kis, Pál
    // Config.appearance.nameStyle:
    // 1     - a) Doe John      b) Kis Pál
    // 2     - a) John Doe      b) Pál Kis
    // other - a) Doe, John     b) Kis, Pál
    static formatName(name) {
        if (typeof name !== 'string') {
            return name;
        }
        let result = name;
        let nameSplit = name.split(', ');
        if (nameSplit.length > 1) {
            if (Config.appearance.nameStyle === 1) {
                result = nameSplit[0].trim() + ' ' + nameSplit[1].trim();
            } else if (Config.appearance.nameStyle === 2) {
                result = nameSplit[1].trim() + ' ' + nameSplit[0].trim();
            } else {
                // default value, already present
            }
        }
        return result;
    }

    // == QbTools.formatName(), de csak ha topHierarchyLevel === 'uv' (bm a bejelentkezett jogkör)
    static formatNameBm(name) {
        let authData = Auth.getData();
        const topHierarchyLevel = authData.user.topHierarchyLevel.toLowerCase();
        if (topHierarchyLevel === 'uv') {
            return QbTools.formatName(name);
        } else {
            return name;
        }
    }

    static copyDom(src) {
        let dst = src.cloneNode(false);

        if (src.hasChildNodes()) {
            let children = src.childNodes;

            for (let i = 0; i < children.length; i++) {
                let child = children[i];
                if (child.nodeName === 'CANVAS') {
                    let img = document.createElement('img');
                    img.setAttribute('src', child.toDataURL('image/png'));
                    img.setAttribute('class', src.getAttribute('class'));
                    child = img;
                }
                dst.appendChild(QbTools.copyDom(child));
            }
        }

        return dst;
    }

    static filterDisabledComponentsMap(componentsMap) {
        let componentsFilteredArray = Object.entries(componentsMap).filter( item => {
            return ! Config.disabledComponents.includes(item[0]);
        } );
        return Object.assign(...componentsFilteredArray.map( item => ( { [item[0]]: item[1] } )));
    }

    static filterDisabledComponentsFilter(componentsList) {
        return componentsList.filter( item => {
            return ! Config.disabledComponents.includes(item.id);
        } );
    }

    static metricDataReady(metricData, items) {
        let ready = true;
        items.forEach(function (item) {
            if (! metricData.hasOwnProperty(item)) {
                ready = false;
            } else if (Object.keys(metricData[item].data || { } ).length === 0) {
                ready = false;
            }
        } );
        return ready;
    }

    static getMetricTiming(metricData, timingItem, items, t) {
        if (! QbTools.metricDataReady(metricData, items)) {
            return t('localizeDashboardNoRefreshTime');
        }
        let requestTime = items
            .map(item => metricData[item].timing[timingItem] )
            .reduce((accumulator, currentValue) => Math.max(accumulator, currentValue) );
        let requestTimeText = moment(requestTime, 'X').format(Config.dateTimeDisplayFormat);
        return requestTimeText;
    }
}

export default QbTools;
