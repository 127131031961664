import React, { useContext } from 'react';
import { Navigation, Sidebar, Footer } from './';
import { useRouteMatch } from 'react-router-dom';
import { AppStateContext } from '../modules';
import { QbErrorBoundary, Config } from '../modules';
import { Swipeable } from 'react-swipeable';

const Layout = ( props ) => {
    const appState = useContext(AppStateContext);
    const config={
        delta : 250,
    }

    const layoutStyle = {
        fontFamily: Config.appearance.globalFontFamily,
        backgroundColor: Config.appearance.globalBackgroundColor,
    }

    return (
        <React.Fragment>
            <QbErrorBoundary scope="layout">
                <div className={ 'form-spinner position-fixed justify-content-center align-items-center ' + ( appState.ajaxInProgress ? 'd-flex' : '' ) }>
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Working...</span>
                    </div>
                </div>
                <div style={layoutStyle} className={ 'layout h-100 ' + (useRouteMatch('/dashboard') ? 'dashboard' : '') + (useRouteMatch('/analytics') ? 'analytics' : '') + (useRouteMatch('/settings') ? 'settings' : '') }>
                    <Sidebar />
                    <Navigation />
                    <Swipeable className="swipe-container" onSwipedRight={appState.toggleMenuVisible} {...config}>
                        { props.children }
                    </Swipeable>
                    <Footer />
                </div>
            </QbErrorBoundary>
        </React.Fragment>
    )
};

export default Layout;
