import React from 'react';
import { Redirect } from 'react-router-dom';
import { BreakdownDisplayBmSubItem } from './';
import { QbTools } from '../modules';

function BreakdownProxyBmSubItem(props) {
    let findData = Object.entries(props.metricData.children?.data || { } ).find(e => (e[0] === decodeURIComponent(props.match.params.subid)) || e[0].endsWith('( ' + props.match.params.subid + ' )'));
    let displayData = undefined;
    if (typeof findData !== 'undefined') {
        displayData = findData[1];
        displayData.name = QbTools.apiNameToFriendlyName(findData[0]);
    }
    if (QbTools.metricDataReady(props.metricData, [ 'children' ] ) && (typeof displayData === 'undefined')) {
        return (<Redirect to={ '/dashboard/' + props.boardtype }/>);
    }
    if (typeof displayData === 'undefined') {
        displayData = {
            USZ: null,
            VI: null,
            KI: null,
            RLC: null,
            RTS: null,
            RST: null,
            name: null,
            key: null,
            userDetails: null,
        };
    } else {
        displayData.key = props.match.params.subid;
    }

    return (
        <React.Fragment>
            <BreakdownDisplayBmSubItem displayData={ displayData } />
        </React.Fragment>
    );
}

export default BreakdownProxyBmSubItem;
