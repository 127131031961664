import React from 'react';

function ServicePointIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 20" fill="none" {...props}>
      <path
        d="M4 15H3a2 2 0 01-2-2V3a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2h-1"
        stroke="#C2C2FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13l5 6H6l5-6z"
        stroke="#C2C2FF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ServicePointIcon;
