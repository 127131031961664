import React from "react";
import {map} from 'lodash'
import {IoMdMenu} from 'react-icons/io';
import {Config} from '../modules';
import {useTranslation} from "react-i18next";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [ removed ] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	// padding: grid * 2,
	margin: `0 0 ${ grid }px 0`,
	
	// change background colour if dragging
	background: isDragging ? "lightgrey" : "white",
	borderRadius: 6,
	
	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	// background: isDraggingOver ? "lightblue" : "lightgrey",
	padding: grid,
	width: '100%'
});


const FiltersDraggableList = (props) => {
	const {dataList, setData} = props
	const {t} = useTranslation()
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const items = reorder(
			dataList,
			result.source.index,
			result.destination.index
		);
		setData(items)
    }

	return <DragDropContext onDragEnd={ onDragEnd }>
		<Droppable droppableId="droppable">
			{ (provided, snapshot) => (
				<div
					{ ...provided.droppableProps }
					ref={ provided.innerRef }
					style={ getListStyle(snapshot.isDraggingOver) }
				>
					{ dataList.map((item, index) => (
						<Draggable key={ item.id } draggableId={ item.id } index={ index }>
							{ (provided, snapshot) => (
								<div
									ref={ provided.innerRef }
									{ ...provided.draggableProps }
									{ ...provided.dragHandleProps }
									style={ getItemStyle(
										snapshot.isDragging,
										provided.draggableProps.style
									) }>
									<div className='d-flex justify-content-between align-items-center py-3 pr-3'
									     style={ {borderRadius: 6} }>
										<div className='d-flex align-items-center form-check'>
											<input
												type="checkbox" id={ String(index) } className="form-check-input" checked={ item.active }
												onClick={ () => {
													const updatedList = map(dataList, (item, updateIndex) => {
														return index === updateIndex ? {...item, active: !item?.active} : item
													})
													setData(updatedList)
												} }/>
											<label className='form-check-label ml-2' htmlFor={ String(index) }>{ (item.secondaryName)&& t(item.secondaryName) + ' – ' }{ t(item.name) }</label>
										</div>
										<IoMdMenu style={{color: Config.appearance.mainColor,}} size={ 30 }/>
									</div>
								</div>
							) }
						</Draggable>
					)) }
					{ provided.placeholder }
				</div>
			) }
		</Droppable>
	</DragDropContext>
	
}


export default FiltersDraggableList
