class Config {
    _authKey = `-----BEGIN CERTIFICATE-----
MIIDsjCCApoCCQCQf4bMChfnvzANBgkqhkiG9w0BAQsFADCBmjELMAkGA1UEBhMC
SFUxETAPBgNVBAgMCEJ1ZGFwZXN0MREwDwYDVQQHDAhCdWRhcGVzdDEQMA4GA1UE
CgwHWHBlcmlvbjEQMA4GA1UECwwHU1cgdGVzdDEWMBQGA1UEAwwNMTkyLjE2OC4w
LjI1MTEpMCcGCSqGSIb3DQEJARYaem9sdGFuLm1lc3phcm9zQHhwZXJpb24uaHUw
HhcNMTgxMDAyMTU0NzQyWhcNMjgwOTI5MTU0NzQyWjCBmjELMAkGA1UEBhMCSFUx
ETAPBgNVBAgMCEJ1ZGFwZXN0MREwDwYDVQQHDAhCdWRhcGVzdDEQMA4GA1UECgwH
WHBlcmlvbjEQMA4GA1UECwwHU1cgdGVzdDEWMBQGA1UEAwwNMTkyLjE2OC4wLjI1
MTEpMCcGCSqGSIb3DQEJARYaem9sdGFuLm1lc3phcm9zQHhwZXJpb24uaHUwggEi
MA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCg80iEI2bF351x5QCKrpqNZhjS
t4yOeUoNGGvRPzrdbijGEvoQ7nfGr0SjEGTY9BIkYQPmCH4RDqMwGYmzwjifUhGw
ozH0Niw9eXYFdMYx+Eyjt5dxItaC8Mp6/k3+9YVQhc8KqVAdSmo0e6+8WNeVN+g0
maiXMS2u82p2DpS3Jj/+Wn4iln1keBaUOkBLZ9AuLM+xjX+YJPMLwtXuG/HRvRHH
emUKn3p1MdAQ7n/yiXOoPscxH2Pj5+ICbzDyipYS7uKkcaqP3Au4/954xT7/2KBG
tyeuabuCFdWw6hMhyrAhybHGm3H5aG8hyiKdklbTeQahNO8rJSQMFmxcrYWRAgMB
AAEwDQYJKoZIhvcNAQELBQADggEBAFAMcQc60h2/4Ji8HbgNyEGCqbmPy8v0Nmx5
OeMIbB5n99MWNYBzPGZmJcWooC8DPeIARnibDxUkeQyG3ABo80L+mz23UMBMfoUO
uZJOau/nkMlYulFI+uigrYwX2JM5XLjxXl0VFB6BA3LnHEDUfuQ7381OILzbdiCp
Gb/djejk21YNuw4bj9CFRGGu1K0waUKHpBRckq1K60HMFl60JPoJrxa1UD7Zmk5W
f3TiJTsnRoyuNKJ0rRAcrd7FPZts1kFdiuRJ3oHuxd7vqrJ3WaLg7eM5nk4AsVqJ
3aGXkQw8QGt8Q0HeIDmXmRMmwrr8uPglpvQoLeGpEOZC6jaIOHU=
-----END CERTIFICATE-----`;
    _transportSecret = 'AbcdefghIj123456789.';
    _apiTimeout = 10; // in seconds
    _tokenRefreshInterval = 300;
    _userprofileCheckInterval = 300;
    _apiTimeFormat = 'YYYY-MM-DD[T]HH:mm:ssZ';
    //_dateTimeDisplayFormat = 'MMM D, YYYY [a][t] h:mm A Z';
    _dateTimeDisplayFormat = 'h:mm MMM D YYYY';
    _onlyDateDisplayFormat = 'D MMM YYYY';
    _onlyTimeDisplayFormat = 'H:mm';
    _legalDateDisplayFormat = 'YYYY MMM DD h:mm:ss A';
    _dataRefreshInterval = 60; // in seconds
    successColor = '#a2c830';
    warningColor = '#ebe31b';
    errorColor = '#e33a3a';
    _graphDefaults = {
        NoShow: [ 15, 30, 100 ],
        ServiceLevel: [ 70, 70, 100 ],
        ServingTime: [ 10, 10, 30 ],
        WaitingTime: [ 10, 20, 30 ],
        LoggedInCounterTime: [ 10, 20, 30 ],
        mapWaitingTime: [ 10, 20, 30 ],
        mapMarkerOpacity: 0.3,
        mapMarkerSize: { min: 8, max: 20 },
        mapDefaultCenter: { lat: 47.40, lng: 19.01527 },
        mapDefaultZoom: 7,
        skipHoursOnDaily: [ 22, 23, 0, 1, 2, 3, 4, 5, 6 ],
    };
    _graphColors = {
        NoShow: [ this.successColor, this.successColor, this.warningColor, this.errorColor ],
        ServiceLevel: [ this.errorColor, this.errorColor, this.warningColor, this.successColor ],
        ServingTime: [ this.successColor, this.successColor, this.warningColor, this.errorColor ],
        WaitingTime: [ this.successColor, this.successColor, this.warningColor, this.errorColor ],
        LoggedInCounterTime: [ this.successColor, this.successColor, this.warningColor, this.errorColor ],
        mapWaitingTime: [ '#000000', this.successColor, this.warningColor, this.errorColor ],
    }
    _appearance = {
        mainColor: '#0066FF',
        metricTitleColor: '#3F3356',
        metricContentBackgroundColor: '#F3F3FC',
        analyticsContentBackgroundColor: '#fff',
        breakdownListBackgroundColor: '#fff',
        breakdownOverviewRowHeight: '70px',
        upperNavigationBackgroundColor: '#fff',
        upperNavigationtextColor: '#000',
        overviewBoxBackgroundColor: '#fff',
        lowerNavigationBackgroundColor: '#f8fbfb',
        upperMenuHeightWOSearch: '60px',
        iconColorWithoutBg: '#000',
        enableStickyTop: true,
        enableClearableInput: true,
        breakdownListItemHeight: '92px',
        overviewInformationRowHeight: '50px',
        secondaryTitleSize: '16px',
        analyticsMaxLineHeightServices: '110px',
        analyticsMaxLineHeightUnusedServices: '110px',
        analyticsMaxLineHeightServiceLevel: '110px',
        helpRowHeight: '25vw',
        helpPageContentSize: '1rem',
        settingsImageHeightWidth: '250px',
        /*darkRed: 'rgba(254, 76, 76, 1)',
        darkGreen: 'rgba(85, 226, 99, 1)',
        darkYellow: 'rgba(245, 201, 55, 1)',
        darkBlue: 'rgba(84, 103, 208, 1)',*/
        darkRed: this.errorColor,
        darkGreen: this.successColor,
        darkYellow: this.warningColor,
        darkBlue: 'rgba(84, 103, 208, 1)',
        nameStyle: 2,
        globalFontFamily: "'Open Sans', sans-serif",
        globalBackgroundColor: '#F8F8F8',
        iconWithBgBackgroundColor: '#F3F3FC',
        iconWithBgIconColor: '#BABABA',
        pieColorsWithBars: ['rgba(34, 171, 217, .5)', this.errorColor, this.successColor, this.warningColor],
        pieColors: [ '#22abd9', '#e33a3a', '#a2c830', '#ebe31b', '#f79f1f', '#9b59b6', '#1abc9c', '#34495e', '#4cd137', '#9980fa' ],
    }
    _disabledComponents = [
//        'MetricProxyServedCustomersAndAverageWaitingTime',
    ]

    _help = {
        pages: [
            {
                image: '/media/help/001_login.png',
                thumbnail: '/media/help/001_login.png',
                head: {
                    en: 'Login',
                    hu: 'Bejelentkezés',
                    de: 'Anmeldung',
                    nl: 'Inloggen',
                    sv: 'Inloggning',
                    nb: 'Innlogging',
                    fi: 'Kirjaudu',
                    ro: 'Conectare',
                    pl: 'Login',
                    sk: 'Prihlásenie',
                    cs: 'Přihlášení',
                    it: 'Accesso',
                    es: 'Acceso',
                    fr: 'S\'identifier',
                },
                title: {
                    en: 'Login',
                    hu: 'Bejelentkezés',
                    de: 'Anmeldung',
                    nl: 'Inloggen',
                    sv: 'Inloggning',
                    nb: 'Innlogging',
                    fi: 'Kirjaudu',
                    ro: 'Conectare',
                    pl: 'Login',
                    sk: 'Prihlásenie',
                    cs: 'Přihlášení',
                    it: 'Accesso',
                    es: 'Acceso',
                    fr: 'S\'identifier',
                },
                content: {
                    en : 'Login with your username and password.',
                    hu : 'Jelentkezzen be a felhasználónevével és jelszavával.',
                    de : 'Melden Sie sich mit Ihrem Nutzernamen und Ihrem Passwort an.',
                    nl : 'Login met je gebruikersnaam en wachtwoord.',
                    sv : 'Logga in med ditt användarnamn och lösenord.',
                    nb : 'Logg inn med ditt brukernavn og passord.',
                    fi : 'Kirjaudu sisään käyttäjätunnuksella ja salasanalla.',
                    ro : 'Conectați-vă cu numele și parola Dumneavoastră de utilizator.',
                    pl : 'Zaloguj się za pomocą swojego loginu i hasła.',
                    sk : 'Prihláste sa svojím užívateľským menom a heslom.',
                    cs : 'Přihlaste se svým uživatelským jménem a heslem.',
                    it : 'Accedere usando il vostro nome utente e la vostra password.',
                    es : 'Acceso con su nombre de usuario y contraseña.',
                    fr : 'Connectez-vous avec votre nom d\'utilisateur et votre mot de passe.',
                },
            },
            {
                image: '/media/help/002_login.png',
                thumbnail: '/media/help/002_login.png',
                title: {
                    en: 'Login',
                    hu: 'Bejelentkezés',
                    de: 'Anmeldung',
                    nl: 'Inloggen',
                    sv: 'Inloggning',
                    nb: 'Innlogging',
                    fi: 'Kirjaudu',
                    ro: 'Conectare',
                    pl: 'Login',
                    sk: 'Prihlásenie',
                    cs: 'Přihlášení',
                    it: 'Accesso',
                    es: 'Acceso',
                    fr: 'S\'identifier',
                },
                content: {
                    en : 'Send a [support request] by tapping the {Support request} button.\n\nOpen the [help] screen by tapping the {Help} button.',
                    hu : 'Küldjön [hibabejelentést] a {Hibabejelentés} gombra koppintva.\n\nNyissa meg a [segítség] felületet a {Segítség} gombra koppintva.',
                    de : 'Durch Tippen auf {Supportanfrage} eine [Supportanfrage] senden.\n\nDurch Tippen auf {Hilfe} die [Hilfeseite] öffnen.',
                    nl : 'Een [supportaanvraag] versturen door op de {Ondersteuningsverzoek}-knop te tikken.\n\nHet scherm [Help] openen door op de {Hjelp}-knop te tikken.',
                    sv : 'Skicka in en [supportbegäran] genom att trycka på knappen {Supportbegäran}.\n\nÖppna skärmen [Hjälp] genom att trycka på knappen {Hjälp}.',
                    nb : 'Send en [støtteforespørsel] ved å trykke på {orespørsel om support}-knappen.\n\nÅpne [hjelp]-skjermen ved å trykke på {Hjelp}-knappen.',
                    fi : 'Lähetä [tukipyyntö] napauttamalla painiketta {Tukipyyntö}.\n\nAvaa [ohje] napauttamalla painiketta {Apu}.',
                    ro : 'Transmiteți o [cerere de asistență] apăsând butonul {Solicitare suport}.\n\nDeschideți ecranul [ajutor] apăsând butonul {Ajutor}.',
                    pl : 'Wyślij [prośbę o pomoc techniczną], naciskając przycisk {Prośba o pomoc}.\n\nOtwórz ekran [pomocy], naciskając przycisk {Pomoc}.',
                    sk : 'Ťuknutím na tlačidlo {Požiadať o podporu} môžete odoslať [žiadosť o technickú podporu].\n\nŤuknutím na tlačidlo {Pomoc} môžete otvoriť obrazovku [pomocníka].',
                    cs : 'Klepnutím na ikonu {Požádat o podporu} odešlete [žádost o podporu].\n\nKlepnutím na ikonu {Pomoc} zobrazíte [nápovědu].',
                    it : 'Inviare una [richiesta di supporto] toccando il pulsante {Richiesta di assistenza}.\n\nAprire la schermata della [guida] toccando il pulsante {Aiuto}.',
                    es : 'Pulse el botón {Pedido de soporte} para enviar una [solicitud de soporte técnico].\n\nPulse el botón {Ayuda} para abrir la pantalla de [ayuda].',
                    fr : 'Envoyer une [demande d\'assistance] en touchant le bouton {Demande de support}.\n\nOuvrir l\'écran [aide] en touchant le bouton {Aide}.',
                },
            },
            {
                image: '/media/help/001_settings.png',
                thumbnail: '/media/help/001_settings.png',
                head: {
                    en : 'Settings',
                    hu : 'Beállítások',
                    de : 'Einstellungen',
                    nl : 'Instellingen',
                    sv : 'Inställningar',
                    nb : 'Innstillinger',
                    fi : 'Asetukset',
                    ro : 'Setări',
                    pl : 'Ustawienia',
                    sk : 'Nastavenia',
                    cs : 'Nastavení',
                    it : 'Impostazioni',
                    es : 'Configuración',
                    fr : 'Paramètres',
                },
                title: {
                    en : 'Settings',
                    hu : 'Beállítások',
                    de : 'Einstellungen',
                    nl : 'Instellingen',
                    sv : 'Inställningar',
                    nb : 'Innstillinger',
                    fi : 'Asetukset',
                    ro : 'Setări',
                    pl : 'Ustawienia',
                    sk : 'Nastavenia',
                    cs : 'Nastavení',
                    it : 'Impostazioni',
                    es : 'Configuración',
                },
                content: {
                    en : 'Setup your profile: select a [photo] and set your [contact] information.',
                    hu : 'Állítsa be a profilját: válasszon egy [képet], és állítsa be az [elérhetőségi] adatait.',
                    de : 'Richten Sie Ihr Profil ein: Wählen Sie ein [Foto] und geben Sie Ihre [Kontakt] Informationen ein.',
                    nl : 'Uw profiel instellen: selecteer een [foto] en stel uw [contact] informatie in.',
                    sv : 'Ställ in din profil: välj ett [foto] och konfigurera din [kontakt]information.',
                    nb : 'Opprett din profil: velg et [bilde] og oppgi din [kontakt] informasjon.',
                    fi : 'Määritä profiili: valitse [kuva] ja määritä [yhteystiedot].',
                    ro : 'Setați-vă profilul: selectați o [fotografie] și setați-vă informațiile [de contact].',
                    pl : 'Ustaw swój profil: wybierz [zdjęcie] i ustaw informacje [kontaktowe].',
                    sk : 'Nastavte si profil: vyberte [fotografiu] a nastavte si [kontaktné] informácie.',
                    cs : 'Nastavte si profil: vyberte [fotografii] a nastavte si [kontaktní] informace.',
                    it : 'Impostare il profilo: scegliere una [foto] e impostare i dati dei [contatti].',
                    es : 'Imposta il tuo profilo: scegli una [foto] e imposta i dati dei tuoi [contatti].',
                    fr : 'Configurer votre profil: sélectionnez une [photo] et définissez vos informations de [contact].',
                },
            },
            {
                image: '/media/help/002_settings.png',
                thumbnail: '/media/help/002_settings.png',
                title: {
                    en : 'Settings',
                    hu : 'Beállítások',
                    de : 'Einstellungen',
                    nl : 'Instellingen',
                    sv : 'Inställningar',
                    nb : 'Innstillinger',
                    fi : 'Asetukset',
                    ro : 'Setări',
                    pl : 'Ustawienia',
                    sk : 'Nastavenia',
                    cs : 'Nastavení',
                    it : 'Impostazioni',
                    es : 'Configuración',
                },
                content: {
                    en : 'Setup your branch: set name, number of service points, address and open hours.\n\nThis option is only available for [branch managers].',
                    hu : 'Állítsa be az üzlet adatait: név, pultok száma, cím és nyitvatartás.\n\nEz az opció kizárólag [üzletvezetők] számára érhető el.',
                    de : 'Richten Sie Ihre Filiale ein: Geben Sie Name, Anzahl der Kundendienststellen, Adresse und Öffnungszeiten ein.\n\nDiese Option ist nur für [Filialleiter] verfügbar.',
                    nl : 'Stel uw branche in: stel naam, aantal servicepunten, adres en openingstijden in.\n\nDeze optie is alleen beschikbaar voor [filiaalmanagers].',
                    sv : 'Ställ in din filial: namn, antal serviceställen, adress och öppettider.\n\nDetta alternativ är endast tillgänligt för [filialchefer].',
                    nb : 'Opprett din filial: opprett navn, antall servicepunkter, adresse og åpningstider.\n\nDette alternativet er bare tilgjengelig for [filialsjefer].',
                    fi : 'Määritä toimipiste: määritä nimi, palvelupisteiden lukumäärä, osoite ja aukioloajat.\n\nTämä vaihtoehto on vain [toimipistejohtajien] käytettävissä.',
                    ro : 'Setați-vă departamentul: setați numele, numărul punctelor de deservire, adresa și programul de lucru.\n\nAceastă opțiune este disponibilă doar pentru [directorii de departamente].',
                    pl : 'Ustaw oddział: podaj nazwę, liczbę punktów usługowych, adres i godziny otwarcia.\n\nTa opcja dostępna jest jedynie dla [kierowników oddziałów].',
                    sk : 'Nastavte si pobočku: nastavte názov, počet obslužných pracovísk, adresu a otváracie hodiny.\n\nTáto možnosť je dostupná iba pre [vedúcich pobočiek].',
                    cs : 'Nastavte si pobočku: nastavte název, počet obslužných pracovišť, adresu a otevírací hodiny.\n\nTato možnost je dostupná jen pro [vedoucí poboček].',
                    it : 'Impostare i dati della vostra filiale: impostare la denominazione, il numero di punti di assistenza, l\'indirizzo e gli orari di apertura.\n\nQuesta opzione è disponibile solo per i [direttori di filiale].',
                    es : 'Configuración de su sucursal: número de puntos de servicio, dirección y horario de atención \n\nEsta opción solo está disponible para [gerentes de sucursal].',
                    fr : 'Configurez votre branche: nom de l\'ensemble, nombre de points de service, adresse et heures d\'ouverture.\n\nCette option n\'est disponible que pour les [directeurs de succursale].',
                },
            },
            {
                image: '/media/help/003_settings.png',
                thumbnail: '/media/help/003_settings.png',
                title: {
                    en : 'Settings',
                    hu : 'Beállítások',
                    de : 'Einstellungen',
                    nl : 'Instellingen',
                    sv : 'Inställningar',
                    nb : 'Innstillinger',
                    fi : 'Asetukset',
                    ro : 'Setări',
                    pl : 'Ustawienia',
                    sk : 'Nastavenia',
                    cs : 'Nastavení',
                    it : 'Impostazioni',
                    es : 'Configuración',
                },
                content: {
                    en : 'Select [language] and manage your [privacy] settings.',
                    hu : 'Válasszon [nyelvet] és kezelje az [adatvédelmi] beállításait.',
                    de : '[Sprache] auswählen und [Datenschutzeinstellungen] verwalten.',
                    nl : 'Uw [taal] selecteren en uw [privacy]-instellingen beheren.',
                    sv : 'Välj [språk] och hantera inställningar för [sekretess].',
                    nb : 'Velg [språk] og administrer [personvern]-innstillinger.',
                    fi : 'Valitse [kieli] ja hallinnoi [yksityisyys]-asetuksiasi.',
                    ro : 'Selectați [limba] și gestionați-vă setările de [confidențialitate].',
                    pl : 'Wybierz [język] i zarządzaj ustawieniami [prywatności].',
                    sk : 'Vyberte [jazyk] a spravujte nastavenia [ochrany osobných údajov].',
                    cs : 'Zvolte [jazyk] a spravujte nastavení [soukromí].',
                    it : 'Selezionare la [lingua] e gestire le impostazioni di [privacy].',
                    es : 'Seleccione el [idioma] y administre su configuración de [privacidad].',
                    fr : 'Sélectionner la [langue] pour gérer vos paramètres de [confidentialité].',
                },
            },
            {
                image: '/media/help/004_settings.png',
                thumbnail: '/media/help/004_settings.png',
                title: {
                    en : 'Settings',
                    hu : 'Beállítások',
                    de : 'Einstellungen',
                    nl : 'Instellingen',
                    sv : 'Inställningar',
                    nb : 'Innstillinger',
                    fi : 'Asetukset',
                    ro : 'Setări',
                    pl : 'Ustawienia',
                    sk : 'Nastavenia',
                    cs : 'Nastavení',
                    it : 'Impostazioni',
                    es : 'Configuración',
                },
                content: {
                    en : 'Clear privacy related information from the device.',
                    hu : 'Törölje a személyes adatokat és azokat tartalmazó bejegyzéseket az eszközről.',
                    de : 'Datenschutzbezogene Informationen vom Gerät löschen.',
                    nl : 'Privacygevoelige informatie van het device wissen.',
                    sv : 'Rensa sekretessrelaterad information från enheten.',
                    nb : 'Slett personvernrelatert informasjon fra enheten.',
                    fi : 'Poista laitteesta yksityisyyttä koskevat tiedot.',
                    ro : 'Ștergeți informațiile de confidențialitate asociate de pe dispozitiv.',
                    pl : 'Usuń informacje o prywatności z urządzenia.',
                    sk : 'Vymažte zo zariadenia informácie týkajúce sa ochrany osobných údajov.',
                    cs : 'Odstraňte ze zařízení soukromé údaje.',
                    it : 'Cancellare dal dispositivo le informazioni associate alla privacy.',
                    es : 'Borre del dispositivo la información relacionada con la privacidad.',
                    fr : 'Effacer les informations liées à la confidentialité de l\'appareil.',
                },
            },
            {
                image: '/media/help/005_settings.png',
                thumbnail: '/media/help/005_settings.png',
                title: {
                    en : 'Settings',
                    hu : 'Beállítások',
                    de : 'Einstellungen',
                    nl : 'Instellingen',
                    sv : 'Inställningar',
                    nb : 'Innstillinger',
                    fi : 'Asetukset',
                    ro : 'Setări',
                    pl : 'Ustawienia',
                    sk : 'Nastavenia',
                    cs : 'Nastavení',
                    it : 'Impostazioni',
                    es : 'Configuración',
                },
                content: {
                    en : 'Open the effective [privacy policy].',
                    hu : 'Nyissa meg az érvényben levő [adatvédelmi szabályzatot].',
                    de : 'Geltende [Datenschutzerklärung] öffnen.',
                    nl : 'Het geldende [privacybeleid] openen.',
                    sv : 'Öppna den gällande [sekretesspolicyn].',
                    nb : 'Åpne den effektive [personvernpolicy].',
                    fi : 'Avaa voimassa oleva [tietosuojakäytäntö].',
                    ro : 'Deschideți [politica de confidențialitate] în vigoare.',
                    pl : 'Otwórz obowiązującą [politykę prywatności].',
                    sk : 'Otvoria sa platné [zásady ochrany osobných údajov].',
                    cs : 'Otevřete platné [zásady ochrany osobních údajů].',
                    it : 'Aprire l\'[informativa sulla privacy] corrente.',
                    es : 'Abra la [política de privacidad] en vigor.',
                    fr : 'Ouvrir la [politique de confidentialité] en vigueur.',
                },
            },
            {
                image: '/media/help/001_dashboard.png',
                thumbnail: '/media/help/001_dashboard.png',
                head: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                title: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                content: {
                    en : 'Select [group] to display data from.\n\nDifferent roles have different group visibility (branch, subregion, region, hq).',
                    hu : 'Válassza ki a [csoportot], amelynek az adatait meg szeretné jeleníteni.\n\nKülönböző szerepkörök különböző csoportokat látnak (üzlet, terület, régió, központ).',
                    de : 'Wählen Sie eine [Gruppe], um ihre Daten anzuzeigen.\n\nDie verschiedenen Rollen verfügen über unterschiedliche Gruppenansichten (Filiale, Unterregion, Region, Zentrale).',
                    nl : 'Selecteer [groep] om gegevens van weer te geven.\n\nVerschillende rollen hebben een verschillende zichtbaarheid van de groep (filiaal, subregio, regio, hoofdkantoor).',
                    sv : 'Välj [grupp] att visa data från.\n\nOlika roller har olika gruppsynlighet (filial, delregion, region, hk).',
                    nb : 'Velg [gruppe] som det skal vises data fra.\n\nUlike roller har ulik gruppesynlighet (filial, subregion, region, hovedkontor).',
                    fi : 'Valitse [ryhmä], jonka tietoja haluat näyttää.\n\nEri rooleille näkyy erilaisia ryhmiä (toimipiste, osa-alue, alue, pääkonttori).',
                    ro : 'Selectați [groupul] din care doriți să vi se afișeze datele.\n\nFuncțiile diferite au vizibilitate de grup diferită (departament, subregiune, regiune, sediu central).',
                    pl : 'Wybierz [grupę] której dane chcesz wyświetlić.\n\nRóżne role widzą różne grupy (oddział, podregion, region, centrala).',
                    sk : 'Vyberte si [skupinu], z ktorej chcete zobraziť údaje.\n\nRozličné roly vidia rozličné skupiny (pobočka, oblasť, región, centrála).',
                    cs : 'Vyberte si [skupinu], z které chcete zobrazit údaje.\n\nRůzné role vidí různé skupiny (pobočka, oblast, region, centrála).',
                    it : 'Selezionare il [gruppo] per mostrare i dati.\n\nA seconda del ruolo è attribuita una diversa visibilità di gruppo (filiale, sottoregione, regione, sede).',
                    es : 'Selecione [grupo] para mostrar los datos de.\n\nLos distintos roles tienen distinta visibilidad de grupo (sucursal, subregión, región, casa matriz).',
                    fr : 'Sélectionnez [group] pour afficher les données.\n\nDifférents rôles ont une visibilité de groupe différente (branche, sous-région, région, hq).',
                },
            },
            {
                image: '/media/help/002_dashboard.png',
                thumbnail: '/media/help/002_dashboard.png',
                title: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                content: {
                    en : 'In case of an [error], a red {!} is displayed, together with the date of the last successful read.',
                    hu : '[Hiba] esetén egy piros {!} jelenik meg a legutolsó sikeres olvasás dátumával együtt.',
                    de : 'Bei einem [Fehler] wird ein roter {!} angezeigt, zusammen mit dem Datum des letzten erfolgreich gelesenen Eintrags.',
                    nl : 'In het geval van een [fout] wordt een rode {!} weergegeven, samen met de datum van de laatste geslaagde aflezing.',
                    sv : 'Utifall ett [fel], visas en röd {!}, tillsammans med datumet för den senaste inläsningen.',
                    nb : 'I tilfelle en [feil] vises en rød {!} sammen med datoen for den siste vellykkede avlesningen.',
                    fi : '[Virheen] sattuessa näkyy punainen {!} sekä edellisen onnistuneen luennan päivämäärä.',
                    ro : 'În cazul unei [erori], se va afișa un {!} roșu, împreună cu data ultimei citiri reușite.',
                    pl : 'W przypadku [błędu] wyświetla się czerwona {!} wraz z datą ostatniego udanego odczytu.',
                    sk : 'V prípade [chyby] sa zobrazí červená {!} a dátum posledného úspešného čítania.',
                    cs : 'V případě [chyby] se zobrazí červená {!} a datum posledního úspěšného čtení.',
                    it : 'In caso di [errore], comparirà un {!} rosso la data dell\'ultima lettura con esito positivo.',
                    es : 'En el caso de un [error], aparece un {!} rojo, junto con la fecha de la última lectura exitosa.',
                    fr : 'En cas de [erreur], un {!} rouge s\'affiche avec la date de la dernière lecture réussie.',
                },
            },
            {
                image: '/media/help/003_dashboard.png',
                thumbnail: '/media/help/003_dashboard.png',
                title: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                content: {
                    en : 'Network activity is displayed in the cell\'s top-right corner.',
                    hu : 'Hálózati aktivitás a cellák jobb felső sarkában van feltüntetve.',
                    de : 'Die Netzwerkaktivität wird in der oberen rechten Ecke der Zelle angezeigt.',
                    nl : 'Netwerkactiviteit wordt weergegeven in de rechterbovenhoek van de cel.',
                    sv : 'Nätverksaktivitet anges längst upp till höger i cellen.',
                    nb : 'Nettverksaktivitet vises i cellens øvre høyre hjørne.',
                    fi : 'Verkon käyttö näytetään solun oikeassa ylänurkassa.',
                    ro : 'Activitatea din rețea este afișată în colțul din dreapta sus al celulei.',
                    pl : 'Aktywność sieci jest wyświetlana w prawym górnym rogu komórki.',
                    sk : 'Sieťová aktivita sa zobrazuje v pravom hornom rohu bunky.',
                    cs : 'Síťová aktivita je zobrazena v pravém horním rohu buňky.',
                    it : 'L\'attività di rete è visualizzata nell\'angolo superiore destro della cella.',
                    es : 'La actividad de red se muestra en la esquina superior derecha de la celda.',
                    fr : 'L\'activité réseau est affichée en haut à droite de la cellule.',
                },
            },
            {
                image: '/media/help/004_dashboard.png',
                thumbnail: '/media/help/004_dashboard.png',
                title: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                content: {
                    en : 'Help, report generation, filter.',
                    hu : 'Segítség, riport generálás, szűrés.',
                    de : 'Hilfe, Berichterstellung, Filtern.',
                    nl : 'Hjelp, rapport genereren, filteren.',
                    sv : 'Hjälp, generering av rapporter, filtrera.',
                    nb : 'Hjelp, generering av rapporten, filtrer.',
                    fi : 'Apu, raportin luonnissa, suodata.',
                    ro : 'Ajutor, generarea raportului, filtrați.',
                    pl : 'Pomoc, generowanie raportów, filtruj.',
                    sk : 'Pomoc, generovanie správ, filtrovať.',
                    cs : 'Pomoc, tvorbě přehledu, filtrovat.',
                    it : 'Aiuto, generazione del report, filtra.',
                    es : 'Ayuda, generar informes, filtrar.',
                    fr : 'Aide, création de rapports, filtrer.',
                },
            },
            {
                image: '/media/help/005_dashboard.png',
                thumbnail: '/media/help/005_dashboard.png',
                title: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                content: {
                    en : 'Date of the last refresh (in case of error: oldest successful cell refresh).',
                    hu : 'A legutóbbi frissítés dátuma (hiba esetén: a legrégebbi sikeres cellafrissítés).',
                    de : 'Datum der letzten Aktualisierung (bei einem Fehler: älteste erfolgreiche Zellenaktualisierung).',
                    nl : 'Datum van de laatste verversing (in geval van fout: oudste succesvolle celvernieuwing).',
                    sv : 'Datum för den senaste uppdateringen (vid fel: uppdatering av senaste cell).',
                    nb : 'Dato for siste oppdatering (i tilfelle feil: eldste vellykkede celleoppdatering).',
                    fi : 'Edellisen päivityksen päivämäärä (virheen sattuessa: vanhin onnistunut solun päivitys).',
                    ro : 'Data ultimei actualizări (în caz de eroare: cel mai veche actualizare reușită a celulei).',
                    pl : 'Data ostatniego odświeżenia (w przypadku błędów: najstarsze udane odświeżenie komórki).',
                    sk : 'Dátum poslednej obnovy (v prípade chyby: posledné úspešné obnovenie bunky).',
                    cs : 'Datum poslední obnovy (v případě chyby: poslední úspěšné obnovení buňky).',
                    it : 'Data dell\'ultimo aggiornamento (in caso di errore: l\'aggiornamento meno recente della cella con esito positivo).',
                    es : 'Fecha de la última actualización (en caso de error: última actualización exitosa de la celda).',
                    fr : 'Date de la dernière actualisation (en cas d\'erreur: actualisation de cellule réussie).',
                },
            },
            {
                image: '/media/help/006_dashboard.png',
                thumbnail: '/media/help/006_dashboard.png',
                title: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                content: {
                    en : 'Performance of the inferior groups or users (sorted).\n\nDifferent roles have different information displayed.',
                    hu : 'Az alárendelt csoportok vagy felhasználók teljesítménye (rendezve).\n\nSzerepkörönként eltérő információk jelennek meg.',
                    de : 'Performance der Untergruppen oder -nutzer (geordnet).\n\nDie verschiedenen Rollen verfügen über eine unterschiedliche Informationsanzeige.',
                    nl : 'Prestaties van de inferieure groepen of gebruikers (gesorteerd).\n\nVerschillende rollen hebben verschillende informatie weergegeven.',
                    sv : 'Prestanda för underställda grupper eller användare (sorterade).\n\nOlika roller har olika information visad.',
                    nb : 'Prestasjon av undergrupper eller brukere (sortert).\n\nUlike roller har ulik informasjon vist.',
                    fi : 'Alempien ryhmien tai käyttäjien suoritukset (lajiteltuna).\n\nEri rooleille näytetään eri tietoja.',
                    ro : 'Performanța grupurilor sau utilizatorilor inferiori (în ordine).\n\nDiferite funcții au afișate informații diferite.',
                    pl : 'Wydajność podrzędnych grup lub użytkowników (uporządkowana).\n\nRóżnym rolom wyświetlają się inne informacje.',
                    sk : 'Výkonnosť podriadených skupín alebo užívateľov (zoradené).\n\nPre rozličné roly sa zobrazujú rozličné informácie.',
                    cs : 'Výkonnost podřízených skupin nebo uživatelů (v pořadí).\n\nPro různé role se zobrazují různé informace.',
                    it : 'Azioni dei gruppi o utenti inferiori (ordinate).\n\nVengono mostrate informazioni diverse a seconda dei ruoli.',
                    es : 'Desempeño de los grupos o usuarios inferiores  (ordenados).\n\nSe muestra distinta información para los distintos roles.',
                    fr : 'Performances des groupes inférieurs ou utilisateurs (triés).\n\nDifférents rôles ont des informations différentes affichées.',
                },
            },
            {
                image: '/media/help/007_dashboard.png',
                thumbnail: '/media/help/007_dashboard.png',
                title: {
                    en : 'Dashboard',
                    hu : 'Dashboard',
                    de : 'Dashboard',
                    nl : 'Dashboard',
                    sv : 'Dashboard',
                    nb : 'Dashboard',
                    fi : 'Dashboard',
                    ro : 'Dashboard',
                    pl : 'Dashboard',
                    sk : 'Dashboard',
                    cs : 'Dashboard',
                    it : 'Dashboard',
                    es : 'Dashboard',
                    fr : 'Dashboard',
                },
                content: {
                    en : 'Tap on the cell, to contact the manager or user and get a performance overview.',
                    hu : 'Koppintson a cellára, hogy kapcsolatba lépjen a menedzserrel vagy a felhasználóval és áttekintést kapjon a teljesítményről.',
                    de : 'Klicken Sie auf die Zelle, um den Manager oder Nutzer zu kontaktieren.',                             
                    nl : 'Klik op de cel om contact op te nemen met de manager of gebruiker.',                                 
                    sv : 'Klicka på cellen för att kontakta chefen eller användaren.',                                         
                    nb : 'Klikk i cellen for å kontakte sjefen eller brukeren.',                                              
                    fi : 'Ota yhteyttä johtajaan tai käyttäjään napsauttamalla solua.',                                       
                    ro : 'Dați click pe celulă pentru a contacta directorul sau utilizatorul.', 
                    pl : 'Kliknij na komórkę, aby skontaktować się z kierownikiem lub użytkownikiem.',                         
                    sk : 'Ak chcete kontaktovať manažéra alebo užívateľa, kliknite na bunku.',                                 
                    cs : 'Pokud chcete kontaktovat manažera nebo uživatele, klikněte na buňku.',                               
                    it : 'Per contattare il gestore o l\'utente cliccare sulla cella.',                                       
                    es : 'Haga clic en la celda para ponerse en contacto con el gerente o con el usuario.',                    
                    fr : 'Cliquez sur la cellule pour contacter le gestionnaire ou l\'utilisateur.',         
                },
            },
            {
                image: '/media/help/001_analysis.png',
                thumbnail: '/media/help/001_analysis.png',
                head: {
                    en : 'Analytics',
                    hu : 'Analízis',
                    de : 'Analysen',
                    nl : 'Analytics',
                    sv : 'Analys',
                    nb : 'Analyser',
                    fi : 'Analytiikka',
                    ro : 'Analiză',
                    pl : 'Analizy',
                    sk : 'Analýzy',
                    cs : 'Analýzy',
                    it : 'Analisi',
                    es : 'Análisis',
                    fr : 'Analytics',
                },
                title: {
                    en : 'Analytics',
                    hu : 'Analízis',
                    de : 'Analysen',
                    nl : 'Analytics',
                    sv : 'Analys',
                    nb : 'Analyser',
                    fi : 'Analytiikka',
                    ro : 'Analiză',
                    pl : 'Analizy',
                    sk : 'Analýzy',
                    cs : 'Analýzy',
                    it : 'Analisi',
                    es : 'Análisis',
                    fr : 'Analytics',
                },
                content: {
                    en : '[Analysed] information in different resolutions.\n\nTap on a cell to change visualization of the data.',
                    hu : '[Analizált] adatok különböző felbontásban.\n\nKoppintson a cellára, hogy megváltozzon az adat megjelenítése.',
                    de : '[Analysierte] Informationen in verschiedenen Auflösungen.\n\nKlicken Sie auf die Zelle, um die bildliche Darstellung der Daten zu ändern.',
                    nl : '[Analyse] informatie in verschillende resoluties.\n\nKlik op de cel om de visualisatie van de gegevens te wijzigen.',
                    sv : '[Analyserad] information i olika upplösningar.\n\nKlicka på cellen för att ändra visning av data.',
                    nb : '[Analysert] informasjon i forskjellige løsninger.\n\nKlikk i cellen for å endre visualisering av dataene.',
                    fi : '[Analysoidut] tiedot eri resoluutioissa.\n\nVaihda tietojen visualisointia napsauttamalla solua.',
                    ro : 'Informații [analizate] în rezoluții diferite.\n\nDați click pe celulă pentru a modifica vizulaizarea datelor.',
                    pl : '[Analizowana] informacja w różnych rozdzielczościach.\n\nKliknij komórkę, by zmienić sposób wizualizacji danych.',
                    sk : '[Analyzované] informácie v rozličných rozlíšeniach.\n\nAk chcete zmeniť spôsob zobrazovania údajov, kliknite na bunku.',
                    cs : '[Analyzované] informace v různých rozlišeních.\n\nPokud chcete změnit způsob zobrazování údajů, klikněte na buňku.',
                    it : 'Informazioni [analizzate] in diverse risoluzioni. \n\nCliccare sulla cella per modificare la visualizzazione dei dati.',
                    es : 'la información [analizada] en distintas resolutiones.\n\nhaga clic en la celda para cambiar la visualizacion de los datos.',
                    fr : '[Analyzed] informations dans différentes résolutions.\n\nCliquez sur la cellule pour changer la visualisation des données.',
                },
            },
            {
                image: '/media/help/002_analysis.png',
                thumbnail: '/media/help/002_analysis.png',
                title: {
                    en : 'Analytics',
                    hu : 'Analízis',
                    de : 'Analysen',
                    nl : 'Analytics',
                    sv : 'Analys',
                    nb : 'Analyser',
                    fi : 'Analytiikka',
                    ro : 'Analiză',
                    pl : 'Analizy',
                    sk : 'Analýzy',
                    cs : 'Analýzy',
                    it : 'Analisi',
                    es : 'Análisis',
                    fr : 'Analytics',
                },
                content: {
                    en : '[Configure] or [export] dashboard diagrams or data.',
                    hu : '[Konfigurálja] vagy [exportálja] a megjelenített grafikonokat, illetve adatokat.',
                    de : '[Konfigurieren] oder [exportieren] Sie Dashboard-Diagrammen oder Daten.', 
                    nl : '[Configure] of [export] dashboard figuren of gegevens.',
                    sv : '[Konfigure] eller [export] dashboardsiffror eller data.',
                    nb : '[Konfigure] eller [eksporter] dashbord tall eller data.',
                    fi : '[Määritä] tai [vie] koontinäytön luvut tai tiedot.', 
                    ro : '[Configurați] sau [exportați] figurile sau datele afișate.',
                    pl : '[Konfiguruj] lub [eksportuj] dane lub wykresy z dashboardu.',
                    sk : '[Konfigurujte] alebo [exportujte] zobrazené grafy alebo údaje.',
                    cs : '[Konfigurujte] nebo [exportujte] zobrazené grafy nebo údaje.',
                    it : '[Configurare] o [esportare] dati o cifre nel dashboard.',
                    es : '[Configurar] o [exportar] números o datos del tablero de control.',
                    fr : '[Configurer] ou [exporter] des figures ou des données de tableau de bord.',
                },
            },
            {
                image: '/media/help/003_analysis.png',
                thumbnail: '/media/help/003_analysis.png',
                title: {
                    en : 'Analytics',
                    hu : 'Analízis',
                    de : 'Analysen',
                    nl : 'Analytics',
                    sv : 'Analys',
                    nb : 'Analyser',
                    fi : 'Analytiikka',
                    ro : 'Analiză',
                    pl : 'Analizy',
                    sk : 'Analýzy',
                    cs : 'Analýzy',
                    it : 'Analisi',
                    es : 'Análisis',
                    fr : 'Analytics',
                },
                content: {
                    en : '[Select] and/or [reorder] the data.',
                    hu : '[Válassza] ki és/vagy [rendezze] sorba az adatokat.',
                    de : 'Daten [auswählen] und/oder neu [organisieren].',
                    nl : 'De gegevens [selecteren] en/of [opnieuw ordenen].',
                    sv : '[Välj] och/eller [omorganisera] data.',
                    nb : '[Velg] og/eller [endre rekkefølge på] data.',
                    fi : '[Valitse] ja/tai [järjestä uudelleen] tiedot.',
                    ro : '[Selectați] și/sau [reordonați] datele.',
                    pl : '[Wybierz] i/lub [zmień kolejność] danych.',
                    sk : '[Vyberte] a/alebo [zmeňte ich poradie] údaje.',
                    cs : '[Zvolte] a/nebo [přeuspořádejte] data.',
                    it : '[Selezionare] e/o [ordinare nuovamente] i dati.',
                    es : '[Seleccione] o [reorganice] los datos.',
                    fr : '[Sélectionner] et/ou [réordonner] les données.',
                },
            },
            {
                image: '/media/help/002_report.png',
                thumbnail: '/media/help/002_report.png',
                head: {
                    en : 'Tickets',
                    hu : 'Bejelentések',
                    de : 'Tickets',
                    nl : 'Tickets',
                    sv : 'Supportbegäranden',
                    nb : 'Ticket',
                    fi : 'Tukitapaukset',
                    ro : 'Tichete',
                    pl : 'Zgłoszenia',
                    sk : 'Lístky',
                    cs : 'Požadavky',
                    it : 'Ticket',
                    es : 'Incidencias',
                    fr : 'Tickets',
                },
                title: {
                    en : 'Tickets',
                    hu : 'Bejelentések',
                    de : 'Tickets',
                    nl : 'Tickets',
                    sv : 'Supportbegäranden',
                    nb : 'Ticket',
                    fi : 'Tukitapaukset',
                    ro : 'Tichete',
                    pl : 'Zgłoszenia',
                    sk : 'Lístky',
                    cs : 'Požadavky',
                    it : 'Ticket',
                    es : 'Incidencias',
                    fr : 'Tickets',
                },
                content: {
                    en : '[Create] new ticket or [view] previous ones.',
                    hu : '[Hozzon létre] új bejelentést, vagy [nézze] meg a korábbiakat.',
                    de : '[Erstellen] Sie ein neues Ticket oder [zeigen] Sie die bisherigen an.',
                    nl : '[Maak] een nieuw ticket of [bekijk] vorige tickets.',
                    sv : '[Skapa] ny supportbegäran eller [visa] föregående.',
                    nb : '[Opprett] ny ticket eller [vis] de foregående.',
                    fi : '[Luo] uusi tukitapaus tai [katso] aiempia.',
                    ro : '[Creați] un tichet nou sau [vizualizați-le] pe cele anterioare.',
                    pl : '[Utwórz] nowe zgłoszenie lub [obejrzyj] poprzednie.',
                    sk : '[Vytvorte] nový lístok alebo [si pozrite] predchádzajúce.',
                    cs : '[Vytvořte] nový požadavek nebo [si prohlédněte] předcházející.',
                    it : '[Creare] nuovi ticket o [visualizzare] i ticket precedenti.',
                    es : '[Crear] nueva incidencia o [visualizar] las anteriores.',
                    fr : '[Créer] nouveau ticket ou [voir] les précédents.',
                },
            },
            {
                image: '/media/help/001_report.png',
                thumbnail: '/media/help/001_report.png',
                title: {
                    en : 'Tickets',
                    hu : 'Bejelentések',
                    de : 'Tickets',
                    nl : 'Tickets',
                    sv : 'Supportbegäranden',
                    nb : 'Ticket',
                    fi : 'Tukitapaukset',
                    ro : 'Tichete',
                    pl : 'Zgłoszenia',
                    sk : 'Lístky',
                    cs : 'Požadavky',
                    it : 'Ticket',
                    es : 'Incidencias',
                    fr : 'Tickets',
                },
                content: {
                    en : 'Fill the fields according to the failures (first 3 are mandatory).\n\nBranch selection is only available for [branch managers].',
                    hu : 'Töltse ki a mezőket szükség szerint (első 3 kötelező).\n\nAz üzletválasztás kizárólag [üzletvezetők] számára érhető el.',
                    de : 'Füllen Sie die Felder gemäß den Störungen aus (die ersten 3 sind Pflichtfelder).\n\nFilialauswahl ist nur für [Filialleiter verfügbar].',
                    nl : 'Vul de velden in op basis van de fouten (eerste 3 zijn vereist). \n\nFiliaalselectie is alleen beschikbaar voor [filiaalmanagers].',
                    sv : 'Fyll i fälten enligt felen (första 3 är obligatoriska).\n\nFilialval är endast tillgängliga för [filialchefer]. ',
                    nb : 'Fyll ut feltene i henhold til feilene (de første 3 er obligatoriske).\n\nValg av filial er bare tilgjengelig for [filialsjefer].',
                    fi : 'Täytä kentät virheiden mukaan (ensimmäiset 3 ovat pakollisia).\n\nToimipisteen valinta on vain [toimipistejohtajien] käytettävissä. ',
                    ro : 'Completați câmpurile în funcție de eșecuri (primele 3 sunt obligatorii).\n\nSelectarea departamentului este disponibilă doar pentru [directorii de departament]. ',
                    pl : 'Wypełnij pola w zależności od błędów (pierwsze 3 są obowiązkowe).\n\nWybór oddziału jest dostępny jedynie dla [kierowników oddziałów]. ',
                    sk : 'Vyplňte polia podľa nedostatkov (prvé 3 sú povinné).\n\nVýber pobočky je dostupný len pre [vedúcich pobočiek]. ',
                    cs : 'Vyplňte pole podle nedostatků (první 3 jsou povinné).\n\nVýběr pobočky je dostupný jen pro [vedoucí poboček]. ',
                    it : 'Completare i campi in base agli insuccessi (i primi 3 sono obbligatori).\n\nLa scelta della filiale è abilitata solo per i [direttori di filiale]. ',
                    es : 'Complete los campos de acuerdo con las fallas (las primeras 3 son obligatorias).\n\nTambién se dispone de selección de sucursal para los [gerentes de sucursal]. ',
                    fr : 'Remplissez les champs en fonction des échecs (les 3 premiers sont des mandrins).\n\nLa sélection de branche n\'est disponible que pour [directeurs de succursale].',
                },
            },
            {
                image: '/media/help/001_help.png',
                thumbnail: '/media/help/001_help.png',
                head: {
                    en : 'Help',
                    hu : 'Segítség',
                    de : 'Hilfe',
                    nl : 'Hjelp',
                    sv : 'Hjälp',
                    nb : 'Hjelp',
                    fi : 'Apu',
                    ro : 'Ajutor',
                    pl : 'Pomoc',
                    sk : 'Pomoc',
                    cs : 'Pomoc',
                    it : 'Aiuto',
                    es : 'Ayuda',
                    fr : 'Aide',
                },
                title: {
                    en : 'Help',
                    hu : 'Segítség',
                    de : 'Hilfe',
                    nl : 'Hjelp',
                    sv : 'Hjälp',
                    nb : 'Hjelp',
                    fi : 'Apu',
                    ro : 'Ajutor',
                    pl : 'Pomoc',
                    sk : 'Pomoc',
                    cs : 'Pomoc',
                    it : 'Aiuto',
                    es : 'Ayuda',
                    fr : 'Aide',
                },
                content: {
                    en : 'Check out the different functions of the app as pages.\n\nTap on the {i} button, to activate the [About] screen.',
                    hu : 'Nézze végig az app különböző funkcióit lapozó nézetben.\n\nA [Rólunk] felület megjelenítéséhez, koppintson az {i} gombra.',
                    de : 'Die einzelnen Funktionen der App in Seitenform kennenlernen.\n\nZum Anzeigen der Seite [Firmeninfos] auf {i} tippen.',
                    nl : 'De verschillende functies van de app in paginaweergave bekijken.\n\nOp de {i}-knop tikken om het scherm [Info] te openen.',
                    sv : 'Kolla in de olika funktionerna i appen som sidor.\n\nTryck på knappen {i} för att aktivera skärmen [Om].',
                    nb : 'Se appens forskjellige funksjoner som sider.\n\nTrykk på {i}-knappen for å aktivere [Om]-skjermen.',
                    fi : 'Tarkastele sovelluksen eri toimintoja sivuina.\n\nAvaa [Tietoja] painamalla {i}.',
                    ro : 'Consultarea diferitelor funcții ale aplicației sub formă de pagini.\n\nApăsați butonul {i} pentru a activa ecranul [Despre].',
                    pl : 'Sprawdź różne funkcje aplikacji jako stron.\n\nNaciśnij przycisk {i}, aby aktywować ekran [Informacje].',
                    sk : 'Pozrite si rôzne funkcie aplikácie usporiadané vo forme stránok.\n\nŤuknutím na tlačidlo {i} prejdete na obrazovku [Informácie].',
                    cs : 'Seznamte se s různými funkcemi aplikace v podobě stránek.\n\nKlepnutím na ikonu {i} zobrazíte část [O nás].',
                    it : 'Nelle seguenti pagine sono elencate le diverse funzioni dell\'app.\n\nToccare il pulsante {i} per attivare la schermata [Informazioni].',
                    es : 'Descubrir las distintas funciones de la aplicación en formato de páginas.\n\nPulse el botón {i} para activar la pantalla [Acerca de].',
                    fr : 'Consulter les différentes fonctions de l\'application sous forme de pages.\n\nToucher le bouton {i} pour activer l\'écran [A propos].',
                },
            },
            {
                image: '/media/help/002_help.png',
                thumbnail: '/media/help/002_help.png',
                title: {
                    en : 'Help',
                    hu : 'Segítség',
                    de : 'Hilfe',
                    nl : 'Hjelp',
                    sv : 'Hjälp',
                    nb : 'Hjelp',
                    fi : 'Apu',
                    ro : 'Ajutor',
                    pl : 'Pomoc',
                    sk : 'Pomoc',
                    cs : 'Pomoc',
                    it : 'Aiuto',
                    es : 'Ayuda',
                    fr : 'Aide',
                },
                content: {
                    en : 'Check out the different functions of the app as a list.\n\n[Filter] the functions by writing in the search bar.',
                    hu : 'Nézze végig az app különböző funkcióit lista nézetben.\n\n[Szűrje] a funkciókat a keresés mezőbe írva.',
                    de : 'Die einzelnen Funktionen der App in Listenform kennenlernen.\n\nFunktionen über die Suchliste [filtern].',
                    nl : 'De verschillende functies van de app in lijstweergave bekijken.\n\nDe functies [filteren] door tekst in de zoekbalk te typen.',
                    sv : 'Kolla in de olika funktionerna i appen som en lista.\n\n[Filtrera] funktionerna genom att skriva i sökfältet.',
                    nb : 'Se appens forskjellige funksjoner som en liste.\n\n[Filtrer] funksjonene ved å skrive i søkefeltet.',
                    fi : 'Tarkastele sovelluksen eri toimintoja luettelona.\n\n[Suodata] toimintoja kirjoittamalla hakuriville.',
                    ro : 'Consultarea diferitelor funcții ale aplicației sub formă de listă.\n\n[Filtrați] funcțiile completând în bara de căutare.',
                    pl : 'Sprawdź różne funkcje aplikacji jako listy.\n\n[Filtruj] funkcje, wpisując je w pasku wyszukiwania.',
                    sk : 'Pozrite si rôzne funkcie aplikácie usporiadané vo forme zoznamu.\n\nFunkcie môžete [filtrovať] zadaním textu na vyhľadávacom paneli.',
                    cs : 'Seznamte se s různými funkcemi aplikace v podobě seznamu.\n\nFunkce můžete [filtrovat] pomocí vyhledávacího řádku.',
                    it : 'Di seguito sono elencate le diverse funzioni dell\'app.\n\n[Filtra] le funzioni scrivendo nella barra di ricerca.',
                    es : 'Descubrir las distintas funciones de la aplicación en una lista.\n\nEscriba en la barra de búsqueda para [filtrar] las funciones.',
                    fr : 'Consulter les différentes fonctions de l\'application sous forme de liste.\n\n[Filtrer] les fonctions en tapant dans la barre de recherche.',
                },
            },
        ]
    };

    _hint = {
        uv: 
            {
            _default: 'localizeDashboardHelpDetailDefault',
            dashboard: 'localizeDashboardHelpBasicUV',
            breakdown: 'localizeDashboardHelpDetailUV',
            analytics: 'localizeAnalyticsHelpBasic',
            camera: 'localizeDashboardHelpCameraUV',
        },
        tv: 
            {
            _default: 'localizeDashboardHelpDetailDefault',
            dashboard: 'localizeDashboardHelpBasicTV',
            breakdown: 'localizeDashboardHelpDetailDefault',
            analytics: 'localizeAnalyticsHelpWithChildren',
            camera: '',
        },
        rv: 
            {
            _default: 'localizeDashboardHelpDetailDefault',
            dashboard: 'localizeDashboardHelpBasicRV',
            breakdown: 'localizeDashboardHelpDetailRV',
            analytics: 'localizeAnalyticsHelpWithChildren',
            camera: '',
        },
        hq: 
            {
            _default: 'localizeDashboardHelpDetailDefault',
            dashboard: 'localizeDashboardHelpBasicHQ',
            breakdown: 'localizeDashboardHelpDetailDefault',
            analytics: 'localizeAnalyticsHelpWithChildren',
            camera: '',
        },
    };

    _tour = {
        pages: [
            {
                image: '/media/tour/new/help_new_welcome_1_en.png',
                title: {
                    en : 'Welcome to QBoard!',
                    hu : 'Üdvözöljük a QBoard-ban!',
                    de : 'Willkommen beim QBoard!',
                    nl : 'Welkom bij QBoard.',
                    sv : 'Välkommen till QBoard!',
                    nb : 'Velkommen til QBoard!',
                    fi : 'Tervetuloa QBoardiin!',
                    ro : 'Bine ați venit la QBoard!',
                    pl : 'Witaj w QBoard!',
                    sk : 'Víta vás QBoard.',
                    cs : 'Vítá vás QBoard!',
                    it : 'Benvenuto in QBoard!',
                    es : 'Le damos la bienvenida a QBoard.',
                    fr : 'Bienvenue dans QBoard !',
                },
                content: {
                    en : 'In dynamic businesses QBoard can help keep the Customer Experience on the highest level, by providing real-time information and allowing to respond to the changes directly from the App.',
                    hu : 'A dinamikusan változó üzleti életben a QBoard alkalmazás a valós idejű információk szolgáltatásával és a változásokra adandó válaszlehetőségekkel segít az Ügyfél Élmény legmagasabb szinten tartásában.',
                    de : 'In dynamischen Unternehmen kann das QBoard dazu beitragen, die Customer Experience auf höchstem Niveau zu halten. Es zeigt Echtzeitinformationen an, sodass Sie direkt über die App auf Änderungen reagieren können.',
                    nl : 'In dynamische bedrijven kan QBoard helpen de klantervaring op het hoogste niveau te houden, door realtime informatie te leveren en doordat er direct vanuit de app op de wijzigingen kan worden gereageerd.',
                    sv : 'QBoard kan hjälpa dynamiska företag att bibehålla en kundupplevelse i toppklass genom att tillhandahålla information i realtid och tillåta respons på förändringar direkt i appen.',
                    nb : 'I dynamiske forretninger kan QBoard hjelpe til med å holde kundeopplevelsen på det høyeste nivået ved å levere informasjon i sanntid og muliggjør en reaksjon på endringene direkte fra appen.',
                    fi : 'Dynaamisessa liiketoiminnassa Qboard auttaa pitämään asiakaskokemuksen korkeimmalla tasolla tarjoten tosiaikaisia tietoja ja kyvyn vastata muutoksiin suoraan sovelluksesta.',
                    ro : 'În cadrul afacerilor dinamice, QBoard poate ajuta la menținerea celui mai ridicat nivel al experienței clienților, furnizând informații în timp real și permițând răspunsul la modificări direct din aplicație.',
                    pl : 'System QBoard może pomóc dynamicznym firmom utrzymać doświadczenie klienta na najwyższym poziomie, dostarczając informacje w czasie rzeczywistym i umożliwiając reagowanie na zmiany bezpośrednio z poziomu aplikacji.',
                    sk : 'V dynamických podnikoch môže QBoard pomôcť udržiavať zákaznícke služby na najvyššej úrovni tým, že poskytuje informácie v reálnom čase a umožňuje reagovať na zmeny priamo v aplikácii.',
                    cs : 'Služba QBoard pomáhá dynamickým firmám udržovat zákaznické služby na co nejvyšší úrovni. Poskytuje informace v reálném čase a umožňuje reagovat na změny přímo z aplikace.',
                    it : 'Nelle aziende dinamiche, QBoard può contribuire a mantenere l\'esperienza del cliente al massimo livello, fornendo informazioni in tempo reale e consentendo di rispondere alle modifiche direttamente dall\'App.',
                    es : 'QBoard ayuda a empresas dinámicas a mantener el máximo nivel de experiencia del cliente. Esto se consigue gracias al suministro de información en tiempo real y a la posibilidad de responder a los cambios directamente desde la aplicación.',
                    fr : 'Dans les entreprises dynamiques QBoard peut contribuer à maintenir la satisfaction des clients au niveau le plus élevé, en fournissant des informations en temps réel et en permettant de répondre aux évolutions directement depuis l\'application.',
                },
            },
            {
                image: '/media/tour/new/help_new_BI_1_en-xperion.png',
                title: {
                    en : 'Respond to changes fast',
                    hu : 'Reagáljon gyorsan a változásokra',
                    de : 'Reagieren Sie schnell auf Änderungen',
                    nl : 'Reageer snel op wijzigingen',
                    sv : 'Svara snabbt på förändringar',
                    nb : 'Reager raskt på endringer',
                    fi : 'Reagoi muutoksiin nopeasti',
                    ro : 'Răspundeți rapid la modificări',
                    pl : 'Reaguj szybko na zmiany',
                    sk : 'Reagujte rýchlo na zmeny',
                    cs : 'Rychle reagujte na změny',
                    it : 'Rispondere rapidamente ai cambiamenti',
                    es : 'Responda rápido a los cambios',
                    fr : 'Répondre rapidement aux changements',
                },
                content: {
                    en : 'Receive and display almost [real-time] information about the [KPIs] of your business.\n\nRespond fast by contacting the appropriate user from the app.',
                    hu : 'Közel [valós időben] fogadhatja és jelenítheti meg a vállalkozásához tartozó [teljesítménymutatókat].\n\nReagáljon gyorsan, és értesítse a megfelelő felhasználót az alkalmazásból.',
                    de : 'Empfangen und Anzeigen von nahezu [Echtzeit]-Informationen zu [KPIs] Ihres Unternehmens.\n\nReagieren Sie schnell, indem Sie den passenden Nutzer über die App kontaktieren',
                    nl : 'Ontvang en toon bijna [real-time] informatie weer over de [KPIs] van uw bedrijf.\n\nReageer snel door contact op te nemen met de juiste gebruiker via de app.',
                    sv : 'Ta emot och visa [i realtid] information om [KPIs] för ditt företag.\n\nSvara snabbt, genom att kontakta lämplig användare av appen.',
                    nb : 'Motta og vis nesten [i sanntid] informasjon om [KPIene] i bedriften din.\n\nSvar raskt, ved å kontakte den riktige brukeren fra appen.',
                    fi : 'Vastaanota ja näytä lähes [reaaliaikaista] tietoa yrityksesi [keskeisistä suoritusindikaattoreista].\n\nReagoi nopeasti ottamalla yhteyttä asiaankuuluvaan käyttäjään sovelluksesta.',
                    ro : ' Primește și afișează aproape [în timp real] informații despre [indicatorii de performanță] ai afacerii Dumneavoastră.\n\nRăspundeți rapid, contactând utilizatorul corespunzător din aplicație.',
                    pl : 'Otrzymuj i wyświetlaj informacje o [kluczowych wskaźnikach efektywności] Twojego biznesu niemal [w czasie rzeczywistym].\n\nReaguj szybko poprzez kontakt z odpowiednim użytkownikiem za pomocą aplikacji',
                    sk : 'Prijímať a zobraziť informácie o [kľúčových ukazovateľoch výkonnosti] Vášho podniku takmer [v reálnom čase].\n\nReagujte rýchlo, kontaktujte príslušného užívateľa z aplikácie.',
                    cs : 'Přijímat a zobrazit informace o [klíčových ukazatelích výkonnosti] Vašeho podniku téměř [v reálném čase].\n\nReagujte rychle, kontaktujte příslušného uživatele z aplikace.',
                    it : 'Ricevere e mostrare quasi [in tempo reale] le informazioni sui [KPI] della sua impresa.\n\nRispondere rapidamente, contattando l\'utente appropriato dell\'app.',
                    es : 'Recibir y mostrar información casi en  [tiempo real] sobre los [KPI] de su negocios.\n\nResponda rápido, contactando al usuario apropiado de la app.',
                    fr : 'Recevoir et afficher des informations presque [en temps réel] sur les [KPI] de votre entreprise.\n\nRépondez rapidement, en contactant l\'utilisateur approprié de l\'application.',
                },
            },
            {
                image: '/media/tour/new/help_new_ticket_1_en-xperion.png',
                title: {
                    en : 'Support request',
                    hu : 'Hibabejelentés',
                    de : 'Supportanfrage',
                    nl : 'Ondersteuningsverzoek',
                    sv : 'Supportbegäran',
                    nb : 'Forespørsel om support',
                    fi : 'Tukipyyntö',
                    ro : 'Solicitare suport',
                    pl : 'Prośba o pomoc',
                    sk : 'Požiadať o podporu',
                    cs : 'Požádat o podporu',
                    it : 'Richiesta di assistenza',
                    es : 'Pedido de soporte',
                    fr : 'Demande de support',
                },
                content: {
                    en : 'Send support requests directly from the app.',
                    hu : 'Küldjön hibabejelentést közvetlenül az alkalmazásból.',
                    de : 'Senden Sie Support-Anfragen direkt über die App',
                    nl : 'Stuur ondersteuningsverzoeken rechtstreeks vanuit de app.',
                    sv : 'Skicka supportbegäran direkt från appen.',
                    nb : 'Send supportforespørsler direkte fra appen.',
                    fi : 'Lähetä tukipyyntöjä suoraan sovelluksesta.',
                    ro : 'Trimiteți solicitare suport direct din aplicație.',
                    pl : 'Wyślij prośbę o pomoc bezpośrednio z aplikacji.',
                    sk : 'Pošlite požiadavku o podporu priamo z aplikácie.',
                    cs : 'Pošlete požadavek na podporu přímo z aplikace.',
                    it : 'Inviare le richieste di assistenza direttamente dall\'app.',
                    es : 'Enviar pedido de soporte directamente de la app.',
                    fr : 'Envoyer des demandes de support directement à partir de l\'application.',
                },
            },
        ]
    };

    static get authKey() {
        return new Config()._authKey;
    }

    static get transportSecret() {
        return new Config()._transportSecret;
    }

    static get apiTimeOffset() {
        return process.env.REACT_APP_API_TIMEOFFSET;
    }

    static get apiUrl() {
        return process.env.REACT_APP_API_URL;
    }

    static get apiTimeFormat() {
        return new Config()._apiTimeFormat;
    }

    static get apiTimeout() {
        return new Config()._apiTimeout;
    }

    static get dateTimeDisplayFormat() {
        return new Config()._dateTimeDisplayFormat;
    }
    static get onlyDateDisplayFormat() {
        return new Config()._onlyDateDisplayFormat;
    }
    static get onlyTimeDisplayFormat() {
        return new Config()._onlyTimeDisplayFormat;
    }

    static get legalDateDisplayFormat() {
        return new Config()._legalDateDisplayFormat;
    }

    static get graphDefaults() {
        return new Config()._graphDefaults;
    }

    static get graphColors() {
        return new Config()._graphColors;
    }

    static get appearance() {
        return new Config()._appearance;
    }

    static get disabledComponents() {
        return new Config()._disabledComponents;
    }

    static get googleMapEnabled() {
        return ! Config.disabledComponents.includes('MetricProxyServedCustomersAndAverageWaitingTime');
    }

    static get help() {
        return new Config()._help;
    }

    static get hint() {
        return new Config()._hint;
    }

    static get tour() {
        return new Config()._tour;
    }

    static get tokenRefreshInterval() {
        return new Config()._tokenRefreshInterval;
    }

    static get userprofileCheckInterval() {
        return new Config()._userprofileCheckInterval;
    }

    static get dataRefreshInterval() {
        return new Config()._dataRefreshInterval;
    }
}

export default Config;
