import React from 'react';
import { MetricDisplayAverageWaitingTime } from './';
import { useTranslation } from 'react-i18next';
import { QbTools } from '../modules';

function MetricProxyRmAverageWaitingTime(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();

    var data = {
        worstName: '',
        worstValue: NaN,
        regName: '',
        regValue: NaN,
        hqName: '',
        hqValue: NaN,
    };

    if (QbTools.metricDataReady(metricData, [ 'current', 'children', 'top' ] )) {
        for (const [childName, child] of Object.entries(metricData.children.data)) {
            if (isNaN(data.worstValue) || (child['VI'] > data.worstValue)) {
                data.worstName = childName;
                data.worstValue = child['VI'];
            }
        }
        data.regName = Object.keys(metricData.current.data)[0];
        data.regValue = metricData.current.data[data.regName]['VI'];
        data.hqName = Object.keys(metricData.top.data)[0];
        data.hqValue = metricData.top.data[data.hqName]['VI'];
    }

    var displayData = {
        graph1Title: QbTools.apiNameToFriendlyName(data.worstName),
        graph1Subtitle: t('localizeWorst'),
        graph1Value: data.worstValue,

        graph2Title: QbTools.apiNameToFriendlyName(data.regName),
        graph2Value: data.regValue,

        graph3Title: QbTools.apiNameToFriendlyName(data.hqName),
        graph3Value: data.hqValue,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'current', 'children', 'top' ], t),
    };

    return (
        <MetricDisplayAverageWaitingTime displayData={ displayData } { ...props } />
    );
}

export default MetricProxyRmAverageWaitingTime;
