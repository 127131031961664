import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "react-image-lightbox/style.css";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import './index.css';
import './qboard.css';
import './custom-drawer.css';
import './i18n.js';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<>
		<NotificationContainer/>
		<App/>
	</>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
