import {ConfirmationModal} from "./index";
import {RiPencilLine} from 'react-icons/ri';
import {useTranslation} from 'react-i18next';
import {SubmitButton, TextField} from './form';
import {useHistory} from 'react-router-dom';
import {MdLanguage, MdSecurity} from 'react-icons/md';
import {Form, Formik} from 'formik';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import {AppStateContext, Auth, QbApi, QbApiTimeoutError, QbTools, Config} from '../modules';
import ProfilePlaceholder from '../img/profile-placeholder.png';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {isEmpty} from 'lodash';
import * as yup from 'yup';
import {UpperNavigation} from './';
import {showMessage} from "../utilities/UiUtils";

const transformUserInfo = (userProfile) => {
	return {
		lastName: QbTools.fullNameSplit(userProfile.chanonicalName.fullName)[0],
		firstName: QbTools.fullNameSplit(userProfile.chanonicalName.fullName)[1],
		email: userProfile.contactInfo.email,
		phone: userProfile.contactInfo.phone,
		mobile: userProfile.contactInfo.mobile,
		branches: {},
		groups: {},
	};
}

function SettingsProfile() {
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
	let history = useHistory();
	const formikInnerRef = useRef();
	const editImageInputRef = useRef();
	const appState = useContext(AppStateContext);
	const [ isUnsavedProfileModal, setUnsavedProfileModal ] = useState(false);
	const [ isGoToLanguageSettingsModal, setGoToLanguageSettings ] = useState(false);
	const [ isGoToPrivacySettingsModal, setGoToPrivacySettings ] = useState(false);
	const [ isUnsavedBranchModal, setUnsavedBranchModal ] = useState(false);
	
	const [ selectedBranchInfo, setSelectedBranchInfo ] = useState({});
	const [ uploadImageBase64, setUploadedImageBase64 ] = useState('');
	//const [ ajaxInProgress, setAjaxInProgress ] = useState(false);
	
	
	const showUnsavedProfileModal = () => setUnsavedProfileModal(true)
	const hideUnsavedProfileModal = () => setUnsavedProfileModal(false)
	
	const showGoToLanguageSettingsModal = () => setGoToLanguageSettings(true)
	const hideGoToLanguageSettingsModal = () => setGoToLanguageSettings(false)
	
	const showGoToPrivacySettingsModal = () => setGoToPrivacySettings(true)
	const hideGoToPrivacySettingsModal = () => setGoToPrivacySettings(false)
	
	const showUnsavedBranchModal = () => setUnsavedBranchModal(true)
	const hideUnsavedBranchModal = () => setUnsavedBranchModal(false)
	
	useEffect(() => {
		appState.setActivePage('settings');
	});
	
	const goBackPressHandler = () => {
		if (formikInnerRef.current.dirty || !isEmpty(uploadImageBase64)) {
			return showUnsavedProfileModal()
		}
		return history.goBack()
	}
	
	const navigateToLanguageSettings = () => {
		if (!formikInnerRef.current.dirty) {
			return history.push('/settings/language')
		}
		return showGoToLanguageSettingsModal()
	}
	
	const navigateToPrivacySettings = () => {
		if (!formikInnerRef.current.dirty) {
			return history.push('/settings/privacy')
		}
		return showGoToPrivacySettingsModal()
	}
	
	const onBranchItemClickHandler = (item) => {
		if (formikInnerRef?.current?.dirty) {
			setSelectedBranchInfo(item)
			return showUnsavedBranchModal()
		}
		history.push(`/settings/branch/${ item.id }`)
	}
	
	const navigateToBranchHandler = () => {
		if (isEmpty(selectedBranchInfo)) {
			return
		}
		const {id} = selectedBranchInfo || {}
		history.push(`/settings/branch/${ id }`)
	}
	
	const clickEditImageIconHandler = () => {
		editImageInputRef.current.click();
	}
	
	const uploadProfileImageHandler = ({target = {}} = {}) => {
		const reader = new FileReader();
		reader.readAsDataURL(target?.files?.[0]);
		reader.onload = async function () {
			const profileImage = reader?.result
			const profileImageStripped = profileImage.replace(/^data:image\/[^;,]+;base64,/, '');
			setUploadedImageBase64(profileImageStripped)
		};
		reader.onerror = function (error) {
		};
	}
	
	
	let userProfile = Auth.getData().userProfile;
	let userMerged = Auth.getData().userMerged;
	let userData = Auth.getData().user;
	const topHierarchyLevel = userData.topHierarchyLevel.toLowerCase();
	let formValues = transformUserInfo(userProfile)
	let placeholders = transformUserInfo(userData)
	
	var branchList = [];
	var groupList = [];
	
	if (topHierarchyLevel === 'uv') {
		branchList.push(
			<div key="header" className="row py-1 title setting-item">
				<div className="col-12">
					<h4 className="font-weight-light mx-3">{ t('localizeSettingsSectionStores') }</h4>
				</div>
			</div>
		);
		for (const item of Object.values(userMerged.branches)) {
			formValues.branches[item.id] = item.name;
			branchList.push(
				<div key={ item.id } to={ '/settings/branch/' + item.id } className='qboard-link'
				     onClick={ () => onBranchItemClickHandler(item) }>
					<div className="row align-items-center py-1 mb-2 setting-item">
						<div className="col-12 px-5">
							<div className="row settings-branch-item">
								<div className="col-10">{ item.name }</div>
								<div className="col-2 text-right">
									<BsChevronRight className='icon-without-bg'/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	} else {
		groupList.push(
			<div key="header" className="row py-1 title setting-item">
				<div className="col-12">
					<h4 className="font-weight-light mx-3">{ t('localizeSettingsSectionGroups') }</h4>
				</div>
			</div>
		);
		for (const item of Object.values(userProfile.groups)) {
			const placeholderItem = Auth.getData().user.groups.filter(userItem => Number(userItem.id) === Number(item.id))[0];
			formValues.groups[item.id] = item.name;
			groupList.push(
				<TextField id={ item.id } name={ 'groups.' + item.id } placeholder={ placeholderItem.name } forGroups
				           isClearable/>
			);
		}
	}
	
	let userprofileImage = null;
	const authUserprofileImage = Auth.getItem('userprofileImage')
	if ((authUserprofileImage !== null) && authUserprofileImage.hasOwnProperty('data') && (authUserprofileImage.data !== '')) {
		userprofileImage = 'data:image/jpeg;base64,' + authUserprofileImage.data;
	}
	
	if (!isEmpty(uploadImageBase64)) {
		userprofileImage = 'data:image/jpeg;base64,' + uploadImageBase64
	}
	
	async function formSubmit(formValues, {resetForm,setSubmitting}) {
        setSubmitting(true);
		let userProfile = {
			chanonicalName: {
				fullName: QbTools.fullNameJoin(formValues.lastName, formValues.firstName),
			},
			contactInfo: {
				email: formValues.email,
				phone: formValues.phone,
				mobile: formValues.mobile,
			}
		};
		if (!isEmpty(uploadImageBase64)) {
			Auth.setItem('userprofileImage', {data: uploadImageBase64});
		}
		if (Object.keys(formValues.groups).length > 0) {
			userProfile.groups = [];
			for (const [ id, name ] of Object.entries(formValues.groups)) {
				userProfile.groups.push({
					id: id,
					name: name,
				});
			}
		}
		const updatedUserProfile = Auth.calcUserProfile(userProfile);
		
		const params = {userProfile: updatedUserProfile}
		if (!isEmpty(uploadImageBase64)) {
			params.userProfile.photo = uploadImageBase64
        }
        try {
			await QbApi.instance.updateUserProfile(appState, params);
            Auth.updateUserProfile(userProfile);
            resetForm({values: formValues})
            setUploadedImageBase64('')
			showMessage({title:webappT("toastTitleSuccess"),message:webappT("toastDescriptionSuccessUpdate")})
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedUpdate"), type: 'error' })
			} else {
				throw error;
			}
		} finally {
			setSubmitting(false)
        }
	}
	
	const profileImageContainerStyle = {
		height: Config.appearance.settingsImageHeightWidth,
		width: Config.appearance.settingsImageHeightWidth,
	}
	
	const validation = {
		phone: /(^\+{1}[0-9 ]*$)/,
	}
	
	let enableSubmit = false;
	
	
	// TODO: form validator, pl. https://formik.org/docs/api/formik alapján - amennyiben formik-nál maradunk
	return (
		<React.Fragment>
			<div className={ (Config.appearance.enableStickyTop) ? "sticky-top" : "" }>
				<UpperNavigation
					leftSide={
						<div className="qboard-link" onClick={ goBackPressHandler }>
							<BsChevronLeft className="icon-with-bg float-none ml-1"/>
						</div>
					}
					middle={
						t('localizeUserSettingsTitle')
					}
				/>
			</div>
			<div className="settings profile-form-input body_container">
				<div className="container px-0">
					<Formik
						innerRef={ formikInnerRef }
						initialValues={ formValues }
						onSubmit={ formSubmit }
						validationSchema={ yup.object().shape({
							phone: yup.string()
								.matches(
									validation.phone,
									webappT('invalidPhone')
								),
							mobile: yup.string()
								.matches(
									validation.phone,
									webappT('invalidPhone')
								),
							email: yup.string()
								.email(webappT('invalidEmail')),
						}) }>
						{ ({dirty, isValid, isSubmitting, setFieldValue}) => {
							return <Form>
								<div className="row">
									<div className="col-12 d-flex justify-content-center">
										<div className="profile-image-with-edit">
											<div style={ profileImageContainerStyle } className="profile-image-container">
												{ userprofileImage !== null ?
													<img alt="User face" src={ userprofileImage } className="settings-profile-image"
													     height="100%"/>
													:
													<img alt="User face" src={ ProfilePlaceholder } className="settings-profile-image"
													     height="100%"/>
												}
												<input type='file'
												       className='d-none'
												       ref={ editImageInputRef }
												       accept="image/x-png,image/gif,image/jpeg"
												       onChange={ uploadProfileImageHandler }/>
											</div>
											<RiPencilLine style={{ backgroundColor: Config.appearance.mainColor, }} className="profile-image-edit" onClick={ clickEditImageIconHandler }/>
										</div>
									</div>
								</div>
								<TextField name="firstName" label={ t('localizeUserFirstName') } isClearable
								           placeholder={ placeholders?.firstName } setFieldValue={setFieldValue}
								/>
								<TextField name="lastName" label={ t('localizeUserLastName') } isClearable
								           placeholder={ placeholders?.lastName } setFieldValue={setFieldValue}/>
								<TextField name="email" label={ t('localizeUserEmail') } isClearable
								           placeholder={ placeholders?.email } setFieldValue={setFieldValue}/>
								<TextField name="phone" label={ t('localizeUserPhone') } pattern="[+][0-9 ]+"
								           isClearable
								           placeholder={ placeholders?.phone } setFieldValue={setFieldValue}/>
								<TextField name="mobile" label={ t('localizeUserMobile') } pattern="[+][0-9 ]+"
								           isClearable
								           placeholder={ placeholders?.mobile } setFieldValue={setFieldValue}/>
								{ (groupList.length > 0) ?
									<div className="section">
										<div key="header" className="row py-1 title setting-item">
                                            <div className="col-12">
                                                <h4 className="font-weight-light mx-3">{ t('localizeSettingsSectionGroups') }</h4>
                                            </div>
                                        </div>
                                        {Object.values(userProfile.groups).map((item) => {
                                                return <TextField id={ item.id } name={ 'groups.' + item.id } placeholder={ Auth.getData().user.groups.filter(userItem => Number(userItem.id) === Number(item.id))[0].name } forGroups
                                                isClearable setFieldValue={ setFieldValue }/>
                                            })}
									</div>
									: ''
								}
								{ (branchList.length > 0) ?
									<div className="section">
										{ branchList }
									</div>
									: ''
								}
								<div className="section">
									
									<div className="row py-1 title setting-item">
										<div className="col-12">
											<h4 className="font-weight-light mx-3">{ t('localizeSettingsSectionOther') }</h4>
										</div>
									</div>
									<div className="qboard-link" onClick={ navigateToLanguageSettings }>
										<div className="row align-items-center py-1 mb-2 setting-item">
											<div className="col-12 px-5">
												<div className="row settings-branch-item">
													<div className="col-10 d-flex align-items-center"><MdLanguage
														style={{ backgroundColor: Config.appearance.mainColor, }} className="other-settings-icon-left"/>{ t('localizeLanguageName') }
													</div>
													<div className="col-2 text-right">
														<BsChevronRight className='icon-without-bg'/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="qboard-link" onClick={ navigateToPrivacySettings }>
										<div className="row align-items-center py-1 mb-2 setting-item">
											<div className="col-12 px-5">
												<div className="row settings-branch-item">
													<div className="col-10 d-flex align-items-center"><MdSecurity
														style={{ backgroundColor: Config.appearance.mainColor, }} className="other-settings-icon-left"/>{ t('localizePrivacy') }
													</div>
													<div className="col-2 text-right">
														<BsChevronRight className='icon-without-bg'/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{
									(isValid) ?
										((dirty || !isEmpty(uploadImageBase64)) && !isSubmitting) ?
											enableSubmit = true
											:
											enableSubmit = false
										:
										enableSubmit = false
								}
								<SubmitButton disabled={ !enableSubmit }/>
							</Form>
						} }
					</Formik>
				</div>
			</div>
			<ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoBack') }
				show={ isUnsavedProfileModal }
				onHide={ hideUnsavedProfileModal }
				onDone={ () => history.goBack() }/>
			<ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoToLanguage') }
				show={ isGoToLanguageSettingsModal }
				onHide={ hideGoToLanguageSettingsModal }
				onDone={ () => history.push('/settings/language') }/>
			<ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoToPrivacy') }
				show={ isGoToPrivacySettingsModal }
				onHide={ hideGoToPrivacySettingsModal }
				onDone={ () => history.push('/settings/privacy') }/>
			<ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoToBranch') }
				show={ isUnsavedBranchModal }
				onHide={ hideUnsavedBranchModal }
				onDone={ navigateToBranchHandler }/>
		</React.Fragment>
	);
}

export default SettingsProfile;
