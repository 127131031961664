import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Auth, Config, QbApiCache, QbTools } from '../modules';
import { AppStateContext } from '../modules';
import { AnalyticsHq, AnalyticsRm, AnalyticsSrm, AnalyticsBm } from './';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { FaCaretDown } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import { UpperNavigation, LowerNavigation } from './';
import { IconContext } from "react-icons";
import moment from 'moment-timezone';

function Analytics() {
    const { t, i18n } = useTranslation();
    const lng = i18n.language;
    const appState = useContext(AppStateContext);
    const [ refreshCounter, setRefreshCounter ] = useState(0);
    const match = useRouteMatch('/analytics/:duration/:id');
    useEffect( () => {
        appState.setActivePage('analytics');
    } );
    useEffect( () => {
        const timer = setInterval(() => {
            incRefreshCounter();
        }, Config.dataRefreshInterval * 1000);

        return () => clearInterval(timer);
    }, [ refreshCounter ] );
    function incRefreshCounter() {
        setRefreshCounter(refreshCounter => refreshCounter + 1);
    }

    const userMerged = Auth.getData().userMerged;
    const groups = userMerged.groups;
    const topHierarchyLevel = userMerged.topHierarchyLevel.toLowerCase();

    //const to = QbTools.nowApiTimeOffset().set('second', 0);
    const to = moment().set('second', 0);

    const durationList = {
        day:    { key: 'day',   name: t('localizeAnalyticsMenuDayTitle'),    shortName: 'DA',    timeResolution: 'HOURLY',   startOffset: -1,   offsetUnit: 'day',   },
        week:   { key: 'week',  name: t('localizeAnalyticsMenuWeekTitle'),   shortName: 'WE',    timeResolution: 'DAILY',    startOffset: -7,   offsetUnit: 'day',   },
        month:  { key: 'month', name: t('localizeAnalyticsMenuMonthTitle'),  shortName: 'MO',    timeResolution: 'DAILY',    startOffset: -1,   offsetUnit: 'month', },
    };
    const analyticsComponents = {
        hq: AnalyticsHq,
        rv: AnalyticsRm,
        tv: AnalyticsSrm,
        uv: AnalyticsBm,
    };
    const [ componentList, setComponentList ] = useState( {
        items: { },
        header: {
            title: Object.values(Auth.getData().userMerged.groups).find(group => group.id === match?.params?.id)?.name,
            subTitle: t(durationList[match.params?.duration]?.name),
        },
    } );
    if (! durationList.hasOwnProperty(match.params.duration)) {
        return ( <Redirect to={ '/analytics/' + Object.keys(durationList)[0] + '/' + Object.keys(groups)[0] } /> );
    }
    if (! groups.hasOwnProperty(match.params.id)) {
        return ( <Redirect to={ '/analytics/' + Object.keys(durationList)[0] + '/' + Object.keys(groups)[0] } /> );
    }
    const durationInfo = durationList[match.params.duration];

    var DisplayComponent = analyticsComponents[topHierarchyLevel];
    var durationSelector = [ ];
    let currentDuration;

    const activeDropdownItem = {
        color: Config.appearance.mainColor,
    }
    for (const [, item] of Object.entries(durationList)) {
        if (item.key === match.params.duration){
            currentDuration = item.name;
        }
        durationSelector.push(
            <Dropdown.Item style={(item.key === match.params.duration) ? activeDropdownItem : null }   key={ item.key } as={ Link } to={ '/analytics/' + item.key + '/' + match.params.id } active={ item.key === match.params.duration }>{ item.name }</Dropdown.Item>
        );
    }

    var itemSelector = [ ];
    const currentBranch = groups[match.params.id];
    for (const group of Object.values(groups)) {
        itemSelector.push(
            <Dropdown.Item style={(match.params.id === group.id)? activeDropdownItem : null} key={ group.id } as={ Link } to={ '/analytics/' + match.params.duration + '/' + group.id } active={ match.params.id === group.id }>{ group.name }</Dropdown.Item>
        );
    }

    function refreshData() {
        QbApiCache.instance.clear();
        incRefreshCounter();
    }

    const dataRefreshIconStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    const upperNavigationTextColor = {
        color: Config.appearance.upperNavigationtextColor,
    }



    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <span className="toggle-menu" onClick={ appState.toggleMenuVisible }>
                            <GiHamburgerMenu />
                        </span>
                    }
                    middle={
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="sub-page-selector" style={ upperNavigationTextColor }>
                                <AiOutlineCalendar className="page-main-icon" /> { currentDuration } 
                                <IconContext.Provider value={{ color: Config.appearance.upperNavigationtextColor, className: "down-icon" }}>
                                    <FaChevronDown />
                                </IconContext.Provider>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                { durationSelector }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    rightSide={
                        <div className="d-flex">
                            <div className="refresh-time pr-1">
                                { to.format(Config.onlyTimeDisplayFormat) }
                                <br />
                                { new Date(to.format(Config.onlyDateDisplayFormat)).toLocaleDateString(lng, {year: 'numeric', month: 'short', day: 'numeric'}) }
                            </div>
                            <div className="refresh-container d-flex align-items-center">
                                <FiRefreshCcw style={ dataRefreshIconStyle } className="data-refresh-icon" onClick={ () => refreshData() } />
                            </div>
                        </div>
                    }
                />
                <LowerNavigation 
                    content={
                        <Dropdown className="branch-selector-container">
                        <Dropdown.Toggle className={ (topHierarchyLevel === 'hq') ? 'c-default' : '' } variant="primary" id="branch-selector">
                            <span className="single-line-page-name">{ currentBranch.name }</span> 
                            { (topHierarchyLevel !== 'hq') ? (
                                <FaCaretDown />
                            ) : '' }
                        </Dropdown.Toggle>
                        { (topHierarchyLevel !== 'hq') ? (
                            <Dropdown.Menu>
                                { itemSelector }
                            </Dropdown.Menu>
                        ) : '' }
                    </Dropdown>
                    }
                    componentList={ componentList }
                />
            </div>
            <div className="dashboard">
                <div className="container">
                    <div className="row align-items-center my-5">
                        <div className="col-lg-12">
                            <DisplayComponent match={ match } durationInfo={ durationInfo } refreshCounter={ refreshCounter } componentList={ componentList } setComponentList={ setComponentList } />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Analytics;
