import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Config, AppStateContext, QbTools, Auth, Constants } from '../modules';
import { Modal, Card } from 'react-bootstrap';
import { MdTune } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { Link, useRouteMatch } from 'react-router-dom';
import { FaRegFilePdf } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';

const OptionsModal = (props) => {
    const { t, i18n } = useTranslation();
    const {t: webappT} = useTranslation('webapp');
    const lng = i18n.language;
    const appState = useContext(AppStateContext);
	const [ isOpen, setOpen ] = useState(false);
    const [ hintShown, setHintShown ] = useState(false);
    const [ notAvailableShown, setNotAvailable ] = useState(false);
    const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);
    const openHint = () => setHintShown(true);
    const closeHint = () => setHintShown(false);
    const openNotAvailable = () => setNotAvailable(true);
    const closeNotAvailable = () => setNotAvailable(false);
    const isDashboard = useRouteMatch('/dashboard');
    const isBreakdown = useRouteMatch('*breakdown*');
    const isDay = useRouteMatch('*day*');
    const isWeek = useRouteMatch('*week*');
    const isMonth = useRouteMatch('*month*');
    const userMerged = Auth.getData().userMerged;
    const topHierarchyLevel = userMerged.topHierarchyLevel.toLowerCase();
    
    let currentFilter;
    if(isDay){
        currentFilter = 'day'
    } else if(isWeek){
        currentFilter = 'week'
    } else if(isMonth){
        currentFilter = 'month'
    }
    
    let hintText;
    if (Config.hint[topHierarchyLevel].hasOwnProperty(appState.activePage)) {
        hintText = Config.hint[topHierarchyLevel][appState.activePage];
    } else if (Config.hint[topHierarchyLevel].hasOwnProperty('_default')) {
        hintText = QbTools.getByLocale(Config.hint[topHierarchyLevel]._default, lng)
    }

    function showHint() {
        closeModal();
        openHint();
    }

    function showNotAvailable() {
        closeModal();
        openNotAvailable();
    }

	return (
		<React.Fragment>
			<div className='d-flex justify-content-start align-items-center c-pointer' onClick={ openModal }>
                <div className="right-setting-icon">
                    <span className="c-pointer">
                        <MdTune />
                    </span>
                </div>
			</div>
			<Modal
				show={ isOpen }
				onHide={ closeModal }
				size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Card>
                        <Card.Header className='qboard-link'>
                            {(isBreakdown)?
                                <div className="popup-link filter d-flex align-items-center" to={ '#' } onClick={ () => showNotAvailable() }>
                                    <span className="popup-icon-container"><FiFilter/></span>
                                    <span className='d-inline-block ml-3'>{ webappT('filter') }</span>
                                </div>
                            :
                                <Link className="popup-link filter d-flex align-items-center" to={ (isDashboard)?'/dashboard/filters': '/analytics/filters/' + currentFilter } >
                                    <span className="popup-icon-container"><FiFilter/></span>
                                    <span className='d-inline-block ml-3'>{ webappT('filter') }</span>
                                </Link>
                            }
                        </Card.Header>
                    </Card>
                    { Constants.pdfApi.enable && props.hasOwnProperty('componentList') && (typeof props.componentList !== 'undefined') ?
                        <Card>
                            <Card.Header className='qboard-link'>
                                <Link className="popup-link pdf d-flex align-items-center" to={{ pathname: '/dashboard/report', state: { componentData: JSON.stringify(props.componentList)} }} >
                                    <span className="popup-icon-container"><FaRegFilePdf/></span>
                                    <span className="d-inline-block ml-3">{ t('localizeReportTitle') }</span>
                                </Link>
                            </Card.Header>
                        </Card>
                    : null }
                    <Card>
                        <Card.Header className='qboard-link' onClick={ () => showHint() } >
                            <div className="popup-link d-flex align-items-center" to={ '#' } >
                                <span className="popup-icon-container"><FiInfo/></span>
                                <span className="d-inline-block ml-3">{ t('localizeHelpTitle') }</span>
                            </div>
                        </Card.Header>
                    </Card>
			</Modal>
            <Modal show={ hintShown } onHide={ closeHint } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="text-center">
                    { t(hintText) }
                </Modal.Body>
            </Modal>
            <Modal show={ notAvailableShown } onHide={ closeNotAvailable } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="text-center">
                    { t('localizeDashboardNoConfigWarning') }
                </Modal.Body>
            </Modal>
		</React.Fragment>
	);
}

export default OptionsModal
