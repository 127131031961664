import React from 'react';
import { MetricDisplayServiceLevel } from './';
import { useTranslation } from 'react-i18next';
import { QbTools } from '../modules';

function MetricProxyHqServiceLevel(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();

    var data = {
        worstName: '',
        worstValue: NaN,
        bestName: '',
        bestValue: NaN,
        hqName: '',
        hqValue: NaN,
    };

    if (QbTools.metricDataReady(metricData, [ 'current', 'children' ] )) {
        for (const [childName, child] of Object.entries(metricData.children.data)) {
            if (isNaN(data.bestValue) || (child['KSZ'] > data.bestValue)) {
                data.bestName = childName;
                data.bestValue = child['KSZ'];
            }
            if (isNaN(data.worstValue) || (child['KSZ'] < data.worstValue)) {
                data.worstName = childName;
                data.worstValue = child['KSZ'];
            }
        }
        data.hqName = Object.keys(metricData.current.data)[0];
        data.hqValue = metricData.current.data[data.hqName]['KSZ'];
    }

    var displayData = {
        graph1Title: QbTools.apiNameToFriendlyName(data.worstName),
        graph1Subtitle: t('localizeWorst'),
        graph1Value: data.worstValue,

        graph2Title: QbTools.apiNameToFriendlyName(data.bestName),
        graph2Subtitle: t('localizeBest'),
        graph2Value: data.bestValue,

        graph3Title: QbTools.apiNameToFriendlyName(data.hqName),
        graph3Value: data.hqValue,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'current', 'children' ], t),
    };

    return (
        <MetricDisplayServiceLevel displayData={ displayData } { ...props } />
    );
}

export default MetricProxyHqServiceLevel;
