import React from 'react';
import { MetricDisplayServedCustomers } from './';
import { useTranslation } from 'react-i18next';
import { QbTools } from '../modules';

function MetricProxyHqServedCustomers(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();

    var data = {
        hqName: '',
        hqValue: NaN,
    };

    if (QbTools.metricDataReady(metricData, [ 'current' ] )) {
        data.hqName = Object.keys(metricData.current.data)[0];
        data.hqValue = metricData.current.data[data.hqName]['USZ'];
    }

    var displayData = {
        graph1Title: QbTools.apiNameToFriendlyName(data.hqName),
        graph1Value: data.hqValue,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'current' ], t),
    };

    return (
        <MetricDisplayServedCustomers displayData={ displayData } { ...props } />
    );
}

export default MetricProxyHqServedCustomers;
