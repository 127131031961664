import React from 'react';
import { Link } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { QbTools, Config, Auth } from '../modules';
import { BsClock, BsDisplayFill } from 'react-icons/bs';
import { TicketIcon } from '../icons';
import { useTranslation } from 'react-i18next';

function BreakdownDisplayLoggedIntoServicePoint(props) {
    var displayData = props.displayData;
    const { t } = useTranslation();
    const match = props.match;
    const progressbarWidth = Math.min((QbTools.rstToElapsed(displayData.RST) / 1800) * 100, 100);
    const progressbarStyle = {
        backgroundColor: Config.graphColors.ServingTime[QbTools.analyticsGraphLimits(QbTools.rstToElapsed(displayData.RST) / 60, Config.graphDefaults.LoggedInCounterTime)],
        width: progressbarWidth + "%",
    };
    const topHierarchyLevel = Auth.getData().user.topHierarchyLevel.toLowerCase();
    let displayClass = '';
    if (topHierarchyLevel === 'uv') {
        displayClass = ( displayData.RLC ? 'at-service-point' : 'not-at-service-point' );
    }

    const BreakdownListItemStyle = {
        minHeight: Config.appearance.breakdownListItemHeight,
    };

    return (
        <div className={ 'metric staff-member-container ' + displayClass } >
            <Link style={BreakdownListItemStyle} className="text-decoration-none" to={ '/dashboard/breakdown/' + match.params.id + '/' + match.params.pagetype + '/' + encodeURIComponent(displayData.itemId) }>
                <div className="progressbar" style={ progressbarStyle }></div>
                <div className="row staff-member align-items-center mx-1">
                    <div className="col-6 col-sm-4 staff-member-name">
                        { QbTools.formatNameBm(displayData.name) }
                    </div>
                    <div className="col-6 col-sm-8 text-right d-flex justify-content-end align-items-center">
                        <div className="staff-member-right-text logged-in-information breakdown-value pr-1">
                            { displayData.RLC ? ( <span className="rlc"><BsDisplayFill className="icon-with-bg-information" /> { displayData.RLC }</span> ) : '' }
                            { displayData.RTS ? ( <span className="rts pl-2"><TicketIcon className="icon-with-bg-information" /> { displayData.RTS }</span> ) : '' }
                            { displayData.RST ? ( <span className="rst pl-2"><BsClock className="icon-with-bg-information" /> { QbTools.formatElapsedTime(QbTools.rstToElapsed(displayData.RST), t) }</span> ) : '' }
                        </div>
                        <div className="breakdown-subitem-link">
                            <BsChevronRight className="icon-with-bg" />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default BreakdownDisplayLoggedIntoServicePoint;
