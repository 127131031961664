import React from 'react';

function DownloadIcon(props) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="1em" height="1em" {...props}>
            <path id="download-rectangle" stroke={ props.stroke } strokeWidth="3" strokeLinejoin="round" strokeMiterlimit="10" fill="none" d="M 39.12,25.5 L 24.25,25.5 C 19.42,25.5 15.5,29.19 15.5,33.75 L 15.5,83.25 C 15.5,87.81 19.42,91.5 24.25,91.5 L 76.75,91.5 C 81.58,91.5 85.5,87.81 85.5,83.25 L 85.5,33.75 C 85.5,29.19 81.58,25.5 76.75,25.5 L 62.75,25.5" />
            <path id="download-bezier3" stroke={ props.stroke } strokeWidth="3" strokeLinejoin="round" strokeMiterlimit="10" fill="none" d="M 50.75,4.5 L 50.75,62 64.5,48.2 M 50.75,4.5 L 50.75,62 37,48.2" />
        </svg>
    );
}

export default DownloadIcon;
