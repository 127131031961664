import {isEmpty} from 'lodash'
import {MdTextsms} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {FiMail, FiPhone} from "react-icons/fi";
import {showApiResponseMessage, showMessage} from "../utilities/UiUtils";
import {AppStateContext, Auth, QbApi, QbTools, Config} from "../modules";
import React, {Fragment, useContext, useState} from 'react'
import {buildMessageRequestBody} from "../utilities/Transform";
import {Accordion, Card, Modal, Spinner} from "react-bootstrap";
import ProfilePlaceholder from "../img/profile-placeholder.png";
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';

const CustomSpinner = () =>
	<Spinner
		animation="border"
		size="sm"
		className='d-inline-block ml-2'
		style={ {marginBottom: 2} }/>

const CustomToggle = ({children, eventKey}) => {
	const decoratedOnClick = useAccordionToggle(eventKey);
	return (
		<div onClick={ decoratedOnClick }>
			{ children }
		</div>
	);
}

const MessagingModal = (props) => {
	const {contactInfo, chanonicalName} = props || {}
	let {email: receiverEmail, mobile: receiverMobile, phone: receiverPhone,} = contactInfo || {}
	const {userName: receiverUserName, fullName} = chanonicalName || {}
	
	// receiverEmail = 'M.UsmanQau786@gmail.com'
	// receiverMobile = '923045223559'
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
	const appState = useContext(AppStateContext);
	const userData = Auth?.getData()?.user;
	const senderUserName = userData?.chanonicalName?.userName
	const [ isOpen, setOpen ] = useState(false);
	const [ isSendSmsLoading, setSendSmsLoading ] = useState(false);
	const [ isSendEmailLoading, setSendEmailLoading ] = useState(false);
	const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);
	
	const [ formState, setFormState ] = useState({smsContent: '', emailContent: ''})
	const resetSmsContent = () => setFormState({...formState, smsContent: ''})
	const resetEmailContent = () => setFormState({...formState, emailContent: ''})
	const onChange = ({target}) => {
		const {value, name} = target ?? {}
		setFormState({...formState, [name]: value})
	}
	
	const buildInitialData = () => {
		return {
			senderUserName,
			internal: receiverUserName,
			email: receiverEmail,
			phoneNumber: receiverMobile,
		}
	}
	
	const sendSmsHandler = async () => {
		setSendSmsLoading(true)
		const body = buildMessageRequestBody({
			...buildInitialData(),
			email: '',
			body: formState.smsContent
		})
		try {
			const response = await QbApi.instance.sendMessage(appState, body);
			resetSmsContent()
			closeModal()
			showApiResponseMessage({
				response,
				successMessage: webappT('smsSentSuccessfully'),
				isSuccessAllowed: false
			})
		} catch (e) {
			showMessage({type: 'error', message: 'Error is found'})
		} finally {
			setSendSmsLoading(false)
		}
	}
	
	const sendEmailHandler = async () => {
		setSendEmailLoading(true)
		try {
			const body = buildMessageRequestBody({
				...buildInitialData(),
				phoneNumber: '',
				body: formState.emailContent,
				isEmail:true
			})
			const response = await QbApi.instance.sendMessage(appState, body);
			resetEmailContent()
			closeModal()
			showApiResponseMessage({
				response,
				isSuccessAllowed: false,
				successMessage: webappT('emailSentSuccessfully')
			})
		} catch (e) {
		} finally {
			setSendEmailLoading(false)
		}
    }
    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
	
	return (
		<Fragment>
			<div className='d-flex justify-content-start align-items-center c-pointer w-100' onClick={ openModal }>
				<img
					alt="Person face"
					className="staff-member-profile-image mr-1"
					src={ ProfilePlaceholder }
					width="32px" height="32px"/>
				<span className="c-pointer d-block">{ QbTools.formatName(fullName ? fullName : props.chanonicalName) }</span>
			</div>
			<Modal
				show={ isOpen }
				onHide={ closeModal }
				size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<div className="contact-type-modal d-flex justify-content-center">
					<Accordion defaultActiveKey="0" className='w-100'>
						{ (!!receiverMobile) && <Card>
							<Card.Header className='qboard-link'>
								<CustomToggle eventKey="0">
									<div className="popup-link sms d-flex align-items-center">
										<span className="popup-icon-container"><MdTextsms/></span>
										<span
											className='d-inline-block ml-3'>{ t('localizeUserContactMessage') }</span>
									</div>
								</CustomToggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0" className='px-2 accordian-body-container'>
								<Fragment>
									<textarea
										className='form-control mt-2'
										name="smsContent"
										autoComplete="off"
										placeholder={ 'Content' }
										value={ formState.smsContent }
										onChange={ onChange }
									/>
									<button
                                        disabled={ isEmpty(formState.smsContent) || isSendSmsLoading }
                                        style={ submitButtonStyle }
										className="btn btn-primary w-100 my-2"
										onClick={ sendSmsHandler }>{ webappT('submit') }
										{ isSendSmsLoading && <CustomSpinner/> }
									</button>
								</Fragment>
							</Accordion.Collapse>
						</Card>
						}
						{ (!!receiverEmail) && <Card>
							<Card.Header className='qboard-link'>
								<CustomToggle eventKey="1">
									<div className="popup-link email d-flex align-items-center">
										<span className="popup-icon-container"><FiMail/></span>
										<span
											className='d-inline-block ml-3'>{ t('localizeUserContactEmail') }</span>
									</div>
								</CustomToggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1" className='px-2 accordian-body-container'>
								<Fragment>
									<textarea
										className='form-control mt-2'
										type="text"
										name="emailContent"
										autoComplete="off"
										placeholder={ 'Content' }
										value={ formState.emailContent }
										onChange={ onChange }
									/>
									<button
                                        disabled={ isEmpty(formState.emailContent) || isSendEmailLoading }
                                        style={ submitButtonStyle }
										className="btn btn-primary w-100 my-2"
										onClick={ sendEmailHandler }
									>{ t('submit') }
										{ isSendEmailLoading && <CustomSpinner/> }
									</button>
								</Fragment>
							</Accordion.Collapse>
						</Card>
						}
						{ (!!receiverMobile) &&
						<Card>
							<Card.Header className='qboard-link'>
								<a className="popup-link mobile d-flex align-items-center"
								   href={ `tel:${ receiverMobile || receiverPhone }` }>
									<span className="popup-icon-container"><FiPhone/></span>
									<span className='d-inline-block ml-3'>{ t('localizeUserContactMobile') }</span>
								</a>
							</Card.Header>
						</Card>
						}
						{ isEmpty(receiverMobile) && isEmpty(receiverEmail) &&
						<Card>
							<Card.Header className='qboard-link text-center'>
								<span className='d-inline-block'>{ webappT('noContactInformationAvailable') }</span>
							</Card.Header>
						</Card>
						}
					</Accordion>
				</div>
			</Modal>
		</Fragment>
	);
}

export default MessagingModal
