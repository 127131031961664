import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Constants, Auth } from '../modules';
import { MetricProxySrmServiceLevel, MetricProxySrmAverageWaitingTime, MetricProxySrmAverageServingTime, MetricProxySrmNoShow, MetricProxySrmServedCustomers, MetricProxyBmActiveServicePoints, MetricProxyBmTicketsServingWaiting } from './';
import { AppStateContext, QbApi, QbErrorBoundary, QbTools } from '../modules';
import {isEmpty} from "lodash";
import {FILTERS_LIST_BM} from "../utilities/Constant";

function DashboardBmBranch(props) {
    const ComponentsMap = QbTools.filterDisabledComponentsMap( {
        'MetricProxyBmActiveServicePoints': MetricProxyBmActiveServicePoints,
        'MetricProxyBmTicketsServingWaiting': MetricProxyBmTicketsServingWaiting,
        'MetricProxySrmServiceLevel': MetricProxySrmServiceLevel,
        'MetricProxySrmAverageWaitingTime': MetricProxySrmAverageWaitingTime,
        'MetricProxySrmAverageServingTime': MetricProxySrmAverageServingTime,
        'MetricProxySrmNoShow': MetricProxySrmNoShow,
        'MetricProxySrmServedCustomers': MetricProxySrmServedCustomers,
    } );

    const appState = useContext(AppStateContext);

    const userProfile = Auth.getData().userProfile || {}
	const filterSort = userProfile?.config?.filterSort || []
    let initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_BM : filterSort
    const defaultFiltersList = FILTERS_LIST_BM;
    let empty = true;

    for (var s = 0; s < initialFiltersList.length; s++) {
        for (var i = 0; i < defaultFiltersList.length; i++) {
            if(defaultFiltersList[i].id === initialFiltersList[s].id){
                empty = false
            }
        }
    }
    if(empty){ initialFiltersList=defaultFiltersList; }

    const from = QbTools.nowApiTimeOffset().set('hour', 0).set('minute', 0).set('second', 0);
    const to = QbTools.nowApiTimeOffset().set('second', 0);
    const apiFrom = from.format();
    const apiTo = to.format();
    const apiTargetPath = props.match.params.id;

    const [ metricData, setMetricData ] = useState( { _state: Constants.ajaxState.init, _failed: false, current: { }, children: { }, leaf: { }, parent: { }, top: { } } );
    useEffect( () => {
        setMetricData(prevState => ( { ...prevState, _state: Constants.ajaxState.loading, _failed: false } ));
        var indices = [ ];

        indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'current',  moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'ROP', 'RCP', 'RTW', 'RTS', 'branchDetails' ], (data) => QbApi.updateMetricData(setMetricData, 'current',  data)));
        indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'parent',   moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'branchDetails' ], (data) => QbApi.updateMetricData(setMetricData, 'parent',   data)));
        indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'top',      moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'branchDetails' ], (data) => QbApi.updateMetricData(setMetricData, 'top',      data)));

        return function cleanup() {
            QbApi.instance.cancelQueue(indices);
        };
    }, [ apiTargetPath, apiFrom, apiTo, appState, props.refreshCounter ] );

    return (
        <div>
			{ initialFiltersList.map(item => {
				const { id, active,position } = item || { };
				const RenderItem = ComponentsMap[id];
				return active && RenderItem ? <QbErrorBoundary key={ id }><RenderItem metricData={ metricData } match={ props.match } setComponentList={ props.setComponentList } position={ position } id={ id } /></QbErrorBoundary> : null;
			} ) }
		</div>
    )
}

export default DashboardBmBranch;
