export const isValidLatLng = ({lat, lng}) => {
	const formattedLat = parseFloat(lat ?? 0)
	const formattedLng = parseFloat(lng ?? 0)
	if (formattedLat < -90 || formattedLat > 90 || formattedLng < -180 || formattedLng > 180) {
		return false
	}
	return true
}

export const isGeoCodingAllowed = ({lat, lng}) => {
	return parseFloat(lat ?? 0) === 0 && parseFloat(lng ?? 0) === 0;
	
}
