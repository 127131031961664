import React, { useContext, useEffect } from 'react';
import { Redirect, Link, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth, Constants, QbTools, Config } from '../modules';
import { AppStateContext } from '../modules';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { UpperNavigation } from './';

function SettingsOpeningHours() {
    const { t } = useTranslation();
    let history = useHistory();
    const match = useRouteMatch('/settings/branch/:id/openinghours');
    const appState = useContext(AppStateContext);
    useEffect( () => {
        appState.setActivePage('settings.openinghours');
    } );

    let authData = Auth.getData();
    let branch = {
        userProfile: authData.userProfile.branches?.find(element => String(element.id) === match.params.id),
        user: authData.user.branches?.find(element => String(element.id) === match.params.id),
    };
    const branchIndex = authData.userProfile.branches?.findIndex(element => String(element.id) === match.params.id);
	if ((branchIndex === -1) || (typeof branchIndex === 'undefined')) {
        return <Redirect to={ '/settings' }/>
    }

    let openingHoursList = [ ];
    for (let index = 0; index < 7; ++index) {
        openingHoursList.push(
            <Link key={ index } className="qboard-link" to={ '/settings/branch/' + match.params.id + '/openinghours/' + index }>
                <div className="row align-items-center py-1 setting-item">
                    <div className="col-4">{ t(Constants.i18nDayOfWeek[index]) }</div>
                    <div className="col-7 text-right">
                        { QbTools.formatStoreOpeningHours(branch.userProfile.open, index, false, t) }
                    </div>
                    <div className="col-1"><BsChevronRight className='icon-with-bg' /></div>
                </div>
            </Link>
        );
    }

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizeStoreWeek')
                    }
                />
            </div>

            <div className="settings">
                <div className="container">
                    { openingHoursList }
                </div>
            </div>
        </React.Fragment>
    );
}

export default SettingsOpeningHours;
