import React, { useEffect, useRef } from 'react';
import { QBDashboardFigureServiceCompare } from '../graph/';

function GraphFigureServiceCompare(props) {
    const canvasGraph = useRef(null);

    useEffect( () => {
        function getParentWidth() {
            return canvasGraph.current.parentElement.clientWidth;
        }

        function getCanvasSize() {
            const width = getParentWidth();
            const height = Math.floor(width / 3);
            return [ width, height ];
        }

        function _resizeHandler() {
            const [ width, height ] = getCanvasSize();
            canvasGraph.current.width = width * 2;
            canvasGraph.current.height = height * 2;

            clearAndDraw();
        }

        function clearAndDraw() {
            const ctx = canvasGraph.current.getContext('2d');
            if (ctx) {
                const [ width, height ] = getCanvasSize();
                var rect = QBDashboardFigureServiceCompare.makeRect(0, 0, width * 2, height * 2);
                QBDashboardFigureServiceCompare.clearCanvas(canvasGraph.current);
                QBDashboardFigureServiceCompare.drawService(
                    canvasGraph.current,
                    props.unit, // compareUnit
                    props.referenceValue, // referenceValue
                    props.value1, // compareValue1
                    props.value2, // compareValue2
                    props.value3, // compareValue3
                    props.overallValue, // overallValue
                    rect,
                    'aspectfit'
                );
            }
        }

        window.addEventListener('resize', _resizeHandler);

        const [ width, height ] = getCanvasSize();
        canvasGraph.current.width = width * 2;
        canvasGraph.current.height = height * 2;

        clearAndDraw();

        return function cleanup() {
            window.removeEventListener('resize', _resizeHandler);
        }
    }, [
        props.unit,
        props.referenceValue,
        props.value1,
        props.value2,
        props.value3,
        props.overallValue,
    ] );

    return (
        <div className="popular-service-canvas-container">
            <canvas ref={canvasGraph} />
        </div>
    );
}

export default GraphFigureServiceCompare;
