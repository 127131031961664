import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { Config, AppStateContext, QbTools } from '../modules';
import { SwitchViewIcon } from '../icons'
import { GrCircleInformation } from 'react-icons/gr';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import { UpperNavigation } from './';
import ProgressBar from 'react-bootstrap/ProgressBar';

function HelpPage() {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const appState = useContext(AppStateContext);
    const lng = i18n.language;
    const match = useRouteMatch('/help/:page');
    const totalPages = Config.help.pages.length;
    const currentPage = match.params.page;
    const helpPage = Config.help.pages[currentPage - 1];
    const currentProgressbarWidth = (currentPage - 1) / (totalPages - 1) * 100;

    useEffect( () => {
        if (helpPage !== undefined) {
            appState.setActivePage('');
            appState.setHelpPage(currentPage);
        }
    } );

    if (helpPage === undefined) {
        return (
            <Redirect pash={ false } to={ '/help' } />
        );
    }

    let prevPage = Number(currentPage) - 1;
    if (prevPage < 1) {
        prevPage = null;
    }
    let nextPage = Number(currentPage) + 1;
    if (nextPage > totalPages) {
        nextPage = null;
    }

    const secondaryTitleStyle = {
        fontSize: Config.appearance.secondaryTitleSize,
    }

    const contentStyle = {
        fontSize: Config.appearance.helpPageContentSize,
    }

    const layoutStyle = {
        backgroundColor: Config.appearance.globalBackgroundColor,
    }

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        <div className="text-center">
                            { t('localizeHelpTitle') }
                            <div style={ secondaryTitleStyle } className="help-page-current-name font-weight-light mb-0">
                                { QbTools.getByLocale(helpPage.title, lng) }
                            </div>
                        </div>
                    }
                    rightSide={
                        <div className="d-flex">
                            <Link replace={ true } className="qboard-link px-1" to={ '/help/' }>
                                <SwitchViewIcon strokeColor={ Config.appearance.mainColor } className="icon-with-bg" />
                            </Link>
                            <Link className="qboard-link px-1 info-icon" to="/about">
                                <GrCircleInformation className="help-information-icon pl-1" />
                            </Link>
                        </div>
                    }
                />
            </div>

            <div style={ layoutStyle } className="layout vh-100">
                <div className="container">
                    <div className="row d-flex pt-3">
                        <div className="col-2 d-flex align-items-center">
                            { prevPage !== null ?
                                (
                                    <Link replace={ true } className="qboard-link" to={ '/help/' + prevPage }>
                                        <AiOutlineArrowLeft className="help-page-navigation navigation-prev" />
                                    </Link>
                                ) : ''
                            }
                        </div>
                        <div className="col-8 text-center">
                            <div className="help-image-container text-center w-100">
                                <img alt={ QbTools.getByLocale(helpPage.title, lng) } src={ helpPage.image } />
                            </div>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                        { nextPage !== null ?
                                (
                                    <Link replace={ true } className="qboard-link" to={ '/help/' + nextPage }>
                                        <AiOutlineArrowRight className="help-page-navigation navigation-next"/>
                                    </Link>
                                ) : ''
                            }
                        </div>
                    </div>
                    <div className="row d-flex pt-3">
                        <div className="col-12 text-center">
                            <div className="row">
                                <div className="col-lg-3 col-12">
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div style={ contentStyle } className="help-description my-3">
                                    { QbTools.helptextToJsx(QbTools.getByLocale(helpPage.content, lng)) }
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                </div>
                            </div>                           
                        </div>
                    </div>
                    <div className="row d-flex my-3">
                        <div className="col-2">
                        </div>
                        <div className="col-8 text-center">
                            
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                    <div className="row d-flex my-3">
                        <div className="col-2">
                        </div>
                        <div className="col-8 d-flex align-items-center">
                            
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-3 tour-navigation">
                    <div className="col-2 col-md-3 d-flex justify-content-end"></div>
                    <div className="col-8 col-md-6 d-flex justify-content-center">
                        <ProgressBar now={currentProgressbarWidth} />
                    </div>
                    <div className="col-2 col-md-3 d-flex justify-content-start"></div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HelpPage;
