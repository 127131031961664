import { sjcl } from './';

var RNCryptor = {};

RNCryptor.sanityCheck = function() {
    if (! ('sha1' in sjcl.hash)) {
        throw new Error('algorithm "sha1" cannot be found in sjcl.hash');
    }
}
/*
    Takes password string and salt WordArray
    Returns key bitArray
*/
RNCryptor.KeyForPassword = function(password, salt) {
    var hmacSHA1 = function (key) {
        var hasher = new sjcl.misc.hmac(key, sjcl.hash.sha1);
        this.encrypt = function () {
            return hasher.encrypt.apply(hasher, arguments);
        };
    };
    return sjcl.misc.pbkdf2(password, salt, 10000, 32 * 8, hmacSHA1);
}

/*
    Takes password string and plaintext bitArray
    cryptoOptions:
        iv
        encryption_salt
        html_salt
    Returns ciphertext bitArray
*/
RNCryptor._encrypt = function(plaintext, password, cryptoOptions) {
    cryptoOptions = cryptoOptions || {}
    var encryption_salt = cryptoOptions["encryption_salt"] || sjcl.random.randomWords(8 / 4); // FIXME: Need to seed PRNG
    var encryption_key = RNCryptor.KeyForPassword(password, encryption_salt);

    var hmac_salt = cryptoOptions["hmac_salt"] || sjcl.random.randomWords(8 / 4);
    var hmac_key = RNCryptor.KeyForPassword(password, hmac_salt);

    var iv = cryptoOptions["iv"] || sjcl.random.randomWords(16 / 4);

    var version = sjcl.codec.hex.toBits("03");
    var options = sjcl.codec.hex.toBits("01");

    var message = sjcl.bitArray.concat(version, options);
    message = sjcl.bitArray.concat(message, encryption_salt);
    message = sjcl.bitArray.concat(message, hmac_salt);
    message = sjcl.bitArray.concat(message, iv);

    var aes = new sjcl.cipher.aes(encryption_key);
    sjcl.beware["CBC mode is dangerous because it doesn't protect message integrity."]();
    var encrypted = sjcl.mode.cbc.encrypt(aes, plaintext, iv);

    message = sjcl.bitArray.concat(message, encrypted);

    var hmac = new sjcl.misc.hmac(hmac_key).encrypt(message);
    message = sjcl.bitArray.concat(message, hmac);

    return message;
}

/*
    Takes password string and message (ciphertext) bitArray
    cryptoOptions:
        iv
        encryption_salt
        html_salt
    Returns plaintext bitArray
*/
RNCryptor._decrypt = function(message, password, cryptoOptions) {
    cryptoOptions = cryptoOptions || {}

    //var version = sjcl.bitArray.extract(message, 0 * 8, 8);
    //var options = sjcl.bitArray.extract(message, 1 * 8, 8);

    var encryption_salt = sjcl.bitArray.bitSlice(message, 2 * 8, 10 * 8);
    var encryption_key = RNCryptor.KeyForPassword(password, encryption_salt);

    var hmac_salt = sjcl.bitArray.bitSlice(message, 10 * 8, 18 * 8);
    var hmac_key = RNCryptor.KeyForPassword(password, hmac_salt);

    var iv = sjcl.bitArray.bitSlice(message, 18 * 8, 34 * 8);

    var ciphertext_end = sjcl.bitArray.bitLength(message) - (32 * 8);

    var ciphertext = sjcl.bitArray.bitSlice(message, 34 * 8, ciphertext_end);

    var hmac = sjcl.bitArray.bitSlice(message, ciphertext_end);

    var expected_hmac = new sjcl.misc.hmac(hmac_key).encrypt(sjcl.bitArray.bitSlice(message, 0, ciphertext_end));

    // .equal is of consistent time
    if (! sjcl.bitArray.equal(hmac, expected_hmac)) {
        throw new sjcl.exception.corrupt("HMAC mismatch or bad password.");
    }

    var aes = new sjcl.cipher.aes(encryption_key);
    sjcl.beware["CBC mode is dangerous because it doesn't protect message integrity."]();
    var decrypted = sjcl.mode.cbc.decrypt(aes, ciphertext, iv);

    return decrypted;
}

RNCryptor.decrypt = function(encryptedBase64Data, password) {
    var encryptedBits = sjcl.codec.base64.toBits(encryptedBase64Data);
    return sjcl.codec.utf8String.fromBits(this._decrypt(encryptedBits, password));
}

RNCryptor.encrypt = function(cleartextData, password) {
    var binaryData = sjcl.codec.utf8String.toBits(cleartextData);
    return sjcl.codec.base64.fromBits(this._encrypt(binaryData, password));
}

RNCryptor.json = { };

RNCryptor.json.decrypt = function(encryptedBase64Data, password) {
    var encryptedBits = sjcl.codec.base64.toBits(encryptedBase64Data);
    return JSON.parse(sjcl.codec.utf8String.fromBits(RNCryptor._decrypt(encryptedBits, password)));
}

RNCryptor.json.encrypt = function(cleartextJson, password) {
    var binaryData = sjcl.codec.utf8String.toBits(JSON.stringify(cleartextJson));
    return sjcl.codec.base64.fromBits(RNCryptor._encrypt(binaryData, password));
}

//module.exports = RNCryptor;

export default RNCryptor;
