import React from 'react'
import {Card, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FaRegCommentDots} from "react-icons/fa";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import {AiTwotoneDislike, AiTwotoneLike} from "react-icons/ai";
import {FEEDBACK_CATEGORIES, FEEDBACK_CATEGORIES_COLORS, FEEDBACK_CATEGORIES_LIST} from "../utilities/Constant";

const getLikeDislikeIcon = ({feedbackCategory}) => {
	if (feedbackCategory === FEEDBACK_CATEGORIES.LIKE) {
		return <AiTwotoneLike/>
	} else if (feedbackCategory === FEEDBACK_CATEGORIES.DISLIKE) {
		return <AiTwotoneDislike/>
	} else if (feedbackCategory === FEEDBACK_CATEGORIES.WOULD_LIKE) {
		return <BsFillQuestionCircleFill/>
	}
	return <FaRegCommentDots/>
}


const FeedbackLikeModal = (props) => {
	const {feedbackCategory, setFeedbackCategory} = props || {}
	const { t:webappT } = useTranslation('webapp');
	return (
		<Modal
			show={ props.show }
			onHide={ props.onHide }
			size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<div className="mx-0 px-0">
				{ FEEDBACK_CATEGORIES_LIST.map(({localizeKey, type}) => {
					const onClick = () => {
						setFeedbackCategory(type)
						props.onHide()
					}
					const textClasses = type === feedbackCategory ? 'font-weight-bold' : ''
					const {light,dark}=FEEDBACK_CATEGORIES_COLORS[type]
					const style={backgroundColor:light,color:dark}
					return <div onClick={ onClick } className='border-bottom'>
						<Card.Header className='qboard-link'>
							<div className="popup-link email d-flex align-items-center">
								<span className="popup-icon-container" style={style}>
									{ getLikeDislikeIcon({feedbackCategory: type}) }
								</span>
								<span className={ `d-inline-block ml-3 ${ textClasses }` }>{ webappT(localizeKey) }</span>
							</div>
						</Card.Header>
					</div>
				}) }
			</div>
		</Modal>
	);
}

export default FeedbackLikeModal
