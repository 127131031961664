import React from 'react';

function TicketIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M13.996 3.884a.257.257 0 00-.008-.363l-1.485-1.485a.257.257 0 00-.364-.009L1.845 12.322a.257.257 0 00.009.364l1.485 1.485a.257.257 0 00.363.008c.686-.687 1.824-.661 2.542.057.717.717.743 1.855.056 2.541a.257.257 0 00.009.363l1.484 1.485a.257.257 0 00.364.009L18.45 8.339a.257.257 0 00-.009-.364l-1.485-1.484a.257.257 0 00-.363-.009c-.686.687-1.824.661-2.541-.056-.718-.718-.744-1.856-.057-2.542zm-.102 3.072c.858.692 2.056.72 2.884.065l1.124 1.124-9.94 9.94-1.124-1.124c.774-.98.578-2.437-.437-3.256-.858-.692-2.055-.72-2.884-.065l-1.124-1.124 9.94-9.94L13.457 3.7c-.774.98-.578 2.438.437 3.256z"
        stroke={(props.stroke)? props.stroke : "#06F"}
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default TicketIcon;
