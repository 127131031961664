import React from 'react'
import {Card, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FiMail, FiPhone} from "react-icons/fi";
import {GiWireframeGlobe} from "react-icons/gi";
import {HOST_URL, SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER} from "../utilities/Constant";

const OfflineContactUsModal = (props) => {
	const {isOpen, closeModal} = props
	const {t} = useTranslation()
	const {t: webappT} = useTranslation('webapp');
	return <Modal
		show={ isOpen }
		onHide={ closeModal }
		size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
		<div>
			<Card.Header className='qboard-link border-bottom'>
				<a href={ `mailto:${ SUPPORT_EMAIL }` }
				   className="popup-link email d-flex align-items-center">
					<span className="popup-icon-container"><FiMail/></span>
					<span
						className='d-inline-block ml-3'>{ t('localizeUserContactEmail') }</span>
				</a>
			</Card.Header>
			<Card.Header className='qboard-link border-bottom'>
				<a className="popup-link mobile d-flex align-items-center" href={ `tel:${ SUPPORT_PHONE_NUMBER }` }>
					<span className="popup-icon-container"><FiPhone/></span>
					<span className='d-inline-block ml-3'>{ t('localizeUserContactMobile') }</span>
				</a>
			</Card.Header>
			<Card.Header className='qboard-link border-bottom' style={ {borderRadius: 4} }>
				<a href="#" className="popup-link sms d-flex align-items-center"
				      onClick={()=>{window.open(HOST_URL);}}>
						<span className="popup-icon-container"><GiWireframeGlobe/></span>
						<span className='d-inline-block ml-3'>{ webappT('openWebsite') }</span>
				</a>
			</Card.Header>
		</div>
	</Modal>
}

export default OfflineContactUsModal

