import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SettingsProfile, SettingsLanguage, SettingsPrivacy, SettingsPrivacyPolicy, SettingsBranch, SettingsStaff, SettingsAddress, SettingsAddressMap, SettingsOpeningHours, SettingsOpeningHour } from './';
import { NoRoutePage } from './';

function Settings() {
    return (
        <Switch>
            <Route exact path="/settings" component={() => <SettingsProfile />} />
            <Route exact path="/settings/language" component={() => <SettingsLanguage />} />
            <Route exact path="/settings/privacy" component={() => <SettingsPrivacy />} />
            <Route exact path="/settings/privacypolicy" component={() => <SettingsPrivacyPolicy />} />
            <Route exact path="/settings/branch/:id" component={() => <SettingsBranch />} />
            <Route exact path="/settings/branch/:id/staff" component={() => <SettingsStaff />} />
            <Route exact path="/settings/branch/:id/address" component={() => <SettingsAddress />} />
            <Route exact path="/settings/branch/:id/address/map" component={() => <SettingsAddressMap />} />
            <Route exact path="/settings/branch/:id/openinghours" component={() => <SettingsOpeningHours />} />
            <Route exact path="/settings/branch/:id/openinghours/:day" component={() => <SettingsOpeningHour />} />

            <Route component={() => <NoRoutePage />} />
        </Switch>
    );
}

export default Settings;
