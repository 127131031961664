import moment from "moment";
import {scaleLinear,} from 'd3-scale'
import {SUPPORT_EMAIL, SUPPORT_USER_NAME} from "./Constant";
import {filter, isEmpty, keyBy, map, max, min, split, values,find} from 'lodash'

export const normalizeData = (dataMap, minSize, maxSize) => {
	const pointsList = map(values(dataMap), (item) => {
		const {USZ} = item || {}
		return USZ || 0
	})
	const minNumber = min(pointsList)
	const maxNumber = max(pointsList)
	const scale = scaleLinear().domain([ minNumber, maxNumber ]).range([ minSize, maxSize ]);
	return {scale}
}

export const generateUniqueId = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = parseFloat('0.' + Math.random().toString().replace('0.', '') + new Date().getTime()) * 16 | 0,
			// eslint-disable-next-line no-mixed-operators
			v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export const buildMessageRequestBody = (props) => {
	const {senderUserName } = props || {}
	const { email, phoneNumber, body = {}} = props || {}
	const recipients = []
	
	if (!isEmpty(email)) {
		recipients.push({"identifier": email, "type": "email"})
	}
	if (!isEmpty(String(phoneNumber))) {
		recipients.push({"identifier": phoneNumber, "type": "sms"})
	}
	return {
		message: [
			{
				uuid: generateUniqueId(),
				createTimestamp: moment().unix(),
				sender: {username: senderUserName},
				recipients: recipients,
				contents: [ {type: "body", content: body} ]
			}
		]
	}
}


export const buildReportRequestBody = (props) => {
	const {senderUserName, senderName,} = props || {}
	const {data = {}, attachments = []} = props || {}
	const {subject, description, system, effect, urgency, group,} = data || {}
	let body = `
    Host: ${ window.location.hostname }\n
    Username:${ senderUserName }\n
    Short description: ${ subject }\n
    Failure description: ${ description }\n
    Affected systems: ${ system }\n
    Effect: ${ effect }\n
    Urgency: ${ urgency }\n
    `
	if (group?.id !== '0') {
		body = body + `Group: ${ group?.name ?? '' } (${ group?.id ?? '' })\n`
	}
	const recipients = [
		{"identifier": SUPPORT_USER_NAME, "type": "internal"},
		{"identifier": SUPPORT_EMAIL, "type": "email"}
	]
	recipients.push()
	const senderInfo = {username: senderUserName}
	if (!isEmpty(String(senderName))) {
		senderInfo.name = senderName
	}
	
	const contents = []
	if (!isEmpty(String(subject))) {
		contents.push({type: "subject", "content": subject})
	}
	contents.push({type: "body", "content": body})
	contents.push({type: "data", "content": JSON.stringify(data ?? {})})
	
	const optionalParams = {}
	if (!isEmpty(String(attachments))) {
		optionalParams.attachments = map(attachments, data => ({
			type: "image/jpeg",
			data: split(data, ',')?.[1] ?? ''
		}))
	}
	
	return {
		message: [
			{
				uuid: generateUniqueId(),
				createTimestamp: moment().unix(),
				sender: senderInfo,
				recipients: recipients,
				contents: contents,
				...optionalParams
			}
		]
	}
}

export const buildFeedbackRequestBody = (props) => {
	const {recipientUserName, recipientName, messageContent, feedbackCategory,senderUserName, topId, images = []} = props || {}
	const attachments = []
	if (!isEmpty(images)) {
		map(images, (image) => {
			const base64Data = split(image, ';base64,')[1]
			!isEmpty(base64Data) && attachments.push({type: 'image/png', data: base64Data})
		})
	}
	return {
		message: [
			{
				topId,
				uuid: generateUniqueId(),
				createTimestamp: moment().unix(),
				// "name": "SUBTOPIC NAME (optional)",
				active: true,
				sender: {"username": recipientUserName, "name": recipientName},
				recipients: [
					// {identifier: "x-hq2", type: "internal"},
					{identifier: SUPPORT_USER_NAME, type: "internal"},
					{identifier: SUPPORT_EMAIL, type: "email"}
				],
				contents: [
					{type: 'subject', content: senderUserName||''},
					{
						type: 'body',
						content: `Host: ${ window.location.hostname }\nUsername: ${ recipientName } \nFeedback: ${ messageContent }`
					},
					{type: "data", content: JSON.stringify({category: feedbackCategory, text: messageContent})}
				],
				attachments
			}
		]
	}
}

const parseJson = (data) => {
	try {
		return JSON.parse(data)
	} catch (e) {
		return {}
	}
}

export const transformReportItems = (dataList,receiverName) => {
	const sortedDataList = (dataList || []).sort((item1, item2,) => {
		return item2.createTimestamp - item1.createTimestamp
	})
	const parsedList = map(sortedDataList, (item, index) => {
		try {
			const {contents, recipients = []} = item || {}
			const stringifyRequest = keyBy(contents, 'type')?.data?.content ?? {}
			const subject = keyBy(contents, 'type')?.subject?.content ?? {}
			const body = keyBy(contents, 'type')?.body?.content ?? {}
			let isEmailSendError = isEmpty(recipients?.filter(item => (item?.status?.state === 'success')))
			const receiverItem=find(recipients,({identifer})=>identifer===receiverName)
			if(!isEmpty(receiverItem)){
				isEmailSendError=false
			}
			return {...item, isEmailSendError, request: {...JSON.parse(stringifyRequest) ?? {}, subject, body},}
		} catch (e) {
			return {}
		}
	})
	return filter(parsedList, item => (!isEmpty(item) && item.active === true))
}

export const transformFeedbackInfo = (dataList) => {
	const sortedDataList = (dataList || []).sort((item1, item2,) => {
		return item2.createTimestamp - item1.createTimestamp
	})
	const parsedList = map(sortedDataList, (item, index) => {
		try {
			const {active} = item
			if (active === false) {
				return item
			}
			const {contents} = item || {}
			const subject = keyBy(contents, 'type')?.subject?.content ?? ''
			const stringifyRequest = keyBy(contents, 'type')?.data?.content ?? '{}'
			const requestObj = parseJson(stringifyRequest)
			const {category, text} = requestObj || {}
			return {...item, request: {subject, message: text, feedbackCategory: category}}
		} catch (e) {
			return {}
		}
	})
	return filter(parsedList, item => !isEmpty(item)&& item.active === true)
}

export const buildUrlParams = (params) => {
	return Object.keys(params)
		.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&');
}
