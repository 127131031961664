import React, { useEffect, useRef } from 'react';
import { QbTools } from '../modules/';
import { useTranslation } from 'react-i18next';
import { BreakdownDisplayServiceLevel } from './';

function BreakdownProxyServiceLevel(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();
    var sortedData = QbTools.sortBy(Object.entries(metricData.children?.data || { } ), 'KSZ', { localeCompare: true } );

    var leafSorted  = [ ];
    const graph1 = useRef(null);
    //const userMerged = Auth.getData().userMerged;
    const pdfTitle = t('localizeMetricServeLevel');

    const jsonMetricData = JSON.stringify(metricData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            props.setComponentList(prevState => {
                prevState.items[1] = {
                    position: 1,
                    id: 'BreakdownProxyServiceLevel',
                    filterName: 'localizeMetricServeLevel',
                    metricData: metricData,
                    render: ( <BreakdownProxyServiceLevel boardtype={ props.boardtype } metricData={ props.metricData } match={ props.match } /> ),
                    pdfData: {
                        requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'children' ], t),
                        type: 'htmlsvg',
                        title: pdfTitle,
                        html: graph1.current.innerHTML,
                    },
                };
                return prevState;
            } );
        }
    }, [ jsonMetricData, metricData, pdfTitle, props, t ] );

    if (props.displayWorst === true) {
        leafSorted = QbTools.sortBy(Object.entries(metricData.leaf?.data || { } ), 'KSZ', { localeCompare: true } );
    }

    var components = [ ];
    for (const [ key, child ] of sortedData) {
        const itemId = QbTools.apiNameToItemId(key);
        var displayData = {
            name: QbTools.apiNameToFriendlyName(key),
            value: child.KSZ,
            path: child.path,
            key: key,
            itemId: itemId,
            id: QbTools.pathToId(child.path),
            worst: {
                name: '',
                value: null,
                path: null,
                id: null,
            },
        };
        if (props.displayWorst === true) {
            const leafData = leafSorted.filter(leaf => { return leaf[1].path.endsWith(child.path); } )[0];
            if (typeof leafData !== 'undefined') {
                displayData.worst = {
                    name: QbTools.apiNameToFriendlyName(leafData[0]),
                    value: leafData[1].KSZ,
                    path: leafData[1].path,
                    key: leafData[0],
                    id: QbTools.pathToId(leafData[1].path),
                }
            }
        }

        components.push(
            <BreakdownDisplayServiceLevel key={ key } displayData={ displayData } match={ props.match } displayWorst={ props.displayWorst } />
        );
    }

    return (
        <div ref={ graph1 } >
            { components }
        </div>
    );
}

export default BreakdownProxyServiceLevel;
