import {NotificationManager} from 'react-notifications';

export const showMessage = ({type = 'success', title, message}) => {
	switch (type) {
		case 'info':
			NotificationManager.info(message, title ?? 'Info', 2000);
			break;
		case 'success':
			NotificationManager.success(message, title ?? 'Success', 2000);
			break;
		case 'warning':
			NotificationManager.warning(message, title ?? 'Warning', 2000);
			break;
		case 'error':
			NotificationManager.error(message, title ?? 'Error', 2000);
			break;
		default: break;
	}
};

export const showApiResponseMessage = async ({response, successMessage, errorMessage, isSuccessAllowed = true, isErrorAllowed = true}) => {
	const {status} = response || {}
	const formattedStatus = String(status)
	if (formattedStatus === '200' || formattedStatus === '201') {
		isSuccessAllowed && showMessage({type: 'success', message: successMessage})
	} else {
		try {
			const {message} = await response.json()
			isErrorAllowed && showMessage({type: 'error', message: message || errorMessage || 'Error is found'})
		} catch (e) {
			isErrorAllowed && showMessage({type: 'error', message: 'Error is found'})
		}
	}
}
