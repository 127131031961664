import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Constants, Auth, AppStateContext } from '../modules';

function TourIndex() {
    var semver = require('semver');
    const appState = useContext(AppStateContext);
    const clientInfo = Auth.getItem('clientInfo');
    const vesionIsOk = (clientInfo.info.platform === Constants.appInfo.platform) && (semver.lte(Constants.appInfo.version, clientInfo.info.version));

    useEffect( () => {
        if (vesionIsOk) {
            appState.setAuthPhase('auth');
            Auth.setAuthPhase('auth');
        }
    }, [ vesionIsOk, appState ] );

    if (vesionIsOk) {
        return '';
    }

    return ( <Redirect to={ '/auth/tour/0' } /> );
}

export default TourIndex;
