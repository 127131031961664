import React, { useEffect, useRef } from 'react';
import { GraphTicket } from './';
import { AjaxStateFragment } from './';
import { useTranslation } from 'react-i18next';

function MetricDisplayBmTicketsServingWaiting(props) {
    var displayData = props.displayData;
    const { t } = useTranslation();
    const graph1 = useRef(null);

    const jsonDisplayData = JSON.stringify(displayData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            let canvasList = graph1.current.querySelectorAll('canvas');
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: 'localizeMetricTicketWaiting',
                    displayData: displayData,
                    render: ( <MetricDisplayBmTicketsServingWaiting displayData={ displayData }/> ),
                    pdfData: {
                        requestTime: displayData._requestTime,
                        type: 'canvaslist',
                        title: t('localizeMetricTicketWaiting'),
                        infoText: isNaN(displayData.serving) ? '' : displayData.serving + ' / ' + displayData.sum,
                        items: [
                        ],
                    },
                };
                for (const canvas of Object.values(canvasList)) {
                    let item = {
                    }
                    Object.defineProperty(item, 'imageData', {
                        get: function () { return canvas.toDataURL('image/png'); },
                        enumerable: true,
                    } );
                    prevState.items[props.position].pdfData.items.push(item);
                }
                return prevState;
            } );
        }
    }, [ jsonDisplayData, displayData, props, t ] );

    var tickets = [ ];
    for (const ticket of displayData.tickets) {
        tickets.push(
            <div key={ ticket.name } className={"ticket-icon " + (ticket.active ? 'active' : 'inactive')}>
                <GraphTicket text={ ticket.name } active={ ticket.active } />
            </div>
        );
    }

    return (
        <div className="metric tickets">
            <div className="row text-center my-1 pt-5 title">
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t('localizeMetricTicketWaiting') }</h4>
                </div>
            </div>
            <div className="row my-1 branch-row-icon">
                <AjaxStateFragment _state={ displayData._state } _failed={ displayData._failed } />
                <div className="col-2 col-md-1 text-center active-graph-counter">
                    { isNaN(displayData.serving) ? '' : ( <React.Fragment> { displayData.serving } / { displayData.sum } </React.Fragment> ) }
                </div>
                <div className="col-10 col-md-11 ticket-icon-holder icon-holder" ref={ graph1 } >
                    { tickets }
                </div>
            </div>
        </div>
    );
}

export default MetricDisplayBmTicketsServingWaiting;
