import React from 'react';
import { Redirect } from 'react-router-dom';
import { QbTools } from '../modules/';
import { BreakdownDisplaySubItem } from './';

function BreakdownProxySubItem(props) {
    let findData = Object.entries(props.metricData.children?.data || { } ).find(e => (e[0] === decodeURIComponent(props.match.params.subid)) || e[0].endsWith('( ' + props.match.params.subid + ' )'));
    let displayData = undefined;
    if (typeof findData !== 'undefined') {
        displayData = findData[1];
        displayData.name = QbTools.apiNameToFriendlyName(findData[0]);
    }
    props.setAreaName(displayData?.name || ( props.displayWorst ? sessionStorage.getItem('bestName') : '' ));
    if (QbTools.metricDataReady(props.metricData, [ 'children' ] ) && (typeof displayData === 'undefined')) {
        return (<Redirect to={ '/dashboard/' + props.boardtype }/>);
    }
    var leafSorted  = [ ];
    const worstData = {
        USZ: null,
        KSZ: null,
        VI: null,
        KI: null,
        name: null,
        managers: [ ],
        path: null,
        key: null,
        id: null,
    };

    if (typeof displayData === 'undefined') {
        displayData = {
            USZ: null,
            KSZ: null,
            VI: null,
            KI: null,
            name: null,
            managers: [ ],
            path: null,
            key: null,
            id: null,
            worst: worstData,
        };
    } else {
        displayData.key = props.match.params.subid;
        displayData.id = QbTools.pathToId(displayData.path);
        displayData.worst = worstData;
    }

    if (props.displayWorst === true) {
        const sortMap = {
            scc: { field: 'USZ', options: { localeCompare: true } },
            sl : { field: 'KSZ', options: { localeCompare: true } },
            awt: { field: 'VI',  options: { localeCompare: true, reverse: true } },
            ast: { field: 'KI',  options: { localeCompare: true, reverse: true } },
        }
        leafSorted = QbTools.sortBy(Object.entries(props.metricData.leaf?.data || { } ), sortMap[props.match.params.pagetype].field, sortMap[props.match.params.pagetype].options);
        const leafData = leafSorted.filter(leaf => { return leaf[1].path.endsWith(displayData.path); } )[0];
        if (typeof leafData !== 'undefined') {
            displayData.worst = leafData[1];
            displayData.worst.name =QbTools.apiNameToFriendlyName(leafData[0]);
            displayData.worst.path = leafData[1].path;
            displayData.worst.key = leafData[0];
            displayData.worst.id = QbTools.pathToId(leafData[1].path);
        }
    }

    return (
        <React.Fragment>
            <BreakdownDisplaySubItem displayData={ displayData } match={ props.match } displayWorst={ props.displayWorst } />
        </React.Fragment>
    );
}

export default BreakdownProxySubItem;
