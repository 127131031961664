import {map} from "lodash";
import moment from "moment";
import {UpperNavigation} from './';
import {useHistory} from 'react-router-dom';
import {BsChevronLeft} from 'react-icons/bs';
import TicketStatusBar from "./TickeStatusBar";
import {AppStateContext, QbApi, Config} from "../modules";
import ListEmptyComponent from "./ListEmptyComponent";
import React, {useContext, useEffect, useState} from 'react';
import {buildUrlParams, transformReportItems} from "../utilities/Transform";

const ReportHistoryListItem = (props) => {
	const {item} = props || {}
	const {createTimestamp} = item || {}
	const {state} = item?.request ?? {}
	const {body} = item?.request ?? {}
	const {name: stateName, progress} = state || {}
	return <div className="container px-0">
		<div className="ticket-list-item">
			<div className="row py-3 px-2 align-items-center">
				<div className="col-6 col-lg-10 ticket-name-list">
					<div className="date-history">
						{ body }
					</div>
				</div>
				<div className="col-6 col-lg-2 d-flex flex-column align-items-end">
					<div className='date'>{ moment.unix(createTimestamp).format('YYYY. MMM. DD. hh:mm:ss') }</div>
					<div className='d-flex'>
						<div className='mr-2'>
							<TicketStatusBar
								state={ stateName }
								progress={ progress }
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

function ReportItemHistory() {
	const history = useHistory();
	const appState = useContext(AppStateContext)
	const [ isLoading, setLoading ] = useState(false)
	const {reportDetailItem} = appState ?? {}
	const {id} = reportDetailItem || {}
	const {subject} = reportDetailItem?.request || {}
	
	const [ reportsList, setReportsList ] = useState([]);
	const fetchDataHandler = async () => {
		try {
			setLoading(true)
			const response = await QbApi.instance.fetchReports(appState,
				buildUrlParams({node: id})
			);
			const filteredList = (response?.message || []).filter(item => String(item?.id) !== String(id))
			const formattedList = transformReportItems(filteredList ?? [])
			setReportsList(formattedList ?? [])
		} catch (e) {
		} finally {
			setLoading(false)
		}
	}
	
	useEffect(() => {
		fetchDataHandler()
	}, [])
	
	return (
		<React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                        </div>
                    }
                    middle={ <div>{ subject }</div> }
                />
            </div>
			<ListEmptyComponent isLoading={isLoading} dataList={reportsList}/>
			{ map(reportsList, item => {
				return <ReportHistoryListItem item={ item }/>
			}) }
		</React.Fragment>
	);
}

export default ReportItemHistory;
