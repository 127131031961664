import moment from "moment";
import * as Yup from "yup";
import {Spinner} from "react-bootstrap";
import {IoIosSend} from 'react-icons/io';
import {MdDelete} from 'react-icons/md';
import {Field, Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {BsChevronLeft, BsFillQuestionCircleFill} from 'react-icons/bs';
import {useHistory} from 'react-router-dom';
import {FaRegCommentDots} from 'react-icons/fa';
import {FiCamera, FiRefreshCcw} from 'react-icons/fi';
import useAsyncState from "../utilities/CustomHooks";
import {ConfirmationModal, UpperNavigation} from './';
import {AppStateContext, Auth, Config, QbApi} from "../modules";
import {FEEDBACK_CATEGORIES, FEEDBACK_CATEGORIES_TO_LOCALIZE_MAP, FETCH_FEED_PAGE_SIZE} from "../utilities/Constant";
import {fetchImage, getBase64} from "../utilities/ImageUtils";
import {AiTwotoneDislike, AiTwotoneLike} from 'react-icons/ai';
import {filter, isEmpty, keyBy, map, size, values} from "lodash";
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {buildFeedbackRequestBody, buildUrlParams, transformFeedbackInfo,} from "../utilities/Transform";
import {showApiResponseMessage} from "../utilities/UiUtils";
import FeedbackLikeModal from "./FeedBackLikeModal";
import {showMessage} from "../utilities/UiUtils";

const feedbackSchema = Yup.object().shape({
	feedback_content: Yup.string().required('Required'),
});

const FeedbackItem = (props) => {
	const {t: webappT} = useTranslation('webapp');
	const {isCurrentUser} = props || {}
	const {createTimestamp, attachments = [], active} = props?.item || {}
	const [ images, setImage ] = useState([])
	const {subject, message, feedbackCategory} = props?.item?.request || {}
	
	const [ isDeleteModal, setDeleteModal ] = useState(false);
	const hideDeleteModal = () => setDeleteModal(false)
	
	const fetchImages = async () => {
		try {
			const promiseList = []
			// eslint-disable-next-line no-unused-expressions
			attachments?.forEach(({id}) => promiseList.push(fetchImage({id})))
			const result = await Promise.all(promiseList)
			setImage(result)
		} catch (e) {
		} finally {
		}
	}
	useEffect(() => {
		if (!isEmpty(attachments) && active === true) {
			fetchImages()
		}
	}, [])
	
	
	const onDeleteHandler = () => {
		if (active) {
			props.onDelete()
			setDeleteModal(false)
		}
	}
	return <div>
		<div className='row mr-0 mb-2'>
			<div className='col-12 mr-0'>
				<div className={ `message-box ${ isCurrentUser ? 'message-right' : 'message-left' }` }>
					{ active ? <Fragment>
						<h4 className="message-box-name">{ subject }</h4>
						{ message }
						<div className="message-time">
							{ moment.unix(createTimestamp).format('YYYY/MM/DD hh:mm A') }
						</div>
						<div className='d-flex flex-wrap'>
							{ images.map(image => <img key={ image } src={ image } className='feedback-image ml-2 mt-2' alt=""/>) }
						</div>
						{ !isEmpty(feedbackCategory) &&
						<div className='feedback-action-buttons d-flex justify-content-between'>
							<div>
								{ feedbackCategory === FEEDBACK_CATEGORIES.LIKE &&
								<AiTwotoneLike className='text-success'/> }
								{ feedbackCategory === FEEDBACK_CATEGORIES.DISLIKE &&
								<AiTwotoneDislike className='text-danger'/> }
							</div>
							<MdDelete className='text-danger qboard-link' onClick={ setDeleteModal }/>
						</div> }
					</Fragment> : <div>
						<span className='text-danger'>{ webappT('thisMessageWasDeleted') }</span>
						<div className="message-time">
							{ moment.unix(createTimestamp).format('YYYY/MM/DD hh:mm A') }
						</div>
					</div> }
				</div>
			</div>
			<ConfirmationModal
				message={ webappT('areYouSureYouWantToDelete') }
				show={ isDeleteModal }
				onHide={ hideDeleteModal }
				onDone={ onDeleteHandler }/>
		</div>
	</div>
}

function Feedback() {
	let history = useHistory();
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
	const appState = useContext(AppStateContext);
	const [ isLoading, setLoading ] = useState(false)
	const [ isLatestRecordsLoading, setLatestRecordsLoading ] = useState(false)
	const [ headerItem, setHeaderItem ] = useAsyncState({})
	const [ isLoadMore, setIsLoadMore ] = useState(true)
	const [ isLikeDislikeModal, setLikeDislikeModal ] = useState(false)
	const [ feedbackCategory, setFeedbackCategory ] = useState(FEEDBACK_CATEGORIES.LIKE)
	const [ feedbackList, setFeedbackList ] = useState([])
	const [ uploadImagesList, setUploadImagesList ] = useState([]);
	const [ isUnsavedFeedbackModal, setUnsavedFeedbackModal ] = useState(false);
	const formikInnerRef = useRef()
	const uploadImageRef = useRef();
	const showUnsavedFeedbackModal = () => setUnsavedFeedbackModal(true)
	const hideUnsavedFeedbackModal = () => setUnsavedFeedbackModal(false)
	
	const showLikeDislikeModal = () => setLikeDislikeModal(true)
	const hideLikeDislikeModal = () => setLikeDislikeModal(false)
	
	const formValues = {feedback_content: ''};
	const userData = Auth?.getData()?.user;
	
	useEffect(() => {
		fetchInitialDataHandler()
	}, [])
	
	const fetchInitialDataHandler = async () => {
		setLoading(true)
		const headerItemsList = await fetchHeaderInfo()
		const latestItem = isEmpty(headerItemsList) ? {} : headerItemsList[0]
		await setHeaderItem(latestItem)
		const params = isEmpty(latestItem) ? {} : {node: latestItem.id}
		fetchDataHandler(params)
	}
	
	const fetchHeaderInfo = async () => {
		try {
			const response = await QbApi.instance.fetchFeedback(appState, buildUrlParams({node: 0}));
			return transformFeedbackInfo(response?.message ?? [])
		} catch (e) {
		} finally {
		}
	}
	
	const fetchDataHandler = async (params) => {
		if (!isLoadMore) {
			return
		}
		
		if (!isEmpty(headerItem)) {
			params.node = headerItem.id
		}
		try {
			setLoading(true)
			const response = await QbApi.instance.fetchFeedback(appState,
				buildUrlParams({...params, paged: true, count: FETCH_FEED_PAGE_SIZE})
			);
			if (isEmpty(response?.message)) {
				setIsLoadMore(false)
			}
			const formattedList = transformFeedbackInfo(response?.message ?? [])
			setFeedbackList(feedbackList?.concat(formattedList))
		} catch (e) {
		} finally {
			setLoading(false)
		}
	}
	
	const loadEarlierMessageHandler = () => {
		const params = {paged: true}
		const lastItem = isEmpty(feedbackList) ? {} : feedbackList[size(feedbackList) - 1]
		if (!isEmpty(lastItem)) {
			params.time = lastItem?.createTimestamp
		}
		fetchDataHandler(params)
	}
	
	const fetchLatestRecordsHandler = async () => {
		const params = {node: 0}
		const firstItem = isEmpty(feedbackList) ? {} : feedbackList[0]
		if (isEmpty(headerItem) && isEmpty(firstItem)) {
			return fetchInitialDataHandler()
		}
		if (!isEmpty(firstItem)) {
			params.time = firstItem?.changeTimestamp
		}
		
		if (!isEmpty(headerItem)) {
			params.node = headerItem?.id
		}
		
		try {
			setLatestRecordsLoading(true)
			const {message = []} = await QbApi.instance.fetchFeedback(appState, buildUrlParams(params));
			const updatedDataMap = {...keyBy(message, 'id'),}
			const formattedList = transformFeedbackInfo(values(updatedDataMap) ?? [])
			setFeedbackList((formattedList || []).concat(feedbackList))
		} catch (e) {
		} finally {
			setLatestRecordsLoading(false)
		}
	}
	
	const formSubmit = async (values, {setSubmitting, resetForm}) => {
        if(process.env.REACT_APP_MAX_ATTACHMENT && uploadImagesList.length > process.env.REACT_APP_MAX_ATTACHMENT){
            showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedCount"), type: "error"})
            return;
        }
		setSubmitting(true)
		try {
			const {userName: recipientUserName, fullName: recipientName} = userData?.chanonicalName || {}
			const {feedback_content} = values || {}
			const body = buildFeedbackRequestBody({
				topId: headerItem?.id || null,
				recipientUserName,
				recipientName,
				messageContent: feedback_content,
				feedbackCategory,
				images: uploadImagesList,
				senderUserName:userData?.chanonicalName?.fullName
			})
			const rawResponse = await QbApi.instance.sendFeedback(appState, body);
			const {message} = await rawResponse.json()
			if (!isEmpty(message[0])) {
				setFeedbackList(transformFeedbackInfo([ message[0], ...feedbackList ]))
			}
			setFeedbackCategory(FEEDBACK_CATEGORIES.LIKE)
		} catch (e) {
		} finally {
			setSubmitting(false)
			resetForm(formValues)
			setUploadImagesList([])
		}
	}
	
	const deleteFeedback = async (deleteItem) => {
		const response = await QbApi.instance.deleteFeedback(appState, {id: deleteItem?.id});
		showApiResponseMessage({response, successMessage: webappT('messageDeletedSuccessfully')})
		const {status} = response || {}
		if (String(status) === '200') {
			const updatedList = filter(feedbackList, oldItem => String(oldItem?.id) !== String(deleteItem.id))
			setFeedbackList(updatedList)
		}
	}
	
	const handleScroll = ({target}) => {
		const {scrollHeight, scrollTop, clientHeight} = target || {}
		const bottom = scrollHeight + scrollTop
		if (bottom === clientHeight) {
			loadEarlierMessageHandler()
		}
	}
	
	const uploadProfileImageHandler = async ({target}) => {
		const promiseList = map(target?.files, file => getBase64(file))
		const imagesList = await Promise.all(promiseList)
        let successful = true;
        if(process.env.REACT_APP_MAX_FILE_SIZE){
            for (var i = 0; i < target?.files.length; i++) {
                if(target?.files[i].size > process.env.REACT_APP_MAX_FILE_SIZE * 1000){
                    successful = false;
                }
            }
        }
        if(successful){
            setUploadImagesList([ ...uploadImagesList || [], ...imagesList || [] ])
        } else {
            showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedSize"), type: "error"})
        }
	}
	
	const onClickCameraIconHandler = () => {
		uploadImageRef.current.click();
	}
	
	const removeImageHandler = (deletedIndex) => {
		setUploadImagesList(filter(uploadImagesList, (_, index) => index !== deletedIndex))
	}
	
	const goBackPressHandler = () => {
		if (formikInnerRef.current.dirty || !isEmpty(uploadImagesList)) {
			return showUnsavedFeedbackModal()
		}
		return history.goBack()
		
	}
	
	const getLikeDislikeIcon = () => {
		const commonProps = {className: "c-pointer feedback-icon mx-2", onClick: showLikeDislikeModal}
		if (feedbackCategory === FEEDBACK_CATEGORIES.LIKE) {
			return <AiTwotoneLike { ...commonProps }/>
		} else if (feedbackCategory === FEEDBACK_CATEGORIES.DISLIKE) {
			return <AiTwotoneDislike { ...commonProps }/>
		} else if (feedbackCategory === FEEDBACK_CATEGORIES.WOULD_LIKE) {
			return <BsFillQuestionCircleFill { ...commonProps }/>
		}
		return <FaRegCommentDots  { ...commonProps }/>
    }
    
    const dataRefreshIconStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
	
	return (
		<React.Fragment>
			<div className={ (Config.appearance.enableStickyTop) ? "sticky-top" : "" }>
				<UpperNavigation
					leftSide={
						<div className="qboard-link" onClick={ goBackPressHandler }>
							<BsChevronLeft className="icon-with-bg float-none ml-1"/>
						</div>
					}
					middle={
						t('localizeFeedbackTitle')
					}
					rightSide={
						<div className='d-flex align-content-center'>
							{ isLatestRecordsLoading ?
								<Spinner animation="border" size="lg" variant="primary"/> :
								<FiRefreshCcw
                                    style={ dataRefreshIconStyle }
									className="data-refresh-icon"
									onClick={ fetchLatestRecordsHandler }/>
							}
						</div>
					}
				/>
			</div>
			<div className="px-0 mb-0 feedback-list-container">
				<div
					style={ {overflowY: 'scroll'} }
					className='d-flex flex-column-reverse vh-100'
					onScroll={ handleScroll }>
					{ isLoading && isEmpty(feedbackList) &&
					<div className='d-flex justify-content-center mb-4'>
						<Spinner animation="border" size="lg" variant="primary"/>
					</div>
					}
                    <div className="d-flex flex-column-reverse messages-container">
                        { map(feedbackList, (item, index) =>
                            <FeedbackItem
                                item={ item }
                                index={ index }
                                key={ String(item.id) }
                                onDelete={ () => deleteFeedback(item) }
                                isCurrentUser={ item?.sender.username === userData?.chanonicalName?.userName }
                            />) }
                    </div>
				</div>
				<div className="position-fixed fixed-bottom bottom-form-container">
					{ !isEmpty(uploadImagesList) && <div className='d-flex flex-wrap mt-3'>
						{ uploadImagesList.map((item, index) =>
							<div className='report-upload-image' key={ item }>
								<img src={ item } alt=""/>
								<button
									className='btn btn-danger remove-upload'
									onClick={ () => removeImageHandler(index) }
								>X
								</button>
							</div>)
						}
					</div>
					}
					<div className='d-flex'>
						<div className="col-12 py-2 px-2 d-flex align-items-center">
							{ getLikeDislikeIcon() }
							<div onClick={ onClickCameraIconHandler } className='qboard-link'>
								<input
									type='file'
									multiple
									className='d-none'
									ref={ uploadImageRef }
									accept="image/x-png,image/gif,image/jpeg"
									onChange={ uploadProfileImageHandler }
									onClick={ (event) => {
										event.target.value = null
									} }
								/>
								<FiCamera className="feedback-icon mx-2"/>
							</div>
							<Formik
								initialValues={ formValues }
								onSubmit={ formSubmit }
								innerRef={ formikInnerRef }
								validationSchema={ feedbackSchema }
							>
								{ ({dirty, isSubmitting}) => {
									return <Form className="w-100">
										<div className="form-container-with-button">
											<Field
												type="text"
												name="feedback_content"
												className="form-control feedback-content"
												placeholder={ webappT(FEEDBACK_CATEGORIES_TO_LOCALIZE_MAP[feedbackCategory]) }
											/>
											{ isSubmitting ?
												<div className='feedback-submit-loading'>
													<Spinner animation="border" size="sm" variant="primary"/>
												</div> :
												<button className="btn form-submit" type="submit"><IoIosSend/></button>
											}
										</div>
									</Form>
								} }
							</Formik>
						</div>
					</div>
				</div>
			</div>
			<ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoBack') }
				show={ isUnsavedFeedbackModal }
				onHide={ hideUnsavedFeedbackModal }
				onDone={ () => history.goBack() }/>
			<FeedbackLikeModal
				show={ isLikeDislikeModal }
				onHide={ hideLikeDislikeModal }
				setFeedbackCategory={ setFeedbackCategory }
				feedbackCategory={ feedbackCategory }
			/>
		</React.Fragment>
	);
}

export default Feedback;
