import * as Yup from 'yup';
import moment from "moment";
import {UpperNavigation} from './';
import {TextField} from './form';
import {FileUploadIcon} from '../icons/';
import {Field, Form, Formik} from 'formik';
import {useDropzone} from 'react-dropzone'
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {BsChevronLeft} from 'react-icons/bs';
import {showMessage} from "../utilities/UiUtils";
import {filter, find, isEmpty, keyBy} from 'lodash'
import {AppStateContext, Auth, QbApi, Config} from '../modules';
import {convertImageToBase64} from "../utilities/ImageUtils";
import {ConfirmationModal, ReviewReportModal} from "./index";
import {buildReportRequestBody} from "../utilities/Transform";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {USERS_CONFIG, USERS_CONFIG_TO_BRANCH_MAPPING} from "../utilities/Constant";

const reportSchema = Yup.object().shape({
	problemHeading: Yup.string().required('Required'),
	failureDescription: Yup.string().required('Required'),
	affectedSystems: Yup.string().required('Required'),
	affectedUsers: Yup.string().required('Required'),
	urgency: Yup.string().required('Required'),
	// branch: Yup.string().required('Required'),
});

const UploadImage = (props) => {
    const {t: webappT} = useTranslation('webapp');
    const {uploadImagesList, setUploadImagesList} = props || {}
	const onDrop = async (files) => {
        const newImages = await Promise.all(files.map(item => convertImageToBase64(item)))
        let successful = true;
        if(process.env.REACT_APP_MAX_FILE_SIZE){
            for (var i = 0; i < files.length; i++) {
                if(files[i].size > process.env.REACT_APP_MAX_FILE_SIZE * 1000){
                    successful = false;
                }
            }
        }
        if(successful){
            setUploadImagesList([ ...uploadImagesList, ...newImages ])
        } else {
            showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedSize"), type: "error"})
        }
	}
	const {getRootProps, getInputProps} = useDropzone({onDrop})
	const removeImageHandler = (deletedIndex) => {
		setUploadImagesList(filter(uploadImagesList, (_, index) => index !== deletedIndex))
	}
	
	return (
		<div>
			<div { ...getRootProps() }>
				<input { ...getInputProps() } />
				<div className="dz-message">
					<FileUploadIcon/>
				</div>
			</div>
			<div className='d-flex flex-wrap mt-3'>
				{ uploadImagesList.map((item, index) =>
					<div className='report-upload-image'>
						<img key={ String(index) } src={ item } alt=""/>
						<button
							className='btn btn-danger remove-upload'
							onClick={ () => removeImageHandler(index) }
						>X
						</button>
					</div>)
				}
			</div>
		</div>
	)
}


function Report() {
	let history = useHistory();
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
	const [ isReviewReport, setReviewReport ] = useState(false)
	const [ isSendReportLoading, setSendReportLoading ] = useState(false);
	const [ uploadImagesList, setUploadImagesList ] = useState([])
	const [ isUnsavedReportModal, setUnsavedReportModal ] = useState(false);
	const appState = useContext(AppStateContext);
	const formikInnerRef = useRef()
	const showReviewReportModal = () => setReviewReport(true)
	const hideReviewReportModal = () => setReviewReport(false)
	
	const showUnsavedReportModal = () => setUnsavedReportModal(true)
	const hideUnsavedReportModal = () => setUnsavedReportModal(false)
	
	useEffect(() => {
		formikInnerRef.current.validateForm();
	})
	let formValues = {
		problemHeading: '',
		failureDescription: '',
		affectedSystems: '',
		affectedUsers: 'one',
		urgency: 'normal',
		branch: '0',
	};
	
	const userData = Auth?.getData()?.user;
	const userName = userData?.chanonicalName?.userName
	const senderName = userName
	const senderUserName = userData?.chanonicalName?.userName
	
	var itemSelector = [];
	const isBranchesAllowed = userData?.topHierarchyLevel !== USERS_CONFIG.HEAD_QUARTER
	const branchTitleKey = USERS_CONFIG_TO_BRANCH_MAPPING[userData?.topHierarchyLevel]
	
	const groups = [ {id: '0', name: webappT('notRelevant')} ].concat(userData.groups);
	if (!senderUserName?.includes('hq')) {
		for (const group of groups) {
			itemSelector.push(
				<option key={ String(group.id) } value={ group.id }>{ group.name }</option>
			);
		}
	}
	
	const showEmailStatusHandler = (response) => {
		const successRecipientsList = (response?.message?.[0].recipients || [])?.filter(item => {
			return item?.status?.state === 'success'
		})
		if (isEmpty(successRecipientsList)) {
			showMessage({title:webappT("toastTitleFailed"), message: webappT('serverIsDownScheduleEmailLater'), type: 'error'})
		} else {
			showMessage({title:webappT("toastTitleSuccess"), message: webappT('reportedSuccessfully')})
		}
	}
	
	
	const submitReportHandler = async () => {
        if(process.env.REACT_APP_MAX_ATTACHMENT && uploadImagesList.length > process.env.REACT_APP_MAX_ATTACHMENT){
            showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedCount"), type: "error"})
            return;
        }
		setSendReportLoading(true)
		try {
			const {
				problemHeading,
				failureDescription,
				affectedSystems,
				affectedUsers,
				urgency,
				branch,
			} = formikInnerRef?.current?.values
			const {resetForm} = formikInnerRef?.current
			const groupsMap = keyBy(groups, 'id')
			const initialData = {
				senderUserName,
				senderName,
				subject: problemHeading,
				body: failureDescription,
				attachments: uploadImagesList,
				affectedSystems,
				data: {
					subject: problemHeading,
					description: problemHeading,
					system: affectedSystems,
					effect: affectedUsers,
					urgency: urgency,
					state: {name: "idle", timestamp: moment().unix(), progress: 0}
				}
			}
			if (isBranchesAllowed) {
				initialData.data.group = {id: branch, name: groupsMap?.[branch]?.name ?? ''}
			}
			const body = buildReportRequestBody(initialData)
			const rawResponse = await QbApi.instance.sendReport(appState, body);
			const {status} = rawResponse || {}
			const response = await rawResponse.json()
			
			if (String(status) === '200' || String(status) === '201') {
				showEmailStatusHandler(response)
			} else {
				showMessage({title:webappT("toastTitleFailed"), message: webappT('errorIsFound'), type: 'error'})
			}
			setUploadImagesList([])
			setReviewReport(false)
			resetForm({})
			setUploadImagesList([])
		} catch (e) {
		} finally {
			setSendReportLoading(false)
		}
	}
	
	const goBackPressHandler = () => {
		if (formikInnerRef.current.dirty || !isEmpty(uploadImagesList)) {
			return showUnsavedReportModal()
		}
		return history.goBack()
		
    }
    
    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
	
	
	return (
		<React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ goBackPressHandler }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                        </div>
                    }
                    middle={
                        t('localizeTopDeskNewTitle')
                    }
                />
            </div>
			
			<div className="container px-0 mt-2 body_container">
				<Formik
					initialValues={ formValues }
					innerRef={ formikInnerRef }
					validationSchema={ reportSchema }
				>
					{ ({isValid}) => {
						return <Form>
							<TextField name="problemHeading" label={ `${t('localizeTopDeskSubjectTitle')}*` }/>
							<TextField name="failureDescription" label={ `${t('localizeTopDeskFailureTitle')}*` }
							           component='textarea'/>
							<TextField name="affectedSystems" label={ `${t('localizeTopDeskSystemTitle')}*` }/>
							<div className="row px-4 align-items-center py-2 setting-item">
								<label htmlFor="affectedUsers"
								       className="col-12 font-weight-bold">{ t('localizeTopDeskEffect') }</label>
							</div>
							<div className="row form-group affectedUsers form-row-group mx-4 my-1 py-4" role="group"
							     aria-labelledby="my-radio-group">
								<div className="col-4 d-flex align-items-center justify-content-start px-2">
									<Field type="radio" name="affectedUsers" value="one" id='one'/>
									<label className="report_checkbox_label mb-0" htmlFor={ 'one' }>
										{ t('localizeTopDeskEffectOne') }
									</label>
								</div>
								<div className="col-4 d-flex align-items-center justify-content-start px-2">
									<Field type="radio" name="affectedUsers" value="multiple" id='multiple'/>
									<label className="report_checkbox_label mb-0" htmlFor='multiple'>
										{ t('localizeTopDeskEffectMultiple') }
									</label>
								</div>
								<div className="col-4 d-flex align-items-center justify-content-start px-2">
									<Field type="radio" name="affectedUsers" value="all" id="all"/>
									<label className="report_checkbox_label mb-0" htmlFor="all">
										{ t('localizeTopDeskEffectAll') }
									</label>
								</div>
							</div>
							<div className="row px-4 align-items-center py-2 setting-item">
								<label htmlFor="urgency"
								       className="col-12 font-weight-bold">{ t('localizeTopDeskUrgency') }</label>
							</div>
							<div className="row form-group urgency form-row-group mx-4 my-1 py-4" role="group"
							     aria-labelledby="my-radio-group">
								<div className="col-4 d-flex align-items-center justify-content-start px-2">
									<Field type="radio" name="urgency" value="normal" id="normal"/>
									<label className="report_checkbox_label mb-0" htmlFor="normal">
										{ t('localizeTopDeskUrgencyNormal') }
									</label>
								</div>
								<div className="col-4 d-flex align-items-center justify-content-start px-2">
									<Field type="radio" name="urgency" value="urgent" id="urgent"/>
									<label className="report_checkbox_label mb-0" htmlFor="urgent">
										{ t('localizeTopDeskUrgencyUrgent') }
									</label>
								</div>
								<div className="col-4 d-flex align-items-center justify-content-start px-2">
									
									<Field type="radio" name="urgency" value="critical" id="critical"/>
									<label className="report_checkbox_label mb-0" htmlFor="critical">
										{ t('localizeTopDeskUrgencyCritical') }
									</label>
								</div>
							</div>
							{ isBranchesAllowed && <>
								<div className="row px-4 align-items-center py-2 setting-item">
									<label htmlFor="branch"
									       className="col-12 font-weight-bold">{ webappT(branchTitleKey) }</label>
								</div>
								<div className="row px-4 align-items-center py-2 setting-item select-container">
									<div className="col-12">
										<Field className="form-control" name="branch" as="select"
										       placeholder="Select the Branch">
											{ itemSelector }
										</Field>
									</div>
								</div>
							</> }
							<div className="row px-4 align-items-center py-2 setting-item">
								<label htmlFor="branch"
								       className="col-12 font-weight-bold">{ t('localizeTopDeskAttachmentTitle') }</label>
							</div>
							<div className="row px-4 pb-3 file-upload-container">
								<div className="col-12">
									<div className="file-upload-container">
										<UploadImage
											uploadImagesList={ uploadImagesList }
											setUploadImagesList={ setUploadImagesList }
										/>
									</div>
								</div>
							</div>
							<div className="row px-4 align-items-center py-1 setting-item mb-3">
								<div className="col-12">
									<button
                                        className="btn btn-primary btn-block"
                                        style={ submitButtonStyle }
										onClick={ showReviewReportModal }
										disabled={ !isValid }>
										{ t('localizeTopDeskButtonSendTitle') }
									</button>
								</div>
							</div>
						</Form>
					} }
				</Formik>
			</div>
			<ReviewReportModal
				show={ isReviewReport }
				onHide={ hideReviewReportModal }
				onDone={ submitReportHandler }
				userName={ userName }
				isLoading={ isSendReportLoading }
				reportData={ formikInnerRef?.current?.values }
				isBranchesAllowed={ isBranchesAllowed }
				branchTitleKey={ branchTitleKey }
				groupItem={ find(groups, {id: formikInnerRef?.current?.values?.branch}) }
			/>
			<ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoBack') }
				show={ isUnsavedReportModal }
				onHide={ hideUnsavedReportModal }
				onDone={ () => history.goBack() }/>
		</React.Fragment>
	);
}

export default Report;
