import {TextField} from './form';
import {isEmpty} from 'lodash'
import {LighImageBox} from "./index";
import {Spinner} from "react-bootstrap";
import {Field, Form, Formik} from 'formik';
import {AppStateContext, Config} from '../modules';
import {useTranslation} from 'react-i18next';
import {BsChevronLeft} from 'react-icons/bs';
import UpperNavigation from './UpperNavigation';
import TicketStatusBar from "./TickeStatusBar";
import {fetchImage} from "../utilities/ImageUtils";
import React, {useContext, useEffect, useState} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';


const AttachmentList = React.memo((props) => {
	const {attachments = [],} = props || {}
	const [ images, setImage ] = useState([])
	const [ isOpenModalBox, setModalBox ] = useState(false)
	const [ isImageLoading, setImageLoading ] = useState(false)
	
	const openModalBox = () => setModalBox(true)
	const closeModalBox = () => setModalBox(false)
	
	const fetchImages = async () => {
		try {
			setImageLoading(true)
			const promiseList = []
			// eslint-disable-next-line no-unused-expressions
			attachments?.forEach(({id}) => promiseList.push(fetchImage({id})))
			const result = await Promise.all(promiseList)
			setImage(result)
		} catch (e) {
		} finally {
			setImageLoading(false)
		}
	}
	useEffect(() => {
		if (!isEmpty(attachments)) {
			fetchImages()
		}
		return fetchImages
	}, [])
	return (
		<div>
			{ isImageLoading && <div className='d-flex justify-content-center mt-3'>
				<Spinner animation="border" variant="primary"/>
			</div> }
			<div className='d-flex flex-wrap mt-3'>
				{ images.map((url) => {
					return <div className='report-upload-image'>
						<img
							alt=""
							key={ url }
							src={ url }
							style={ {width: 100, height: 100} }
							onClick={ openModalBox }
						/>
					</div>
				}) }
			</div>
			<LighImageBox
				images={ images }
				isOpen={ isOpenModalBox }
				onClose={ closeModalBox }
			/>
		</div>
	)
})

function ReportItem() {
	const history = useHistory();
	const location = useLocation();
	
	const {t} = useTranslation();
	const {reportDetailItem} = useContext(AppStateContext)
	
	if (isEmpty(reportDetailItem) && isEmpty(reportDetailItem?.request)) {
		return <Redirect
			to={ {pathname: '/', state: {from: location},} }
		/>
	}
	const {attachments} = reportDetailItem || {}
	const {subject, description, system, effect, urgency, group, state} = reportDetailItem?.request || {}
	const {name: stateName, progress} = state || {}
	const {id: groupId, name: groupName} = group || {}
	let formValues = {
		problemHeading: subject,
		failureDescription: description,
		affectedSystems: system,
		affectedUsers: effect,
		urgency: urgency,
		branch: '',
	};
	
	const itemSelector = [];
	itemSelector.push(
		<option key={ groupId } value={ groupId }>{ groupName }</option>
	);
	
	const navigateToHistory = () => {
		history.push(`/report/item/history`)
	}
	
	return (
		<React.Fragment>
			<div className={ (Config.appearance.enableStickyTop) ? "sticky-top" : "" }>
				<UpperNavigation
					leftSide={
						<div className="qboard-link" onClick={()=>{
							history.goBack()
						} }>
							<BsChevronLeft className="icon-with-bg float-none ml-1"/>
						</div>
					}
					middle={
						t('localizeTopDeskDetailTitle')
					}
					rightSide={
						<TicketStatusBar
							state={ stateName }
							progress={ progress }
							onClick={ navigateToHistory }
						/>
					}
				/>
			</div>
			
			<div className="container px-0 pt-4 body_container ">
				<Formik initialValues={ formValues }>
					<Form>
						<TextField name="problemHeading" label={ t('localizeTopDeskSubjectTitle') }
						           disabled="disabled"/>
						<TextField name="failureDescription" label={ t('localizeTopDeskFailureTitle') }
						           disabled="disabled"/>
						<TextField name="affectedSystems" label={ t('localizeTopDeskSystemTitle') }
						           disabled="disabled"/>
						
						<div className="row px-4 align-items-center py-2 setting-item">
							<label htmlFor="affectedUsers"
							       className="col-12 font-weight-bold">{ t('localizeTopDeskEffect') }</label>
						</div>
						<div className="row form-group form-row-group archive mx-4 my-1 py-4" role="group"
						     aria-labelledby="my-radio-group">
							<div className="col-4 d-flex align-items-center justify-content-start px-2">
								
								<Field type="radio" name="affectedUsers" value="One" disabled="disabled" checked/>
								<label className="report_checkbox_label mb-0">
									{ t('localizeTopDeskEffectOne') }
								</label>
							</div>
							<div className="col-4 d-flex align-items-center justify-content-start px-2">
								<Field type="radio" name="affectedUsers" value="Multiple" disabled="disabled"/>
								<label className="report_checkbox_label mb-0">
									{ t('localizeTopDeskEffectMultiple') }
								</label>
							</div>
							<div className="col-4 d-flex align-items-center justify-content-start px-2">
								<Field type="radio" name="affectedUsers" value="All" disabled="disabled"/>
								<label className="report_checkbox_label mb-0">
									{ t('localizeTopDeskEffectAll') }
								</label>
							</div>
						</div>
						
						<div className="row px-4 align-items-center py-2 setting-item">
							<label htmlFor="urgency"
							       className="col-12 font-weight-bold">{ t('localizeTopDeskUrgency') }</label>
						</div>
						<div className="row form-group urgency form-row-group archive mx-4 my-1 py-4" role="group"
						     aria-labelledby="my-radio-group">
							<div className="col-4 d-flex align-items-center justify-content-start px-2">
								<Field type="radio" name="urgency" value="normal" id="normal" disabled="disabled"/>
								<label className="report_checkbox_label mb-0" htmlFor="normal">
									{ t('localizeTopDeskUrgencyNormal') }
								</label>
							</div>
							<div className="col-4 d-flex align-items-center justify-content-start px-2">
								<Field type="radio" name="urgency" value="urgent" id="urgent" disabled="disabled"/>
								<label className="report_checkbox_label mb-0" htmlFor="urgent">
									{ t('localizeTopDeskUrgencyUrgent') }
								</label>
							</div>
							<div className="col-4 d-flex align-items-center justify-content-start px-2">
								
								<Field type="radio" name="urgency" value="critical" id="critical" disabled="disabled"/>
								<label className="report_checkbox_label mb-0" htmlFor="critical">
									{ t('localizeTopDeskUrgencyCritical') }
								</label>
							</div>
						</div>
						{ !isEmpty(groupName) && <>
							<div className="row px-4 align-items-center py-2 setting-item">
								<label htmlFor="branch" className="col-12 font-weight-bold">{ t('localizeStore') }</label>
							</div>
							<div className="row px-4 align-items-center py-2 setting-item select-container">
								<div className="col-12">
									<Field className="form-control branch" name="branch" as="select"
									       placeholder="Select the Branch" disabled="disabled">
										{ itemSelector }
									</Field>
								</div>
							</div>
						</>
						}
						<div className="row px-4 align-items-center py-2 setting-item">
							<label htmlFor="branch"
							       className="col-12 font-weight-bold">{ t('localizeTopDeskAttachmentTitle') }</label>
						</div>
						<div className="row px-4 pb-3 file-upload-container">
							<div className="col-12">
								<div className="file-upload-container">
									<AttachmentList attachments={ attachments }/>
								</div>
							</div>
						</div>
					</Form>
				</Formik>
			</div>
		</React.Fragment>
	);
}

export default ReportItem;
