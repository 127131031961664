import {isEmpty, map} from 'lodash'
import {UpperNavigation} from "./index";
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import React, {useContext, useState} from 'react'
import FiltersDraggableList from "./DraggableList";
import {BsChevronLeft} from 'react-icons/bs';
import {FILTERS_LIST_ANALYTICS_HQ_DAY, FILTERS_LIST_ANALYTICS_HQ_WEEK, FILTERS_LIST_ANALYTICS_HQ_MONTH} from "../utilities/Constant";
import {FILTERS_LIST_ANALYTICS_RM_DAY, FILTERS_LIST_ANALYTICS_RM_WEEK, FILTERS_LIST_ANALYTICS_RM_MONTH} from "../utilities/Constant";
import {FILTERS_LIST_ANALYTICS_BM_DAY, FILTERS_LIST_ANALYTICS_BM_WEEK, FILTERS_LIST_ANALYTICS_BM_MONTH} from "../utilities/Constant";
import {FILTERS_LIST_ANALYTICS_SRM_DAY, FILTERS_LIST_ANALYTICS_SRM_WEEK, FILTERS_LIST_ANALYTICS_SRM_MONTH} from "../utilities/Constant";
import {AppStateContext, Auth, QbApi, QbApiTimeoutError, QbTools, Config} from "../modules";
import { showMessage } from '../utilities/UiUtils';

const AnalyticsFilters = (props) => {
    const appState = useContext(AppStateContext);
    const userMerged = Auth.getData().userMerged;
    //const groups = userMerged.groups; 
    const topHierarchyLevel = userMerged.topHierarchyLevel.toLowerCase();
	const userProfile = Auth.getData().userProfile || {}
    const filterSort = userProfile?.config?.filterSort || []
    let initialFiltersList;
    let defaultFiltersList;
    let finalFiltersList = [];
    let unusedFiltersList = [];
    const isDay = useRouteMatch('*day*');
    const isWeek = useRouteMatch('*week*');
    const isMonth = useRouteMatch('*month*');
    
    if (topHierarchyLevel === 'hq'){
        if(isDay){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_HQ_DAY : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_HQ_DAY
        } else if(isWeek){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_HQ_WEEK : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_HQ_WEEK
        } else if(isMonth){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_HQ_MONTH : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_HQ_MONTH
        }
    } else if (topHierarchyLevel === 'rv'){
        if(isDay){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_RM_DAY : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_RM_DAY
        } else if(isWeek){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_RM_WEEK : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_RM_WEEK
        } else if(isMonth){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_RM_MONTH : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_RM_MONTH
        }
    } else if (topHierarchyLevel === 'uv'){
        if(isDay){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_BM_DAY : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_BM_DAY
        } else if(isWeek){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_BM_WEEK : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_BM_WEEK
        } else if(isMonth){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_BM_MONTH : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_BM_MONTH
        }
    } else if (topHierarchyLevel === 'tv'){
        if(isDay){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_SRM_DAY : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_SRM_DAY
        } else if(isWeek){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_SRM_WEEK : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_SRM_WEEK
        } else if(isMonth){
            initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_ANALYTICS_SRM_MONTH : filterSort
            defaultFiltersList = FILTERS_LIST_ANALYTICS_SRM_MONTH
        }
    }
    var s = 0;
    var i = 0;

    for (s = 0; s < initialFiltersList.length; s++) {
        let exists = false;
        for (i = 0; i < defaultFiltersList.length; i++) {
            if(defaultFiltersList[i].id === initialFiltersList[s].id){
                exists = true
            }
        }
        (!exists)&& unusedFiltersList.push(initialFiltersList[s])
    }

    for (i = 0; i < defaultFiltersList.length; i++) {
        let exists = false;
        for (s = 0; s < initialFiltersList.length; s++) {
            if(defaultFiltersList[i].id === initialFiltersList[s].id){
                exists = true
                finalFiltersList.push(initialFiltersList[s])
            }
        }
        (!exists)&& finalFiltersList.push(defaultFiltersList[i])
    }
    finalFiltersList.sort(function(a, b){return a.position - b.position});
    finalFiltersList = QbTools.filterDisabledComponentsFilter(finalFiltersList);

	const [ filtersList, setFiltersList ] = useState(finalFiltersList)
	const history = useHistory()
	const {t: webappT} = useTranslation('webapp')
	const {t} = useTranslation();
	const goBackPressHandler = () => {
		return history.goBack()
    }
	
	const onDonePressHandler = async () => {
		const reformattedList = map(filtersList, (item, index) => {
			return {...item, position: index + 1}
        })
        const updatedUserProfile = Auth.updateUserProfile({config: {filterSort: reformattedList.concat(unusedFiltersList)}});
		try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage( { title: t('webapp:toastTitleFailed'), message: t('webapp:toastDescriptionFailedUpdate'), type: 'error' } )
			} else {
				throw error;
			}
		}
		goBackPressHandler()
    }

	/*const onResetPressHandler = async () => {
		const updatedUserProfile = Auth.updateUserProfile({config: {filterSort: ''}});
		try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage( { title: t('webapp:toastTitleFailed'), message: t('webapp:toastDescriptionFailedUpdate'), type: 'error' } )
			} else {
				throw error;
			}
		}
		history.go(0)
    }*/
    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
	
	return <div>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
            <UpperNavigation
                leftSide={
                    <div className='text-white qboard-link' onClick={ goBackPressHandler }>
                        <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                    </div>
                }
                middle={ webappT('configure') }
                rightSide={
                    <button style={ submitButtonStyle } className="btn btn-primary bm-sm" onClick={ onDonePressHandler }>
                        { webappT('done') }
                    </button>
                }
            />
            </div>
		<div>
			<FiltersDraggableList
				dataList={ filtersList }
				setData={ (updatedFiltersList) => setFiltersList(updatedFiltersList) }/>
		</div>
	</div>
}

export default AnalyticsFilters
