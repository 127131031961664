import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {AppStateContext, Auth, Constants, QbApi, QbErrorBoundary, QbTools} from '../modules';
import {
	MetricProxyHqAverageServingTime,
	MetricProxyHqAverageWaitingTime,
	MetricProxyHqNoShow,
	MetricProxyHqServedCustomers,
	MetricProxyHqServiceLevel,
	MetricProxyServedCustomersAndAverageWaitingTime
} from './';
import {isEmpty} from "lodash";
import {FILTERS_LIST_HQ} from "../utilities/Constant";

function DashboardHqHeadquarter(props) {
    const ComponentsMap = QbTools.filterDisabledComponentsMap( {
        'MetricProxyHqServiceLevel': MetricProxyHqServiceLevel,
        'MetricProxyHqAverageWaitingTime': MetricProxyHqAverageWaitingTime,
        'MetricProxyHqAverageServingTime': MetricProxyHqAverageServingTime,
        'MetricProxyHqNoShow': MetricProxyHqNoShow,
        'MetricProxyHqServedCustomers': MetricProxyHqServedCustomers,
        'MetricProxyServedCustomersAndAverageWaitingTime': MetricProxyServedCustomersAndAverageWaitingTime,
    } );

	const appState = useContext(AppStateContext);
	
	const userProfile = Auth.getData().userProfile || {}
	const filterSort = userProfile?.config?.filterSort || []
    let initialFiltersList = isEmpty(filterSort) ? FILTERS_LIST_HQ : filterSort
    const defaultFiltersList = FILTERS_LIST_HQ;
    let empty = true;

    for (var s = 0; s < initialFiltersList.length; s++) {
        for (var i = 0; i < defaultFiltersList.length; i++) {
            if(defaultFiltersList[i].id === initialFiltersList[s].id){
                empty = false
            }
        }
    }
    if(empty){ initialFiltersList=defaultFiltersList; }
	
	const from = QbTools.nowApiTimeOffset().set('hour', 0).set('minute', 0).set('second', 0);
	const to = QbTools.nowApiTimeOffset().set('second', 0);
	const apiFrom = from.format();
	const apiTo = to.format();
	const apiTargetPath = props.match.params.id;
	
	const [ metricData, setMetricData ] = useState({
		_state: Constants.ajaxState.init, _failed: false,
		current: {},
		children: {},
		leaf: {},
		parent: {},
		top: {}
	});
	useEffect(() => {
		setMetricData(prevState => ({...prevState, _state: Constants.ajaxState.loading, _failed: false}));
		var indices = [];
		
		indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'current', moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'branchDetails' ], (data) => QbApi.updateMetricData(setMetricData, 'current', data)));
		indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'children', moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'branchDetails' ], (data) => QbApi.updateMetricData(setMetricData, 'children', data)));
		indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'leaf', moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'branchDetails' ], (data) => QbApi.updateMetricData(setMetricData, 'leaf', data)));
		
		return function cleanup() {
			QbApi.instance.cancelQueue(indices);
		};
	}, [ apiTargetPath, apiFrom, apiTo, appState, props.refreshCounter ]);
	
	return (
		<div>
			{ initialFiltersList.map(item => {
				const { id, active,position } = item || { };
				const RenderItem = ComponentsMap[id];
				return active && RenderItem ? <QbErrorBoundary key={ id }><RenderItem metricData={ metricData } match={ props.match } setComponentList={ props.setComponentList } position={ position } id={ id } /></QbErrorBoundary> : null;
			}) }
		</div>
	)
}

export default DashboardHqHeadquarter;
