import React, { useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { AuthLegalForm, LegalDocumentsCurrentList, LegalDocumentsCurrentShowPdf } from './';
import { NoRoutePage } from './';
import { Config, Auth, QbTools, QbApi } from '../modules';

function AuthLegalDocuments(props) {
    let location = useLocation();
    const [ ajaxInProgress, setAjaxInProgress ] = useState(false);

    let redirectTo = '/dashboard';
    try {
        if (props.hierarcyChanged === false) {
            redirectTo = location.state.from.pathname;
        }
    } catch (e) {
    }

    if (props.authPhase !== 'legal') {
        setTimeout( () => {
            props.setHierarcyChanged(false);
        }, 0);
        return <Redirect to={ redirectTo } />;
    }

    async function acceptLegalDocuments(e, appState, lng) {
        setAjaxInProgress(true);
        let apiBody = [ ];

        const authLegalDocuments = Auth.getItem('legalDocuments');
        for (const legalId of Auth.getItem('legalMissing').data) {
            const legalDocument = Auth.getItem('legalDocuments').data.find(element => element.id === legalId);
            const legalToAccept = {
                'id':       legalId,
                'type':     legalDocument.type,
                'version':  legalDocument.version,
                'name':     QbTools.getByLocale(authLegalDocuments.localization[legalDocument.name], lng),
                'path':     QbTools.getByLocale(authLegalDocuments.localization[legalDocument.path], lng),
                'overview': QbTools.getByLocale(authLegalDocuments.localization[legalDocument.overview], lng),
            };
            apiBody.push(legalToAccept);
        }

        try {
            let responseLegalAccept = await QbApi.instance.fetch(appState, Config.apiUrl + 'legal/accept', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + Auth.getToken()
                },
                body: JSON.stringify(apiBody),
            } );
            if (! responseLegalAccept.ok) {
                let dataLegalAccept;
                try {
                    dataLegalAccept = await responseLegalAccept.blob();
                } catch (e) {
                }
                if ('errorCode' in dataLegalAccept) {
                    throw new Error('Error calling endpoint "legal/accept": ' + dataLegalAccept.message + ' (' + dataLegalAccept.errorCode + ')');
                } else {
                    throw new Error('Error calling endpoint "legal/accept": ' + responseLegalAccept.statusText + ' (' + responseLegalAccept.status + ')');
                }
            }
            Auth.setItem('legalMissing', [ ] );

            // TODO: az így frissült legal/documents újrakérése, majd => Auth.setItem('legalDocuments', dataLegalDocuments);

            // XXX: itt nem szabad setAjaxInProgress(false) -t hívni, mert fura hibák jönnek elő
            // XXX: valószínűleg összeveszik az appState.setAuthPhase() miatti újrarendereléssel
            // XXX: de ez nem probléma, mivel authPhase állítása miatt úgyis átnavigálunk egy új oldalra
            appState.setAuthPhase('tour');
            Auth.setAuthPhase('tour');
        } catch (error) {
            setAjaxInProgress(false);
            QbApi.instance.sendErrorReport( {
                error: error && error.toString(),
                stack: error && error.stack,
            } );
            alert(error.message);
        }
    }

    return (
        <React.Fragment>
            <div className={ 'form-spinner position-fixed justify-content-center align-items-center ' + ( ajaxInProgress ? 'd-flex' : '' ) }>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Working...</span>
                </div>
            </div>

            <Switch>
                <Route exact path="/auth/legal" component={() => <AuthLegalForm acceptLegalDocuments={ acceptLegalDocuments } />} />
                <Route exact path="/auth/legal/current"><LegalDocumentsCurrentList pathPrefix="auth/" missing={ 1 } acceptLegalDocuments={ acceptLegalDocuments } /></Route>
                <Route exact path="/auth/legal/current/:id"><LegalDocumentsCurrentShowPdf pathPrefix="auth/" /></Route>

                <Route component={() => <NoRoutePage />} />
            </Switch>
        </React.Fragment>
    );
}

export default AuthLegalDocuments;
