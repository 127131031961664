import React from 'react';

function MenuIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 15" fill="none" {...props}>
      <path
        d="M0 7.5c0-.69.56-1.25 1.25-1.25h12.5a1.25 1.25 0 110 2.5H1.25C.56 8.75 0 8.19 0 7.5zm0-6.25C0 .56.56 0 1.25 0h17.5a1.25 1.25 0 110 2.5H1.25C.56 2.5 0 1.94 0 1.25zm0 12.5C0 14.44.56 15 1.25 15h6.544a1.25 1.25 0 100-2.5H1.25c-.69 0-1.25.56-1.25 1.25z"
        fill="#000"
      />
    </svg>
  );
}

export default MenuIcon;
