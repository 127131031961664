import moment from 'moment';

class QbApiCache {
    static instance = QbApiCache.instance || new QbApiCache();

    cache = { };
    cacheLength = 0;
    config = {
        active: true,
        maxSize: 100, // cache max elemszám
        clenupSize: 20, // ha a cache megtelt, cleanup során ennyi elemet távolítunk el
    };

    put(key, value) {
        if (! QbApiCache.instance.config.active) {
            return value;
        }
        if (! QbApiCache.instance.has(key)) {
            QbApiCache.instance.cacheLength++;
        }
        const now = moment();
        QbApiCache.instance.cache[key] = {
            atime: now,
            value: value,
        }
        QbApiCache.instance.clenup();
        return value;
    }

    remove(key) {
        if (! QbApiCache.instance.config.active) {
            return undefined;
        }
        if (! QbApiCache.instance.has(key)) {
            return undefined;
        }
        const value = QbApiCache.instance.cache[key];
        if (delete QbApiCache.instance.cache[key]) {
            QbApiCache.instance.cacheLength--;
        }
        return value;
    }

    has(key) {
        if (! QbApiCache.instance.config.active) {
            return false;
        }
        return QbApiCache.instance.cache.hasOwnProperty(key);
    }

    get(key) {
        if (! QbApiCache.instance.config.active) {
            return undefined;
        }
        if (QbApiCache.instance.has(key)) {
            const now = moment();
            QbApiCache.instance.cache[key].atime = now;
            return QbApiCache.instance.cache[key].value;
        } else {
            return undefined;
        }
    }

    clear() {
        QbApiCache.instance.cache = { };
        QbApiCache.instance.cacheLength = 0;
    }

    clenup() {
        if (QbApiCache.instance.cacheLength > QbApiCache.instance.config.maxSize) {
            var atimes = [ ];
            for (const [key, value] of Object.entries(QbApiCache.instance.cache)) {
                atimes.push( {
                    key: key,
                    atime: value.atime,
                } );
            }
            atimes.sort(function (a, b) {
                return a.atime.isSame(b.atime) ? 0 : (a.atime.isBefore(b.atime) ? -1 : 1);
            } );
            for (var index = 0; index < QbApiCache.instance.config.clenupSize; index++) {
                QbApiCache.instance.remove(atimes[index].key);
            }
        }
    }
}

export default QbApiCache;
