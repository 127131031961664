import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphFigureServiceCompare, GraphPie } from './';
import { AjaxStateFragment } from './';
import { Config, QbTools } from '../modules';

function AnalyticsDisplayPopularServices(props) {
    const { t } = useTranslation();
    var displayData = props.displayData;
    var pieData = props.pieData;
    const graph1 = useRef(null);
    const graph1Title = useRef(null);
    var pieChart = false;
    var defaultChartShown = 'composed';
    if (props.hasOwnProperty('pieData')) {
        pieChart = true;
        defaultChartShown = 'pie';
    }
    const [ chartShown, setChartShown ] = useState(defaultChartShown);
    const [ chartChanged, setChartChanged ] = useState(0);

    const jsonDisplayData = JSON.stringify(displayData);
    const jsonPieData = JSON.stringify(pieData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: displayData.graphTitle,
                    displayData: displayData,
                    render: ( <AnalyticsDisplayPopularServices { ...props } /> ),
                    pdfData: {
                        requestTime: displayData._requestTime,
                        type: 'htmlsvg',
                        titleHtml: graph1Title.current.outerHTML,
                        html: QbTools.copyDom(graph1.current).outerHTML, // XXX: getter?
                    },
                };
                return prevState;
            } );
        }
    }, [ jsonDisplayData, displayData, jsonPieData, pieData, props, chartShown, chartChanged ] );

    function handleChartSwitch(event) {
        if (! pieChart) {
            return;
        }
        setChartShown(prevState => {
            if (prevState === 'composed') {
                return 'pie';
            } else {
                return 'composed';
            }
        } );
        // a props.setComponentList()-et késleltetve hívjuk, így a recharts újra tudja rajzolni a grafikont, mielőtt lekérjük a tartalmát
        setTimeout( () => {
            setChartChanged(prevState => ( prevState + 1 ));
        }, 0);
    }

    const popularServicesListStyle = {
        height: Config.appearance.analyticsMaxLineHeightServices,
    }

    var TK = [ ];
    displayData.TK.forEach(function (item, index) {
        TK.push(
            <div style={ popularServicesListStyle } key={ index } className="analytics-popular-service-row row border-bottom py-2 mx-0">
                <div className="col-5 col-md-8 col-lg-8 col-xl-9 align-self-center">
                    { item.title }
                </div>
                <div className="col-7 col-md-4 col-lg-4 col-xl-3 d-flex align-items-center justify-content-end">
                    <GraphFigureServiceCompare unit={ displayData.durationInfo.shortName } overallValue={ displayData.overallValue } referenceValue={ (item.tk1 === undefined)? 0 : item.tk1 } value1={ (item.tk2 === undefined)? 0 : item.tk2 } value2={ (item.tk3 === undefined)? 0 : item.tk3 } value3={ (item.tk4 === undefined)? 0 : item.tk4 } />
                </div>
            </div>
        );
    } );
    const roundDotStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    return (
        <div className="metric">
            <div className="row text-center my-1 pt-3 title" ref={ graph1Title } >
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t(displayData.graphTitle) }</h4>
                </div>
            </div>
            <div className="row my-1 position-relative chart-container" onClick={ handleChartSwitch }>
                <AjaxStateFragment _state={ displayData._state } _failed={ displayData._failed } />
                <div className="col-12 px-0">
                    { chartShown === 'composed' ? (
                        <div className="analytics-popular-service-chart">
                            { TK }
                        </div>
                    ) : (
                        <div className="analytics-pie-chart chart-with-picture d-flex align-items-center">
                            <GraphPie pieData={ pieData }/>
                        </div>
                    ) }
                    { (pieChart) &&
                        <div className="graph-active-dots d-flex justify-content-end">
                            <div style={ roundDotStyle } className={ 'round-dot ' + (chartShown === 'composed' ? 'active' : '') } />
                            <div style={ roundDotStyle } className={ 'round-dot ' + (chartShown === 'pie'      ? 'active' : '') } />
                        </div>
                    }
                </div>
                <div className="chart-print-container">
                    { chartShown === 'composed' ? (
                        <div className="analytics-popular-service-chart" ref={ graph1 } >
                            { TK }
                        </div>
                    ) : (
                        <div className="analytics-pie-chart chart-with-picture d-flex align-items-center" ref={ graph1 } >
                            <GraphPie pieData={ pieData }/>
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default AnalyticsDisplayPopularServices;
