import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiFilter } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaRegFilePdf } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';

function Modalbox(props) {
    const {t} = useTranslation();

    return (
        <div className="contact-type-modal d-flex justify-content-center"> 
            <div className="text-center">
                <div className="page-settings">
                    <Link className="popup-link filter d-flex align-items-center" to={ '#' }>
                        <span className="popup-icon-container">
                            <FiFilter />
                        </span>
                        Filter
                    </Link>
                    <Link className="popup-link pdf d-flex align-items-center" to={ '#' }>
                        <span className="popup-icon-container">
                            <FaRegFilePdf />
                        </span>
                        Generate PDF
                    </Link>
                    <Link className="popup-link help d-flex align-items-center" to="/help" >
                        <span className="popup-icon-container">
                            <FiInfo />
                        </span>
                        { t('localizeHelpTitle') }
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Modalbox;
