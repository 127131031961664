import React from 'react';
import { Constants } from '../modules';

function AjaxStateFragment(props) {
    const stateMap = { };
    stateMap[Constants.ajaxState.done] = 'ajax-done';
    stateMap[Constants.ajaxState.loading] = 'ajax-loading';
    stateMap[Constants.ajaxState.failed] = 'ajax-error';
    let ajaxState = props._state;
    if (props._failed && (ajaxState !== Constants.ajaxState.loading)) {
        ajaxState = Constants.ajaxState.failed;
    }
    return (
        <div className={ 'metric-ajax-state ' + stateMap[ajaxState] }>
            <div className="ajax-spinner spinner-border text-dark" role="status">
                <span className="sr-only">Working...</span>
            </div>
            <div className="ajax-error-holder">
                <div className="ajax-error-marker"></div>
            </div>
        </div>
    );
}

export default AjaxStateFragment;
