import React, { useContext, useState } from 'react';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';
import { Config, Constants, Auth, AppStateContext, QbTools, QbApi } from '../modules';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';

function TourPage() {
    const { t, i18n } = useTranslation();
    const appState = useContext(AppStateContext);
    const lng = i18n.language;
    const match = useRouteMatch('/auth/tour/:page');
    const tourPage = Config.tour.pages[match.params.page];
    const [ ajaxInProgress, setAjaxInProgress ] = useState(false);

    if ((tourPage === undefined) || (tourPage.type === 'title')) {
        return (
            <Redirect to={ '/auth/tour' } />
        );
    }

    let prevPage = Number(match.params.page) - 1;
    while ((prevPage > -1) && (Config.tour.pages[prevPage].type === 'title')) {
        prevPage--;
    }
    if (prevPage === -1) {
        prevPage = null;
    }
    let nextPage = Number(match.params.page) + 1;
    const len = Config.tour.pages.length
    while ((nextPage < len) && (Config.tour.pages[nextPage].type === 'title')) {
        nextPage++;
    }
    if (nextPage >= len) {
        nextPage = null;
    }

    async function finishTour(e) {
        setAjaxInProgress(true);
        let newClientInfo = Auth.getItem('clientInfo');
        newClientInfo.info = {
            'platform': Constants.appInfo.platform,
            'version': Constants.appInfo.version,
        };
        try {
            let response = await QbApi.instance.fetch(appState, Config.apiUrl + 'info/client/' + Constants.appInfo.platform, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': 'Bearer ' + Auth.getToken()
                },
                body: JSON.stringify(newClientInfo),
            } );
            if (! response.ok) {
                let data;
                try {
                    data = await response.blob();
                } catch (e) {
                }
                if ('errorCode' in data) {
                    throw new Error('Error calling endpoint "info/client": ' + data.message + ' (' + data.errorCode + ')');
                } else {
                    throw new Error('Error calling endpoint "info/client": ' + response.statusText + ' (' + response.status + ')');
                }
            }
            Auth.setItem('clientInfo', newClientInfo);

            appState.setAuthPhase('auth');
            Auth.setAuthPhase('auth');
        } catch (error) {
            setAjaxInProgress(false);
            QbApi.instance.sendErrorReport( {
                error: error && error.toString(),
                stack: error && error.stack,
            } );
            alert(error.message);
        }
        setAjaxInProgress(false);
    }
    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    return (
        <React.Fragment>
            <div className={ 'form-spinner position-fixed justify-content-center align-items-center ' + ( ajaxInProgress ? 'd-flex' : '' ) }>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Working...</span>
                </div>
            </div>
            <div className="layout vh-100">
                <div className="container">
                    <div className="row d-flex pt-3">
                        <div className="col-2 d-flex align-items-center">
                            { 
                                prevPage !== null ?
                                    (
                                        <Link replace={ true } className="qboard-link" to={ '/auth/tour/' + prevPage }>
                                            <AiOutlineArrowLeft className="help-page-navigation navigation-prev" />
                                        </Link>
                                    ) : ''
                            }
                        </div>
                        <div className="col-8 text-center">
                            <div className="help-image-container text-center w-100">
                            { 
                                tourPage.hasOwnProperty('image') && (tourPage.image !== '') ? (
                                    <img className="img-fluid mb-3" alt={ QbTools.getByLocale(tourPage.title, lng) } src={ tourPage.image } />
                                ) : '' 
                            }
                            </div>
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                            { 
                                nextPage !== null ?
                                    (
                                        <Link replace={ true } className="qboard-link" to={ '/auth/tour/' + nextPage }>
                                            <AiOutlineArrowRight className="help-page-navigation navigation-next"/>
                                        </Link>
                                    ) : ''
                            }
                        </div>
                    </div>
                    <div className="row d-flex pt-3">
                        <div className="col-12 text-center">
                            <div className="row">
                                <div className="col-lg-3 col-12"></div>
                                <div className="col-lg-6 col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>{ QbTools.getByLocale(tourPage.title, lng) }</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-4">
                                        <div className="col-lg-12 col-12">
                                            { QbTools.helptextToJsx(QbTools.getByLocale(tourPage.content, lng)) }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12"></div>
                            </div>                           
                        </div>
                    </div>
                    <div className="row d-flex my-3">
                        <div className="col-2">
                        </div>
                        <div className="col-8 text-center">
                            
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                    <div className="row d-flex my-3">
                        <div className="col-2">
                        </div>
                        <div className="col-8 d-flex align-items-center">
                            
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-3 tour-navigation">
                    <div className="col-3 col-md-3 d-flex justify-content-end"></div>
                    <div className="col-6 col-md-6 d-flex justify-content-center">
                        <div style={ submitButtonStyle } className="btn btn-primary btn-block end-tour" onClick={ () => finishTour() }>{ t('localizeTourSkipTitle') }</div>
                    </div>
                    <div className="col-3 col-md-3 d-flex justify-content-start">
                            
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TourPage;
