import React from 'react';

function ShareIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M16 11v6a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h6M13 1h6v6M8 12L19 1"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShareIcon;
