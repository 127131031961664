import React from 'react';
import { AnalyticsDisplayPopularServices } from './';
import { Config, QbTools } from '../modules';
import { useTranslation } from 'react-i18next';

function AnalyticsProxyMostPopularServices(props) {
    const { t } = useTranslation();
    var sprintf = require('sprintf-js').sprintf;
    var metricData = props.metricData;
    var overallValue = 0;
    var sortedData = [ ];
    if (QbTools.metricDataReady(metricData, [ 'tk1' ] )) {
        for (const [key, ] of Object.entries(metricData.tk1.data).filter(item => (item[1] !== null))) {
            sortedData.push( {
                title: key,
                tk1: metricData.tk1.data[key],
                tk2: (metricData.tk2.hasOwnProperty('data') ? metricData.tk2.data[key] : null),
                tk3: (metricData.tk3.hasOwnProperty('data') ? metricData.tk3.data[key] : null),
                tk4: (metricData.tk4.hasOwnProperty('data') ? metricData.tk4.data[key] : null),
            } );
            overallValue += metricData.tk1.data[key];
        }
        sortedData.sort(function (a, b) {
            return b.tk1 - a.tk1;
        } );
    }

    var displayData = {
        graphTitle: props.graphTitle,
        TK: sortedData.slice(0, 5),
        durationInfo: props.durationInfo,
        overallValue: overallValue,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'tk1', 'tk2', 'tk3', 'tk4' ], t),
    };

    var pieValues = [
        { value: 0, color: Config.appearance.pieColors[0], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[1], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[2], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[3], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[4], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[5], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[6], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[7], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[8], label: '', legend: '' },
        { value: 0, color: Config.appearance.pieColors[9], label: '', legend: '' },
    ];

    pieValues = pieValues.slice(0, displayData.TK.length);
    for (var index = 0, len = displayData.TK.length; index < len; ++index) {
        const item = displayData.TK[index];
        pieValues[index].value = item.tk1;
        pieValues[index].legend = item.title;
        pieValues[index].label = sprintf('%.1f %%', item.tk1 / overallValue * 100);
    }
    var pieData = {
        values: pieValues,
    };

    return (
        <AnalyticsDisplayPopularServices displayData={ displayData } pieData={ pieData } { ...props } />
    );
}

export default AnalyticsProxyMostPopularServices;
