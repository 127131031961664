import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { AppStateContext, Auth, QbApi, QbApiTimeoutError, Config, Constants } from '../modules';
import { UpperNavigation } from './';
import { ConfirmationModal } from './';
import {showMessage} from "../utilities/UiUtils";

function SettingsPrivacy() {
    const { t } = useTranslation();
    const {t: webappT} = useTranslation('webapp');
    let history = useHistory();
    const appState = useContext(AppStateContext);
    const [ ajaxInProgress, setAjaxInProgress ] = useState(false);
    const [ clearUserNameModal, setClearUserNameModal ] = useState(false);
    const [ clearFeedbackModal, setClearFeedbackModal ] = useState(false);
    const [ clearSupportRequsetModal, setClearSupportRequsetModal ] = useState(false);

    const feedbackStatus = Constants.feedbackStatus.status;
    const supportStatus = Constants.supportStatus.status;

    const showClearUserNameModal = () => setClearUserNameModal(true)
    const hideClearUserNameModal = () => setClearUserNameModal(false)
    const showClearFeedbackModal = () => setClearFeedbackModal(true)
    const hideClearFeedbackModal = () => setClearFeedbackModal(false)
    const showClearSupportRequsetModal = () => setClearSupportRequsetModal(true)
    const hideClearSupportRequsetModal = () => setClearSupportRequsetModal(false)

    useEffect( () => {
        appState.setActivePage('settings.privacy');
    } );

    const handleClearUserName = async () => {
        hideClearUserNameModal();
        setAjaxInProgress(true);

        try {
            const updatedUserProfile = Auth.clearUserProfile();
            await QbApi.instance.updateUserProfile(appState, { userProfile: updatedUserProfile.userProfile } );
            await QbApi.instance.updateUserProfileImage(appState, '');
            Auth.setItem('userprofileImage', { data: '' } );
            showMessage({title:webappT('toastTitleSuccess'),message:webappT('toastDescriptionSuccessDelete')})
        } catch (error) {
            if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
                showMessage({title:webappT('toastTitleFailed'),message:webappT('toastDescriptionFailedUpdate'), type: 'error' })
            } else {
                alert(error.message);
            }
        } finally {
            setAjaxInProgress(false);
        }
    }

    const handleClearFeedback = async () => {
        hideClearFeedbackModal();
        setAjaxInProgress(true);

        try {
            await QbApi.instance.deleteMessages(appState, 'feedback');
            showMessage({title:webappT('toastTitleSuccess'),message:webappT('toastDescriptionSuccessDelete')})
        } catch (error) {
            alert(error.message);
        } finally {
            setAjaxInProgress(false);
        }
    }

    const handleClearSupportRequset = async () => {
        hideClearSupportRequsetModal();
        setAjaxInProgress(true);

        try {
            await QbApi.instance.deleteMessages(appState, 'support');
            showMessage({title:webappT('toastTitleSuccess'),message:webappT('toastDescriptionSuccessDelete')})
        } catch (error) {
            alert(error.message);
        } finally {
            setAjaxInProgress(false);
        }
    }

    return (
        <React.Fragment>
            <div className={ 'form-spinner position-fixed justify-content-center align-items-center ' + ( ajaxInProgress ? 'd-flex' : '' ) }>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Working...</span>
                </div>
            </div>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizePrivacy')
                    }
                />
            </div>

            <div className="settings privacy">
                <div className="container py-2 px-0 position-relative">
                    <div className="row px-4">
                        <div className="col-12">

                            <div className="row mt-4 mb-2">
                                <div className="col-12">
                                    <div className="qboard-link" onClick={ () => showClearUserNameModal() }>
                                        <div className="row settings-branch-item">
                                            <div className="col-10 d-flex align-items-center">{ t('localizeSettingsPrivacyClearUserName') }</div>
                                            <div className="col-2 text-right">{/*<BsChevronRight className='icon-without-bg' />*/}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 mb-4">
                                <div className="col-12">{ t('localizeSettingsPrivacyClearUserDescription') }</div>
                            </div>

                            {feedbackStatus &&
                                <div className="row mt-4 mb-2">
                                    <div className="col-12">
                                        <div className="qboard-link" onClick={ () => showClearFeedbackModal() }>
                                            <div className="row settings-branch-item">
                                                <div className="col-10 d-flex align-items-center">{ t('localizeSettingsPrivacyClearFeedbackName') }</div>
                                                <div className="col-2 text-right">{/*<BsChevronRight className='icon-without-bg' />*/}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">{ t('localizeSettingsPrivacyClearFeedbackDescription') }</div>
                                </div>}

                            {supportStatus &&
                                <div className="row mt-4 mb-2">
                                    <div className="col-12">
                                        <div className="qboard-link" onClick={ () => showClearSupportRequsetModal() }>
                                            <div className="row settings-branch-item">
                                                <div className="col-10 d-flex align-items-center">{ webappT('localizeSettingsPrivacyClearTopDeskName') }</div>
                                                <div className="col-2 text-right">{/*<BsChevronRight className='icon-without-bg' />*/}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">{ webappT('localizeSettingsPrivacyClearTopDeskDescription') }</div>
                                </div>
                            }

                            <div className="row mt-4 mb-2">
                                <div className="col-12">
                                    <Link className="qboard-link" to="/settings/privacypolicy">
                                        <div className="row settings-branch-item">
                                            <div className="col-10 d-flex align-items-center">{ t('localizePrivacy') }</div>
                                            <div className="col-2 text-right"><BsChevronRight className='icon-without-bg' /></div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal message={ t('localizePrivacyUserClearWarningMessage') } noTitle={ true } show={ clearUserNameModal } onHide={ hideClearUserNameModal } onDone={ () => handleClearUserName() }/>
            <ConfirmationModal message={ t('localizePrivacyFeedbackClearWarningMessage') } noTitle={ true } show={ clearFeedbackModal } onHide={ hideClearFeedbackModal } onDone={ () => handleClearFeedback() }/>
            <ConfirmationModal message={ t('webapp:localizePrivacySupportRequsetClearWarningMessage') } noTitle={ true } show={ clearSupportRequsetModal } onHide={ hideClearSupportRequsetModal } onDone={ () => handleClearSupportRequset() }/>
        </React.Fragment>
    );
}

export default SettingsPrivacy;
