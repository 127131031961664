import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QbTools, Auth, Config } from '../modules';
import { BsChevronLeft } from 'react-icons/bs';
import { UpperNavigation, LegalDocumentsFragment } from './';

// almost identical with LegalDocumentsCurrentList
function SettingsPrivacyPolicy(props) {
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const [ legalDocuments, setLegalDocuments ] = useState( [ ] );
    const [ pageTitle, setPageTitle ] = useState('');
    const lng = i18n.language;
    let pathPrefix = '';
    if (props.hasOwnProperty('pathPrefix')) {
        pathPrefix = props.pathPrefix;
    }

    useEffect( () => {
        let legalData = Auth.getItem('legalDocuments');
        let legalDocumentsFragment = [ ];
        for (let index = 0, len = legalData.data.length; index < len; ++index) {
            const legal = legalData.data[index];
            if (legal.type !== 'privacyPolicy') {
                continue;
            }
            setPageTitle(QbTools.getByLocale(legalData.localization[legal.name], lng));
            legalDocumentsFragment.push(
                <LegalDocumentsFragment legalData={ legalData } index={ index } pathPrefix={ pathPrefix } />
            );
        }
        setLegalDocuments(legalDocumentsFragment);
    }, [ lng, t, pathPrefix ] );

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        pageTitle
                    }
                />
            </div>

            <div className="container">
                { legalDocuments }
            </div>
        </React.Fragment>
    );
}

export default SettingsPrivacyPolicy;
