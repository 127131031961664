import React from 'react';
import { Auth } from '../modules';
import { Redirect } from 'react-router-dom';

function DashboardBmCameraImage(props) {
    const boardtype = 'camera';
    const userMerged = Auth.getData().userMerged;
    const branches = userMerged.branches;
    const cameras = branches[props.match.params.id]?.cameras;

    if (! cameras.hasOwnProperty(props.match.params.cameraId)) {
        return ( <Redirect to={ '/dashboard/' + boardtype + '/' + props.match.params.id } /> );
    }

    const camera = cameras[props.match.params.cameraId];

    return (
        <React.Fragment>
            <div className="container">
            <div className="camera-item text-center my-1 pt-3 title">
                <div className="row">
                    <h4 className="col-12 text-center font-weight-bold analytics-graph-full-title">{ camera.name }</h4>
                </div>
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <img alt={ camera.name } width="100%" src={ camera.url } />
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
            </div>
            </div>

        </React.Fragment>
    );
}

export default DashboardBmCameraImage;
