import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
            var layout = document.getElementsByClassName("layout");
            var root = document.querySelector("#root > .container");
            if(layout.length > 0 && layout){
                layout[0].scrollTop = 0;
            }
            if(root !== 'undefined' && root){
                root.scrollTop = 0;
            }
		}
	}

	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop)