import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Auth, QbApi, QbApiTimeoutError } from '../modules';
import { AppStateContext, Config } from '../modules';
import { BsCheck, BsChevronLeft } from 'react-icons/bs';
import { UpperNavigation } from './';
import { showMessage } from '../utilities/UiUtils';

function SettingsLanguage() {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const appState = useContext(AppStateContext);
    const [ searchString, setSearchString ] = useState('');

    const toSearch = searchString.trim();
    const toSearchArr = toSearch.toLowerCase().split(' ');

    // TODO: a language listát i18n.js-ből és SettingsLanguage.js-ből át kell tenni valami külsö(bb) config file-ba
    const languageCodes = [ 'hu', 'en', 'de', 'nl', 'sv', 'nb', 'fi', 'ro', 'pl', 'sk', 'cs', 'it', 'es', 'fr', ];
    const languageCodesList = [ 'system', ...languageCodes ];

    var activeLanguageCode = '';
    try {
        activeLanguageCode = Auth.getData().userProfile.settings.language.code;
    } catch (e) {
    }
    if (! languageCodes.includes(activeLanguageCode)) {
        activeLanguageCode = 'system';
    }

    var languageSelectorFragment = [ ];
    var activeLanguageFragment = '';
    for (const languageCode of languageCodesList) {
        let contains2 = false;

        if (toSearch !== '') {
            contains2 = true;
            for (const word of toSearchArr) {
                if (! t('localizeLanguage' + ucfirst(languageCode)).toLowerCase().includes(word) && !languageCode.includes(word)) {
                    contains2 = false;
                }
            }
        }
        if ((toSearch === '') || contains2) {
            languageSelectorFragment.push(
                <div key={ languageCode } className="col-12 c-pointer" onClick={ () => changeLanguage(languageCode) }>
                    <div className="row py-2 d-flex align-items-center settings-language-item">
                        <div className="col-8">
                            <div>{ t('localizeLanguage' + ucfirst(languageCode)) }</div>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center settings-secondary-text">
                            { languageCode === activeLanguageCode ? <BsCheck className='icon-with-bg' /> : '' }
                        </div>
                    </div>
                </div>
            );
        }
        
        if (languageCode === activeLanguageCode) {
            activeLanguageFragment = (
                <div className="col-12 c-pointer">
                    <div className="row py-2 d-flex align-items-center settings-language-item">
                        <div className="col-8">
                            <div>{ t('localizeLanguage' + ucfirst(languageCode)) }</div>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center settings-secondary-text">
                            <BsCheck className='icon-with-bg' />
                        </div>
                    </div>
                </div>
            );
        }
    }

    function ucfirst(string) {
        return string[0].toUpperCase() + string.slice(1);
    }

    async function changeLanguage(languageCode) {
        if (! languageCodes.includes(languageCode)) {
            languageCode = '';
        }
        const userProfile = {
            settings: {
                language: {
                    code: languageCode
                }
            }
        };
        const updatedUserProfile = Auth.updateUserProfile(userProfile);
        localStorage.setItem('qbLanguage', languageCode);
        i18n.changeLanguage(languageCode);
        try {
            await QbApi.instance.updateUserProfile(appState, { userProfile: updatedUserProfile } );
        } catch (error) {
            if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
                showMessage( { title: t('webapp:toastTitleFailed'), message: t('webapp:toastDescriptionFailedUpdate'), type: 'error' } )
            } else {
                throw error;
            }
        }
    }

    function handleSearchChange(event) {
        setSearchString(event.target.value);
    }

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        t('localizeLanguageName')
                    }
                    bottom={
                        <input className="form-control help-search-field" type="text" name="search" onChange={ handleSearchChange } />
                    }
                />
            </div>

            <div className="settings language" >
                <div className="container">
                    <div className="row align-items-center py-1 mb-2 setting-item mx-4">
                        <div className="col-12">
                            <div className="row font-weight-bold">
                                <div className="mb-2">{ t('localizeActive') }</div>
                            </div>
                            <div className="row settings-branch-item py-0">
                                { activeLanguageFragment }
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center py-1 mb-2 setting-item mx-4">
                        <div className="col-12">
                            <div className="row font-weight-bold">
                                <div className="mb-2">{ t('localizeAll') }</div>
                            </div>
                            <div className="row settings-branch-item py-0">
                                { languageSelectorFragment }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SettingsLanguage;
