import React, { useEffect, useState, useContext } from 'react';
import { AppStateContext } from '../modules';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QbApi, Config } from '../modules';
import { BsChevronLeft } from 'react-icons/bs';
import { UpperNavigation } from '.';
import { Modal } from 'react-bootstrap';
import ImageGridList from '../utilities/ImageGridList.jsx';

export default function LegalDocumentsShowPdf(props) {
    let history = useHistory();
    const { t } = useTranslation();
    const appState = useContext(AppStateContext);
    const [ legalDocument, setLegalDocument ] = useState( [ ] );
    const [ pageTitle, setPageTitle ] = useState('');
    const [ isLoading, setLoading ] = useState(false);
    const [ errorShown, setErrorShown ] = useState(false);

    const legal = props.legal;

    function openError() {
        setErrorShown(true);
    }

    function closeError() {
        setErrorShown(false);
        history.goBack();
    }

    // XXX: cache-elni a lekért pdf-et(?)
    useEffect( () => {
        async function getDocument() {
            if (legal != null) {
                const legalName = legal.name;
                const legalPath = legal.path;

                setPageTitle(legalName);
                setLoading(true);
            
                try {
                    const documentPath = 'legal/document/' + legalPath;
                    const documentType = await QbApi.instance.getRawAsync(appState, documentPath + '?noData');
            
                    const documentUrl = Config.apiUrl + documentPath;
                    const documentPages = (typeof documentType != "string") ? 
                        await getPages(documentUrl) :
                        null;

                    const renderFragment = documentPages !== null ? 
                        (<ImageGridList className="text-center" images={documentPages}/>) :
                        (<iframe title="Pdf Preview" src={ documentUrl } width="100%" height="100%" frameBorder="0"></iframe>);
             
                    const legalDocumentFragment = (
                        <div className="row h-100">
                            <div className="col-12">
                                {renderFragment}
                            </div>
                        </div>
                    );
            
                    setLegalDocument(legalDocumentFragment);
                }
                catch (e) {
                    openError();
                }

                setLoading(false);
            }
        }

        async function getPages(apiPath) {
            let out = null;

            if (apiPath !== null) {
                apiPath += '/pages';
                const response = await fetch(apiPath, { method: 'GET' } );
                const count = await response.text();

                if (count !== '') {
                    out = [];
                    for (let i = 0; i < parseInt(count); ++i) {
                        out.push( apiPath + '/' + i);
                    }
                }
                
                if (out === null) {
                    throw new Error('Page loading error');
                }
            }
    
            return out;
        }

        getDocument();
    }, [ legal ] );

    return (
        <React.Fragment>
            <div className={ 'form-spinner position-fixed justify-content-center align-items-center ' + ( isLoading ? 'd-flex' : '' ) }>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Working...</span>
                </div>
            </div>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation 
                    leftSide={
                        <div className="qboard-link" onClick={ () => history.goBack() }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </div>
                    }
                    middle={
                        pageTitle 
                    }
                />
            </div>

            <div className="container pdf">
                { legalDocument }
            </div>

            <Modal show={ errorShown } onHide={ closeError } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="text-center">
                    { t('localizeBackendErrorLoading') }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}