import moment from "moment";
import {UpperNavigation} from './';
import {Spinner} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {FiRefreshCcw} from "react-icons/fi";
import TicketStatusBar from "./TickeStatusBar";
import {Link, useHistory} from 'react-router-dom';
import {AppStateContext, QbApi, Config, Auth} from "../modules";
import ListEmptyComponent from "./ListEmptyComponent";
import {isEmpty, map, size, keyBy, values} from 'lodash'
import {REPORT_STATUS_COLORS} from "../utilities/Constant";
import InfiniteScroll from "react-infinite-scroll-component";
import React, {useContext, useEffect, useState} from 'react';
import {BsChevronLeft, BsChevronRight, BsPlus} from 'react-icons/bs';
import {buildUrlParams, transformReportItems} from "../utilities/Transform";

const ReportItem = (props = {}) => {
	const {item, setReportDetailItem} = props || {}
	const history = useHistory()
	const {createTimestamp, isEmailSendError} = item || {}
	const {subject, description, urgency, state} = item?.request ?? {}
	
	const {name: stateName, progress} = state || {}
	const navigateToDetailItemHandler = () => {
		setReportDetailItem(item)
		history.push(`/report/item`)
	}
	
	const navigateToHistory = (event) => {
		setReportDetailItem(item)
		event.stopPropagation();
		history.push(`/report/item/history`)
    }
    
	return <div className="container px-0 qboard-link" onClick={ navigateToDetailItemHandler }>
		<div className={ `overflow-hidden ticket-list-item ${ isEmailSendError && 'ticket-list-error' }` }>
			<div className="row py-3 px-2 align-items-center">
				<div className="col-6 col-lg-10 ticket-name-list">
					<div className='d-flex pl-2 report-word-break justify-content-start'>
						<div className='report-status-box' style={ {backgroundColor: REPORT_STATUS_COLORS[urgency]} }/>
						<div className='qboard-link ml-3' onClick={ navigateToDetailItemHandler }>
							{ subject }
							<div className="date">
								{ description }
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-lg-2 d-flex flex-column align-items-end">
					<div className='date'>{ moment.unix(createTimestamp).format('YYYY. MMM. DD. hh:mm:ss') }</div>
					<div className='d-flex'>
						<div className='mr-2'>
							<TicketStatusBar
								state={ stateName }
								progress={ progress }
								onClick={ navigateToHistory }
							/>
						</div>
						<div className="qboard-link">
							<BsChevronRight className="icon-with-bg"/>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	</div>
}

const DEFAULT_REPORTS = []
const REPORTS_PAGE_SIZE = 40

function ReportList() {
	let history = useHistory();
	const {t} = useTranslation();
	const appState = useContext(AppStateContext);
	const [ isLoading, setLoading ] = useState(false)
	const [ isLatestRecordsLoading, setLatestRecordsLoading ] = useState(false)
	const {setReportDetailItem} = appState || {}
	const [ isLoadMore, setIsLoadMore ] = useState(true)
	const [ reportsList, setReportsList ] = useState(DEFAULT_REPORTS);
	
	const userData = Auth?.getData()?.user;
	const userName = userData?.chanonicalName?.userName
	
	
	const fetchDataHandler = async (params) => {
		if (!isLoadMore) {
			return
		}
		try {
			setLoading(true)
			const response = await QbApi.instance.fetchReports(appState,
				buildUrlParams({...params, count: REPORTS_PAGE_SIZE})
			);
			if (isEmpty(response?.message)) {
				setIsLoadMore(false)
			}
			const formattedList = transformReportItems(response?.message ?? [], userName)
			setReportsList([ ...reportsList ?? [], ...formattedList ?? [] ])
		} catch (e) {
		} finally {
			setLoading(false)
		}
	}
	
	useEffect(() => {
		fetchDataHandler({paged: true, node: 0})
	}, [])
	
	const onEndReachHandler = () => {
		const params = {paged: true, node: 0}
		const lastItem = isEmpty(reportsList) ? {} : reportsList[size(reportsList) - 1]
		if (!isEmpty(lastItem)) {
			params.time = lastItem?.createTimestamp
		}
		fetchDataHandler(params)
	}
	
	const fetchLatestRecordsHandler = async () => {
		const params = {node: 0}
		const firstItem = isEmpty(reportsList) ? {} : reportsList[0]
		if (!isEmpty(firstItem)) {
			params.time = firstItem?.changeTimestamp
		}
		
		try {
			setLatestRecordsLoading(true)
			const {message = []} = await QbApi.instance.fetchReports(appState, buildUrlParams(params));
			const updatedDataMap = {
				...keyBy(reportsList, 'id'),
				...keyBy(message, 'id')
			}
			const formattedList = transformReportItems(values(updatedDataMap) ?? [], userName)
			setReportsList(formattedList)
		} catch (e) {
		} finally {
			setLatestRecordsLoading(false)
		}
    }
    const dataRefreshIconStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    const upperNavigationColor = {
        color: Config.appearance.upperNavigationtextColor,
    }
	
	return (
		<React.Fragment>
			<div className={ (Config.appearance.enableStickyTop) ? "sticky-top" : "" }>
				<UpperNavigation
					leftSide={
						<div className="qboard-link" onClick={ () => history.replace('/') }>
							<BsChevronLeft className="icon-with-bg float-none ml-1"/>
						</div>
					}
					middle={
						t('localizeTopDeskListTitle')
					}
					rightSide={
						<div className='d-flex align-content-center'>
							<div className="refresh-container d-flex align-items-center">
								{ isLatestRecordsLoading ?
									<Spinner animation="border" size="lg" variant="primary"/> :
									<FiRefreshCcw
                                        style={ dataRefreshIconStyle }
										className="data-refresh-icon"
										onClick={ fetchLatestRecordsHandler }/>
								}
							</div>
							<Link style={ upperNavigationColor} className="create-ticket ml-2" to={ '/report/create' }>
								<BsPlus className='mb-2'/>
							</Link>
						</div>
					}
				/>
			</div>
			<div className='body_container pb-2'>
				<ListEmptyComponent isLoading={ isLoading } dataList={ reportsList }/>
				<InfiniteScroll
					dataLength={ size(reportsList) }
					hasMore={ true }
					height={ `100%` }
					next={ onEndReachHandler }
				
				>{ map(reportsList, (item, index) =>
					<ReportItem
						key={ String(index) } item={ item }
						setReportDetailItem={ setReportDetailItem }/>) }
				</InfiniteScroll>
			</div>
		</React.Fragment>
	)
}

export default ReportList
