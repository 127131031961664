import { RNCryptor } from './rncryptor';
import { Config, AuthStorage, QbApiCache } from './';
import moment from 'moment';

class Auth {

    /**
     * Authenticate a user. Save authData object in Local Storage
     *
     * @param {object} authData
     */
    static authenticateUser(authData) {
        const now = moment().unix();

        try { authData.token        = RNCryptor.decrypt(authData.token       , Config.transportSecret) ?? authData.token;        } catch(err) {}
        try { authData.refreshToken = RNCryptor.decrypt(authData.refreshToken, Config.transportSecret) ?? authData.refreshToken; } catch(err) {}

        const cryptoSecret = authData.token.substring(0, 7) + Config.transportSecret + authData.token.substring(authData.token.length - 3, authData.token.length);
        authData.secret = RNCryptor.decrypt(authData.secret, cryptoSecret);
        authData.user = RNCryptor.json.decrypt(authData.user, cryptoSecret);
        authData.userProfile = RNCryptor.json.decrypt(authData.userProfile, cryptoSecret);
        authData.userMerged = null;
        authData.authTokenAt = now;
        authData.authTokenRefreshAt = now;
        authData.refreshTokenAt = now;
        authData.userprofileCheckAt = now;
        authData.userprofileCheckRefreshAt = now;
        authData.authPhase = 'hasData';
        authData.legalDocuments = null;
        authData.legalMissing = null;
        authData.legalHistory = null;
        authData.userprofileImage = null;
        authData.clientInfo = null;
        authData.clientConfig = null;

        authData = Auth.fixupUserProfile(authData);
        authData = Auth.mergeUserProfile(authData);

        new AuthStorage().setItem('authData', authData);
    }

    // nem a storage-ben levő authData-n dolgozik, hanem a praméterben kapotton
    // return value-ban adja vissza a frissített változatot
    static fixupUserProfile(authData) {
        function fixupNonexistent(key, initialValue) {
            if (! userProfile.hasOwnProperty(key)) {
                userProfile[key] = initialValue;
            }
        }

        function fixupGroups() {
            for (const userGroup of user.groups) {
                let match = false;
                for (const userprofileGroup of userProfile.groups) {
                    if (Number(userGroup.id) === Number(userprofileGroup.id)) {
                        match = true;
                        break;
                    }
                }
                if (! match) {
                    userProfile.groups.push( {
                        id: userGroup.id,
                        name: '',
                    } );
                }
            }
        }

        function fixupBranches() {
            for (const userBranch of user.branches) {
                let match = false;
                for (const userprofileBranch of userProfile.branches) {
                    if (Number(userBranch.id) === Number(userprofileBranch.id)) {
                        match = true;
                        break;
                    }
                }
                if (! match) {
                    userProfile.branches.push( {
                        address: {
                            address: '',
                            street: '',
                            county: '',
                            state: '',
                            country: '',
                            ISOCountryCode: '',
                            city: '',
                            postalCode: '',
                            longitude: '',
                            latitude: '',
                        },
                        counters: '',
                        open: userBranch.open,
                        staff: [ ],
                        cameras: [ ],
                        id: userBranch.id,
                        name: '',
                    } );
                }
            }
        }

        let user = authData.user;
        let userProfile = authData.userProfile;

        fixupNonexistent('settings', {
            language: {
                code: '',
            },
            privacy: {
                appAnalyticsEnabled: user.settings?.privacy?.appAnalyticsEnabled,
                feedbackNameEnabled: user.settings?.privacy?.feedbackNameEnabled,
            },
        } );
        fixupNonexistent('config', {
            filterSort: [ ],
        } );
        fixupNonexistent('groups', [ ] );
        fixupNonexistent('chanonicalName', {
            userName: user.chanonicalName?.userName,
            fullName: ', ',
        } );
        fixupNonexistent('contactInfo', {
            address: '',
            email: '',
            phone: '',
            mobile: '',
        } );
        if (user.hasOwnProperty('topHierarchyLevel')) {
            userProfile.topHierarchyLevel = user.topHierarchyLevel;
        }

        if (user.topHierarchyLevel.toLowerCase() === 'uv') {
            fixupNonexistent('branches', [ ] );
            fixupBranches();
        }
        fixupGroups();

        return authData;
    }

    static decrypt(encrypted) {
        const authData = Auth.getData();
        const cryptoSecret = authData.token.substring(0, 7) + Config.transportSecret + authData.token.substring(authData.token.length - 3, authData.token.length);
        return RNCryptor.decrypt(encrypted, cryptoSecret);
    }

    static encrypt(plaintext) {
        const authData = Auth.getData();
        const cryptoSecret = authData.token.substring(0, 7) + Config.transportSecret + authData.token.substring(authData.token.length - 3, authData.token.length);
        return RNCryptor.encrypt(plaintext, cryptoSecret);
    }

    static setAuthPhase(authPhase) {
        const authData = Auth.getData();
        authData.authPhase = authPhase;
        new AuthStorage().setItem('authData', authData);
    }

    static getAuthPhase() {
        const storeData = new AuthStorage().getItem('authData');
        if (storeData === null) {
            return 'unauth';
        }
        return storeData.authPhase;
    }

    static setItem(key, value) {
        let authData = Auth.getData();
        if (authData === null) {
            authData = { };
        }
        authData[key] = value;
        authData = Auth.mergeUserProfile(authData);
        new AuthStorage().setItem('authData', authData);
    }

    static getItem(key) {
        return Auth.getData()[key];
    }

    static safeGetItem(key) {
        try {
            return Auth.getItem(key);
        } catch (error) {
            return undefined;
        }
    }

    static mergeUserProfile(authData) {
        function mergeRecursive(u, up, trim = false) {
            let merged;

            if (u === null) {
                merged = up;
            } else if (Array.isArray(u)) { // XXX: config.filterSort, branches.<id>.cameras - a user struktúrát használjuk, nem biztos, hogy ez jó működés
                merged = u;
            } else if (typeof u === 'object') {
                merged = { };
                for (let [ key, ] of Object.entries(u)) {
                    if (key === 'fullName') {
                        merged[key] = mergeRecursive(u[key], up[key], true);
                    } else {
                        merged[key] = mergeRecursive(u[key], up[key] || '' );
                    }
                }
            } else {
                let check = up;
                if (trim) {
                    check = up.trim().replace(/^,$/, '');
                }
                if (check !== '') {
                    merged = up;
                } else {
                    merged = u;
                }
            }
            return merged;
        }

        function fixupGroupsArray(groups) {
            if (! Array.isArray(groups)) {
                groups = [ ];
            }
            let out = { };
            for (const group of groups) {
                out[group.id] = JSON.parse(JSON.stringify(group));
            }
            return out;
        }

        authData.userMerged = { };
        authData.userMerged.chanonicalName    = mergeRecursive(authData.user?.chanonicalName,    authData.userProfile?.chanonicalName);
        authData.userMerged.contactInfo       = mergeRecursive(authData.user?.contactInfo,       authData.userProfile?.contactInfo);
        authData.userMerged.config            = mergeRecursive(authData.user?.config,            authData.userProfile?.config);
        authData.userMerged.settings          = mergeRecursive(authData.user?.settings,          authData.userProfile?.settings);
        authData.userMerged.topHierarchyLevel = mergeRecursive(authData.user?.topHierarchyLevel, authData.userProfile?.topHierarchyLevel);

        authData.userMerged.groups   = mergeRecursive(fixupGroupsArray(authData.user?.groups),   fixupGroupsArray(authData.userProfile?.groups));
        authData.userMerged.branches = mergeRecursive(fixupGroupsArray(authData.user?.branches), fixupGroupsArray(authData.userProfile?.branches));

        return authData;
    }

    static clearUserProfile() {
        let authData = Auth.getData();
        authData.userProfile = { };
        authData = Auth.fixupUserProfile(authData);
        authData = Auth.mergeUserProfile(authData);
        new AuthStorage().setItem('authData', authData);
        return authData;
    }

    /**
     * Check if a user is authenticated - check if authData is saved in Local Storage
     *
     * @returns {boolean}
     */
    static isUserAuthenticated() {
        const storeData = new AuthStorage().getItem('authData');
        if (storeData === null) {
            return false;
        }
        return (storeData.authPhase === 'auth');
    }

    /**
     * Deauthenticate a user. Remove authData from Local Storage.
     *
     */
    static deauthenticateUser() {
        QbApiCache.instance.clear();
        new AuthStorage().removeItem('authData');
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */
    static getToken() {
        const data = Auth.getData();
        if ((data !== null) && ('token' in data)) {
            return data.token;
        } else {
            return null;
        }
    }

    /**
     * Get a authData value.
     *
     * @returns {object}
     */
    static getData() {
        return new AuthStorage().getItem('authData');
    }

    /**
     * calculate userprofile for update
     *
     * @returns {object}
     */
    static calcUserProfile(userProfile) {
        let authData = new AuthStorage().getItem('authData');
        let updateData = userProfile;
        if (userProfile.hasOwnProperty('userProfile')) {
            updateData = userProfile.userProfile;
        }
        for (const [key, ] of Object.entries(updateData)) {
            if (updateData[key] instanceof Array) {
                authData.userProfile[key] = updateData[key];
            } else {
                updateData[key] = { ...authData.userProfile[key], ...updateData[key] };
            }
        }

        return updateData;
    }

    /**
     * Update userprofile in localStorage
     *
     * @returns {object}
     */
    static updateUserProfile(userProfile) {
        let authData = new AuthStorage().getItem('authData');
        let updateData = Auth.calcUserProfile(userProfile);

        authData.userProfile = { ...authData.userProfile, ...updateData };
        authData = Auth.mergeUserProfile(authData);
        new AuthStorage().setItem('authData', authData);
        return updateData;
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */
    static getRefreshToken() {
        const data = Auth.getData();
        if ((data !== null) && ('refreshToken' in data)) {
            return data.refreshToken;
        } else {
            return null;
        }
    }

    static setToken(token) {
        const authData = Auth.getData();
        if ((authData !== null) && ('token' in authData)) {
            authData.token = token;
            authData.authTokenAt = moment().unix();
            new AuthStorage().setItem('authData', authData);
            return authData;
        } else {
            return null;
        }
    }
}

export default Auth;
