import React, {useContext, useEffect, useState, useRef} from 'react';
import {Redirect, useRouteMatch, useHistory, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Auth, QbApi, QbApiTimeoutError, AppStateContext, Config} from '../modules';
import {Formik, Form} from 'formik';
import {TextField, SubmitButton} from './form';
import {BsChevronLeft} from 'react-icons/bs';
import {MdMap} from "react-icons/md";
import { UpperNavigation, ConfirmationModal } from './';
import {showMessage} from "../utilities/UiUtils";
import { addressToLatLngHandler } from '../utilities/PlaceUtils';

function SettingsAddress() {
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
	let history = useHistory();
	const match = useRouteMatch('/settings/branch/:id/address');
    const appState = useContext(AppStateContext);
    const formikInnerRef = useRef();

    const [ isUnsavedProfileModal, setUnsavedProfileModal ] = useState(false);
    const showUnsavedProfileModal = () => setUnsavedProfileModal(true)
    const hideUnsavedProfileModal = () => setUnsavedProfileModal(false)
	
	let authData = Auth.getData();
	let branch = {
		userProfile: authData.userProfile.branches?.find(element => String(element.id) === match.params.id),
		user: authData.user.branches?.find(element => String(element.id) === match.params.id),
	};
	const branchIndex = authData.userProfile.branches?.findIndex(element => String(element.id) === match.params.id);
	
	useEffect(() => {
		appState.setActivePage('settings.address');
	});
	if ((branchIndex === -1) || (typeof branchIndex === 'undefined')) {
        return <Redirect to={ '/settings' }/>
    }
	
	let formValues = {
		country: branch.userProfile.address.country,
		state: branch.userProfile.address.state,
		postalCode: branch.userProfile.address.postalCode,
		city: branch.userProfile.address.city,
		address: branch.userProfile.address.address,
		ISOCountryCode: branch.userProfile.address.ISOCountryCode,
	};
	
	async function formSubmit(formValues, {resetForm, setSubmitting}) {
        setSubmitting(true)
		let address = authData.userProfile.branches[branchIndex].address;
		const updateData = {
			country: formValues.country,
			state: formValues.state,
			postalCode: formValues.postalCode,
			city: formValues.city,
			address: formValues.address,
			ISOCountryCode: formValues.ISOCountryCode,
		}
		address = {...address, ...updateData};
        let toGeocodeAddress = (address.postalCode ?? '') + ' ' + (address.address ?? '') + ', ' + (address.city ?? '') + ', ' + (address.country ?? '');
        const response = await addressToLatLngHandler(toGeocodeAddress);
        if ((response.lat !== 0) && (response.lng !== 0)) {
            address.latitude = response.lat;
            address.longitude = response.lng;
        }
		authData.userProfile.branches[branchIndex].address = address;
		const userProfile = {
			branches: authData.userProfile.branches,
		};
		
		const updatedUserProfile = Auth.calcUserProfile(userProfile);
        try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
            Auth.updateUserProfile(userProfile);
            resetForm({values: formValues})
			showMessage({title:webappT("toastTitleSuccess"),message:webappT("toastDescriptionSuccessUpdate")})
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedUpdate"), type: 'error' })
			} else {
				throw error;
			}
		} finally {
			setSubmitting(false)
        }
	}
	
	const goBackHandler = () => {
		if (!formikInnerRef.current.dirty) {
			return history.goBack()
		}
		return showUnsavedProfileModal()
	}
	
	return (
		<React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ goBackHandler }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                        </div>
                    }
                    middle={
                        t('localizePostalAddress')
                    }
                    rightSide={
                        Config.googleMapEnabled ?
                            <Link className="qboard-link" to={ '/settings/branch/' + match.params.id + '/address/map' }>
                                <MdMap className="icon-with-bg "/>
                            </Link>
                        : null
                    }
                />
            </div>
			<div className="settings">
				<div className="container">
					<Formik
                        innerRef={ formikInnerRef }
						initialValues={ formValues }
						onSubmit={ formSubmit }>
                        { ({dirty, isSubmitting, setFieldValue}) => {
                        return  <Form>
                                    <TextField name="country" label={ t('CNPostalAddressCountryKey') } isClearable  setFieldValue={setFieldValue}/>
                                    <TextField name="state" label={ t('CNPostalAddressStateKey') } isClearable setFieldValue={setFieldValue}/>
                                    <TextField name="postalCode" label={ t('CNPostalAddressPostalCodeKey') } isClearable setFieldValue={setFieldValue}/>
                                    <TextField name="city" label={ t('CNPostalAddressCityKey') } isClearable setFieldValue={setFieldValue}/>
                                    <TextField name="address" label={ t('CNPostalAddressStreetKey') } isClearable setFieldValue={setFieldValue}/>
                                    <TextField name="ISOCountryCode" label={ t('CNPostalAddressISOCountryCodeKey') } isClearable setFieldValue={setFieldValue}/>
                                    <SubmitButton disabled={ !(dirty && !isSubmitting) }/>
                                </Form>
                        }}
					</Formik>
				</div>
			</div>
            <ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoBack') }
				show={ isUnsavedProfileModal }
				onHide={ hideUnsavedProfileModal }
				onDone={ () => history.goBack() }/>
		</React.Fragment>
	);
}

export default SettingsAddress;
