import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from 'react-icons/bs';
import { AiOutlineExport } from "react-icons/ai";
import { UpperNavigation } from './';

function FeedbackImageOne() {
    let history = useHistory();
    const { t } = useTranslation();


    return (
        <React.Fragment>
            <UpperNavigation 
                leftSide={
                    <div className="qboard-link" onClick={ () => history.goBack() }>
                        <BsChevronLeft className="icon-with-bg float-none ml-1" />
                    </div>
                }
                middle={
                    t('localizeFeedbackTitle')
                }
            />

            <div className="container">
                <div className="row justify-content-center p-2">
                    <img alt="placeholder" src="https://via.placeholder.com/400x600" />
                </div>
                <div className="row image-bottom-control position-fixed fixed-bottom bottom-form-container">
                    <div className="col-12" >
                        <AiOutlineExport />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default FeedbackImageOne;
