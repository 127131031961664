import React from 'react';

function DashboardInvalid() {
    return (
        <div>
            invalid
        </div>
    )
}

export default DashboardInvalid;
