import React, {useContext, useEffect, useRef, useState} from 'react';
import {Redirect, useRouteMatch, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Auth, Constants, QbTools, QbApi, QbApiTimeoutError, Config} from '../modules';
import {AppStateContext} from '../modules';
import {Formik, Form} from 'formik';
import {TextField, SubmitButton} from './form';
import {BsChevronLeft} from 'react-icons/bs';
import {ConfirmationModal} from "./index";
import {showMessage} from "../utilities/UiUtils";
import { UpperNavigation } from './';
import * as yup from 'yup';

function SettingsOpeningHour() {
	const {t} = useTranslation();
	const {t: webappT} = useTranslation('webapp');
	let history = useHistory();
	const formikInnerRef = useRef();
	const match = useRouteMatch('/settings/branch/:id/openinghours/:day');
	const appState = useContext(AppStateContext);
	const [ isUnsavedProfileModal, setUnsavedProfileModal ] = useState(false);
	const showUnsavedProfileModal = () => setUnsavedProfileModal(true)
	const hideUnsavedProfileModal = () => setUnsavedProfileModal(false)
	useEffect(() => {
		appState.setActivePage('settings.openinghour');
	});
	
	let authData = Auth.getData();
	let branch = {
		userProfile: authData.userProfile.branches?.find(element => String(element.id) === match.params.id),
		user: authData.user.branches?.find(element => String(element.id) === match.params.id),
	};
	const branchIndex = authData.userProfile.branches?.findIndex(element => String(element.id) === match.params.id);
	if ((branchIndex === -1) || (typeof branchIndex === 'undefined')) {
        return <Redirect to={ '/settings' }/>
    }
	
	let formValues = {
		'from': QbTools.isoTimeToHm(branch.userProfile.open[match.params.day]?.from),
		'to': QbTools.isoTimeToHm(branch.userProfile.open[match.params.day]?.to),
		'closed': branch.userProfile.open[match.params.day]?.closed,
	};
	
	async function formSubmit(formValues, {resetForm,setSubmitting}) {
		setSubmitting(true)
		let open = {
			from: QbTools.HmToisoTime(formValues.from),
			to: QbTools.HmToisoTime(formValues.to),
			closed: formValues.closed, // XXX: state variable
		}
		authData.userProfile.branches[branchIndex].open[match.params.day] = open
		const userProfile = {
			branches: authData.userProfile.branches,
		};
		
		const updatedUserProfile = Auth.calcUserProfile(userProfile);
		try {
			await QbApi.instance.updateUserProfile(appState, {userProfile: updatedUserProfile});
            Auth.updateUserProfile(userProfile);
			resetForm({values: formValues})
			showMessage({title:webappT("toastTitleSuccess"),message:webappT("toastDescriptionSuccessUpdate")})
		} catch (error) {
			if ((error instanceof QbApiTimeoutError) || (error instanceof TypeError)) {
				showMessage({title:webappT("toastTitleFailed"),message:webappT("toastDescriptionFailedUpdate"), type: 'error' })
			} else {
				throw error;
			}
		} finally {
			setSubmitting(false)
		}
	}
	
	const goBackPressHandler = () => {
		if (!formikInnerRef.current.dirty) {
			return history.goBack()
		}
		return showUnsavedProfileModal()
    }
    const validation = {
        time:  /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    }
    let enableSubmit = false;

    const onoffswitchStyle = {
        backgroundColor: Config.appearance.mainColor,
        opacity: '.5',
    }
    const onoffswitchCheckedStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
	
	return (
		<React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <div className="qboard-link" onClick={ goBackPressHandler }>
                            <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                        </div>
                    }
                    middle={
                        t(Constants.i18nDayOfWeek[match.params.day])
                    }
                />
            </div>
			
			<div className="settings">
				<div className="container">
					<Formik
						initialValues={ formValues }
						innerRef={ formikInnerRef }
						onSubmit={ formSubmit }
                        validationSchema={yup.object().shape({
                            from: yup.string()
                                .matches(
                                    validation.time,
                                    webappT('invalidTime')
                                ),
                            to: yup.string()
                                .matches(
                                    validation.time,
                                    webappT('invalidTime')
                                ),
                          })}>
						{ ({dirty, setFieldValue,isSubmitting, values, isValid}) => {
							const {closed} = values || {}
							return <Form>
								<div
									key="closed"
									className="qboard-link row align-items-center py-1 setting-item mx-2">
									<div className="col-9">{ t('localizeOpenHoursOpen') }</div>
									<div className="col-3">
										{ (closed !== false) ?
											<div style={onoffswitchStyle} className="onoffswitch"
											     onClick={ () => setFieldValue('closed', false) }>
												<span className="onoffswitch-dot"/>
											</div> :
											<div style={onoffswitchCheckedStyle} className="onoffswitch checked"
											     onClick={ () => setFieldValue('closed', true) }>
												<span className="onoffswitch-dot"/>
											</div>
										}
									</div>
								</div>
                                { (closed !== false) ?
                                    ''
                                    :
                                    <div>
                                        <TextField name="from" label={ t('localizeOpenHoursLimitFrom') } pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$" isClearable setFieldValue={setFieldValue}/>
                                        <TextField name="to" label={ t('localizeOpenHoursLimitTo') } pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$" isClearable  setFieldValue={setFieldValue}/>
                                    </div>
                                }
                                {
                                    (isValid)?
                                        (dirty && !isSubmitting)?
                                            enableSubmit = true
                                        :
                                            enableSubmit = false
                                    :
                                    enableSubmit = false
                                }
								<SubmitButton disabled={ !enableSubmit }/>
							</Form>
						} }
					</Formik>
				</div>
			</div>
			<ConfirmationModal
				message={ webappT('youHaveUnsavedChangesGoBack') }
				show={ isUnsavedProfileModal }
				onHide={ hideUnsavedProfileModal }
				onDone={ () => history.goBack() }/>
		</React.Fragment>
	);
}

export default SettingsOpeningHour;
