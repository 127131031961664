import React, { useEffect, useRef } from 'react';
import { QbTools } from '../modules/';
import { useTranslation } from 'react-i18next';
import { BreakdownDisplayLoggedIntoServicePoint } from './';

function BreakdownProxyLoggedIntoServicePoint(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();
    var sortedData = QbTools.sortBy(Object.entries(metricData.children?.data || { } ), 'RLC', { localeCompare: true } );
    sortedData = QbTools.filterStaffData(sortedData, props.match.params.id);
    const graph1 = useRef(null);
    //const userMerged = Auth.getData().userMerged;
    const pdfTitle = t('localizeMetricLoggedInCounter');

    const jsonMetricData = JSON.stringify(metricData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            props.setComponentList(prevState => {
                prevState.items[1] = {
                    position: 1,
                    id: 'BreakdownProxyLoggedIntoServicePoint',
                    filterName: 'localizeMetricLoggedInCounter',
                    metricData: metricData,
                    render: ( <BreakdownProxyLoggedIntoServicePoint boardtype={ props.boardtype } metricData={ props.metricData } match={ props.match } /> ),
                    pdfData: {
                        requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'children' ], t),
                        type: 'htmlsvg',
                        title: pdfTitle,
                        html: graph1.current.innerHTML,
                    },
                };
                return prevState;
            } );
        }
    }, [ jsonMetricData, metricData, pdfTitle, props, t ] );

    var components = [ ];
    for (const [ key, child ] of sortedData) {
        const itemId = QbTools.apiNameToItemId(key);
        var displayData = {
            key: key,
            itemId: itemId,
            name: QbTools.apiNameToFriendlyName(key),
            RLC: child['RLC'],
            RTS: child['RTS'],
            RST: child['RST'], // TODO: ISO timestamp (valósidejű) - de mi a TZ? ami alapján kéne, hogy konverrtáljuk "<x> minute" formára?
        };
        components.push(
            <BreakdownDisplayLoggedIntoServicePoint key={ key } displayData={ displayData } match={ props.match }/>
        );
    }

    return (
        <div ref={ graph1 } >
            { components }
        </div>
    );
}

export default BreakdownProxyLoggedIntoServicePoint;
