import React from 'react';
import {ErrorMessage, Field} from 'formik';
import {Config} from '../../modules';
import {ImCross} from 'react-icons/im';

function TextField(props) {
	const {disabled = false, component = 'input', isRightIcon = false, isClearable = false, forGroups = false} = props || {}
    const InputComponent = `${ component || 'input' }`
    
    function removeContent(e) {
        e.preventDefault();
        if(props?.setFieldValue){
            props.setFieldValue(props.name, '')
        } else {
            var list = document.getElementsByName(props.name);
            for (var item of list) {
                item.value = '';
            }
        }
        
    }
	return (
        (!forGroups)?
		<div className="row px-4 align-items-center py-2 setting-item">
			<label htmlFor={ props.name } className="col-12 font-weight-bold">{ props.label }</label>
			<div className="col-12 text-right">
				<Field name={ props.name }>
					{ ({field}) => (
						<div className='d-flex input-group align-items-center justify-content-end'>
							<InputComponent
								type="text"
								name={ props.name }
								disabled={ disabled }
								placeholder={ props?.placeholder }
                                className={ `form-control ${ isRightIcon ? 'border-right-0' : '' }` }
                                pattern={ props?.pattern }
                                title={ props?.title }
                                onChange={ props?.onChange }
								{ ...field }/>
							{ isRightIcon && <div className="input-group-append border-left-0 input-right-icon-container">
								{props.rightIcon}
							</div>
							}
                            { (isClearable && Config.appearance.enableClearableInput) && <button className='delete-input' type="reset" defaultValue="Reset" onClick={removeContent} ><ImCross /></button> }
						</div>
					) }
				</Field>
				<ErrorMessage className="text-right text-danger" component="div" name={ props.name }/>
			</div>
        </div>
        :
        <div key={ props.id } className="row align-items-center py-1 px-4 setting-item">
            <div className="col-12 text-right">
                <div className='d-flex input-group align-items-center justify-content-end'>
                    <Field className="form-control" name={ props.name } type="text" placeholder={ props.placeholder }/>
                    { (isClearable && Config.appearance.enableClearableInput) && <button className='delete-input' type="reset" defaultValue="Reset" onClick={removeContent} ><ImCross /></button> }
                </div>
            </div>
            <ErrorMessage name={props.name}/>
        </div>
	);
}

export default TextField;
