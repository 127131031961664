import React, {useContext, useEffect, useState} from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { AppStateContext, Auth, Config, Constants, QbApi, QbTools } from '../modules';
import moment from 'moment';
import { BsChevronLeft } from 'react-icons/bs';
import { BreakdownProxySubItem } from './';
import { AjaxStateFragment } from './';
import { useTranslation } from 'react-i18next';
import { UpperNavigation } from './';
import { HashLink } from 'react-router-hash-link';

function DashboardMapSubItem(props) {
    const boardtype = 'metric';
    const appState = useContext(AppStateContext);
    const { t } = useTranslation();
    const [ refreshCounter, setRefreshCounter ] = useState(0);
    const match = useRouteMatch('/dashboard/' + boardtype + '/:id/map/:subid');
    const [ areaName, setAreaName ] = useState('');

    const from = QbTools.nowApiTimeOffset().set('hour', 0).set('minute', 0).set('second', 0);
    const to = QbTools.nowApiTimeOffset().set('second', 0);
    const apiFrom = from.format();
    const apiTo = to.format();
    const apiTargetPath = match.params.id;
    const apiTargetPathSubId = match.params.subid;

    useEffect(() => {
        appState.setActivePage('dashboard');
    });
    useEffect(() => {
        const timer = setInterval(() => {
            incRefreshCounter();
        }, Config.dataRefreshInterval * 1000);

        return () => clearInterval(timer);
    }, []);

    function incRefreshCounter() {
        setRefreshCounter(refreshCounter => refreshCounter + 1);
    }

    const [ metricData, setMetricData ] = useState( { _state: Constants.ajaxState.init, _failed: false, current: { }, children: { }, leaf: { }, parent: { }, top: { } } );
    useEffect( () => {
        setMetricData(prevState => ( { ...prevState, _state: Constants.ajaxState.loading, _failed: false } ));
        var indices = [ ];

        // XXX: köv line hack: a leaf-et kérjük le, ám metricData.children -ben tároljuk el, hogy BreakdownProxySubItem jól kezelje
        if (refreshCounter === 0) {
            indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPath, 'leaf',     moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'branchDetails' ], (data) => QbApi.updateMetricData(setMetricData, 'children', data)));
        }
        indices.push(QbApi.instance.getMetricGroupbased(appState, apiTargetPathSubId, 'current',  moment(apiFrom), moment(apiTo), [ 'KSZ', 'USZ', 'VI', 'KI', 'NS', 'branchDetails', 'managers' ], (data) => QbApi.updateMetricData(setMetricData, 'children', data)));

        return function cleanup() {
            QbApi.instance.cancelQueue(indices);
        };
    }, [ apiTargetPath, apiTargetPathSubId, apiFrom, apiTo, appState, refreshCounter ] );

    const userData = Auth.getData().user;
    const groups = userData.groups;
    if (!groups.find(element => String(element.id) === match.params.id)) {
        return (<Redirect to={ '/dashboard/' + boardtype + '/' + groups[0].id  }/>);
    }

    /*function refreshData() {
        QbApiCache.instance.clear();
        incRefreshCounter();
    }*/

    const secondaryTitleStyle = {
        fontSize: Config.appearance.secondaryTitleSize,
    }

    return (
        <React.Fragment>
            <div className={(Config.appearance.enableStickyTop)? "sticky-top": ""}>
                <UpperNavigation
                    leftSide={
                        <HashLink className="qboard-link" to={"/dashboard/" + boardtype + '/' + match.params.id + '#google-map'}>
                            <BsChevronLeft className="icon-with-bg float-none ml-1" />
                        </HashLink>
                    }
                    middle={
                        <div className="text-center">
                            { t('localizeDashboardTypeSummary') }
                            <div style={ secondaryTitleStyle } className="subregion-name-upper-navigation font-weight-light mb-0">
                                { areaName }
                            </div>
                        </div>
                    }
                />
            </div>

            <div className="dashboard">
                <div className="container px-0 position-relative">
                    <AjaxStateFragment _state={ metricData._state } _failed={ metricData._failed } />
                    <div className="row align-items-center my-3">
                        <div className="col-lg-12">
                            <div>
                                <BreakdownProxySubItem boardtype={ boardtype } metricData={ metricData } match={ match } setAreaName={ setAreaName } displayWorst={ false } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DashboardMapSubItem;
