import { Constants, AuthStorageLocalStorage, AuthStorageMemory } from './';

class AuthStorage {
    static instance;
    static storageObject;
    static track;

    constructor() {
        const instance = this.constructor.instance;
        if (instance) {
            return instance;
        }

        this.constructor.storageObject = this.getStorageObject();
        this.constructor.instance = this;
    }

    getItem(keyName, toThrow = false) {
        return AuthStorage.storageObject.getItem(keyName, toThrow);
    }

    setItem(keyName, keyValue) {
        return AuthStorage.storageObject.setItem(keyName, keyValue);
    }

    removeItem(keyName) {
        return AuthStorage.storageObject.removeItem(keyName);
    }

    getStorageObject = function() {
        if (Constants.authStorage === 'localStorage') {
            return new AuthStorageLocalStorage();
        } else if (Constants.authStorage === 'memory') {
            return new AuthStorageMemory();
        }
        throw new Error('Auth: unknown storage engine: ' + Constants.authStorage);
    }
}

export default AuthStorage;
