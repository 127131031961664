import React, { useEffect, useRef } from 'react';
import { QBDashboardFigureNoShowCount } from '../graph/';
import { Config } from '../modules/';

function GraphNoShowCount(props) {
    const canvasGraph = useRef(null);

    useEffect( () => {
        function getParentWidth() {
            return canvasGraph.current.parentElement.clientWidth;
        }

        function _resizeHandler() {
            canvasGraph.current.width = getParentWidth() * 2;
            canvasGraph.current.height = getParentWidth() * 2;

            clearAndDraw();
        }

        function clearAndDraw() {
            const ctx = canvasGraph.current.getContext('2d');
            if (ctx) {
                const graphDefaults = Config.graphDefaults;
                var rect = QBDashboardFigureNoShowCount.makeRect(0, 0, getParentWidth() * 2, getParentWidth() * 2);
                QBDashboardFigureNoShowCount.clearCanvas(canvasGraph.current);
                QBDashboardFigureNoShowCount.drawCount(
                    canvasGraph.current,
                    0, // const_inactive_alpha
                    graphDefaults.NoShow[0], // const_percent_limit_green
                    graphDefaults.NoShow[1], // const_percent_limit_yellow
                    props.value, // value_result
                    props.max, // value_referenc
                    rect,
                    'aspectfit',
                    Config.appearance.darkGreen,
                    Config.appearance.darkYellow,
                    Config.appearance.darkRed,
                );
            }
        }

        window.addEventListener('resize', _resizeHandler);

        canvasGraph.current.width = getParentWidth() * 2;
        canvasGraph.current.height = getParentWidth() * 2;

        clearAndDraw();

        return function cleanup() {
            window.removeEventListener('resize', _resizeHandler);
        }
    }, [ props.value, props.max ] );

    return (
        <div>
            <canvas ref={canvasGraph} />
        </div>
    );
}

export default GraphNoShowCount;
