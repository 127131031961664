import React, { useEffect, useState, useContext } from 'react';
import { AppStateContext } from '../modules';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { QbApi, Auth } from '../modules';
import LegalDocumentsShowPdf from '../components/LegalDocumentsShowPdf';

function LegalDocumentsHistoryShowPdf() {
    const { t, i18n } = useTranslation();
    const appState = useContext(AppStateContext);
    const [ legal, setLegal ] = useState( null );
    const lng = i18n.language;
    const match = useRouteMatch('/legal/history/:id');
    const legalId = match.params.id;

    // XXX: cache-elni a lekért pdf-et(?)
    useEffect( () => {
        async function renderOutput() {
            let legalHistory = Auth.getItem('legalHistory');
            if ((typeof legalHistory === 'undefined') || (legalHistory === null)) {
                legalHistory = await QbApi.instance.getAsync(appState, 'legal/history');
                Auth.setItem('legalHistory', legalHistory);
            }

            for (const [, legalPerDate] of Object.entries(legalHistory.data)) {
                for (let index = 0, len = legalPerDate.length; index < len; ++index) {
                    const legal = legalPerDate[index];
                    if (String(legal.id) === legalId) {
                        setLegal(legal);
                    }
                }
            }
        }
        renderOutput();
    }, [ legalId, lng, t, appState ] );

    return (<LegalDocumentsShowPdf legal = {legal}/>);
}

export default LegalDocumentsHistoryShowPdf;
