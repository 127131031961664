//
//  QBDashboardFigureCustomerCount.js
//  qboard
//
//  Created by Zoltan Meszaros on 2020. 05. 25..
//  Copyright © 2020 Xperion. All rights reserved.
//
//  Generated by PaintCode
//  http://www.paintcodeapp.com
//



//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    QBDashboardFigureCustomerCount.drawCustomer_count(canvas, const_inactive_alpha, value_1, value_2, value_3, title_1, title_2, title_3, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//
// Available Utilities:
//    QBDashboardFigureCustomerCount.clearCanvas(canvas)
//    QBDashboardFigureCustomerCount.makeRect(x, y, width, height)


//// Create StyleKit Object
var QBDashboardFigureCustomerCount = {};
(function() {

    //// Drawing Methods

    function drawCustomer_count(canvas, const_inactive_alpha, value_1, value_2, value_3, title_1, title_2, title_3, targetFrame, resizing, color1, color2, color3) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        //var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 200, 170), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 200, resizedFrame.h / 170);


        //// Color Declarations
        /*var green = 'rgba(22, 162, 14, 1)';
        var yellow = 'rgba(211, 221, 14, 1)';
        var red = 'rgba(254, 0, 0, 1)';*/
        var green = color1;
        var yellow = color2;
        var red = color3;

        //// Variable Declarations
        var visible_1 = true;
        var alpha = value_1 >= 0 ? 1 : const_inactive_alpha;
        var progress_1 = value_1 > 0 ? value_1 / value_1 : 1;
        var percent_float_1 = progress_1 * 100;
        var percent_int_1 = Math.floor(percent_float_1);
        var percent_fraction_int_1 = 10 * (percent_float_1 - percent_int_1);
        var text_result_1 = ('' + Math.round(percent_fraction_int_1 > 9 ? percent_int_1 + 1 : percent_int_1)) + '.' + Math.round(percent_fraction_int_1 > 9 ? 0 : percent_fraction_int_1) + '%';
        var angle_result_1 = -1 * progress_1 * 360 + 90;
        var text_value_1 = value_1 > 0 ? ('' + Math.round(value_1)) : '0';
        var isValid_1 = value_1 >= 0;
        var progress_2 = value_2 > value_1 ? progress_1 : (value_1 > 0 ? value_2 / value_1 : 1);
        var percent_float_2 = progress_2 * 100;
        var percent_int_2 = Math.floor(percent_float_2);
        var percent_fraction_int_2 = 10 * (percent_float_2 - percent_int_2);
        var text_result_2 = ('' + Math.round(percent_fraction_int_2 > 9 ? percent_int_2 + 1 : percent_int_2)) + '.' + Math.round(percent_fraction_int_2 > 9 ? 0 : percent_fraction_int_2) + '%';
        var angle_result_2 = -1 * progress_2 * 360 + 90;
        var text_value_2 = value_2 > value_1 ? (value_1 > 0 ? ('' + Math.round(value_1)) : text_value_1) : ('' + Math.round(value_2));
        var isValid_2 = value_1 >= 0 && value_2 >= 0;
        var visible_2 = isValid_2;
        var progress_3 = value_3 > value_2 ? progress_2 : (value_3 / value_1 > progress_2 ? progress_2 : (value_1 > 0 ? value_3 / value_1 : 1));
        var percent_float_3 = progress_3 * 100;
        var percent_int_3 = Math.floor(percent_float_3);
        var percent_fraction_int_3 = 10 * (percent_float_3 - percent_int_3);
        var text_result_3 = ('' + Math.round(percent_fraction_int_3 > 9 ? percent_int_3 + 1 : percent_int_3)) + '.' + Math.round(percent_fraction_int_3 > 9 ? 0 : percent_fraction_int_3) + '%';
        var angle_result_3 = -1 * progress_3 * 360 + 90;
        var text_value_3 = value_3 > Math.min(value_2, value_1) ? ('' + Math.round(Math.min(value_2, value_1))) : ('' + Math.round(value_3));
        var isValid_3 = isValid_2 && value_3 >= 0;
        var visible_3 = isValid_3;

        //// part3
        //// Group 8
        //// Group 7
        if (isValid_1)
        {
            //// Bezier Drawing
            context.save();
            context.translate(0, 167);
            context.rotate(-90 * Math.PI / 180);

            context.save();
            context.globalAlpha = alpha;
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(0, 10);
            context.strokeStyle = green;
            context.lineWidth = 5;
            context.lineJoin = 'round';
            context.stroke();
            context.restore();

            context.restore();


            //// Text Drawing – Bottom text bottom
            context.save();
            context.globalAlpha = alpha;
            var textRect = makeRect(-10, 160, 60, 14);
            context.fillStyle = 'rgb(0, 0, 0)';
            context.font = '10px HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'left';
            var textTotalHeight = 10 * 1.3;
            context.fillText(title_1, textRect.x + textRect.w/2, textRect.y + 10 + textRect.h / 2 - textTotalHeight / 2);
            context.restore();
        }




        //// Group 5
        if (visible_3)
        {
            //// Bezier 2 Drawing
            context.save();
            context.translate(0, 143.5);
            context.rotate(-90 * Math.PI / 180);

            context.save();
            context.globalAlpha = alpha;
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(0, 10);
            context.strokeStyle = red;
            context.lineWidth = 5;
            context.lineJoin = 'round';
            context.stroke();
            context.restore();

            context.restore();


            //// Text 3 Drawing - Bottom text top
            context.save();
            context.globalAlpha = alpha;
            var text3Rect = makeRect(-10, 137, 60, 14);
            context.fillStyle = 'rgb(0, 0, 0)';
            context.font = '10px HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'left';
            var text3TotalHeight = 10 * 1.3;
            context.fillText(title_3, text3Rect.x + text3Rect.w/2, text3Rect.y + 10 + text3Rect.h / 2 - text3TotalHeight / 2);
            context.restore();
        }




        //// Group 6
        if (visible_2)
        {
            //// Bezier 3 Drawing
            context.save();
            context.translate(0, 155);
            context.rotate(-90 * Math.PI / 180);

            context.save();
            context.globalAlpha = alpha;
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(0, 10);
            context.strokeStyle = yellow;
            context.lineWidth = 5;
            context.lineJoin = 'round';
            context.stroke();
            context.restore();

            context.restore();


            //// Text 2 Drawing – Bottom text center
            context.save();
            context.globalAlpha = alpha;
            var text2Rect = makeRect(-10, 148, 60, 14);
            context.fillStyle = 'rgb(0, 0, 0)';
            context.font = '10px HelveticaNeue-Light, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'left';
            var text2TotalHeight = 10 * 1.3;
            context.fillText(title_2, text2Rect.x + text2Rect.w/2, text2Rect.y + 10 + text2Rect.h / 2 - text2TotalHeight / 2);
            context.restore();
        }






        //// Group 9
        if (visible_1)
        {
            //// data_oval Drawing
            context.save();
            context.globalAlpha = alpha;
            arc(context, 40, 11, 120, 119.5, -90, -angle_result_1, false);
            context.strokeStyle = green;
            context.lineWidth = 5;
            context.stroke();
            context.restore();
        }


        if (visible_2)
        {
            //// data_oval 2 Drawing
            context.save();
            context.globalAlpha = alpha;
            arc(context, 46, 17, 108, 107.5, -90, -angle_result_2, false);
            context.strokeStyle = yellow;
            context.lineWidth = 5;
            context.stroke();
            context.restore();
        }


        if (visible_3)
        {
            //// data_oval 3 Drawing
            context.save();
            context.globalAlpha = alpha;
            arc(context, 52, 23, 96, 95.5, -90, -angle_result_3, false);
            context.strokeStyle = red;
            context.lineWidth = 5;
            context.stroke();
            context.restore();
        }


        //// Group
        //// Group 4
        if (isValid_1)
        {
            //// Text 4 Drawing
            context.save();
            context.translate(122, 81.5);

            context.save();
            context.globalAlpha = alpha;
            var text4Rect = makeRect(-21, -5.5, 42, 11);
            context.fillStyle = green;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'left';
            var text4TotalHeight = 10 * 1.3;
            context.fillText(text_result_1, text4Rect.x, text4Rect.y + 10 + text4Rect.h / 2 - text4TotalHeight / 2);
            context.restore();

            context.restore();


            //// Text 7 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text7Rect = makeRect(58, 76, 33, 11);
            context.fillStyle = green;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'right';
            var text7TotalHeight = 10 * 1.3;
            context.fillText(text_value_1, text7Rect.x + text7Rect.w, text7Rect.y + 10 + text7Rect.h / 2 - text7TotalHeight / 2);
            context.restore();


            //// Text 10 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text10Rect = makeRect(94, 76, 7, 11);
            context.fillStyle = green;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'center';
            var text10TotalHeight = 10 * 1.3;
            context.fillText('-', text10Rect.x + text10Rect.w/2, text10Rect.y + 10 + text10Rect.h / 2 - text10TotalHeight / 2);
            context.restore();
        }




        //// Group 3
        if (visible_2)
        {
            //// Text 5 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text5Rect = makeRect(101, 65, 42, 11);
            context.fillStyle = yellow;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'left';
            var text5TotalHeight = 10 * 1.3;
            context.fillText(text_result_2, text5Rect.x, text5Rect.y + 10 + text5Rect.h / 2 - text5TotalHeight / 2);
            context.restore();


            //// Text 8 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text8Rect = makeRect(58, 65, 33, 11);
            context.fillStyle = yellow;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'right';
            var text8TotalHeight = 10 * 1.3;
            context.fillText(text_value_2, text8Rect.x + text8Rect.w, text8Rect.y + 10 + text8Rect.h / 2 - text8TotalHeight / 2);
            context.restore();


            //// Text 11 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text11Rect = makeRect(94, 65, 7, 11);
            context.fillStyle = yellow;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'center';
            var text11TotalHeight = 10 * 1.3;
            context.fillText('-', text11Rect.x + text11Rect.w/2, text11Rect.y + 10 + text11Rect.h / 2 - text11TotalHeight / 2);
            context.restore();
        }




        //// Group 2
        if (visible_3)
        {
            //// Text 6 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text6Rect = makeRect(101, 54, 42, 11);
            context.fillStyle = red;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'left';
            var text6TotalHeight = 10 * 1.3;
            context.fillText(text_result_3, text6Rect.x, text6Rect.y + 10 + text6Rect.h / 2 - text6TotalHeight / 2);
            context.restore();


            //// Text 9 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text9Rect = makeRect(58, 54, 33, 11);
            context.fillStyle = red;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'right';
            var text9TotalHeight = 10 * 1.3;
            context.fillText(text_value_3, text9Rect.x + text9Rect.w, text9Rect.y + 10 + text9Rect.h / 2 - text9TotalHeight / 2);
            context.restore();


            //// Text 12 Drawing
            context.save();
            context.globalAlpha = alpha;
            var text12Rect = makeRect(94, 54, 7, 11);
            context.fillStyle = red;
            context.font = '10px HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif';
            context.textAlign = 'center';
            var text12TotalHeight = 10 * 1.3;
            context.fillText('-', text12Rect.x + text12Rect.w/2, text12Rect.y + 10 + text12Rect.h / 2 - text12TotalHeight / 2);
            context.restore();
        }
        
        context.restore();

    }

    //// Infrastructure

    function clearCanvas(canvas) {
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    // Possible arguments for 'resizing' parameter are:
    //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
    //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
    //   'stretch': The content is stretched to match the entire target rectangle.
    //   'center': The content is centered in the target rectangle, but it is NOT resized.
    function applyResizingBehavior(resizing, rect, targetRect) {
        if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
            return rect;
        }

        var scales = makeSize(0, 0);
        scales.w = Math.abs(targetRect.w / rect.w);
        scales.h = Math.abs(targetRect.h / rect.h);

        switch (resizing) {
            case 'aspectfit': {
                scales.w = Math.min(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'aspectfill': {
                scales.w = Math.max(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'stretch':
            case undefined:
                break;
            case 'center': {
                scales.w = 1;
                scales.h = 1;
                break;
            }
            default:
                throw new Error('Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.');
        }

        var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h));
        result.w *= scales.w;
        result.h *= scales.h;
        result.x = targetRect.x + (targetRect.w - result.w) / 2;
        result.y = targetRect.y + (targetRect.h - result.h) / 2;
        return result;
    }

    function arc(context, x, y, w, h, startAngle, endAngle, isClosed) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, Math.PI/180*startAngle, Math.PI/180*endAngle, false);
        if (isClosed)
        {
            context.lineTo(1, 1);
            context.closePath();
        }
        context.restore();
    }

    function makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    function equalRects(r1, r2) {
        return r1.x === r2.x && r1.y === r2.y && r1.w === r2.w && r1.h === r2.h;
    }

    function makeSize(w, h) {
        return { w: w, h: h };
    }

    function initializeCanvas(canvas) {
        if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        var context = canvas.getContext('2d');

        var devicePixelRatio = window.devicePixelRatio || 1;
        var backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        var pixelRatio = devicePixelRatio / backingStorePixelRatio;

        //canvas.style.width = canvas.width + 'px';
        //canvas.style.height = canvas.height + 'px';
        canvas.width *= pixelRatio;
        canvas.height *= pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return canvas;
    }

    //// Public Interface

    // Drawing Methods
    QBDashboardFigureCustomerCount.drawCustomer_count = drawCustomer_count;

    // Utilities
    QBDashboardFigureCustomerCount.clearCanvas = clearCanvas;
    QBDashboardFigureCustomerCount.makeRect = makeRect;

})();

export default QBDashboardFigureCustomerCount;
