import React from 'react';
import {Config} from '../modules';
import { GlobalStyles } from './';

function UpperNavigation(props) {

    const navStyleWOSearch = {
        minHeight: Config.appearance.upperMenuHeightWOSearch,
      };

      const navigationUpperStyle = {
          backgroundColor: Config.appearance.upperNavigationBackgroundColor,
          color: Config.appearance.upperNavigationtextColor,
      }

      const navigationUpperTextStyle = {
          color: Config.appearance.upperNavigationtextColor,
      }


    return (
        <div style={ navigationUpperStyle } className="navigation navigation-upper py-2 d-flex align-items-center">
            <GlobalStyles />
            <div className="container">
                <div style={navStyleWOSearch} className="row nav">
                    <div className={`d-flex align-items-start ${props.rightSide ? "col-4" : "col-2"}`}>
                        { props.leftSide }
                    </div>
                    <div style={navigationUpperTextStyle} className={`d-flex justify-content-center font-weight-bold text-center ${props.rightSide ? "col-4" : "col-8"}`}>
                        { props.middle }
                    </div>
                    <div className={` d-flex align-content-center justify-content-end ${props.rightSide ? "col-4" : "col-2"}`}>
                        { props.rightSide }
                    </div>
                </div>
                {
                    (props.bottom)?
                        <div className="row search">
                            <div className="col-12">
                                {props.bottom}
                            </div>
                        </div>
                    :''
                }
            </div>
        </div>
    );
}

export default UpperNavigation;
