import React, {Component} from "react";
import "react-image-lightbox/style.css";
import LightBox from "react-image-lightbox";


class LightImageBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			photoIndex: 0,
		};
	}
	
	static getDerivedStateFromProps(props, state) {
		if (!props.isOpen) {
			return {photoIndex: 0}
		}
	}
	
	render() {
		const {isOpen} = this.props
		const {photoIndex} = this.state;
		const images = this.props?.images || []
		return (
			<div>
				{ isOpen && (
					<LightBox
						mainSrc={ images[photoIndex] }
						nextSrc={ images[photoIndex + 1] }
						prevSrc={ images[photoIndex - 1] }
						onCloseRequest={ this.props.onClose }
						onMovePrevRequest={ () =>
							this.setState({
								photoIndex: (photoIndex - 1)
							})
						}
						onMoveNextRequest={ () =>
							this.setState({
								photoIndex: (photoIndex + 1)
							})
						}
					/>
				) }
			</div>
		);
	}
}

export default LightImageBox
