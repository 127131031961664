import React from 'react';
import {useTranslation} from 'react-i18next';
import { Config } from '../../modules';

function SubmitButton(props) {
    const {disabled=false}=props||{}
    const {t: webappT} = useTranslation('webapp');
    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }
    return (
        <div className="row px-4 align-items-center py-1 setting-item">
            <div className="col-12">
                <button style={ submitButtonStyle } className="btn btn-primary btn-block" type="submit" disabled={disabled}>
                    {( props.text ) ?  props.text : webappT('submit') }
                </button>
            </div>
        </div>
    );
}

export default SubmitButton;
