import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsDisplayUnusedServices } from './';
import { AjaxStateFragment } from './';
import { QbTools } from '../modules';

function AnalyticsProxyUnusedServices(props) {
    const { t } = useTranslation();
    var metricData = props.metricData;
    var sortedData = Object.entries(metricData.tk1?.data || { } )
        .filter(item => (item[1] === null));
    const graph1 = useRef(null);
    const graph1Title = useRef(null);

    const jsonDisplayData = JSON.stringify(sortedData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: props.graphTitle,
                    displayData: sortedData,
                    render: ( <AnalyticsProxyUnusedServices { ...props } /> ),
                    pdfData: {
                        requestTime: QbTools.getMetricTiming(props.metricData, 'request', [ 'tk1' ], t),
                        type: 'htmlsvg',
                        titleHtml: graph1Title.current.outerHTML,
                        html: graph1.current.innerHTML,
                    },
                };
                return prevState;
            } );
        }
    }, [ jsonDisplayData, sortedData, props, t ] );


    /*
    sortedData.sort(function (a, b) {
        return a[1].KSZ - b[1].KSZ;
    } );
    */

    var components = [ ];
    for (const [key, ] of sortedData) {
        var displayData = {
            name: key,
        };
        components.push(
            <AnalyticsDisplayUnusedServices key={ key } displayData={ displayData }/>
        );
    }

    return (
        <div className="metric">
            <div className="row text-center my-1 pt-3 title" ref={ graph1Title } >
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t(props.graphTitle) }</h4>
                </div>
            </div>
            <div className="row my-1">
                <div className="col-12">
                    <div className="unused-services-container position-relative chart-container" ref={ graph1 } >
                        <AjaxStateFragment _state={ metricData._state } _failed={ metricData._failed } />
                        { components }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalyticsProxyUnusedServices;
