class AuthStorageMemory {
    constructor() {
        this.constructor.storage = { };
    }

    getItem(keyName, toThrow = false) {
        if (this.constructor.storage.hasOwnProperty(keyName)) {
            return this.constructor.storage[keyName];
        }
        return null;
    }

    setItem(keyName, keyValue) {
        this.constructor.storage[keyName] = keyValue;
        return undefined;
    }

    removeItem(keyName) {
        delete this.constructor.storage[keyName];
        return undefined;
    }
}

export default AuthStorageMemory;
