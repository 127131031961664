import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Auth } from '../modules';

function AnalyticsRedirect() {
    const match = useRouteMatch('/analytics/:duration');
    const userMerged = Auth.getData().userMerged;
    const groups = userMerged.groups;
    if (! match) {
        return (
            <Redirect to={ '/analytics/day/' + Object.keys(groups)[0] } />
        );
    }
    return (
        <Redirect to={ '/analytics/' + match.params.duration + '/' + Object.keys(groups)[0] } />
    );
}

export default AnalyticsRedirect;
