import React from "react";
import {isEmpty} from "lodash";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function ListEmptyComponent(props) {
	const {isLoading,dataList}=props||{}
	const {t} = useTranslation()
	if (!isEmpty(dataList)) {
		return null
	}
	
	return <div className='d-flex justify-content-center mt-3'>
		{ isLoading ? <Spinner animation="border" variant="primary"/> :
			<div className='text-center'>{ t('localizeDashboardNoDataWarning') }</div> }
	</div>
}

export default ListEmptyComponent;
