import React from 'react';

function GeneratePdfIcon(props) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
            <path id="icon-bezier2" stroke={ props.stroke } strokeOpacity="1" strokeWidth="1" strokeLinejoin="round" strokeMiterlimit="10" fill="none" d="M 16.5,17.5 L 16.5,18.5 3.5,18.5 3.5,1.5 11.62,1.5 16.5,6.75 16.5,17.5 M 11.62,1.5 L 11.62,6.75 16.5,6.75" />
        </svg>
    );
}

export default GeneratePdfIcon; 
