import React, { useEffect, useRef } from 'react';
import { GraphServiceLevel } from './';
import { AjaxStateFragment } from './';
import { useTranslation } from 'react-i18next';

function MetricDisplayServiceLevel(props) {
    var displayData = props.displayData;
    const { t } = useTranslation();
    const graph1 = useRef(null);
    const graph2 = useRef(null);
    const graph3 = useRef(null);

    const jsonDisplayData = JSON.stringify(displayData);
    useEffect( () => {
        if (props.hasOwnProperty('setComponentList')) {
            let canvas1 = graph1.current.querySelector('canvas');
            let canvas2 = graph2.current.querySelector('canvas');
            let canvas3 = graph3.current.querySelector('canvas');
            props.setComponentList(prevState => {
                prevState.items[props.position] = {
                    position: props.position,
                    id: props.id,
                    filterName: 'localizeDashboardServeLevelTitle',
                    displayData: displayData,
                    render: ( <MetricDisplayServiceLevel displayData={ displayData }/> ),
                    pdfData: {
                        requestTime: displayData._requestTime,
                        type: 'canvas3',
                        title: t('localizeDashboardServeLevelTitle'),
                        items: [
                            {
                                subTitle: displayData.graph1Subtitle,
                                graphTitle: displayData.graph1Title,
                            },
                            {
                                subTitle: displayData.graph2Subtitle,
                                graphTitle: displayData.graph2Title,
                            },
                            {
                                graphTitle: displayData.graph3Title,
                            },
                        ],
                    },
                };
                Object.defineProperty(prevState.items[props.position].pdfData.items[0], 'imageData', {
                    get: function () { return canvas1.toDataURL('image/png'); },
                    enumerable: true,
                } );
                Object.defineProperty(prevState.items[props.position].pdfData.items[1], 'imageData', {
                    get: function () { return canvas2.toDataURL('image/png'); },
                    enumerable: true,
                } );
                Object.defineProperty(prevState.items[props.position].pdfData.items[2], 'imageData', {
                    get: function () { return canvas3.toDataURL('image/png'); },
                    enumerable: true,
                } );
                return prevState;
            } );
        }
    }, [ jsonDisplayData, displayData, props, t ] );

    return (
        <div className="metric">
            <div className="row text-center my-1 pt-5 title">
                <div className="col-12">
                    <h4 className="font-weight-bold analytics-graph-full-title">{ t('localizeDashboardServeLevelTitle') }</h4>
                </div>
            </div>
            <div className="row metric-content text-center my-1 px-2">
                <AjaxStateFragment _state={ displayData._state } _failed={ displayData._failed } />
                <div className="col-4 px-1 justify-content-center">
                    <div className="metric-item" ref={ graph1 } >
                        <GraphServiceLevel value={ displayData.graph1Value * 100 } />
                        { displayData.graph1Subtitle ?
                            <p className="metric-graph-title subtitle mb-0">
                                { displayData.graph1Subtitle }
                            </p>
                        : '' }
                        <p className="metric-graph-title">
                            { displayData.graph1Title }
                        </p>
                    </div>
                </div>
                <div className="col-4 px-1 justify-content-center">
                    <div className="metric-item" ref={ graph2 } >
                        <GraphServiceLevel value={ displayData.graph2Value * 100 } />
                        { displayData.graph2Subtitle ?
                            <p className="metric-graph-title subtitle mb-0">
                                { displayData.graph2Subtitle }
                            </p>
                        : '' }
                        <p className="metric-graph-title">
                            { displayData.graph2Title }
                        </p>
                    </div>
                </div>
                <div className="col-4 px-1 justify-content-center">
                    <div className="metric-item" ref={ graph3 } >
                        <GraphServiceLevel value={ displayData.graph3Value * 100 } />
                        <p className="metric-graph-title">
                            { displayData.graph3Title }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MetricDisplayServiceLevel;
