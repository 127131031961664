export { default as Navigation } from './Navigation';
export { default as Footer } from './Footer';
export { default as Layout } from './Layout';
export { default as ScrollToTop } from './ScrollToTop';
export { default as GlobalStyles } from './GlobalStyles';
export { default as Sidebar } from './Sidebar';
export { default as Modalbox } from './Modalbox';
export { default as UpperNavigation } from './UpperNavigation';
export { default as LowerNavigation } from './LowerNavigation';
export { default as OptionsModal } from './OptionsModal';

export { default as Home } from './Home';
export { default as About } from './About';
export { default as LoginPage } from './LoginPage';
export { default as LoginForm } from './LoginForm';
export { default as AuthLegalForm } from './AuthLegalForm';
export { default as AuthLegalDocuments } from './AuthLegalDocuments';
export { default as NoRoutePage } from './NoRoutePage';

// dashboard metrikáknak
export { default as Dashboard } from './Dashboard';
export { default as DashboardInvalid } from './DashboardInvalid';
export { default as DashboardHqHeadquarter } from './DashboardHqHeadquarter';
export { default as DashboardHqRegions } from './DashboardHqRegions';
export { default as DashboardRmRegion } from './DashboardRmRegion';
export { default as DashboardRmSubregions } from './DashboardRmSubregions';
export { default as DashboardSrmSubregion } from './DashboardSrmSubregion';
export { default as DashboardSrmBranches } from './DashboardSrmBranches';
export { default as DashboardBmBranch } from './DashboardBmBranch';
export { default as DashboardBmStaff } from './DashboardBmStaff';
export { default as DashboardBmCamera } from './DashboardBmCamera';

// dashboard child itemeknek
export { default as DashboardBreakdown } from './DashboardBreakdown';

// dashboard map subitem
export { default as DashboardMapSubItem } from './DashboardMapSubItem';

// MetricProxy: metrikák számítása lekért adatok és hierarchiában elfoglalt hely alapján
export { default as MetricProxyHqServiceLevel } from './MetricProxyHqServiceLevel';
export { default as MetricProxyHqAverageWaitingTime } from './MetricProxyHqAverageWaitingTime';
export { default as MetricProxyHqAverageServingTime } from './MetricProxyHqAverageServingTime';
export { default as MetricProxyHqNoShow } from './MetricProxyHqNoShow';
export { default as MetricProxyHqServedCustomers } from './MetricProxyHqServedCustomers';

export { default as MetricProxyRmServiceLevel } from './MetricProxyRmServiceLevel';
export { default as MetricProxyRmAverageWaitingTime } from './MetricProxyRmAverageWaitingTime';
export { default as MetricProxyRmAverageServingTime } from './MetricProxyRmAverageServingTime';
export { default as MetricProxyRmNoShow } from './MetricProxyRmNoShow';
export { default as MetricProxyRmServedCustomers } from './MetricProxyRmServedCustomers';

export { default as MetricProxySrmServiceLevel } from './MetricProxySrmServiceLevel';
export { default as MetricProxySrmAverageWaitingTime } from './MetricProxySrmAverageWaitingTime';
export { default as MetricProxySrmAverageServingTime } from './MetricProxySrmAverageServingTime';
export { default as MetricProxySrmNoShow } from './MetricProxySrmNoShow';
export { default as MetricProxySrmServedCustomers } from './MetricProxySrmServedCustomers';

export { default as MetricProxyBmActiveServicePoints } from './MetricProxyBmActiveServicePoints';
export { default as MetricProxyBmTicketsServingWaiting } from './MetricProxyBmTicketsServingWaiting';

export { default as MetricProxyServedCustomersAndAverageWaitingTime } from './MetricProxyServedCustomersAndAverageWaitingTime';

// MetricDisplay: metrikák megjelenítése
export { default as MetricDisplayServiceLevel } from './MetricDisplayServiceLevel';
export { default as MetricDisplayAverageWaitingTime } from './MetricDisplayAverageWaitingTime';
export { default as MetricDisplayAverageServingTime } from './MetricDisplayAverageServingTime';
export { default as MetricDisplayNoShow } from './MetricDisplayNoShow';
export { default as MetricDisplayServedCustomers } from './MetricDisplayServedCustomers';
export { default as MetricDisplayBmActiveServicePoints } from './MetricDisplayBmActiveServicePoints';
export { default as MetricDisplayBmTicketsServingWaiting } from './MetricDisplayBmTicketsServingWaiting';

// BreakdownProxy: metrikák számítása lekért adatok és hierarchiában elfoglalt hely alapján
export { default as BreakdownProxyServedCustomers } from './BreakdownProxyServedCustomers';
export { default as BreakdownProxyServiceLevel } from './BreakdownProxyServiceLevel';
export { default as BreakdownProxyAverageWaitingTime } from './BreakdownProxyAverageWaitingTime';
export { default as BreakdownProxyAverageServingTime } from './BreakdownProxyAverageServingTime';
export { default as BreakdownProxyLoggedIntoServicePoint } from './BreakdownProxyLoggedIntoServicePoint';

// BreakdownDisplay: metrikák megjelenítése
export { default as BreakdownDisplayServedCustomers } from './BreakdownDisplayServedCustomers';
export { default as BreakdownDisplayServiceLevel } from './BreakdownDisplayServiceLevel';
export { default as BreakdownDisplayAverageWaitingTime } from './BreakdownDisplayAverageWaitingTime';
export { default as BreakdownDisplayAverageServingTime } from './BreakdownDisplayAverageServingTime';
export { default as BreakdownDisplayLoggedIntoServicePoint } from './BreakdownDisplayLoggedIntoServicePoint';

// Breakdown elemei részletező nézet
export { default as BreakdownProxySubItem } from './BreakdownProxySubItem';
export { default as BreakdownProxyWorstItem } from './BreakdownProxyWorstItem';
export { default as BreakdownDisplayItemSelector } from './BreakdownDisplayItemSelector';
export { default as BreakdownProxyBmSubItem } from './BreakdownProxyBmSubItem';
export { default as BreakdownDisplaySubItem } from './BreakdownDisplaySubItem';
export { default as BreakdownDisplayWorstItem } from './BreakdownDisplayWorstItem';
export { default as BreakdownDisplayBmSubItem } from './BreakdownDisplayBmSubItem';
export { default as MessagingModal } from './MessagingModal';

// camera
export { default as DashboardCamera } from './DashboardCamera';
export { default as DashboardBmCameraImage } from './DashboardBmCameraImage';

// hierarcy descriptor
export { default as DashboardHierarchy } from './DashboardHierarchy';

// analytics
export { default as Analytics } from './Analytics';
export { default as AnalyticsRedirect } from './AnalyticsRedirect';

export { default as AnalyticsHq } from './AnalyticsHq';
export { default as AnalyticsRm } from './AnalyticsRm';
export { default as AnalyticsSrm } from './AnalyticsSrm';
export { default as AnalyticsBm } from './AnalyticsBm';

// AnalyticsProxy: metrikák számítása lekért adatok és hierarchiában elfoglalt hely alapján
export { default as AnalyticsProxyCustomerCountServiceLevel } from './AnalyticsProxyCustomerCountServiceLevel';
export { default as AnalyticsProxyCustomerCountWaitingTime } from './AnalyticsProxyCustomerCountWaitingTime';
export { default as AnalyticsProxyCustomerCountServingTime } from './AnalyticsProxyCustomerCountServingTime';
export { default as AnalyticsProxyCustomerCountNoShowCustomerCount } from './AnalyticsProxyCustomerCountNoShowCustomerCount';
export { default as AnalyticsProxyServiceLevels } from './AnalyticsProxyServiceLevels';
export { default as AnalyticsProxyMostPopularServices } from './AnalyticsProxyMostPopularServices';
export { default as AnalyticsProxyLeastPopularServices } from './AnalyticsProxyLeastPopularServices';
export { default as AnalyticsProxyUnusedServices } from './AnalyticsProxyUnusedServices';

// AnalyticsDisplay: metrikák megjelenítése
export { default as AnalyticsDisplayLineBarComposedWithPie } from './AnalyticsDisplayLineBarComposedWithPie';
export { default as AnalyticsDisplayServiceLevels } from './AnalyticsDisplayServiceLevels';
export { default as AnalyticsDisplayPopularServices } from './AnalyticsDisplayPopularServices';
export { default as AnalyticsDisplayUnusedServices } from './AnalyticsDisplayUnusedServices';

// grafikonok
export { default as GraphServiceLevel } from './GraphServiceLevel';
export { default as GraphAverageServingTime } from './GraphAverageServingTime';
export { default as GraphAverageWaitingTime } from './GraphAverageWaitingTime';
export { default as GraphNoShowCount } from './GraphNoShowCount';
export { default as GraphServedCustomers } from './GraphServedCustomers';
export { default as GraphServicePoint } from './GraphServicePoint';
export { default as GraphTicket } from './GraphTicket';
export { default as GraphFigureServiceCompare } from './GraphFigureServiceCompare';

export { default as GraphLineBarComposed } from './GraphLineBarComposed';
export { default as GraphPie } from './GraphPie';

// settings
export { default as Settings } from './Settings';
export { default as SettingsProfile } from './SettingsProfile';
export { default as SettingsLanguage } from './SettingsLanguage';
export { default as SettingsPrivacy } from './SettingsPrivacy';
export { default as SettingsPrivacyPolicy } from './SettingsPrivacyPolicy';
export { default as SettingsBranch } from './SettingsBranch';
export { default as SettingsStaff } from './SettingsStaff';
export { default as SettingsAddress } from './SettingsAddress';
export { default as SettingsAddressMap } from './SettingsAddressMap';
export { default as SettingsOpeningHours } from './SettingsOpeningHours';
export { default as SettingsOpeningHour } from './SettingsOpeningHour';
export { default as ConfirmationModal } from './ConfirmationModal';
export { default as GoogleMapDrawerFullPage } from './GoogleMapDrawerFullPage';

// help
export { default as Help } from './Help';
export { default as HelpIndex } from './HelpIndex';
export { default as HelpPage } from './HelpPage';

// report
export { default as Report } from './Report';
export { default as ReportList } from './ReportList';
export { default as ReportItem } from './ReportItem';
export { default as ReportItemHistory } from './ReportItemHistory';
export { default as ReportReview } from './ReportReview';
export { default as ReviewReportModal } from './ReviewReportModal';
export { default as LighImageBox } from './LighImageBox';

// feedback
export { default as Feedback } from './Feedback';
export { default as FeedbackImageUpload } from './FeedbackImageUpload';
export { default as FeedbackImageOne } from './FeedbackImageOne';
export { default as FeedbackImageTwo } from './FeedbackImageTwo';
export { default as FeedbackNew } from './FeedbackNew';

// legal
export { default as LegalDocuments } from './LegalDocuments';
export { default as LegalDocumentsCurrentList } from './LegalDocumentsCurrentList';
export { default as LegalDocumentsCurrentShowPdf } from './LegalDocumentsCurrentShowPdf';
export { default as LegalDocumentsHistoryList } from './LegalDocumentsHistoryList';
export { default as LegalDocumentsHistoryShowPdf } from './LegalDocumentsHistoryShowPdf';

// tour == onboarding
export { default as Tour } from './Tour';
export { default as TourIndex } from './TourIndex';
export { default as TourPage } from './TourPage';

//google map
export { default as CustomGoogleMap } from './CustomGoogleMap';

// fragment
export { default as LegalDocumentsFragment } from './LegalDocumentsFragment';
export { default as AjaxStateFragment } from './AjaxStateFragment';
