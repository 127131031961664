import React  from 'react';
import { Link, useLocation, useRouteMatch } from "react-router-dom";

const BreakdownDisplayItemSelector = (props) => {
    let location = useLocation();
    const isWorst = useRouteMatch({
        path: "*/worst",
      });
    let keepValue = false
    if(location.state){
        keepValue = location.state.keepValue;
    }

    if(!keepValue){
        sessionStorage.removeItem('bestName');
        sessionStorage.removeItem('worstName');
        sessionStorage.removeItem('bestLink');
        sessionStorage.removeItem('worstLink');

        if(!sessionStorage.getItem('bestName') || sessionStorage.getItem('bestName') === null){
            if(props.bestName !== null)
                sessionStorage.setItem('bestName', props.bestName);
        }
        if(!sessionStorage.getItem('worstName') || sessionStorage.getItem('worstName') === null){
            if(props.worstName !== null)
                sessionStorage.setItem('worstName', props.worstName);
        }
        if(!sessionStorage.getItem('bestLink') || sessionStorage.getItem('bestLink') === null){
            if(props.bestLink !== null)
                sessionStorage.setItem('bestLink', props.bestLink);
        }
        if(!sessionStorage.getItem('worstLink') || sessionStorage.getItem('worstLink') === null){
            if(props.worstName !== null)
                sessionStorage.setItem('worstLink', props.worstLink);
        }
    }

    return (
        <div className="container">
            { props.keepValue }
            <div className="row text-center my-1 pt-3 title d-flex justify-content-center">
                <div className="col-12 col-lg-3"></div>
                <div className="regio-selector p-0 col-10 col-lg-6">
                    <div className="row m-0">
                        <div className="col-12 col-md-6 p-0">
                            <Link className={`left-selector w-100 h-100 ${!isWorst ? "active" : ""}`} replace={ true }
                                to={{ pathname: sessionStorage.getItem('bestLink'), state: { keepValue: true } }}>
                                { sessionStorage.getItem('bestName') }
                            </Link>
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            <Link className={`right-selector w-100 h-100 ${isWorst ? "active" : ""}`} replace={ true }
                                to={{ pathname: sessionStorage.getItem('worstLink'), state: { keepValue: true } }}>
                                { sessionStorage.getItem('worstName') }
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-3"></div>
            </div>
        </div>
    );
}

export default BreakdownDisplayItemSelector;
