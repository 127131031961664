import {useTranslation} from "react-i18next";
import {compose, withProps} from "recompose";
import {Config} from "../modules";
import React, {useEffect, useState} from "react";
import {API_KEY, TESTING_PLACE_LAT_LNG_1} from "../utilities/Constant";
import {addressToLatLngHandler, navigateMap} from "../utilities/PlaceUtils";
import {isGeoCodingAllowed, isValidLatLng} from "../utilities/ValidationUtils";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import {BsChevronLeft} from "react-icons/bs";
import { UpperNavigation } from './';

let mapRef = null
const NavigateMarkerMap = compose(
	withProps({
		googleMapURL:
			`https://maps.googleapis.com/maps/api/js?key=${ API_KEY }&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={ {height: `100%`} }/>,
		containerElement: <div style={ {height: '100vh', width: '100%'} }/>,
		mapElement: <div style={ {height: `100%`} }/>
	}),
	withScriptjs,
	withGoogleMap
)(props => {
	const {markerLocation} = props || {}
	/*const {
		getMapRef = () => {
		}
	} = props || {}*/
	useEffect(() => {
		navigateMap(mapRef, markerLocation)
	}, [ markerLocation ])
	return <GoogleMap
		defaultOptions={ {
			streetViewControl: false,
			scaleControl: false,
			mapTypeControl: false,
			rotateControl: false,
			fullscreenControl: false
		} }
		defaultZoom={ 15 }
		defaultCenter={ TESTING_PLACE_LAT_LNG_1 }
		onClick={ props.updateMarkerLocation }
		ref={ (ref) => (mapRef = ref) }>
		<Marker position={ markerLocation }/>
	</GoogleMap>
});

const BodyDrawerBody = (props) => {
	const {t: webappT} = useTranslation('webapp');
	const {lat, lng, address} = props?.location ?? {}
	const [ markerLocation, setMarkerLocation ] = useState({lat, lng})
	useEffect(() => {
		const fetchData = async () => {
			if (!isValidLatLng({lat, lng}) || isGeoCodingAllowed({lat, lng})) {
				const response = await addressToLatLngHandler(address)
				setMarkerLocation(response)
			} else {
				setMarkerLocation({lat, lng})
			}
		}
		fetchData()
	}, [])
	
	const onSaveHandler = () => {
		props.onUpdateLocation(markerLocation)
    }
    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    function handleUpdateMarkerLocation(event) {
        const lat = event.latLng.lat()
        const lng = event.latLng.lng()
        setMarkerLocation( { lat, lng } );
        if (typeof props.onUpdateMarkerLocation === 'function') {
            props.onUpdateMarkerLocation(markerLocation);
        }
    }

	return <div>
        <UpperNavigation
            leftSide={
                <div className='text-white qboard-link' onClick={ props.closeGoogleMapModal }>
                    <BsChevronLeft className="icon-with-bg float-none ml-1"/>
                </div>
            }
            middle={
                <div className='font-weight-bold'>{ props?.branchName ?? webappT('branch')  }</div>
            }
            rightSide={
	            <button style={ submitButtonStyle } className="btn btn-primary bm-sm" onClick={ onSaveHandler }>
		            { webappT('save') }
	            </button>
            }
        />
		<NavigateMarkerMap
			markerLocation={ markerLocation }
			updateMarkerLocation={ handleUpdateMarkerLocation }
        />
	</div>
}

const GoogleMapDrawerFullPage = (props) => {
	return (
		<div>
            <BodyDrawerBody
                branchName={ props?.branchName}
                location={ props?.location ?? {} }
                closeGoogleMapModal={ props.closeGoogleMapModal }
                onUpdateLocation={ props.onUpdateLocation }
                onUpdateMarkerLocation={ props.onUpdateMarkerLocation }
            />
		</div>
	);
}

export default GoogleMapDrawerFullPage;
