import React from 'react';
import { QbTools, Config } from '../modules/';
import { AnalyticsDisplayLineBarComposedWithPie } from './';
import { useTranslation } from 'react-i18next';

function AnalyticsProxyCustomerCountNoShowCustomerCount(props) {
    const { t } = useTranslation();
    var metricData = props.metricData;
    var sortedData = Object.entries(metricData.timebased?.data || { } );
    sortedData = QbTools.analyticsFilterDailyChart(sortedData, props.durationInfo.key);
    sortedData.sort(function (a, b) {
        return (a[0] < b[0] ? -1 : ( a[0] > b[0] ? 1 : 0 ));
    } );
    const graphDefaults = Config.graphDefaults;

    var data = {
        values: [ ],
    };

    var pieData = {
        values: [
            { value: 0, color: Config.appearance.pieColorsWithBars[0] }, //BLUE
            { value: 0, color: Config.appearance.pieColorsWithBars[3] }, //YELLOW
            { value: 0, color: Config.appearance.pieColorsWithBars[1] }, //RED
            { value: 0, color: Config.appearance.pieColorsWithBars[2] }, //GREEN
        ],
    };

    for (const [key, child] of sortedData) {
        data.values.push( {
            datetime: QbTools.analyticsFormatDatetime(key, props.durationInfo.key, t),
            graph1: child.USZ,
            graph2: child.NS,
        } );
        pieData.values[QbTools.analyticsGraphLimits(child.KSZ * 100, graphDefaults.ServiceLevel)].value++;
    }

    var displayData = {
        mainTitle: 'localizeAnalyticsCustomerCountTitle',
        subTitle: 'localizeAnalyticsNoShowCountTitle',

        graph1Title: 'localizeAnalyticsCustomerCountTitle',

        graph2Title: 'localizeAnalyticsNoShowCountTitle',

        values: data.values,

        colors: [
            { color: Config.graphColors.NoShow[1], max: Config.graphDefaults.NoShow[0] },
            { color: Config.graphColors.NoShow[2], max: Config.graphDefaults.NoShow[1] },
            { color: Config.graphColors.NoShow[3] },
        ],
        colorFunction: (payload) => { return payload.graph2 / payload.graph1 * 100 || 0; },

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'timebased' ], t),
    };

    return (
        <AnalyticsDisplayLineBarComposedWithPie displayData={ displayData } pieData={ pieData } { ...props } />
    );
}

export default AnalyticsProxyCustomerCountNoShowCustomerCount;
