import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

function GraphPie(props) {
    const pieData = props.pieData;
    const values = pieData.values
        .filter(item => (item.value > 0));
    var hasLegend = false;
    if ((values.length > 0) && values[0].hasOwnProperty('legend')) {
        hasLegend = true;
    }

    var sprintf = require('sprintf-js').sprintf;
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ( {
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    } ) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        var label = sprintf('%.1f %%', percent * 100);
        if (values[index].hasOwnProperty('label')) {
            label = values[index].label;
        }

        return (
            <text x={x} y={y} fill="#000000" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                { label }
            </text>
        );
    };

    var legendPayload = [ ];
    if (hasLegend) {
        for (const item of values) {
            legendPayload.push( {
                value: item.legend,
                type: 'rect',
                color: item.color,
            } );
        }
    }

    return (
        <ResponsiveContainer width="100%" height={(props.hasGraphLineBar)? 300 : 500 }>
            <PieChart height={(props.hasGraphLineBar)? 300 : 500 } margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                <Pie
                    isAnimationActive={ false }
                    data={ values }
                    labelLine={ false }
                    label={renderCustomizedLabel}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius="30%"
                    outerRadius="80%"
                    fill="#8884d8"
                >
                    {
                        values.map((entry, index) => <Cell key={ index } fill={ values[index % values.length].color }/>)
                    }
                </Pie>
                { hasLegend ? <Legend className="piechart-legend" layout="vertical" verticalAlign="bottom" align="center" payload={legendPayload} /> : '' }
            </PieChart>
        </ResponsiveContainer>
    );
}

export default GraphPie;
