import React from 'react';
import { MetricDisplayAverageWaitingTime } from './';
import { useTranslation } from 'react-i18next';
import { QbTools } from '../modules';

function MetricProxySrmAverageWaitingTime(props) {
    var metricData = props.metricData;
    const { t } = useTranslation();

    var data = {
        subregName: '',
        subregValue: NaN,
        regName: '',
        regValue: NaN,
        hqName: '',
        hqValue: NaN,
    };

    if (QbTools.metricDataReady(metricData, [ 'current', 'parent', 'top' ] )) {
        data.subregName = Object.keys(metricData.current.data)[0];
        data.subregValue = metricData.current.data[data.subregName]['VI'];
        data.regName = Object.keys(metricData.parent.data)[0];
        data.regValue = metricData.parent.data[data.regName]['VI'];
        data.hqName = Object.keys(metricData.top.data)[0];
        data.hqValue = metricData.top.data[data.hqName]['VI'];
    }

    var displayData = {
        graph1Title: QbTools.apiNameToFriendlyName(data.subregName),
        graph1Value: data.subregValue,

        graph2Title: QbTools.apiNameToFriendlyName(data.regName),
        graph2Value: data.regValue,

        graph3Title: QbTools.apiNameToFriendlyName(data.hqName),
        graph3Value: data.hqValue,

        _state: metricData._state,
        _failed: metricData._failed,
        _requestTime: QbTools.getMetricTiming(metricData, 'request', [ 'current', 'parent', 'top' ], t),
    };

    return (
        <MetricDisplayAverageWaitingTime displayData={ displayData } { ...props } />
    );
}

export default MetricProxySrmAverageWaitingTime;
