import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { QbTools } from '../modules';
import { BsChevronRight } from 'react-icons/bs';

function LegalDocumentsFragment(props) {
    const { t, i18n } = useTranslation();
    const lng = i18n.language;
    const legalData = props.legalData;
    const legal = legalData.data[props.index];
    const pathPrefix = props.pathPrefix || '';

    return (
        <div key={ legal.id }>
            <div className="row help-page-title py-1">
                <div className="col-12">{ QbTools.getByLocale(legalData.localization[legal.name], lng) } ({ legal.version })</div>
            </div>
            <div className="row">
                <div className="col-12 mt-2">{ t('localizeDashboardTypeSummary') }</div>
                <div className="col-12 mt-3">{ QbTools.getByLocale(legalData.localization[legal.overview], lng) }</div>
            </div>

            <Link className="qboard-link" to={ '/' + pathPrefix + 'legal/current/' + legal.id }>
                <div className="row align-items-center py-1 mb-2 setting-item">
                    <div className="col-12 px-3">
                        <div className="row settings-branch-item">
                            <div className="col-10 d-flex align-items-center">
                                { t('mzlegal:localizeLegalOpenDocument') }
                            </div>
                            <div className="col-2 text-right">
                                <BsChevronRight className='icon-without-bg'/>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default LegalDocumentsFragment;
