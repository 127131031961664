//import React, { PropTypes } from 'react';
import React, {useContext, useEffect, useState} from 'react';
import { AppStateContext, Constants, Config } from '../modules';
import QboardLogo from '../img/qboard_logo.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import OfflineContactUsModal from "./OfflineContactUsModal";
import { ImCross } from 'react-icons/im';

function LoginForm( {
    onSubmit,
    onChange,
    onBlur,
    errors,
    user,
    ajaxInProgress,
    authPhase,
} ) {
    
    const [isOfflineContactUsModal,setOfflineContactUsModal]=useState(false)
    
    const appState = useContext(AppStateContext);
    const { t } = useTranslation();
    const {t: webappT} = useTranslation('webapp');
    
    const openOfflineContactUsModal =()=>setOfflineContactUsModal(true)
    const closeOfflineContactUsModal =()=>setOfflineContactUsModal(false)
    
    useEffect( () => {
        appState.setActivePage('login');
    } );

    function onCopy(event) {
        event.preventDefault();
        return false;
    }

    function removeContentUsername(e) {
        e.preventDefault();
        var list = document.getElementsByName('username');
        for (var item of list) {
            item.value = '';
        }
    }
    function removeContentPassword(e) {
        e.preventDefault();
        var list = document.getElementsByName('password');
        for (var item of list) {
            item.value = '';
        }
    }

    const layoutStyle = {
        backgroundColor: Config.appearance.globalBackgroundColor,
    }

    const submitButtonStyle = {
        backgroundColor: Config.appearance.mainColor,
    }

    return (
        <React.Fragment>
            <div className={ 'form-spinner position-fixed justify-content-center align-items-center ' + ( (ajaxInProgress || authPhase === 'hasData') ? 'd-flex' : '' ) }>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Working...</span>
                </div>
            </div>
            <div style={ layoutStyle } className="layout vh-100">
                <div className="container login-form-with-logo h-100 d-flex justify-content-center">
                    <div className="row justify-content-center align-items-center mw-100">
                        <div className="col-md-2 col-0" />
                        <div className="col-md-8 col-10">
                            <img alt="Qboard logo" className="pb-2" width="100%" src={ QboardLogo } />

                            <form action="/" onSubmit={ onSubmit } autoComplete={ Constants.security.loginForm === 'password' ? 'on' : 'off' }>
                                <div className="form-group">
                                    { errors.summary ? <div className="alert alert-danger" role="alert">{ errors.summary }</div> : '' }
                                    { errors.debug ? <div className="alert alert-info" role="alert"><pre style={{ whiteSpace: 'pre-wrap' }}>{ errors.debug }</pre></div> : '' }
                                    <label className="login-lorm-label" htmlFor="username">{ t('localizeLoginUsernamePlaceholder') }</label>
                                    <div className='d-flex input-group align-items-center justify-content-end'>
                                        <input
                                            className={ 'form-control ' + (errors.username ? 'is-invalid' : '') }
                                            type="text"
                                            name="username"
                                            onChange={ onChange }
                                            onBlur={ onBlur }
                                            value={ user.username }
                                            autocapitalize="none"
                                        />
                                        { (Config.appearance.enableClearableInput) && <button className='delete-input' type="reset" defaultValue="Reset" onClick={removeContentUsername} ><ImCross /></button> }
                                        {/* <div className="invalid-feedback">{ errors.username }</div> */}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="login-lorm-label" htmlFor="password">{ t('localizeLoginPasswordPlaceholder') }</label>
                                    <div className='d-flex input-group align-items-center justify-content-end'>
                                        { Constants.security.loginForm === 'password' ? (
                                            <input
                                                className='form-control'
                                                type="password"
                                                name="password"
                                                onChange={ onChange }
                                                onBlur={ onBlur }
                                                value={ user.password }
                                            />
                                        ) : (
                                            <input
                                                className='form-control password-like'
                                                type="text"
                                                name="password"
                                                autoComplete="off"
                                                onCopy={ onCopy }
                                                onChange={ onChange }
                                                onBlur={ onBlur }
                                                value={ user.password }
                                                autocapitalize="none"
                                            />
                                        ) }
                                        { (Config.appearance.enableClearableInput) && <button className='delete-input' type="reset" defaultValue="Reset" onClick={removeContentPassword} ><ImCross /></button> }
                                    </div>
                                </div>

                                <div className="button-line">
                                    <button style={ submitButtonStyle } className="btn btn-primary btn-block login" type="submit">{ t('localizeLoginLoginTitle') }</button>
                                    <Link className="btn btn-secondary btn-block" to="/help">{ t('localizeHelpTitle') }</Link>
                                </div>
                                <div className="c-pointer d-flex justify-content-end mt-2" onClick={openOfflineContactUsModal}>
                                    <span className='btn btn-secondary btn-block'>
                                    {webappT('contactUs')}
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-2 col-0" />
                    </div>
                </div>
            </div>
            <OfflineContactUsModal
              isOpen={isOfflineContactUsModal}
              closeModal={closeOfflineContactUsModal}
            />
        </React.Fragment>
    );
}
/*
LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};
*/
export default LoginForm;
